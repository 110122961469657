import * as React from "react";

import { Box, LinearProgress, LinearProgressProps } from "@mui/material";
import Typography from "@mui/material/Typography";
export default function ChallengeProgressTracker(
  props: LinearProgressProps & {
    value: number;
    currentChallenge: number;
    totalChallenges: number;
    hideLabel?: boolean;
  }
) {
  const { sx, totalChallenges, currentChallenge, hideLabel, ...rest } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",

        ".MuiTypography-root": { color: "white" },
        ...(props.sx ? props.sx : {}),
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...rest} />
      </Box>
      <Box sx={{ display: hideLabel ? "none" : "block", minWidth: 55 }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          {`${currentChallenge} / ${totalChallenges}`}
        </Typography>
      </Box>
    </Box>
  );
}
