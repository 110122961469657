import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Goal } from "lib/progression/slice";
import { Categories } from "lib/card/components/CategoryBadge";

import { createSelector } from "reselect";
import { RootState } from "store";

import defaultState from "./static/app.json";

export interface AppMetaData {
  // Force upgrade
  min: string;
  // Optional upgrade
  current: string;
}

// Define a type for the slice state
export interface Reward {
  type: string;
  amount: number;
  id?: string;
  name?: string;
  rewards?: Reward[];
}

interface LevelScaledReward extends Omit<Reward, "amount"> {
  amount: number[];
}
export interface WeightedReward extends LevelScaledReward {
  weight: number;
}
export interface Level {
  experienceRequired: number;
  rewards: Reward[];
}
interface Rules {
  hitRewards: LevelScaledReward[];
  perfectRewards: LevelScaledReward[];
  completionRewards: LevelScaledReward[];
  bonusRewards: WeightedReward[];
}

export interface ChallengePossibility {
  question: string;
  answer: string;
  id: string;
}

interface WordPossibility {
  challengeLevel: number;
  items: string[];
}
interface NumberPossibility {
  challengeLevel: number;
  items: [number[]];
}
interface Source {
  agilityNumbers: { possibility: NumberPossibility[] };
  agilityWords: {
    possibility: WordPossibility[];
  };
}
interface Challenge {
  rules: Rules;
  source: Source;
}

export type Rarity = 0 | 1 | 2 | 3 | 4;

export interface Unlockable {
  id: string;
  rarity: Rarity;
  name: string;
  type: string;
  categories: Categories[];
  unlock: {
    level?: number;
  };
  description: string;
  author?: string;
  attribution: {
    author: string;
    referralLink: string;
  };
}

export interface Game {
  id: string;
  type: string;
  subtype: string;
  to: string;
  imageSrc: string;
  title: string;
  description: string;
  help: string;
  unlockLevel: number;
  challengeRating: number;
  cost: {
    basic: number;
    premium?: number;
  };
}

export interface Pack {
  id: string;
  cost: { basic?: number; premium?: number };
  name: string;
  description: string;
  amount: number;
}

export interface BasicCurrencyItem {
  id: string;
  name: string;
  description: string;
  amount: number;
  premiumCurrencyPrice: number;
  type: string;
  closedAsset: string;
}

export interface PossibleGoal extends Goal {
  id: string;
  weight: number;
}

export interface Zen {
  xpBoostPercentage: number[];
  rewardTrack: { premiumCost: number; reward: Reward }[];
}

export type AchievementTypes =
  | "discover"
  | "spendBasic"
  | "completeGoal"
  | "gamesPlayed";
export type AchievementRule = {
  rewards: Reward[];
  completeThreshold: number;
};
export interface AppState {
  version: string;
  levels: Level[];
  challenge: Challenge;
  unlockable: {
    items: Unlockable[];
    recycleRarityCurrencyReward: number[];
    unlockRarityExperienceReward: number[];
  };
  goal: {
    freebieRefreshPrice: number;
    unlockLevel: number;
    pool: PossibleGoal[];
  };
  store: {
    fastTrackProgression: {
      xpCost: number;
    };
    packs: Pack[];
    basic: BasicCurrencyItem[];
  };
  games: Game[];
  zen: Zen;
  achievements: {
    items: { [key in AchievementTypes]: AchievementRule[] };
  };
}

// Define the initial state using that type
// const initialState: AppState = {
//   levels: [],
//   challenge: {},
// };

//const initialState = defaultState as AppState;

export const appSlice = createSlice({
  name: "app",
  initialState: {
    levels: [],
    challenge: {
      rules: { hitRewards: [], perfectRewards: [] },
      source: {
        agilityWords: { possibility: [] },
        agilityNumbers: { possibility: [] },
      },
    },
    store: {
      fastTrackProgression: { xpCost: 0 },
      packs: [],
      basic: [],
      premium: [],
    },
    games: [],
    goal: { freebieRefreshPrice: 0, pool: [], unlockLevel: 0 },
    unlockable: {
      items: [],
      recycleRarityCurrencyReward: [],
      unlockRarityExperienceReward: [],
    },
    achievements: { items: { discover: [], spendBasic: [], completeGoal: [] } },
  } as unknown as AppState,
  reducers: {
    setAppData: (state, action: PayloadAction<AppState>) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAppData } = appSlice.actions;

export default appSlice.reducer;

export const getCurrentLevelData = createSelector(
  (state: RootState) => state.app.levels[state.progression.level - 1],
  (level) => level
);
