import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { useAppSelector } from "lib/hooks/react-redux";

import Grid from "@mui/material/Grid";
import BuyButton from "lib/purchase/components/BuyButton";
import GradientText from "lib/typography/components/GradientText";

import { addPayment, createPaymentIntent } from "lib/data/firebase";
import Clickable from "lib/audio/components/Clickable";
import theme from "theme";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { CheckBox } from "@mui/icons-material";
//import payment from "lib/payment/payment";

export default function CheckoutForm() {
  const user = useAppSelector((state) => state.user);
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [savePaymentInfo, setSavePaymentInfo] = React.useState(true);

  React.useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        throw new Error("No payment intent");
      }
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/dashboard`,
        receipt_email: email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(`${error.message}`);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    //layout: "tabs",
  };

  return (
    <form
      id="payment-form"
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "24px",
      }}
      onSubmit={handleSubmit}
    >
      <LinkAuthenticationElement
        id="link-authentication-element"
        options={{ defaultValues: { email: user.email || "" } }}
        onChange={(e) => {
          setEmail(e.value.email);
        }}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {isLoading || !stripe || !elements ? (
        <CircularProgress
          sx={{ alignSelf: "center", margin: "auto", justifySelf: "center" }}
          color="secondary"
        />
      ) : (
        <>
          <Button
            variant="contained"
            sx={{ mt: 4, width: "100%" }}
            disabled={isLoading || !stripe || !elements}
            id="submit"
            type="submit"
          >
            <GradientText sx={{ fontSize: "3em" }}>Pay now</GradientText>
          </Button>
        </>
      )}

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
