import * as React from "react";

import { Box, BoxProps, Card, CardActionArea, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { keyframes } from "@mui/system";
import ChallengeProgressTracker from "lib/agility/components/ChallengeProgressTracker";
import StopWatch from "lib/agility/components/StopWatch";
import FlashCard from "lib/agility/components/FlashCard";
import runAward from "lib/animation/RewardCanvas";
import Game from "lib/game/components/Game";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { chanceRewards } from "lib/progression/poolReward";
import { award, goalActionProgress, useFreebie } from "lib/progression/slice";
import useFreebieListener from "lib/game/useFreebieListener";
import { ChallengePossibility, Reward } from "lib/data/slice";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import generateTokenFromRuleData from "lib/token/generateTokenFromRuleData";
import { grey } from "@mui/material/colors";
import GradientText from "lib/typography/components/GradientText";
import LocalActivityTwoToneIcon from "@mui/icons-material/LocalActivityTwoTone";
import theme from "theme";
export interface AnswerPadProps extends Pick<BoxProps, "sx"> {
  onMousePositionUpdate: ({ x, y }: { x: number; y: number }) => void;
  onUseFreebie: () => void;
  onSelect: (value: string) => void;
  options: string[];
}
export default function AnswerPad({
  onSelect,
  options,
  onUseFreebie,
  onMousePositionUpdate,
  sx,
}: AnswerPadProps) {
  const progression = useAppSelector((state) => state.progression);
  return (
    <Box
      sx={{
        zIndex: -1,
        position: "relative",
        height: "10em",
        width: "60%",

        [theme.breakpoints.down("md")]: { width: "95%" },
        ...sx,
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        justifyContent="flex-start"
      >
        {[...options].map((option) => (
          <Grid
            item
            key={`${option}`}
            sx={{
              flex: "0 0 33%",
              [theme.breakpoints.down("sm")]: {
                flex: "0 0 50%",
              },
            }}
          >
            <Card
              onMouseDown={(ev: React.MouseEvent) => {
                onMousePositionUpdate({ x: ev.clientX, y: ev.clientY });
              }}
              onClick={() => {
                onSelect(option);
              }}
              elevation={2}
              sx={{
                width: "100%",
                height: "100%",
                verticalAlign: "middle",
                cursor: "pointer",

                background: "rgba( 255, 255, 255, 0.25 )",
                boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
                backdropFilter: "blur( 4px )",

                border: "1px solid rgba( 255, 255, 255, 0.58 )",
              }}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <GradientText
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    fontSize: `${
                      5 - (option.length > 6 ? option.length / 5 : 0)
                    }em`,
                    WebkitTextStrokeColor: grey[800],
                    background: `linear-gradient(180deg, ${grey[200]} 35%, transparent)`,
                    [theme.breakpoints.down("md")]: {
                      fontSize: `${
                        3 - (option.length > 6 ? option.length / 7 : 0)
                      }em`,
                    },
                    [theme.breakpoints.down("sm")]: {
                      fontSize: `${
                        4 - (option.length > 6 ? option.length / 5 : 0)
                      }em`,
                      WebkitTextStrokeWidth: option.length > 9 ? "1px" : "2px",
                    },
                  }}
                >
                  {option}
                </GradientText>
              </CardActionArea>
            </Card>
          </Grid>
        ))}

        {progression.freebies > 0 && progression.tutorialStep >= 14 && (
          <Grid
            item
            key={`freebie`}
            sx={{
              flex: "0 0 33%",
              [theme.breakpoints.down("sm")]: {
                flex: "0 0 50%",
              },
            }}
          >
            <Card
              onMouseDown={(ev: React.MouseEvent) => {
                onMousePositionUpdate({ x: ev.clientX, y: ev.clientY });
              }}
              onClick={() => {
                onUseFreebie();
              }}
              elevation={2}
              sx={{
                width: "100%",
                height: "100%",
                cursor: "pointer",

                background: "rgba( 255, 255, 255, 0.25 )",
                boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
                backdropFilter: "blur( 4px )",

                border: "1px solid rgba( 255, 255, 255, 0.58 )",
              }}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    flexDiretion: "column",
                    justifyContent: "center",
                  }}
                >
                  <LocalActivityTwoToneIcon
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pointerEvents: "none",
                      fontSize: "5em",
                      color: "#fafafa",
                      [theme.breakpoints.down("md")]: { fontSize: "3em" },

                      [theme.breakpoints.down("sm")]: { fontSize: "4em" },
                    }}
                  />
                  <Box
                    sx={{
                      fontSize: "0.75em",
                      color: "#fafafa",
                      position: "absolute",
                      left: "50%",
                      bottom: 2,
                      transform: "translateX(-50%)",
                      textShadow:
                        "-1px -1px 3px #222,1px 1px 3px #222,1px -1px 3px #222,-1px 1px 3px #222",
                      zIndex: 2,
                    }}
                  >
                    {progression.freebies}
                  </Box>
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
