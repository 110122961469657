import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "./Modal";
import { Level, Reward } from "lib/data/slice";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import RewardItem from "lib/progression/components/RewardItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { authSignOut } from "lib/data/firebase";
import { useNavigate } from "react-router-dom";
import { setGlobalVolume } from "lib/audio/visualizer";
import { setGlobalVolume as setClickVolume } from "lib/audio/sfx";
import { setGlobalVolume as setParticleVolume } from "lib/animation/RewardCanvas";
import { setMusic, setSFX } from "lib/preferences/slice";
import { Divider, SvgIcon, TextField, useMediaQuery } from "@mui/material";
import RedditIcon from "@mui/icons-material/Reddit";
import DiscordIcon from "lib/social/icon/discord";
import { Box } from "@mui/system";
import UpgradeAccountModal from "./UpgradeAccount";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RedeemableModal from "lib/redeemable/components/RedeemableModal";
import theme from "theme";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { grey } from "@mui/material/colors";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

export interface SettingsModalProps {
  onClose: (ev: any) => void | (() => void);
}

export default function RewardModal({ onClose }: SettingsModalProps) {
  const [isUpgradingAccount, setIsUpgradingAccount] = React.useState(false);
  const [renderConfirmSignout, setRenderConfirmSignout] = React.useState(false);
  const [showAppVersion, setShowAppVersion] = React.useState(true);
  const preferences = useAppSelector((state) => state.preferences);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const appVersion = useAppSelector((state) => state.app.version);
  const [code, setCode] = React.useState("");
  const [attemptCode, setAttemptCode] = React.useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isFullScreen, setIsFullScreen] = React.useState(
    document.fullscreenElement ? true : false
  );

  if (isUpgradingAccount) {
    return (
      <UpgradeAccountModal
        onClose={(ev) => {
          if (ev.type === "click") {
            onClose(ev);
          }
        }}
      />
    );
  }
  if (renderConfirmSignout) {
    return (
      <Modal
        onClose={onClose}
        dialogTitle="Hold on!"
        dialogBodyContent={
          <>
            If you sign out now, you will lose all access to your account.
            <Divider component="div" sx={{ mb: 4 }} role="presentation" />
            Consider
            <Button
              sx={{ display: "flex", whiteSpace: "nowrap" }}
              onClick={() => {
                setIsUpgradingAccount(true);
              }}
              variant="outlined"
              startIcon={<UpgradeIcon />}
            >
              {" "}
              Linking your account
            </Button>{" "}
            to an email address so you don't lose your progress.
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
              <Button
                onClick={() => {
                  setRenderConfirmSignout(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="error"
                variant="contained"
                startIcon={<LayersClearIcon />}
                onClick={() => {
                  authSignOut();
                  navigate("/", { replace: true });
                }}
              >
                Sign Out
              </Button>
            </Box>
          </>
        }
      />
    );
  }
  return (
    <Modal
      onClose={onClose}
      dialogTitle={"Settings"}
      dialogProps={{
        fullWidth: true,
        maxWidth: "sm",
        sx: {
          whiteSpace: "nowrap",
          [theme.breakpoints.down("md")]: {
            "& .MuiPaper-root": {
              margin: "0",
              width: "100%",
            },
          },
        },
      }}
      dialogBodyContent={
        <>
          <Grid
            justifyContent="space-evenly"
            direction="column"
            alignItems="center"
            container
            spacing={isMobile ? 1 : 4}
          >
            <Grid
              justifyContent="flex-start"
              direction="row"
              alignItems="center"
              container
              item
              spacing={isMobile ? 1 : 4}
            >
              <Grid item xs>
                <Tooltip title="help@thrivemind.app">
                  <Button
                    fullWidth
                    href={`mailto:help@thrivemind.app?subject=${user.uid}`}
                    variant="outlined"
                    startIcon={<ContactSupportIcon />}
                  >
                    Contact Support
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={preferences.sfx}
                      onChange={(ev, value) => {
                        setParticleVolume(!value ? 0 : 1);
                        setClickVolume(!value ? 0 : 1);
                        dispatch(setSFX(value));
                      }}
                    />
                  }
                  label="Sound effects"
                />
              </Grid>
            </Grid>
            <Grid
              justifyContent="flex-start"
              direction="row"
              alignItems="center"
              container
              item
              spacing={4}
            >
              {user.isAnonymous ? (
                <Grid item xs>
                  <Button
                    fullWidth
                    onClick={() => {
                      setIsUpgradingAccount(true);
                    }}
                    variant="outlined"
                    startIcon={<UpgradeIcon />}
                  >
                    Link your account
                  </Button>
                </Grid>
              ) : (
                <Grid item xs>
                  <Button fullWidth disabled variant="outlined">
                    Account linked
                  </Button>
                </Grid>
              )}
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={preferences.music}
                      onChange={(ev, value) => {
                        setGlobalVolume(!value ? 0 : 0.5);
                        dispatch(setMusic(value));
                      }}
                    />
                  }
                  label="Music"
                />
              </Grid>
            </Grid>
            <Grid
              justifyContent="flex-start"
              direction="row"
              alignItems="center"
              container
              item
              spacing={isMobile ? 1 : 4}
            >
              <Grid item xs>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ fontFamily: "Sriracha" }}
                  href="/privacy.html"
                  target="_blank"
                >
                  Privacy Policy
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ fontFamily: "Sriracha" }}
                  href="/terms.html"
                  target="_blank"
                >
                  Terms & Conditions
                </Button>
              </Grid>
            </Grid>
            <Grid
              justifyContent="flex-start"
              direction="row"
              alignItems="center"
              container
              item
              spacing={isMobile ? 1 : 4}
              component={"form"}
              onSubmit={(ev: any) => {
                ev.preventDefault();
                setAttemptCode(code);
              }}
            >
              <Grid item xs>
                <TextField
                  variant="outlined"
                  value={code}
                  placeholder="Enter code"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "7.5px 14px",
                    },
                  }}
                  onChange={(ev) => {
                    setCode(ev.target.value);
                  }}
                />
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  type="submit"
                  variant="outlined"
                  sx={{ fontFamily: "Sriracha" }}
                >
                  Submit Code
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              justifyContent="flex-start"
              direction="row"
              alignItems="center"
              container
              spacing={isMobile ? 1 : 4}
            >
              <Grid item xs>
                <Button
                  fullWidth
                  href=""
                  startIcon={
                    isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />
                  }
                  onClick={() => {
                    const elem = document.documentElement;
                    if (isFullScreen) {
                      setIsFullScreen(false);
                      document.exitFullscreen();
                    } else {
                      setIsFullScreen(true);
                      if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                      }
                      // else if (elem.webkitRequestFullscreen) {
                      //   /* Safari */
                      //   elem.webkitRequestFullscreen();
                      // } else if (elem.msRequestFullscreen) {
                      //   /* IE11 */
                      //   elem.msRequestFullscreen();
                      // }
                    }
                  }}
                  target="_blank"
                  variant="outlined"
                >
                  {isFullScreen && "Exit "}Full Screen
                </Button>
              </Grid>
              {/* <Grid item xs>
                <Button
                  fullWidth
                  onClick={() => {
                    if (user.isAnonymous) {
                      setRenderConfirmSignout(true);
                    } else {
                      authSignOut();
                      navigate("/", { replace: true });
                    }
                  }}
                  variant="contained"
                  startIcon={<ExitToAppIcon />}
                >
                  Sign Out
                </Button>
              </Grid> */}
            </Grid>
            <Grid
              item
              justifyContent="flex-start"
              direction="row"
              alignItems="center"
              container
              spacing={isMobile ? 1 : 4}
            >
              <Grid item xs>
                <Button
                  fullWidth
                  href="/credits.html"
                  target="_blank"
                  variant="outlined"
                >
                  Credits
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  onClick={() => {
                    if (user.isAnonymous) {
                      setRenderConfirmSignout(true);
                    } else {
                      authSignOut();
                      navigate("/", { replace: true });
                    }
                  }}
                  variant="contained"
                  startIcon={<ExitToAppIcon />}
                >
                  Sign Out
                </Button>
              </Grid>
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              Join the Community
              <Divider component="div" role="presentation"></Divider>
              <Box sx={{ display: "flex", gap: 8, justifyContent: "center" }}>
                <Button
                  href="https://discord.gg/E9zAtAqAJS"
                  target="_blank"
                  startIcon={<DiscordIcon />}
                >
                  Discord
                </Button>
                <Button
                  href="https://reddit.com/r/thrivemind"
                  target="_blank"
                  startIcon={<RedditIcon />}
                >
                  Reddit
                </Button>
              </Box>
            </Grid>{" "}
            {showAppVersion ? (
              <Box
                onClick={() => {
                  setShowAppVersion(false);
                  navigator.clipboard.writeText(user.uid);
                  window.setTimeout(() => {
                    setShowAppVersion(true);
                  }, 1000);
                }}
                sx={{
                  color: grey[500],
                  position: "absolute",
                  bottom: 4,
                  letterSpacing: "2px",
                  fontSize: "0.8em",
                }}
              >
                {appVersion}
              </Box>
            ) : (
              <Box
                sx={{
                  color: grey[500],
                  position: "absolute",
                  bottom: 4,
                  letterSpacing: "2px",
                  fontSize: "0.8em",
                  "@keyframes SettingsUserIdFadeOut": {
                    "0%": {
                      opacity: 1,
                    },
                    "100%": {
                      opacity: 0,
                    },
                  },
                  animation: "SettingsUserIdFadeOut 1s ease-in",
                }}
              >
                Copied User ID to clipboard
              </Box>
            )}
          </Grid>

          {attemptCode && (
            <RedeemableModal
              code={code}
              onClose={(ev: any) => {
                setAttemptCode("");
                setCode("");
                if (ev.success) {
                  onClose(ev);
                }
              }}
            />
          )}
        </>
      }
    />
  );
}
