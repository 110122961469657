import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import React from "react";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Level, Reward } from "lib/data/slice";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { grey, green, blue, purple, orange } from "@mui/material/colors";
import GradientText from "lib/typography/components/GradientText";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { pushModal } from "lib/modal/slice";
import AvatarGroup from "@mui/material/AvatarGroup";
import CategoryBadge, { Categories } from "lib/card/components/CategoryBadge";
import { Avatar, Box } from "@mui/material";
import { SxProps } from "@mui/material";
import playSound from "lib/audio/sfx";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
interface RewardItemProps extends Reward {
  color?: string;
  dark?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  verbose?: boolean;
  sx?: SxProps;
  sound?: string;
}
export default function RewardItem({
  type,
  id,
  amount,
  dark,
  name,
  sx,
  onClick,
  color = "#89023E",
  verbose = false,
  sound = "item-found",
}: RewardItemProps) {
  const unlockables = useAppSelector((state) => state.app.unlockable);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (sx && "animationDelay" in sx) {
      // @ts-ignore
      const seconds = Math.round(parseFloat(sx["animationDelay"]) * 100) / 100;

      window.setTimeout(() => {
        playSound(sound);
      }, seconds * 1000);
    }
    if (sx && "animation" in sx && !("animationDelay" in sx)) {
      playSound(sound);
    }
  }, []);
  switch (type) {
    case "basic_currency":
      return (
        <GradientText
          dark={dark}
          sx={{
            color,
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            ...sx,
          }}
        >
          <AcUnitIcon
            color={dark ? "primary" : "secondary"}
            sx={{
              marginLeft: 1,
              marginRight: -0.1,
              // @ts-ignore
              color: sx?.color ? sx.color : "inherit",
              //stroke: dark ? "#FFD700" : "#89023E",
            }}
            fontSize="large"
          />
          {amount.toLocaleString("en-US")} {verbose ? "Shards" : ""}
        </GradientText>
      );
      break;
    case "premium_currency":
      return (
        <GradientText
          dark={dark}
          sx={{
            color,
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            ...sx,
          }}
        >
          <PremiumCurrencyIcon
            color={dark ? "primary" : "secondary"}
            sx={{
              marginLeft: 1,
              marginRight: -0.1,
              // @ts-ignore
              color: sx?.color ? sx.color : dark ? "primary" : "secondary",
              //stroke: dark ? "#FFD700" : "#89023E",
              //strokeWidth: "0.4px",
            }}
            fontSize="large"
          />
          {amount.toLocaleString("en-US")} {verbose ? "Strands" : ""}
        </GradientText>
      );
      break;
    case "background":
      const unlockable = unlockables.items.find((item) => item.id === id) ?? {
        name: "",
        rarity: 0,
      };
      let border = `3px solid ${grey[400]}`;
      switch (unlockable.rarity) {
        case 1:
          border = `3px solid ${green[400]}`;
          break;
        case 2:
          border = `3px solid ${blue[400]}`;
          break;
        case 3:
          border = `3px solid ${purple[400]}`;
          break;
        case 4:
          border = `4px solid ${orange[400]}`;
          break;
      }
      return (
        <Tooltip title={unlockable.name}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 0,
              marginLeft: 1,
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                opacity: 0,
                borderRadius: "100%",
                boxShadow: `0 0 9px 10px rgba(255, 167, 38, 0.5)`,
                "@keyframes RewardItemLegendaryGlow": {
                  from: {
                    opacity: 0,
                  },
                  to: {
                    opacity: 1,
                  },
                },
                animation:
                  unlockable.rarity === 4
                    ? "RewardItemLegendaryGlow 1.2s infinite alternate"
                    : "none",
              },
              ...sx,
            }}
            onClick={
              onClick
                ? onClick
                : () => {
                    dispatch(
                      pushModal({
                        componentName: "UnlockableDetails",
                        props: { unlockable },
                      })
                    );
                  }
            }
          >
            <Avatar
              sx={{
                // @ts-ignore
                width: sx?.width ? sx.width : 50,
                // @ts-ignore
                height: sx?.height ? sx.height : 50,
                marginRight: amount > 1 ? 1 : 0,
                border,
                "@keyframes rewardItemUnlockableEntrance": {
                  "0%": {
                    transform: "scale(0.4) translateY(5em)",
                    borderRadius: "100%",
                    opacity: 0,
                  },
                  "50%": {
                    transform: "translateY(0)",
                    borderRadius: "initial",
                    opacity: 1,
                  },
                  "100%": {
                    transform: "scale(1) translateY(0)",
                  },
                },
                animation: "rewardItemUnlockableEntrance 1s forwards",
              }}
              src={`/assets/unlockable/thumbnail/${id}.jpg`}
            />
            {amount > 1 ? `x${amount}` : ""}
          </Typography>
        </Tooltip>
      );
    case "experience":
      return (
        <GradientText
          dark={dark}
          sx={{
            color,
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            ...sx,
          }}
        >
          <AutoAwesomeIcon
            color={dark ? "primary" : "secondary"}
            sx={{
              marginLeft: 1,
              marginRight: -0.1,
              // @ts-ignore
              color: sx?.color ? sx.color : "inherit",
              //stroke: dark ? "#FFD700" : "#89023E",
            }}
            fontSize="large"
          />
          {amount.toLocaleString("en-US")} {verbose ? "Experience" : ""}
        </GradientText>
      );

      break;
    case "zen_experience":
      return (
        <GradientText
          dark={dark}
          sx={{
            color,
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            ...sx,
          }}
        >
          <SelfImprovementIcon
            color={dark ? "primary" : "secondary"}
            sx={{
              marginLeft: 1,
              marginRight: -0.1,
              // @ts-ignore
              color: sx?.color ? sx.color : "inherit",
              //stroke: dark ? "#FFD700" : "#89023E",
            }}
            fontSize="large"
          />
          {amount.toLocaleString("en-US")} {verbose ? "Zen" : ""}
        </GradientText>
      );
      break;
    case "pack":
      return (
        <Tooltip title={`${name}`}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 0,
              alignItems: "center",
              justifyContent: "center",
              ...sx,
            }}
          >
            <img
              style={{
                maxWidth: 50,
              }}
              src={`/assets/pack_back.png`}
            />
          </Typography>
        </Tooltip>
      );
    case "freebies":
      return (
        <GradientText
          dark={dark}
          sx={{
            color,
            verticalAlign: "middle",
            display: "flex",
            ...sx,
          }}
        >
          <LocalActivityIcon
            color={dark ? "primary" : "secondary"}
            sx={{
              marginLeft: 1,
              marginTop: 0.8,
              marginRight: -0.1,
              //stroke: dark ? "#FFD700" : "#89023E",
            }}
            fontSize="large"
          />
          {amount.toLocaleString("en-US")}
        </GradientText>
      );
    default:
      if (type.indexOf("token_currency") > -1) {
        const category = type.split("_")[2] as Categories;
        return (
          <Box sx={{ display: "flex", ...sx }}>
            <CategoryBadge name={category} size="medium" />
            {amount > 1 && (
              <GradientText
                dark={dark}
                sx={{
                  color,
                  verticalAlign: "middle",
                  display: "flex",
                  marginLeft: "0.2em",
                }}
              >
                {amount.toLocaleString("en-US")}
              </GradientText>
            )}
          </Box>
        );
      }
      return <div>Unknown item {type}</div>;
  }
}
