import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#89023E",
    },
    secondary: {
      main: "#FFD700",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: ["Sriracha", "cursive"].join(","),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // overrides tooltip
        },
        popper: {
          // overrides the popper
          zIndex: 10000 + "!important",
        },
      },
    },
  },
});

export default theme;
