import Grid, { GridProps } from "@mui/material/Grid";
import Paper, { PaperProps } from "@mui/material/Paper";
import * as React from "react";

import { AcUnit } from "@mui/icons-material";
import { IconButton, styled, useMediaQuery } from "@mui/material";
import runAward from "lib/animation/RewardCanvas";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { addBasicCurrency } from "lib/progression/slice";
import GradientText from "lib/typography/components/GradientText";
import { useNavigate } from "react-router-dom";

import Box from "@mui/system/Box";

import LastGamePanel from "lib/dashboard/components/LastGamePanel";
import { requestNotificationPermission } from "lib/data/firebase";
import { pushModal } from "lib/modal/slice";
import BuyButton from "lib/purchase/components/BuyButton";
import theme from "theme";
import AchievementTile from "lib/achievement/components/AchievementTile";
import { forwardRef } from "react";

type TileType =
  | "freeBasicCurrency"
  | "spinWheel"
  | "lastGame"
  | "subscribeNotifications"
  | "achievements"
  | "claimableConsumables";

const StyledImage = styled("img")``;
interface LiveTileProps extends PaperProps {
  paperRef: PaperProps["ref"];
}
function LiveTile({ sx, paperRef }: LiveTileProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const news = useAppSelector((state) => state.news);
  const lastGame = useAppSelector((state) => state.navigation.lastGame);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const fullState = useAppSelector((state) => state);
  const hasClaimableConsumableThreshold =
    useAppSelector((state) =>
      Object.values(state.progression.consumables).filter(
        (c) => c.hasConsumed === false
      )
    ).length > 4;

  const [canClaimFreeBasicCurrency, setCanClaimFreeBasicCurrency] =
    React.useState(progression.currency.basic < 100);
  const canClaimDailyBonus =
    Date.now() - 60 * 60 * 24 * 1000 >
    new Date(progression.dailyBonus.lastClaimed).getTime();
  let tileType: TileType = canClaimFreeBasicCurrency
    ? "freeBasicCurrency"
    : "achievements";

  // const [shouldShowNotificationCTA, setShouldShowNotificationCTA] =
  //   React.useState(Notification.permission !== "granted");
  if (canClaimDailyBonus) {
    tileType = "spinWheel";
  } else if (
    hasClaimableConsumableThreshold &&
    progression.tutorialStep >= 14
  ) {
    tileType = "claimableConsumables";
  }

  let onClick;
  let title;
  let image;
  let buttonCTA;

  let content: React.ReactNode = "";
  switch (tileType) {
    case "achievements":
      return <AchievementTile />;
      break;
    // case "subscribeNotifications":
    //   onClick = () => {
    //     requestNotificationPermission();
    //     setShouldShowNotificationCTA(false);
    //   };
    //   title = "Allow Notifications";
    //   image = "assets/hero/notifications.png";
    //   buttonCTA = "Accept";

    //   break;
    case "claimableConsumables":
      onClick = () => {
        const unlockables = fullState.app.unlockable.items.filter(
          (u) => u.id in progression.consumables
        );
        const rewards = [];
        for (let unlockable of unlockables) {
          if (!progression.consumables[unlockable.id].hasConsumed) {
            rewards.push({
              type: "experience",
              amount:
                fullState.app.unlockable.unlockRarityExperienceReward[
                  unlockable?.rarity ?? 0
                ],
              id: unlockable.id,
            });
          }
        }
        dispatch(
          pushModal({
            componentName: "UnlockableDetails",
            props: {
              rewards: rewards,
              unlockable: unlockables,
            },
          })
        );
      };
      title = "New Cards Discovered";
      image = "assets/hero/claimable-consumables.png";
      buttonCTA = "Collect";

      break;
    case "freeBasicCurrency":
      return (
        <IconButton
          disabled={!canClaimFreeBasicCurrency}
          sx={{
            width: "100%",
            height: "100%",
          }}
          onClick={() => {
            if (canClaimFreeBasicCurrency) {
              const amount = Math.floor(Math.random() * 5 + 1);
              runAward(
                amount,
                "/assets/particle/basic_currency_particle.svg",
                undefined,
                undefined,
                undefined,
                isMobile
              );
              dispatch(addBasicCurrency(amount));
              setCanClaimFreeBasicCurrency(false);
              window.setTimeout(() => {
                setCanClaimFreeBasicCurrency(true);
              }, 1000);
            }
          }}
        >
          <AcUnit
            sx={{
              width: "100%",
              height: "100%",
              opacity: canClaimFreeBasicCurrency ? 1 : 0.5,
            }}
            color={"secondary"}
          />
        </IconButton>
      );
      break;
    case "spinWheel":
      onClick = () => {
        navigate("/daily");
      };
      title = "Free Spin Ready";
      image = "assets/hero/spin-wheel.png";
      buttonCTA = "Feeling Lucky?";

      break;
  }

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: "pointer",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "space-between",
          // gap: 2,
        },
      }}
    >
      <GradientText
        sx={{
          WebkitTextStrokeWidth: "0.5px",
          fontSize: "clamp(1rem, 10vw, 1.4em)",
          whiteSpace: "nowrap",
          textAlign: "center",
          padding: 2,
          [theme.breakpoints.down("md")]: {
            padding: 0,
            fontSize: "clamp(0.8rem, 5vw, 1em)",
          },
        }}
      >
        {title}
      </GradientText>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flex: "0 0 60%",
          maxHeight: "60%",
        }}
      >
        <StyledImage
          src={image}
          sx={{
            background: "radial-gradient(white, transparent 75%)",
            maxHeight: "100%",
            width: "100%",
            objectFit: "cover",
            [theme.breakpoints.down("md")]: { maxHeight: "4em" },
          }}
        />
      </Box>

      <BuyButton
        color="secondary"
        variant="contained"
        sx={{
          //display: tileType === "news" ? "none" : "block",

          opacity: 0,
          width: "100%",
          borderRadius: "0 0 32px 32px",
          fontSize: isMobile ? "clamp(0.8rem, 4vw, 1em)" : "1.4em",
          padding: 0,
          letterSpacing: isMobile ? "1px" : "4px",

          ".MuiButton-root": {
            marginTop: "auto",
          },
        }}
        label={buttonCTA}
      ></BuyButton>
    </Box>
  );
}

const LiveTileWithRef = forwardRef(
  (props: PaperProps, ref: PaperProps["ref"]) => (
    <LiveTile {...props} paperRef={ref} />
  )
);
export default LiveTileWithRef;
