import { shuffleArray } from "lib/utils/array";
import store from "store";
// @ts-ignore
import rwc from "random-weighted-choice";
import { Unlockable } from "lib/data/slice";
import { PossibleGoal } from "lib/data/slice";
import { iconsByCategoryName } from "lib/card/components/CategoryBadge";

export const generateGoal = (): PossibleGoal => {
  const progression = store.getState().progression;
  const elligibleGoals = store.getState().app.goal.pool;

  //const tokenGoals =

  let goalId = rwc(elligibleGoals);
  let goal = {
    ...(store
      .getState()
      .app.goal.pool.find((goal: any) => goal.id === goalId) as PossibleGoal),
  };
  if (goal.type === "exchangeTokens") {
    const possibleCategories = Object.keys(iconsByCategoryName);
    goal.requirementMeta =
      possibleCategories[(possibleCategories.length * Math.random()) << 0];
    goal.rewards.map((reward) => ({
      ...reward,
      amount: reward.amount * progression.level,
    }));
    goal.progress =
      progression.currency.token[
        goal.requirementMeta as keyof typeof iconsByCategoryName
      ];
    goal.requiredAmount = goal.requiredAmount * progression.level;
  }
  return goal;
};
