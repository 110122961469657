import * as React from "react";
import { signIn } from "lib/data/firebase";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import GradientText from "lib/typography/components/GradientText";
import Clickable from "lib/audio/components/Clickable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Paper } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "theme";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: ["Sriracha", "cursive"].join(","),
  },
});
interface State {
  password: string;
  showPassword: boolean;
}
export default function SignInPage() {
  const navigate = useNavigate();
  const [values, setValues] = React.useState<State>({
    password: "",
    showPassword: false,
  });
  const [error, setError] = React.useState<string | undefined>();
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={async (ev) => {
          setError(undefined);
          ev.preventDefault();
          const formData = new FormData(ev.target as HTMLFormElement);
          const entries = formData.entries();
          const payload: any = {};
          for (const pair of entries) {
            payload[pair[0]] = pair[1];
          }

          try {
            const response = await signIn({
              email: payload.email,
              password: payload.password,
            });
            navigate("/dashboard");
          } catch (e: any) {
            switch (e.code) {
              case "auth/user-not-found":
                setError("We could not find an acocunt with that email");
                break;
              case "auth/wrong-password":
                setError("Your password is incorrect");
                break;
              default:
                setError("An unexpected error has occurred");
                break;
            }
          }
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            background: "rgba( 255, 255, 255, 0.25 )",
            boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
            backdropFilter: "blur( 4px )",
            justifyContent: "center",
            alignItems: "center",
            borderBottomRightRadius: "40px",
            borderBottomLeftRadius: "40px",
          }}
        >
          <ThemeProvider theme={darkTheme}>
            <TextField
              variant="standard"
              label="Email Address"
              id="outlined-start-adornment"
              sx={{ margin: "8px 32px", width: "25ch" }}
              name="email"
            />
            <FormControl
              sx={{ margin: "8px 32px", width: "25ch" }}
              variant="standard"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <Input
                name="password"
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <Clickable>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </Clickable>
                  </InputAdornment>
                }
              />
            </FormControl>{" "}
            {error && (
              <Paper sx={{ display: "flex", width: "100%" }}>
                <Alert
                  severity="error"
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  <AlertTitle>Error Signing In</AlertTitle>
                  {error}
                </Alert>
              </Paper>
            )}
          </ThemeProvider>
          <Clickable>
            <Button
              onClick={() => {
                // onClose();
                // navigate(game.to);
              }}
              size="large"
              sx={{
                fontFamily: "'Sriracha', cursive",
                marginLeft: "auto",
                letterSpacing: "6px",
                width: "100%",
                marginTop: "auto",
                borderRadius: "0 0 40px 40px",
                [theme.breakpoints.down("md")]: {
                  marginTop: "auto",
                },
              }}
              autoFocus
              variant="contained"
              color="primary"
              type="submit"
            >
              <GradientText
                sx={{
                  fontSize: "clamp(1.2em, 6vw, 3em)",
                  WebkitTextStrokeColor: "#FFD700",

                  background:
                    "linear-gradient(180deg, #FFD700 35%, transparent)",
                }}
              >
                Sign In
              </GradientText>
            </Button>
          </Clickable>
        </Paper>
      </form>
    </>
  );
}
