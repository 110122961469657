import * as React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import theme from "./theme";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { NotificationsTwoTone } from "@mui/icons-material";
import { requestNotificationPermission } from "lib/data/firebase";
import { pushModal, resetModals } from "lib/modal/slice";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
let ErrorBoundary;
if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
  });
  ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);
} else {
  ErrorBoundary = React.Fragment;
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./asset-caching-sw.js", { scope: "./" })
    .then(function () {
      console.log("Service Worker Registered");
    })
    .catch(function (err) {
      console.log("Service Worker Failed to Register", err);
    });
}

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />

            <App />
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

serviceWorkerRegistration.register();

if (Notification.permission === "granted") {
  console.log("Notification permission granted, requesting gcm");
  requestNotificationPermission();
}

const bc = new BroadcastChannel("thrivemind");

bc.onmessage = (event) => {
  if (event.data === `first-tab`) {
    bc.postMessage(`No you're not.`);
    store.dispatch(
      pushModal({
        componentName: "FatalError",
        props: {
          title: "Tab out of date!",
          type: "OldTab",
          body: "This tab is out of date, either refresh the page or go to the latest tab running ThriveMind.",
        },
      })
    );
  }
  if (event.data === `No you're not.`) {
    //alert(`An instance of this site is already running`);
  }
};

bc.postMessage(`first-tab`);

if (process.env.REACT_APP_GRACEFUL_ERROR_HANDLING) {
  // window.onerror = function myErrorHandler(...details) {
  //   store.dispatch(resetModals());
  //   store.dispatch(
  //     pushModal({
  //       componentName: "FatalError",
  //       props: {
  //         title: "Whoops",
  //         type: "UncaughtError",
  //         body: "An unexpected error occurred, please refresh the page and try again later.",
  //       },
  //     })
  //   );
  //   return false;
  // };
}
