import * as React from "react";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { useFreebie } from "lib/progression/slice";

export default function useAnimationEffect(
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) {
  const canAnimate = useAppSelector((state) => state.navigation.canAnimate);
  deps = deps || [];
  React.useEffect(() => {
    if (canAnimate) {
      effect();
    }
  }, [canAnimate, ...deps]);
}
