import * as React from "react";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Box, styled, Typography, useMediaQuery } from "@mui/material";
import { grey } from "@mui/material/colors";
import useAnimationEffect from "lib/animation/useAnimationEffect";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { AchievementTypes } from "lib/data/slice";
import GradientText from "lib/typography/components/GradientText";
import { animate, easeIn } from "popmotion";
import theme from "theme";

import AcUnitIcon from "@mui/icons-material/AcUnit";
import FilterIcon from "@mui/icons-material/Filter";
import SportsScoreIcon from "@mui/icons-material/SportsScoreOutlined";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { pushModal } from "lib/modal/slice";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "hsl(51deg 100% 20%)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "hsl(51deg 100% 50%)",
  },
  [theme.breakpoints.down("md")]: { height: 8 },
}));
interface AchievementState {
  type: AchievementTypes;
  progress: number;
  completeThreshold: number;
}

interface BorderLinearProgressWithLabelProps extends LinearProgressProps {
  children: React.ReactNode;
}
const BorderLinearProgressWithLabel = (
  props: BorderLinearProgressWithLabelProps
) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        textShadow:
          "1px 1px 1px hsl(51deg 100% 20%), -1px 1px 1px hsl(51deg 100% 20%), -1px -1px 1px hsl(51deg 100% 20%), 1px -1px 1px hsl(51deg 100% 20%)",
      }}
    >
      <BorderLinearProgress {...props} />

      <Box
        sx={{
          position: "absolute",
          color: "hsl(51deg 100% 50%)",
          left: "50%",
          transform: "translateX(-50%)",
          [theme.breakpoints.down("md")]: { fontSize: "0.6em" },
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
export default function ZenTile() {
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const gameData = useAppSelector((state) => state.app);

  const [hasLoaded, setHasLoaded] = React.useState(false);

  const discoverProgress = Object.keys(progression.unlocks).length;

  const achievements: AchievementState[] = Object.entries(
    gameData.achievements.items
  ).map(([achievementType, achievementData]) => {
    const type = achievementType as AchievementTypes;
    let progress = 0;
    let completeThreshold;
    switch (type) {
      case "discover":
        progress = Object.keys(progression.unlocks).length;

        break;
      case "spendBasic":
        progress = progression.currency.basicSpent;
        break;
      case "completeGoal":
        progress = progression.goals.completeAmount;
        break;
    }

    completeThreshold =
      achievementData[progression.achievements.progress[type]]
        ?.completeThreshold ?? 9999999;

    return {
      type,
      progress,
      completeThreshold,
    };
  });

  //   [
  //     {
  //       type: "discover",
  //       progress: Math.min(discoverProgress, 10),
  //       completeThreshold: 10,
  //     },
  //     { type: "spendBasic", progress: 0, completeThreshold: 10 },
  //     { type: "completeGoal", progress: 0, completeThreshold: 10 },
  //   ];

  useAnimationEffect(() => {
    const duration = 1500;

    window.setTimeout(() => {
      animate({
        from: progression.zen.lastSeenExperience,
        to: progression.zen.experience,
        duration,

        ease: easeIn,
        //type: "spring",
        onUpdate: (latest: number) => {
          //playSound("zen-experience-gain");
        },
        onComplete: () => {
          setHasLoaded(true);
        },
      });
    }, 100);
  }, [progression.zen.experience]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      onClick={() => {
        let rewards = [];
        let achievementsWithRewards = [];
        for (let achievement of achievements) {
          if (achievement.progress >= achievement.completeThreshold) {
            rewards.push(
              ...gameData.achievements.items[achievement.type][
                progression.achievements.progress[achievement.type]
              ].rewards
            );
            achievementsWithRewards.push(achievement.type);
          }
        }

        if (rewards.length > 0) {
          dispatch(
            pushModal({
              componentName: "Reward",
              props: {
                rewards,
                source: `achievements:${achievementsWithRewards.join(",")}`,
              },
            })
          );
        }
      }}
      className="AchievementTile"
      sx={{
        cursor: "pointer",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingLeft: 2,
        paddingRight: 2,
        gap: 3,
        [theme.breakpoints.down("md")]: {
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "space-between",
          gap: 1,
        },
      }}
    >
      <GradientText
        sx={{
          fontSize: "2em",
          whiteSpace: "nowrap",
          textAlign: "center",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "100%",
          "&:before": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "0",
            background: "linear-gradient(#222 5%,#FFD700 5%, transparent 100%)",
          },
          [theme.breakpoints.down("md")]: {
            padding: 0,
            fontSize: "clamp(0.8rem, 5vw, 1em)",
            WebkitTextStrokeWidth: "0.5px",
          },
        }}
      >
        <EmojiEventsIcon
          color="secondary"
          sx={{
            fontSize: "1em",
            background: `radial-gradient(#22222225,transparent 80%)`,

            // stroke: "#FFD700",
            // strokeWidth: "1px",

            // fill: `url(#GameIconGradientFill)`,
          }}
        />
        Achievements
      </GradientText>
      {achievements.map((achievement) => {
        let title;
        let AchievementIcon = FilterIcon;
        const barValue =
          achievement.progress >= achievement.completeThreshold
            ? 100
            : (achievement.progress / achievement.completeThreshold) * 100;
        const borderLabel =
          achievement.progress >= achievement.completeThreshold
            ? `Click to Claim`
            : `${achievement.progress} / ${achievement.completeThreshold}`;
        switch (achievement.type) {
          case "discover":
            title = isMobile ? "Discover Cards" : "Discover New Cards";
            AchievementIcon = FilterIcon;
            break;
          case "completeGoal":
            title = "Complete Goals";
            AchievementIcon = SportsScoreIcon;
            break;
          case "spendBasic":
            title = "Spend Shards";
            AchievementIcon = AcUnitIcon;
            break;
        }
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: grey[50],
                fontSize: "1em",
                letterSpacing: "2px",
                gap: 1,
                whiteSpace: "nowrap",
                [theme.breakpoints.down("md")]: { fontSize: "0.6em" },
              }}
            >
              <AchievementIcon
                color="secondary"
                sx={{ fontSize: "1.1em" }}
              ></AchievementIcon>
              {title}
            </Typography>
            <BorderLinearProgressWithLabel
              sx={{
                width: "100%",
                alignSelf: "stretch",
                justifySelf: "stretch",
              }}
              variant="determinate"
              value={barValue}
            >
              {borderLabel}
            </BorderLinearProgressWithLabel>
          </Box>
        );
      })}
    </Box>
  );
}
