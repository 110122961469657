import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "lib/data/slice";
import modalSlice from "lib/modal/slice";
import navigationSlice from "lib/navigation/slice";
import progressionSlice from "lib/progression/slice";
import paymentSlice from "lib/payment/slice";
import userSlice from "lib/user/slice";
import preferencesSlice from "lib/preferences/slice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import newsSlice from "lib/welcome-news/slice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["preferences"],
};
const rootReducer = combineReducers({
  progression: progressionSlice,
  modal: modalSlice,
  app: appSlice,
  navigation: navigationSlice,
  payment: paymentSlice,
  user: userSlice,
  preferences: preferencesSlice,
  news: newsSlice,
});

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
) as unknown as typeof rootReducer;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
