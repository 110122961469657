import { shuffleArray } from "lib/utils/array";
import store from "store";
import { setIsMusicPlaying } from "lib/navigation/slice";

let baseX = 0;
let ctx;
let audioContexts = {};
let previousBaseX = 0;
const setBaseX = (newBaseX) => {
  ctx.translate(previousBaseX * -1 + newBaseX, 0);
  previousBaseX = newBaseX;
};

const localStorageRaw = window.localStorage.getItem("persist:root") || "{}";
const localStorage = JSON.parse(localStorageRaw);
let globalVolume =
  localStorage?.preferences && JSON.parse(localStorage.preferences).music
    ? 0.5
    : 0;

const setGlobalVolume = (newVolume) => {
  globalVolume = newVolume;

  for (let context of Object.values(audioContexts)) {
    if (context.track) {
      context.track.volume = globalVolume;
      try {
        context.track.play();
      } catch (e) {
        console.log(e);
      }
    }
  }
};
let lastTypePlayed = "";
export { setBaseX, setGlobalVolume, lastTypePlayed };

let musicTrack;
let hasInitialized = false;
let audioCtx;
let typeSources = [
  "/assets/audio/music-1.mp3",
  "/assets/audio/music-2.mp3",
  "/assets/audio/music-3.mp3",
  "/assets/audio/music-4.mp3",
  "/assets/audio/music-5.mp3",
  "/assets/audio/music-6.mp3",
  "/assets/audio/music-7.mp3",
  "/assets/audio/music-8.mp3",
  "/assets/audio/music-9.mp3",
  "/assets/audio/music-10.mp3",
];

const trackOrder = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
shuffleArray(trackOrder);
let currentTrack = 0;
export default async function initialize() {
  store.dispatch(setIsMusicPlaying(true));
  const prevVolume = new Number(globalVolume);

  // for (let con of Object.values(audioContexts)) {
  //   while (globalVolume > 0) {
  //     globalVolume = Math.max(globalVolume - 0.05, 0);

  //     con.track.volume = globalVolume;
  //     await new Promise((resolve) => setTimeout(resolve, 100));
  //   }
  //   con.track.pause();
  // }

  // await new Promise((resolve) => setTimeout(resolve, 500));

  globalVolume = prevVolume;

  const type = trackOrder[currentTrack];
  lastTypePlayed = type;

  if (audioContexts[type]) {
  } else {
    let audioCtx = {
      track: new Audio(),
    };
    audioCtx.track.preload = "auto";
    audioCtx.track.volume = globalVolume;
    audioCtx.track.src = typeSources[type];
    audioCtx.track.crossOrigin = "anonymous";
    audioContexts[type] = audioCtx;
    audioContexts[type].track.addEventListener("ended", function () {
      audioContexts[type].track.pause();
      if (currentTrack < typeSources.length - 1) {
        currentTrack++;
      } else {
        currentTrack = 0;
      }

      initialize();
    });
  }

  audioContexts[type].track.play();
}
