import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "./Modal";
import { Level, Reward } from "lib/data/slice";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import RewardItem from "lib/progression/components/RewardItem";
import { buyPack } from "lib/progression/slice";
import { guaranteedRewards } from "lib/progression/poolReward";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import QuizIcon from "@mui/icons-material/Quiz";
import GradientText from "lib/typography/components/GradientText";
import Chip from "@mui/material/Chip";
import CardActionArea from "@mui/material/CardActionArea";
import Clickable from "lib/audio/components/Clickable";
import { generatePack } from "lib/pack/generatePack";
import { pushModal } from "../slice";

import theme from "theme";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

export interface BuyPacksModalProps {
  onClose: (ev: any) => void | (() => void);
}

export default function BuyPacksModal({ onClose }: BuyPacksModalProps) {
  const progression = useAppSelector((state) => state.progression);
  const packs = useAppSelector((state) => state.app.store.packs);
  const dispatch = useAppDispatch();
  return (
    <Modal
      onClose={onClose}
      dialogHeaderContent={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
          }}
        >
          <GradientText
            dark
            sx={{
              display: "inline-block",
              whiteSpace: "nowrap",
              fontSize: "3em",
            }}
          >
            Buy Packs
          </GradientText>
          <Typography
            sx={{
              display: "inline-block",
              marginLeft: "auto",

              [theme.breakpoints.down("md")]: { marginLeft: 0 },
            }}
          >
            Each pack contains 5 cards with 1 guaranteed Rare or better
          </Typography>
        </Box>
      }
      dialogProps={{ maxWidth: "lg", fullWidth: true }}
      dialogBodyContent={
        <>
          <Grid justifyContent="space-evenly" alignItems="center" container>
            {packs.map((pack) => {
              let canAfford = false;
              if (pack.cost.basic) {
                if (progression.currency.basic > pack.cost.basic) {
                  canAfford = true;
                }
              } else if (pack.cost.premium) {
                if (progression.currency.premium > pack.cost.premium) {
                  canAfford = true;
                }
              }
              return (
                <Grid
                  key={`${pack.id}`}
                  item
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{
                    fontSize: "1em",

                    width: "260px",
                    height: "600px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <GradientText dark>{pack.name}</GradientText>
                  <Typography
                    sx={{
                      fontSize: "1.5em",
                      textAlign: "center",
                      marginBottom: "1em",
                    }}
                  >
                    {pack.description}
                  </Typography>
                  <Typography>
                    {pack.cost.basic && (
                      <Chip
                        color={canAfford ? "primary" : "error"}
                        variant="outlined"
                        size="medium"
                        icon={
                          <AcUnitIcon
                            color={canAfford ? "primary" : "error"}
                            fontSize="medium"
                          />
                        }
                        label={pack.cost.basic.toLocaleString("en-US")}
                      ></Chip>
                    )}
                    {pack.cost.premium && (
                      <Chip
                        color={canAfford ? "primary" : "error"}
                        variant="outlined"
                        size="medium"
                        icon={
                          <PremiumCurrencyIcon
                            color={canAfford ? "primary" : "error"}
                            fontSize="medium"
                          />
                        }
                        label={pack.cost.premium.toLocaleString("en-US")}
                      ></Chip>
                    )}
                  </Typography>
                  <div
                    style={{
                      position: "relative",
                      height: "340px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "auto",
                    }}
                  >
                    {Array.from(Array(pack.amount)).map((item, idx) => (
                      <Card
                        sx={{
                          color: "#FFD700",
                          overflow: "visible",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          width: "200px",
                          height: "333px",
                          background: 'url("assets/pack_back.png")',
                          backgroundSize: "cover",
                          position: "absolute",
                          transform: `rotate(${idx}deg)`,
                          transformOrigin: "bottom",
                        }}
                      ></Card>
                    ))}
                  </div>
                  <Clickable>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        width: "70%",
                        justifySelf: "center",
                        fontSize: "1.2em",
                        borderRadius: "2em",
                        marginTop: "0,8em",
                      }}
                      onClick={() => {
                        if (pack.cost.basic) {
                          if (progression.currency.basic > pack.cost.basic) {
                            dispatch(
                              buyPack({
                                pack: generatePack(),
                                basic: pack.cost.basic,
                              })
                            );
                          } else {
                            dispatch(
                              pushModal({
                                componentName: "BuyBasicCurrency",
                                props: {},
                              })
                            );
                          }
                        } else if (pack.cost.premium) {
                          if (
                            progression.currency.premium > pack.cost.premium
                          ) {
                            dispatch(
                              buyPack({
                                pack: Array.from(
                                  { length: pack.amount },
                                  (x, i) => {
                                    return generatePack();
                                  }
                                ),
                                premium: pack.cost.premium,
                              })
                            );
                          } else {
                            dispatch(
                              pushModal({
                                componentName: "BuyPremiumCurrency",
                                props: {},
                              })
                            );
                          }
                        } else {
                          console.log("What");
                        }
                      }}
                    >
                      Buy
                    </Button>
                  </Clickable>
                </Grid>
              );
            })}
          </Grid>
        </>
      }
    />
  );
}
