import Button, { ButtonProps } from "@mui/material/Button";
import * as React from "react";
import theme from "theme";

interface BuyButtonProps extends ButtonProps {
  label: React.ReactNode;
  animationDelay?: number;
}
export default function BuyButton({
  label,
  animationDelay = 0,
  sx,
  ...rest
}: BuyButtonProps) {
  return (
    <Button
      {...rest}
      sx={{
        fontSize: "2em",
        letterSpacing: "4px",
        overflow: "hidden",
        borderRadius: "1.5em",
        right: "auto",
        bottom: "auto",
        whiteSpace: "nowrap",
        "&:before": {
          content: "''",
          position: "absolute",

          top: 0,
          left: "-100px",
          width: "70px",
          height: "100%",
          background: "rgba(255, 247, 204, 0.3)",
          transform: "skewX(-30deg)",
          animationName: "slide",
          animationDuration: "2.8s",
          animationTimingFunction: "linear",
          animationDelay: `${animationDelay}s`,
          animationIterationCount: "infinite",
          animationDirection: "normal",
          backgroundImage: `linear-gradient(
                to right, 
                rgba(255, 247, 204, 0.13) 0%,
                rgba(255, 247, 204, 0.13) 77%,
                rgba(255, 247, 204, 0.5) 92%,
                rgba(255, 247, 204, 0.0) 100%
                );
            }`,
          "@keyframes slide": {
            "0%": {
              opacity: 1,
              left: "-100px",
              top: 0,
            },

            "10%": {
              left: "290px",
              top: 0,
            },
            "98%": {
              opacity: 0,
              left: "290px",
              top: 0,
            },
            "99%": {
              opacity: 0,
              left: "-100px",
              top: 0,
            },

            "100%": {
              opacity: 0,
              left: "-100px",
              top: 0,
            },
          },
        },

        ...sx,
      }}
      size="large"
      color="secondary"
      variant="contained"
      autoFocus
    >
      {label}
    </Button>
  );
}
