import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import RedeemIcon from "@mui/icons-material/Redeem";
import TollIcon from "@mui/icons-material/Toll";
import { Box, BoxProps, Paper, useMediaQuery } from "@mui/material";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid, { GridProps } from "@mui/material/Grid";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import moment from "moment";
import * as React from "react";
import { GoalCardProps } from "./GoalCard";
import CheckIcon from "@mui/icons-material/Check";
import SchoolIcon from "@mui/icons-material/School";
import LiveTile from "lib/live-panel/LiveTile";
import { modalSlice, pushModal } from "lib/modal/slice";
import theme from "theme";
import { Link, LinkProps } from "react-router-dom";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import GradientText from "lib/typography/components/GradientText";
import { red } from "@mui/material/colors";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { AutoModePremiumCurrencyIcon } from "lib/design-system/components/AutoModeIcon";
import { getAppVersion } from "lib/data/firebase";
import ZenTile from "lib/zen/ZenTile";
import TileRevealCanvas from "lib/dashboard/TileRevealCanvas";
import useAnimationEffect from "lib/animation/useAnimationEffect";
import { buyPack, setTutorialStep } from "lib/progression/slice";
import playSound from "lib/audio/sfx";
import { setCanAnimate, setSpotlight } from "lib/navigation/slice";
import { generatePack } from "lib/pack/generatePack";
interface DashboardCardProps extends LinkProps {
  assetName: string;
  highlight?: boolean;
  sx?: BoxProps["sx"];
  cardRef?: React.Ref<HTMLAnchorElement>;
}
const DashboardCard = ({
  children,
  assetName,
  to,
  onClick,
  style,
  sx,
  highlight,
  cardRef,
  ...rest
}: DashboardCardProps) => (
  <Link
    ref={cardRef}
    style={{
      textDecoration: "none",
      color: "inherit",
      height: "100%",
      position: "relative",
      display: "flex",
      width: "100%",
      ...style,
    }}
    to={to}
    onClick={onClick}
    {...rest}
  >
    {highlight && (
      <Box
        sx={{
          boxShadow: "",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          "@keyframes DashboardPremiumCurrencyPanelOngoing": {
            "0%": {
              boxShadow: "0 0 10px -10px #FFD700",
            },
            "90%": {
              opacity: 1,
            },
            "100%": {
              opacity: 0,
              boxShadow: "0 0 10px 10px #FFD70000",
            },
          },
          animation: "DashboardPremiumCurrencyPanelOngoing 3s infinite",
        }}
      ></Box>
    )}
    <Box
      sx={{
        background: "rgba( 255, 255, 255, 0.25 )",
        boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
        backdropFilter: "blur( 8px )",
        border: "40px solid transparent",
        borderImage: `url("assets/panel/${assetName}.png") 190 195 190 195`,
        borderImageWidth: "30px 30px 30px 30px",
        textAlign: "center",
        verticalAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundPosition: "center",
        height: "100%",
        cursor: "pointer",
        width: "100%",
        "&:hover": {
          filter:
            "brightness(1.2) contrast(110%) drop-shadow(0px 0px 15px #fff)",
        },

        [theme.breakpoints.down("md")]: {
          borderWidth: "20px",
          borderImageWidth: "15px 15px 15px 15px",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          fontSize: "1.4em",
          textShadow: `-2px -2px 0.4em ${red[900]}, 2px -2px 0.4em ${red[900]}, -2px 2px 0.4em ${red[900]}, 2px 2px 0.4em ${red[900]}`,
        }}
      >
        {children}
      </Box>
    </Box>
  </Link>
);
const DashboardCardWithRef = React.forwardRef(
  (props: DashboardCardProps, ref: React.Ref<HTMLAnchorElement>) => (
    <DashboardCard {...props} cardRef={ref} />
  )
);
const TILE_REVEAL_DESKTOP = {
  liveTile: { unlockGameThreshold: 2, tutorialStep: 2 },
  zenTile: { unlockGameThreshold: 0, tutorialStep: 1 },
  cards: { unlockGameThreshold: 3, tutorialStep: 5 },
  packs: { unlockGameThreshold: 3, tutorialStep: 7 },
  shop: { unlockGameThreshold: 3, tutorialStep: 9 },
  tokens: { unlockGameThreshold: 4, tutorialStep: 10 },
  goals: { unlockGameThreshold: 4, tutorialStep: 12 },
  freebies: { unlockGameThreshold: 4, tutorialStep: 14 },
  market: { unlockGameThreshold: 9, tutorialStep: 16 },
};
export const TILE_REVEAL_MOBILE = {
  liveTile: { unlockGameThreshold: 2, tutorialStep: 2 },
  zenTile: { unlockGameThreshold: 0, tutorialStep: 1 },
  cards: { unlockGameThreshold: 3, tutorialStep: 4 },
  packs: { unlockGameThreshold: 3, tutorialStep: 6 },
  shop: { unlockGameThreshold: 3, tutorialStep: 9 },
  tokens: { unlockGameThreshold: 4, tutorialStep: 9 },
  goals: { unlockGameThreshold: 4, tutorialStep: 12 },
  freebies: { unlockGameThreshold: 4, tutorialStep: 14 },
  market: { unlockGameThreshold: 9, tutorialStep: 16 },
};

function DashboardContent() {
  const appState = useAppSelector((state) => state.app);
  const progression = useAppSelector((state) => state.progression);
  console.log(appState.unlockable.items.filter((game) => !game.attribution));
  const news = useAppSelector((state) => state.news);
  const dispatch = useAppDispatch();
  const appVersion = useAppSelector((state) => state.app.version);
  const canClaimPremium = moment(progression.lastPremiumClaim * 1000).isBefore(
    moment().subtract(1, "hours")
  );
  const modals = useAppSelector((state) => state.modal.modals);
  const recentUnlocksRaw = Object.entries(progression.unlocks)
    .map(([id, unlockable]) => {
      return { id, ...unlockable };
    })
    .sort((a, b) =>
      moment(a.dateUnlocked).diff(moment(b.dateUnlocked)) <= 0 ? 1 : -1
    );

  const recentUnlocks = recentUnlocksRaw
    .map((unlockable) => {
      return {
        ...unlockable,
        ...appState.unlockable.items.find((item) => item.id === unlockable.id),
      };
    })
    .slice(0, 4);

  const goals = useAppSelector((state) => state.progression.goals);
  let renderableGoalCards: GoalCardProps[] = [...goals.current];
  const completedGoalsAmount = goals.current.filter(
    (goal) => goal.progress >= goal.requiredAmount
  ).length;
  while (renderableGoalCards.length < 3) {
    renderableGoalCards.push({ asRefresh: true });
  }

  const compareAppVersion = async () => {
    const appVersionMeta = await getAppVersion();
    if (!appVersionMeta.min) {
      return;
    }
    if (
      parseInt(appVersion.replace(/\./g, "")) <
      parseInt(appVersionMeta.min.replace(/\./g, ""))
    ) {
      dispatch(
        pushModal({
          componentName: "FatalError",
          props: {
            title: "Update Required",
            type: "UpdateRequired",
            body: "New features are available, please refresh the game to get the latest content!",
          },
        })
      );

      console.log("update required");
    }
  };

  React.useEffect(() => {
    compareAppVersion();
    if (progression.tutorialStep === 0) {
      dispatch(setCanAnimate(false));
      dispatch(
        pushModal({
          componentName: "News",
          props: {
            title: "Welcome to ThriveMind",
            type: "intro",
            image: "assets/news/intro.jpg",
            rewards: [],
          },
        })
      );
    } else if (progression.tutorialStep === 3) {
      dispatch(
        pushModal({
          componentName: "News",
          props: {
            title: "Shards",
            type: "bettingBasic",
            image: "assets/news/bettingBasic.jpg",
            rewards: [],
          },
        })
      );
    } else if (progression.tutorialStep === 4) {
      dispatch(
        pushModal({
          componentName: "News",
          props: {
            title: "Discovery Rewards",
            type: "newCardRewards",
            image: "assets/news/newCardRewards.jpg",
            rewards: [],
          },
        })
      );
    } else if (progression.tutorialStep === 6) {
      // dispatch(
      //   setSpotlight({
      //     queryTarget: `.packs-tile`,
      //     message: { text: `Play another game`, position: "right" },
      //   })
      // );
    } else if (progression.tutorialStep === 8) {
      dispatch(
        setSpotlight({
          queryTarget: "",
          message: { text: "", position: isMobile ? "top" : "right" },
        })
      );
      dispatch(
        pushModal({
          componentName: "News",
          props: {
            title: "Strands",
            type: "premium",
            image: "assets/news/premium.jpg",
          },
        })
      );
    } else if (
      progression.tutorialStep === 9 &&
      progression.achievements.progress.gamesPlayed >=
        TILE_REVEAL_DESKTOP.tokens.unlockGameThreshold
    ) {
      dispatch(
        pushModal({
          componentName: "News",
          props: {
            title: "Tokens",
            type: "tokens",
            image: "assets/news/tokens.jpg",
          },
        })
      );
    } else if (
      (isMobile &&
        (progression.tutorialStep === 10 || progression.tutorialStep === 8)) ||
      progression.tutorialStep === 11
    ) {
      dispatch(setCanAnimate(true));
    } else if (!progression.tutorial.recycling && progression.level >= 999) {
    } else if (news.hasSeen === false && progression.tutorialStep >= 14) {
      for (let item of news.items) {
        dispatch(pushModal({ componentName: "News", props: item }));
      }
    }
  }, []);
  const [hasSeenFreebieTutorial, setHasSeenFreebieTutorial] =
    React.useState(false);
  React.useEffect(() => {
    if (
      !hasSeenFreebieTutorial &&
      progression.tutorialStep === 13 &&
      modals.length === 0
    ) {
      setHasSeenFreebieTutorial(true);
      dispatch(setCanAnimate(false));
      dispatch(
        pushModal({
          componentName: "News",
          props: {
            title: "Freebies",
            type: "freebies",
            image: "assets/news/freebies.jpg",
          },
        })
      );
    }
  }, [progression.tutorialStep, modals.length]);
  const [revealTargetRef, setRevealTargetRef] =
    React.useState<HTMLElement | null>(null);
  //const [revealTarget, setRevealTarget] = React.useState(0);

  useAnimationEffect(() => {
    switch (progression.tutorialStep) {
      case TILE_REVEAL_DESKTOP.zenTile.tutorialStep - 1:
        // Reveal Zen EXP right away
        break;
      case TILE_REVEAL_DESKTOP.liveTile.tutorialStep - 1:
        if (
          progression.achievements.progress.gamesPlayed <
          TILE_REVEAL_DESKTOP.liveTile.unlockGameThreshold
        ) {
          return;
        }
        break;
      case TILE_REVEAL_DESKTOP.cards.tutorialStep - 1:
        if (
          progression.achievements.progress.gamesPlayed <
          TILE_REVEAL_DESKTOP.cards.unlockGameThreshold
        ) {
          return;
        }
        break;
      case TILE_REVEAL_DESKTOP.packs.tutorialStep - 1:
        if (
          progression.achievements.progress.gamesPlayed <
          TILE_REVEAL_DESKTOP.packs.unlockGameThreshold
        ) {
          return;
        }
        break;
      case TILE_REVEAL_DESKTOP.tokens.tutorialStep - 1:
        if (
          progression.achievements.progress.gamesPlayed <
          TILE_REVEAL_DESKTOP.tokens.unlockGameThreshold
        ) {
          return;
        }

        break;

      case TILE_REVEAL_DESKTOP.shop.tutorialStep - 1:
        if (
          progression.achievements.progress.gamesPlayed <
          TILE_REVEAL_DESKTOP.shop.unlockGameThreshold
        ) {
          return;
        }

        break;
      case TILE_REVEAL_DESKTOP.goals.tutorialStep - 1:
        if (
          progression.achievements.progress.gamesPlayed <
          TILE_REVEAL_DESKTOP.goals.unlockGameThreshold
        ) {
          console.log("out");
          return;
        }

        break;
      case TILE_REVEAL_DESKTOP.market.tutorialStep - 1:
        if (
          progression.achievements.progress.gamesPlayed <
          TILE_REVEAL_DESKTOP.market.unlockGameThreshold
        ) {
          return;
        }

        break;
      case TILE_REVEAL_DESKTOP.freebies.tutorialStep - 1:
        if (
          progression.achievements.progress.gamesPlayed <
          TILE_REVEAL_DESKTOP.freebies.unlockGameThreshold
        ) {
          return;
        }

        break;
    }
    //setRevealTarget(revealTarget);

    if (!revealTargetRef) {
      return;
    }

    if (isMobile && revealTargetRef.dataset.mobileDeferTo) {
      dispatch(
        setSpotlight({
          queryTarget: `.${revealTargetRef.dataset.mobileDeferTo}`,
          message: {
            text: `${revealTargetRef.dataset.spotlightMessage}`,
            position: "top",
          },
        })
      );
      return;
    }
    window.setTimeout(() => {
      const boundingClientRect = revealTargetRef.getBoundingClientRect();
      playSound("reveal-tile");

      TileRevealCanvas.reset({
        width: boundingClientRect.width,
        height: boundingClientRect.height,
        style: `top: ${boundingClientRect.top}px;left: ${boundingClientRect.left}px;right: ${boundingClientRect.right}px;bottom: ${boundingClientRect.bottom}px; position: absolute; z-index: -1;pointer-events:none;transition:opacity 0.5s ease-in-out;opacity:0;`,
      });
      TileRevealCanvas.start({
        start: {
          x: boundingClientRect.width / 2,
          y: boundingClientRect.height / 2,
        },
        end: { x: boundingClientRect.right, y: boundingClientRect.bottom },
      });
      dispatch(
        setSpotlight({
          queryTarget: `.${revealTargetRef.dataset.queryClass}`,
          offset: { size: 0, left: 0, top: 0 },
          message: {
            text: `${revealTargetRef.dataset.spotlightMessage}`,
            position: isMobile ? "top" : "right",
          },
        })
      );
      window.setTimeout(() => {
        dispatch(setTutorialStep(progression.tutorialStep + 1));
        playSound("reveal-tile-finish");
      }, 1000);
      window.setTimeout(() => {
        TileRevealCanvas.stop();
        if (progression.tutorialStep === 8) {
          dispatch(
            pushModal({
              componentName: "News",
              props: {
                title: "Keep Playing",
                type: "playMore",
                image: "assets/news/playMore.jpg",
              },
            })
          );
        }
      }, 2500);
    }, 500);
  }, [revealTargetRef]);

  const claimableConsumableAmount = useAppSelector((state) =>
    Object.values(state.progression.consumables).filter(
      (c) => c.hasConsumed === false
    )
  ).length;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 2,
        mb: 4,
        position: "relative",
        [theme.breakpoints.up("md")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },

        [theme.breakpoints.down("md")]: {
          mt: 2,
        },
      }}
    >
      <Grid
        container
        alignItems="stretch"
        columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
        rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
        sx={{
          "@keyframes DashboardTileReveal": {
            "0%": {
              opacity: 1,
              transform: "scale(0.1) translateY(1em)",
              filter: "drop-shadow(0px 0px 20px #fafafa)",
            },
            "100%": {
              opacity: 1,
              transform: "scale(1) translateY(0)",
              filter: "drop-shadow(2px 2px 2px #fafafa00)",
            },
          },
        }}
      >
        <Grid item xs={6} md={4} lg={3}>
          <Paper
            className="live-tile"
            data-query-class="live-tile"
            data-spotlight-message="Go collect your daily reward"
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.liveTile.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 280,
              background: "#89023E",

              boxShadow: "0 8px 32px 0 rgba(255, 255, 255, 0.37)",
              backdropFilter: "blur( 4px )",
              border: "1px solid rgba(255,255,255,0.4)",
              borderRadius: "2em",
              p: 0,
              pointerEvents:
                progression.tutorialStep <
                TILE_REVEAL_DESKTOP.liveTile.tutorialStep
                  ? "none"
                  : "auto",
              opacity:
                progression.tutorialStep <=
                TILE_REVEAL_DESKTOP.liveTile.tutorialStep
                  ? 0
                  : 1,

              animation:
                progression.tutorialStep ===
                TILE_REVEAL_DESKTOP.liveTile.tutorialStep
                  ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                  : "none",

              [theme.breakpoints.down("md")]: { height: "20vh", pt: 1 },
            }}
          >
            <LiveTile
              ref={
                progression.tutorialStep ===
                TILE_REVEAL_DESKTOP.liveTile.tutorialStep - 1
                  ? setRevealTargetRef
                  : () => {}
              }
              sx={{
                transformOrigin: "center",
                opacity:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.liveTile.tutorialStep
                    ? 0
                    : 1,
                animation:
                  progression.tutorialStep ===
                  TILE_REVEAL_DESKTOP.liveTile.tutorialStep
                    ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                    : "none",
              }}
            />
          </Paper>
        </Grid>
        <Grid
          item
          xs={6}
          md={4}
          lg={3}
          sx={{
            [theme.breakpoints.down("md")]: { order: -1 },
          }}
        >
          <Paper
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.zenTile.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 280,
              background: "#89023E",

              boxShadow: "0 8px 32px 0 rgba(255, 255, 255, 0.37)",
              backdropFilter: "blur( 4px )",
              border: "1px solid rgba(255,255,255,0.4)",
              borderRadius: "2em",
              p: 0,
              opacity:
                progression.tutorialStep <=
                TILE_REVEAL_DESKTOP.zenTile.tutorialStep
                  ? 0
                  : 1,

              animation:
                progression.tutorialStep ===
                TILE_REVEAL_DESKTOP.zenTile.tutorialStep
                  ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                  : "none",

              [theme.breakpoints.down("md")]: {
                height: "20vh",
                pt: 1,
              },
            }}
          >
            <ZenTile />
          </Paper>
        </Grid>
        <Grid
          item
          xs={6}
          md={3}
          lg={3}
          direction="row"
          sx={{
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          <DashboardCard
            highlight={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.zenTile.tutorialStep
            }
            className="play-tile"
            assetName="menu_5"
            to=""
            onClick={() =>
              dispatch(pushModal({ componentName: "GameSelect", props: {} }))
            }
          >
            <VideogameAssetOutlinedIcon
              color="secondary"
              sx={{ width: "2em", height: "2em", stroke: red[900] }}
              fontSize="large"
            />
            <GradientText>Play</GradientText>
          </DashboardCard>
        </Grid>
        <Grid
          item
          xs={6}
          md={3}
          lg={3}
          direction="row"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7em",
            },
          }}
        >
          <Paper
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.shop.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              background: "transparent",
              boxShadow: "none",
              height: "100%",
              width: "100%",
            }}
          >
            <DashboardCard
              highlight={
                progression.tutorialStep ===
                TILE_REVEAL_DESKTOP.shop.tutorialStep
              }
              assetName="menu_3"
              to=""
              sx={{
                pointerEvents:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.liveTile.tutorialStep
                    ? "none"
                    : "auto",
                opacity:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.shop.tutorialStep
                    ? 0
                    : 1,
                animation:
                  progression.tutorialStep ===
                  TILE_REVEAL_DESKTOP.shop.tutorialStep
                    ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                    : "none",
              }}
              onClick={() =>
                dispatch(
                  pushModal({ componentName: "BuyPremiumCurrency", props: {} })
                )
              }
            >
              <PremiumCurrencyIcon
                color="secondary"
                sx={{
                  width: "2em",
                  height: "2em",
                  stroke: red[900],
                }}
                fontSize="large"
              />
              <GradientText>Shop</GradientText>
            </DashboardCard>
          </Paper>
        </Grid>
        <Grid
          item
          xs={6}
          md={3}
          lg={3}
          sx={{
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          <Paper
            className="cards-tile"
            data-query-class="cards-tile"
            data-spotlight-message="Go to your Card collection"
            data-mobile-defer-to="bottom-bar-cards"
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.cards.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              background: "transparent",
              boxShadow: "none",
              height: "100%",
              width: "100%",
            }}
          >
            <DashboardCard
              assetName="menu_1"
              to="/cards"
              sx={{
                pointerEvents:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.cards.tutorialStep
                    ? "none"
                    : "auto",
                opacity:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.cards.tutorialStep
                    ? 0
                    : 1,

                animation:
                  progression.tutorialStep ===
                  TILE_REVEAL_DESKTOP.cards.tutorialStep
                    ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                    : "none",
              }}
            >
              {claimableConsumableAmount > 0 && (
                <Chip
                  color="secondary"
                  variant="outlined"
                  sx={{
                    textShadow: "none",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                  size="small"
                  icon={<BurstModeIcon color="secondary" fontSize="small" />}
                  label={claimableConsumableAmount}
                ></Chip>
              )}
              <CalendarViewMonthIcon
                color="secondary"
                sx={{ width: "2em", height: "2em", stroke: red[900] }}
                fontSize="large"
              />
              <GradientText>Cards</GradientText>
            </DashboardCard>
          </Paper>
        </Grid>
        <Grid
          item
          xs={6}
          md={3}
          lg={3}
          sx={{
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          <Paper
            className="packs-tile"
            data-query-class="packs-tile"
            data-spotlight-message="Go to your Pack collection"
            data-mobile-defer-to="bottom-bar-packs"
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.packs.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              background: "transparent",
              boxShadow: "none",
              height: "100%",
              width: "100%",
            }}
          >
            <DashboardCard
              sx={{
                pointerEvents:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.packs.tutorialStep
                    ? "none"
                    : "auto",
                opacity:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.packs.tutorialStep
                    ? 0
                    : 1,

                animation:
                  progression.tutorialStep ===
                  TILE_REVEAL_DESKTOP.packs.tutorialStep
                    ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                    : "none",
              }}
              assetName="menu_2"
              to="/packs"
            >
              {progression.packs.length > 0 && (
                <Chip
                  color="secondary"
                  variant="outlined"
                  sx={{
                    textShadow: "none",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                  size="small"
                  icon={<FiberNewIcon color="secondary" fontSize="small" />}
                  label={progression.packs.length}
                ></Chip>
              )}
              <RedeemIcon
                color="secondary"
                sx={{ width: "2em", height: "2em", stroke: red[900] }}
                fontSize="large"
              />
              <GradientText>Packs</GradientText>
            </DashboardCard>
          </Paper>
        </Grid>
        <Grid
          item
          xs={6}
          md={3}
          lg={3}
          sx={{
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          {" "}
          <Paper
            className="tokens-tile"
            data-query-class="tokens-tile"
            data-spotlight-message="Go to your Token collection"
            data-mobile-defer-to="bottom-bar-tokens"
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.tokens.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              background: "transparent",
              boxShadow: "none",
              height: "100%",
              width: "100%",
            }}
          >
            <DashboardCard
              sx={{
                pointerEvents:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.liveTile.tutorialStep
                    ? "none"
                    : "auto",
                opacity:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.tokens.tutorialStep
                    ? 0
                    : 1,
                animation:
                  progression.tutorialStep ===
                  TILE_REVEAL_DESKTOP.tokens.tutorialStep
                    ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                    : "none",
              }}
              assetName="menu_6"
              to="/tokens"
            >
              <TollIcon
                color="secondary"
                sx={{ width: "2em", height: "2em", stroke: red[900] }}
                fontSize="large"
              />
              <GradientText>Tokens</GradientText>
            </DashboardCard>
          </Paper>
        </Grid>

        <Grid
          item
          xs={6}
          md={3}
          lg={3}
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7em",
            },
          }}
        >
          {" "}
          <Paper
            className="goals-tile"
            data-query-class="goals-tile"
            data-spotlight-message="Check out your Goals"
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.goals.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              background: "transparent",
              boxShadow: "none",
              height: "100%",
              width: "100%",
            }}
          >
            <DashboardCard
              sx={{
                pointerEvents:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.goals.tutorialStep
                    ? "none"
                    : "auto",
                opacity:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.goals.tutorialStep
                    ? 0
                    : 1,
                animation:
                  progression.tutorialStep ===
                  TILE_REVEAL_DESKTOP.goals.tutorialStep
                    ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                    : "none",
              }}
              assetName="menu_4"
              to=""
              onClick={() => {
                dispatch(
                  pushModal({
                    componentName: "CurrentGoals",
                    props: { goals: renderableGoalCards },
                  })
                );
              }}
            >
              {completedGoalsAmount > 0 && (
                <Chip
                  color="secondary"
                  variant="outlined"
                  sx={{
                    textShadow: "none",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                  size="small"
                  icon={<CheckIcon color="secondary" fontSize="small" />}
                  label={`${completedGoalsAmount}`}
                ></Chip>
              )}
              <SportsScoreOutlinedIcon
                color="secondary"
                sx={{
                  width: "2em",
                  height: "2em",
                  stroke: red[900],
                  [theme.breakpoints.down("md")]: {
                    height: "1.2em",
                    width: "1.2em",
                  },
                }}
                fontSize="large"
              />
              <GradientText>Goals</GradientText>
            </DashboardCard>
          </Paper>
        </Grid>

        {/* <Grid
          item
          xs={6}
          md={3}
          lg={3}
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7em",
            },
          }}
        >
          <Paper
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.market.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              background: "transparent",
              boxShadow: "none",
              height: "100%",
              width: "100%",
            }}
          >
            <DashboardCard
              sx={{
                pointerEvents:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.market.tutorialStep
                    ? "none"
                    : "auto",
                opacity:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.market.tutorialStep
                    ? 0
                    : 1,

                animation:
                  progression.tutorialStep ===
                  TILE_REVEAL_DESKTOP.market.tutorialStep
                    ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                    : "none",
              }}
              assetName="menu_2"
              to="/"
              onClick={() => {
                dispatch(
                  pushModal({ componentName: "BuyBasicCurrency", props: {} })
                );
              }}
            >
              {canClaimPremium && (
                <Chip
                  color="secondary"
                  variant="outlined"
                  sx={{
                    textShadow: "none",
                    position: "absolute",
                    top: 0,
                    right: -8,
                    padding: "1em 0.2em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  size="small"
                  icon={
                    <AutoModePremiumCurrencyIcon
                      color="secondary"
                      fontSize="small"
                    />
                  }
                  label={isMobile ? "" : "Ready"}
                ></Chip>
              )}
              <AcUnitIcon
                color="secondary"
                sx={{
                  width: "2em",
                  height: "2em",
                  stroke: red[900],
                  [theme.breakpoints.down("md")]: {
                    height: "1.2em",
                    width: "1.2em",
                  },
                }}
                fontSize="large"
              />
              <GradientText>Market</GradientText>
            </DashboardCard>
          </Paper>
        </Grid> */}
        <Grid
          item
          xs={6}
          md={3}
          lg={3}
          sx={{
            [theme.breakpoints.down("md")]: {
              display: "block",
              fontSize: "0.7em",
            },
          }}
        >
          <Paper
            className="freebies-tile"
            data-query-class="freebies-tile"
            data-spotlight-message="Check out the Freebies"
            ref={
              progression.tutorialStep ===
              TILE_REVEAL_DESKTOP.freebies.tutorialStep - 1
                ? setRevealTargetRef
                : () => {}
            }
            sx={{
              display: "flex",
              background: "transparent",
              boxShadow: "none",
              height: "100%",
              width: "100%",
            }}
          >
            <DashboardCard
              sx={{
                pointerEvents:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.freebies.tutorialStep
                    ? "none"
                    : "auto",
                opacity:
                  progression.tutorialStep <=
                  TILE_REVEAL_DESKTOP.freebies.tutorialStep
                    ? 0
                    : 1,

                animation:
                  progression.tutorialStep ===
                  TILE_REVEAL_DESKTOP.freebies.tutorialStep
                    ? "DashboardTileReveal 0.6s cubic-bezier(0, 0, 0.13, 1.28) forwards"
                    : "none",
              }}
              assetName="menu_3"
              to=""
              onClick={() => {
                dispatch(
                  pushModal({ componentName: "BuyFreebies", props: {} })
                );
              }}
            >
              {" "}
              <Chip
                color="secondary"
                variant="outlined"
                sx={{
                  textShadow: "none",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                size="small"
                icon={<LocalActivityIcon color="secondary" fontSize="small" />}
                label={`${progression.freebies}`}
              ></Chip>
              <LocalActivityIcon
                color="secondary"
                sx={{
                  width: "2em",
                  height: "2em",
                  stroke: red[900],
                  [theme.breakpoints.down("md")]: {
                    height: "1.2em",
                    width: "1.2em",
                  },
                }}
                fontSize="large"
              />
              <GradientText>Freebies</GradientText>
            </DashboardCard>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
