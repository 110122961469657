import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "./Modal";
import { Level, Reward, Unlockable } from "lib/data/slice";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import RewardItem from "lib/progression/components/RewardItem";
import Clickable from "lib/audio/components/Clickable";
import GradientText from "lib/typography/components/GradientText";
import playSound from "lib/audio/sfx";

import clsx from "clsx";
import PackCanvas from "lib/pack/PackCanvas";
import { Box, useMediaQuery } from "@mui/material";
import { grey, green, blue, purple, orange } from "@mui/material/colors";

import theme from "theme";
import { setCanAnimate } from "lib/navigation/slice";

const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const UnstyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  ${(props) => props.theme.breakpoints.down("md")} {
    background-color: rgba(0, 0, 0, 1);
  }
  -webkit-tap-highlight-color: transparent;
`;

export interface RewardModalProps extends Level {
  onClose: (ev: any) => void | (() => void);
  navTo?: string;
  title: string;
  packId?: string | string[];
  source?: string;
}

function GenericRewardModal({
  onClose,
  rewards,
  title,
  packId,
}: RewardModalProps) {
  React.useEffect(() => {
    playSound("modal-load");
  }, []);

  return (
    <Modal
      onClose={onClose}
      dialogTitle={title}
      imageSrc="/assets/hero/quest-complete.png"
      dialogHeaderContent={<GradientText dark>Congratulations</GradientText>}
      dialogBodyContent={
        <>
          <GradientText dark sx={{ fontSize: "clamp(1em, 5vw, 2em)" }}>
            You Got
          </GradientText>
          <Grid justifyContent="space-evenly" alignItems="center" container>
            {rewards.map((reward) => {
              return (
                <Grid key={`${reward.type}${reward.id}`} item>
                  <RewardItem dark {...reward} />
                </Grid>
              );
            })}
          </Grid>
        </>
      }
      dialogActions={
        <Clickable>
          <Button
            onClick={onClose}
            sx={{
              letterSpacing: "2px",
            }}
            size="large"
            variant="contained"
            autoFocus
          >
            Claim
          </Button>
        </Clickable>
      }
    />
  );
}

function PackRewardItem({ reward, idx }: { idx: number; reward: Unlockable }) {
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [shouldRender, setShouldRender] = React.useState(false);
  const [rewardRef, setRewardRef] = React.useState<HTMLElement | null>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  React.useEffect(() => {
    if (!isAnimating && rewardRef) {
      let timeout;
      switch (idx) {
        case 0:
          timeout = 0;
          break;
        case 1:
          timeout = 200;
          break;
        case 2:
          timeout = 350;
          break;
        case 3:
          timeout = 400;
          break;
        case 4:
          timeout = 450;
          break;
      }
      window.setTimeout(() => {
        setIsAnimating(true);
        let color;
        switch (reward.rarity) {
          case 0:
            color = grey[200];
            break;
          case 1:
            color = green[200];
            break;
          case 2:
            color = blue[200];
            break;
          case 3:
            color = purple[200];
            break;
          case 4:
            color = orange[200];
            break;
        }
        const { top, left, width, height } = rewardRef.getBoundingClientRect();

        const animationId = PackCanvas.startPackAnimation({
          top: top + height / 2 - 200,
          left: left + width / 2 - 200,
          width: width + 200,
          height: height + 200,
          color,
        });
        const animation = document.querySelector<HTMLCanvasElement>(
          `[data-anim-frame="${animationId}"]`
        );

        if (animation && animation.style) {
        }
        window.setTimeout(() => {
          setShouldRender(true);
          // const animation = document.querySelector<HTMLCanvasElement>(
          //   `[data-anim-frame="${animationId}"]`
          // );
          if (animation && animation.style) {
            animation.style.opacity = "0";
          }
        }, 550);
      }, timeout);
    }
  }, [rewardRef]);

  return (
    <Grid
      xs={8}
      key={`unlockable${reward.id}`}
      justifyContent="center"
      alignItems="center"
      item
      sx={{
        outline: "none !important",
      }}
    >
      <Box
        ref={setRewardRef}
        sx={{
          height: "10vw",
          width: "10vw",
          marginLeft: "auto",
          marginRight: "auto",
          zIndex: "999999",
          position: "relative",
          opacity: 0,
          "@keyframes PackOpenRewardAnimation": {
            "0%": {
              transform: "scale(0)",
              opacity: 0,
            },
            "50%": {
              opacity: 1,
            },
            "100%": {
              transform: "scale(1)",
              opacity: 1,
            },
          },
          animation: shouldRender
            ? "PackOpenRewardAnimation 0.8s cubic-bezier(0, 0.9, 0.44, 1.41) forwards"
            : "none",

          [theme.breakpoints.down("md")]: {
            height: "40vw",
            width: "40vw",
          },
        }}
      >
        <RewardItem
          dark
          {...reward}
          amount={1}
          sound=""
          type="background"
          sx={{
            height: isMobile ? "40vw" : "10vw",
            width: isMobile ? "40vw" : "10vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Typography
          sx={{
            color: grey[100],
            textShadow: `2px 2px 2px #222, -2px -2px 2px #222, 2px -2px 2px #222, -2px 2px 2px #222`,
            textTransform: "uppercase",
            letterSpacing: "4px",

            padding: "0.1em 0.45em",
            textAlign: "center",
            borderRadius: "0.8em",
            fontSize: "1.5em",
            bottom: 8,
            left: "50%",
            transform: "translateX(-50%)",
            whiteSpace: "nowrap",
            position: "absolute",
            zIndex: 1,

            [theme.breakpoints.down("md")]: {
              letterSpacing: "1px",
              fontSize: "clamp(0.6em, 6vw, 1em)",
              marginTop: "-0.5em",
            },
          }}
        >
          {reward.name}
        </Typography>
      </Box>
    </Grid>
  );
}

function PackOpenModal({ onClose, rewards, title, packId }: RewardModalProps) {
  const unlockables = useAppSelector((state) => state.app.unlockable.items);
  const dispatch = useAppDispatch();
  const rewardsSorted = rewards.map((reward) => {
    const unlockable = unlockables.find(
      (unlockable) => unlockable.id === reward.id
    )!;
    return unlockable;
  });

  //const rewardsSorted = rewards.
  React.useEffect(() => {
    //PackCanvas.startPackAnimation();
    playSound("pack-reward");
    dispatch(setCanAnimate(false));
    return () => {
      PackCanvas.clearAnimations();
      dispatch(setCanAnimate(true));
    };
  }, []);
  console.log({ rewards, rewardsSorted });
  return (
    <UnstyledModal
      open
      sx={{
        cursor: "pointer",
        zIndex: 10000,
      }}
      components={{ Root: Backdrop }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          outline: "none!important",
        }}
      >
        <Box className="pack-open-canvas-container"></Box>
        <Grid
          justifyContent="space-evenly"
          alignItems="flex-start"
          container
          sx={{
            mt: 8,
            width: "50vw",
            outline: "none!important",
            height: "100%",
            [theme.breakpoints.down("md")]: {
              mt: 0,
              width: "100vw",
              height: "auto",
            },
          }}
          spacing={2}
          columns={16}
        >
          {rewardsSorted.map((reward, idx) => {
            if (!reward) return null;
            return <PackRewardItem key={reward.id} idx={idx} reward={reward} />;
          })}
        </Grid>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{
            flex: "0",
            marginTop: "auto",
            width: "auto",
            alignSelf: "center",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            [theme.breakpoints.down("md")]: {
              transform: "none",
              position: "static",
              mb: 1,
            },
          }}
        >
          <GradientText
            sx={{
              fontSize: "3em",
              letterSpacing: "4px",
              zIndex: 11,
              alignSelf: "center",
              //fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            }}
          >
            Collect
          </GradientText>
        </Button>
      </Box>
    </UnstyledModal>
  );
}

export default function RewardModal(props: RewardModalProps) {
  const progression = useAppSelector((state) => state.progression);

  if (props.packId && props.rewards.length < 6) {
    return <PackOpenModal {...props} />;
  }
  return <GenericRewardModal {...props} />;
}
