import AcUnitIcon from "@mui/icons-material/AcUnit";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { useMediaQuery } from "@mui/material";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import runAward from "lib/animation/RewardCanvas";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { buyFreebie } from "lib/progression/slice";
import GradientText from "lib/typography/components/GradientText";
import * as React from "react";
import { setConstantValue } from "typescript";
import Clickable from "lib/audio/components/Clickable";
import Modal from "./Modal";
import { pushModal } from "../slice";
import theme from "theme";
import { setSpotlight } from "lib/navigation/slice";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  marginBottom: 20,

  backgroundColor: "#22222225",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

interface Reward {
  currency: number;
}

const StatHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <GradientText
      variant="h3"
      sx={{
        marginTop: 2,
        fontSize: "2rem",
        display: "inline-block",
        textDecoration: "underline",
        textDecorationColor: "#22222275",
        WebkitTextStrokeColor: "#FFD700",

        color: "transparent",
        WebkitTextStrokeWidth: "1px",
        background: "linear-gradient(180deg, #FFD700 35%, #89023E)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
      }}
      gutterBottom
    >
      {children}
    </GradientText>
  );
};

export interface BuyFreebiesModalProps {
  reward: Reward;
  onClose: (ev: any) => void | (() => void);
}
export default function BuyFreebiesModal({
  onClose,
  reward,
}: BuyFreebiesModalProps) {
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const [spendAmount, setSpendAmount] = React.useState(1);
  const levels = useAppSelector((state) => state.app.levels);
  const store = useAppSelector((state) => state.app.store);
  const nextLevel = progression.level + 1;
  const cost =
    store.fastTrackProgression.xpCost *
    (levels[progression.level].experienceRequired - progression.experience);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  React.useEffect(() => {
    if (progression.tutorialStep === 14) {
      dispatch(
        setSpotlight({
          queryTarget: ``,
          message: {
            text: ``,
            position: isMobile ? "top" : "right",
          },
        })
      );
    }
    window.setTimeout(() => {
      dispatch(
        pushModal({
          componentName: "News",
          props: {
            type: "freebies2",
            title: "Congratulations",
            image: "assets/news/freebies2.jpg",
          },
        })
      );
    }, 1500);
  }, []);
  return (
    <Modal
      onClose={onClose}
      sx={{
        background: "#89023E",
        color: "#FFD700",
      }}
      dialogTitle={
        <GradientText
          sx={{
            fontSize: "3rem",
            background: "linear-gradient(180deg, #FFD700 35%, #89023E)",
            WebkitTextStrokeColor: "#FFD700",

            [theme.breakpoints.down("md")]: {
              fontSize: "clamp(1em, 10vw, 2em)",
            },
          }}
        >
          Buy Freebies
        </GradientText>
      }
      dialogHeaderContent={
        <>
          <Typography
            sx={{
              verticalAlign: "middle",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            Spend {spendAmount}{" "}
            <PremiumCurrencyIcon
              sx={{ marginLeft: -0.1, marginTop: 0.3, marginRight: 0.4 }}
              fontSize="small"
            />{" "}
            for {spendAmount * 5}
            <LocalActivityIcon
              sx={{ marginLeft: 0.1, marginTop: 0.2, marginRight: -0.1 }}
              fontSize="small"
            />
          </Typography>
          <Slider
            sx={{ paddingTop: "2em" }}
            aria-label="Freebie Exchange"
            defaultValue={1}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            color="secondary"
            onChange={(ev, newValue) => {
              setSpendAmount(newValue as number);
            }}
            max={Math.min(progression.currency.premium, 100)}
          />
        </>
      }
      dialogBodyContent={
        <Card
          sx={{
            background: "#89023E",
            color: "#FFD700",
            overflow: "visible",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "0.6em 1.8em 0",
            [theme.breakpoints.down("md")]: { p: 0 },
          }}
        >
          <IconButton
            color={"secondary"}
            sx={{
              "@keyframes buyFreebiesHue": {
                "0%": {
                  filter: "hue-rotate(0deg)",
                },
                "100%": {
                  filter: "hue-rotate(360deg)",
                },
              },
              fontSize: "3em",
              animation: "buyFreebiesHue 10s infinite",
              "&:hover": {
                animationSpeed: "2s",
              },
            }}
          >
            {[
              ...Array.from(
                Array(spendAmount - 1 <= 10 ? spendAmount - 1 : 10)
              ),
            ].map((el, idx) => (
              <LocalActivityIcon
                key={idx}
                sx={{
                  fontSize: "3em",
                  position: "absolute",
                  left: `${((idx + 1) / 8) * (idx % 2 ? 1 : -1)}em`,
                  filter: "brightness(0.8)",
                  zIndex: "-1",
                }}
              />
            ))}
            <LocalActivityIcon sx={{ fontSize: "3em" }} />
          </IconButton>
          <Typography>{spendAmount * 5} Pack</Typography>
          <Typography>
            Press the Freebie button in a game to get an instant perfect!
          </Typography>
          <Clickable>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                bottom: -10,
                width: "70%",
                justifySelf: "center",
                fontSize: "1.2em",
                borderRadius: "2em",
              }}
              onClick={(ev) => {
                if (progression.currency.premium >= spendAmount) {
                  dispatch(
                    buyFreebie({
                      freebies: spendAmount * 5,
                      premium: spendAmount,
                    })
                  );
                  onClose(ev);

                  runAward(
                    spendAmount * 5,
                    "/assets/particle/freebie_particle.svg"
                  );
                } else {
                  dispatch(
                    pushModal({
                      componentName: "BuyPremiumCurrency",
                      props: {},
                    })
                  );
                }
              }}
            >
              {spendAmount}{" "}
              <PremiumCurrencyIcon
                sx={{ marginLeft: -0.1, marginRight: 0.4 }}
                fontSize="small"
              />{" "}
              Buy
            </Button>
          </Clickable>
        </Card>
      }
    />
  );
}
