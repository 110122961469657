import { CardActionArea, Chip, Divider, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";
import Box from "@mui/system/Box";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tilt from "react-parallax-tilt";
import { pushModal } from "lib/modal/slice";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Clickable from "lib/audio/components/Clickable";
import { Goal, refreshGoal } from "lib/progression/slice";
import ChallengeProgressTracker from "lib/agility/components/ChallengeProgressTracker";
import { generateGoal } from "lib/goal/generateGoal";
import GradientText from "lib/typography/components/GradientText";
import theme from "theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import RewardItem from "lib/progression/components/RewardItem";
import playSound from "lib/audio/sfx";

export interface CardWithGoal extends Goal {
  asRefresh?: undefined | never;
  refreshIndex?: number;
  inProgressOnClick?: () => void;
}

export interface CardWithRefresh {
  type?: never;
  progress?: never;
  requiredAmount?: never;
  requirementMeta?: never;
  rewards?: never;
  asRefresh: boolean;
  hasClaimed?: never;
  refreshIndex?: number;
  inProgressOnClick?: () => void;
}

export type GoalCardProps = CardWithGoal | CardWithRefresh;
export default function GoalCard({
  type,
  progress,
  requiredAmount,
  requirementMeta,
  rewards,
  asRefresh,
  refreshIndex,
  hasClaimed,
  inProgressOnClick,
}: GoalCardProps) {
  const progression = useAppSelector((state) => state.progression);
  if (hasClaimed) {
    asRefresh = true;
  }

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  progress = typeof progress === "undefined" ? 0 : progress;
  requiredAmount = typeof requiredAmount === "undefined" ? 0 : requiredAmount;
  const canClaim = !hasClaimed && progress >= requiredAmount;
  const [isClaiming, setIsClaiming] = React.useState(false);
  const dispatch = useAppDispatch();
  let title;
  let imageSrc =
    type === "playCard"
      ? `/assets/goal/${type}/${requirementMeta}.svg`
      : `/assets/goal/${type}.svg`;

  switch (type) {
    case "playCard":
      title = "Play games with a specific Card";
      switch (requirementMeta) {
        case "forest":
          title = "Play games with a Forest Card";
          break;
        case "mountain":
          title = "Play games with a Mountain Card";
          break;
        case "space":
          title = "Play games with a Space Card";
          break;
      }
      break;
    case "perfectStreak":
      title = "Get a perfect streak";
      break;
    case "playWord":
      title = "Play a Words game";
      break;
    case "playEquations":
      title = "Play an Equations game";
      break;
    case "freeClaim":
      title = "Claim this free reward";
      break;
    case "exchangeTokens":
      title = `Exchange ${requirementMeta} Tokens`;
      imageSrc = `assets/particle/token/token_currency_${requirementMeta}.svg`;
      break;
  }

  if (asRefresh) {
    imageSrc = "/assets/goal/refresh.svg";
  }

  let content = (
    <Clickable>
      <Card
        sx={{
          color: grey[50],
          width: "100%",
          height: "100%",
          background: canClaim
            ? "radial-gradient(#FFD70080, #FFD70025 0%, transparent 20%, transparent 60%, #FFD70025 80%, #FFD70080)"
            : "radial-gradient(#ffffff 0%, #ffffff10 80%,  transparent 100%)",
          backgroundSize: "400% 100%",
          backgroundPosition: "100% 50%",
          backgroundRepeat: "no-repeat",
          border: "1px solid #ffffff85",
          [theme.breakpoints.down("md")]: {},
        }}
      >
        <CardActionArea
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
          onClick={() => {
            if (asRefresh) {
              const goalData = generateGoal();
              dispatch(
                refreshGoal({
                  goalIndex: refreshIndex!,
                  goalData: {
                    type: goalData.type,
                    requiredAmount: goalData.requiredAmount,
                    progress:
                      goalData.type === "freeClaim" ? 1 : goalData.progress,
                    rewards: goalData.rewards,
                    hasClaimed: false,
                    requirementMeta: goalData.requirementMeta || "",
                  },
                })
              );
            } else {
              if (canClaim) {
                playSound("goal-complete");
                setIsClaiming(true);
                window.setTimeout(() => {
                  setIsClaiming(false);
                  dispatch(
                    pushModal({
                      componentName: "Reward",
                      props: { rewards, source: `goal.${refreshIndex}` },
                    })
                  );
                }, 250);
              } else {
                if (inProgressOnClick) {
                  inProgressOnClick();
                }
              }
            }
          }}
        >
          <CardMedia
            component="img"
            image={imageSrc}
            alt={type}
            sx={{
              height: "100%",
              objectFit: "contain",
              objectPosition: "center",
              maxWidth: "20%",
              filter: "drop-shadow(0px 0px 5px rgba(255, 255, 255, 1))",

              [theme.breakpoints.down("md")]: {
                width: "50%",
                margin: "auto",
              },
            }}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              [theme.breakpoints.down("md")]: {},
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              sx={{
                textShadow: "0 0 0.4em #222",
                fontFamily: "Sriracha",
                [theme.breakpoints.down("md")]: {
                  flex: 1,

                  fontSize: "clamp(1em, 4vw, 1.8em)",
                },
              }}
              component="div"
            >
              {asRefresh ? "New Goal Incoming" : title}
            </Typography>

            {!asRefresh && (
              <>
                <ChallengeProgressTracker
                  sx={{
                    filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 1))",
                    ".MuiTypography-root": {
                      color: grey[50],
                      textShadow: "0 0 0.4em #fafafa",
                    },
                  }}
                  color="secondary"
                  currentChallenge={Math.min(progress, requiredAmount)}
                  totalChallenges={requiredAmount}
                  value={(progress / requiredAmount) * 100}
                />
                {canClaim && (
                  <GradientText
                    dark
                    sx={{
                      textAlign: "center",
                      bottom: "0.2em",
                      position: "absolute",
                      top: 0,
                      fontSize: "3em",
                      minHeight: "3.2em",
                      "@keyframes goalCardPulse": {
                        "0%": {
                          WebkitTextStrokeColor: "#89023E",
                        },
                        "100%": {
                          WebkitTextStrokeColor: "#FFD700",
                        },
                      },
                      animation: "goalCardPulse 4s infinite alternate",
                    }}
                  >
                    Claim Reward
                  </GradientText>
                )}
              </>
            )}
          </CardContent>

          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {!asRefresh && rewards && (
              <>
                <Typography sx={{}}>Rewards</Typography>
                {rewards.map((reward, index) => (
                  <RewardItem color="#FFD700" {...reward} />
                ))}
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Clickable>
  );

  return (
    <Box
      sx={{
        position: "relative",

        width: "100%",
        height: "100%",
        "@keyframes GoalCardIsClaiming": {
          "0%": {
            filter: "sepia(0) contrast(100%)",
          },
          "100%": {
            filter: "sepia(1) contrast(1000%)",
          },
        },
        animation: isClaiming ? "GoalCardIsClaiming 0.25s forwards" : "none",
        [theme.breakpoints.down("md")]: { width: "100%" },
      }}
    >
      {!asRefresh && (
        <CardMedia
          component="img"
          image={"/assets/goal/refresh.svg"}
          alt={"Get new goal"}
          height="40"
          sx={{
            objectFit: "contain",
            objectPosition: "center",
            top: -20,
            right: -20,
            borderRadius: "50%",
            position: "absolute",
            width: "40px",
            background: "#fafafa",
            boxShadow: "0 0 0 1px #22222220",
            zIndex: 11,
            padding: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(
              pushModal({
                componentName: "GoalRefresh",
                props: { refreshIndex },
              })
            );
          }}
        />
      )}

      {content}
    </Box>
  );
}
