import { guaranteedRewards } from "lib/progression/poolReward";
import { Pack } from "lib/progression/slice";
import { v4 as uuidv4 } from "uuid";
export function generatePack(): Pack {
  return {
    id: uuidv4(),
    name: "Basic Pack",
    amount: 1,
    rewards: guaranteedRewards(5),
    type: "pack",
  };
}
