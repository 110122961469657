import * as React from "react";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "lib/hooks/react-redux";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { levelUpStart } from "../slice";
import { useAppDispatch } from "lib/hooks/react-redux";
import { getCurrentLevelData } from "lib/data/slice";
import GradientText from "lib/typography/components/GradientText";
import visualizer, { setBaseX } from "lib/audio/visualizer";
// @ts-ignore
import Fireworks from "lib/progression/animation/Fireworks";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import theme from "theme";
import { PaperProps, useMediaQuery } from "@mui/material";
import millify from "millify";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { BoxProps } from "@mui/system";

interface BorderLinearProgressProps extends LinearProgressProps {
  isReceivingExperience: boolean;
}
const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "isReceivingExperience",
})<BorderLinearProgressProps>(({ theme, value, isReceivingExperience }) => ({
  height: 60,
  opacity: 1,
  border: "1px solid #22222250",
  borderRadius: 30,

  filter: isReceivingExperience ? "brightness(2)" : "brightness(1)",
  [`&:hover, &.fadeIn`]: {
    opacity: 1,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(255,255,255,0.3)",
  },
  transition: "all 2s ease-out",
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 30,

    background:
      theme.palette.mode === "light"
        ? "conic-gradient(from -90deg, hsl(51deg 100% 40%),  hsl(51deg 100% 50%), hsl(51deg 100% 40%)) fixed"
        : "#308fe8",
    backgroundPositionX: `${(25 * (100 - (value || 0))) / 100}vw`,
    animation: "progressBarIdle 2s ease-out infinite alternate",
    transition: "all 1s ease",

    "@keyframes progressBarIdle": {
      "0%": {
        filter: "contrast(75%)",
      },
      "100%": {
        filter: "contrast(120%) drop-shadow(16px 16px 20px #FFD700)",
      },
    },
  },
  WebkitMaskImage:
    "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,1), rgba(0,0,0,0.4), rgba(0,0,0,0.6))",
  MaskImage:
    "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,1), rgba(0,0,0,0.4), rgba(0,0,0,0.6))",
}));

interface LevelBadgeContainerProps extends BoxProps {
  isInGame: boolean;
}
const LevelBadgeContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isInGame",
})<LevelBadgeContainerProps>(({ theme, isInGame }) => ({
  position: "absolute",
  pointerEvents: "none",
  left: "50%",
  top: "0em",

  //transform: "translateX(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 11,
  transform: "translate(-50%, 0%)",

  "@keyframes levelBadgeIdle": {
    "0%": {
      transformOrigin: "bottom",
      transform: "translate(-50%, -100%)  rotateX(100deg)",
      opacity: 0,
    },
    "5%": {
      transform: "translate(-50%, 0%) scale(1, 1) rotateX(0deg)",
      opacity: 1,
      transformOrigin: "bottom",
      [theme.breakpoints.down("md")]: {
        transform: "translate(-50%, -50%) scale(1, 1) rotateX(0deg)",
      },
    },
    "95%": {
      transform: "translate(-50%, 0%) scale(1, 1) rotateX(0deg)",
      opacity: 1,
      transformOrigin: "top",
    },
    "100%": {
      transform: "translate(-50%, 100%)  rotateX(-100deg)",
      opacity: 0,
      transformOrigin: "top",
    },
  },

  "@keyframes levelBadgeIdleMobile": {
    "0%": {
      transformOrigin: "bottom",
      transform: "translate(-50%, -100%)  rotateX(100deg)",
      opacity: 0,
    },
    "5%": {
      transform: "translate(-50%, -50%) scale(1, 1) rotateX(0deg)",
      opacity: 1,
      transformOrigin: "bottom",
    },
    "95%": {
      transform: "translate(-50%, -50%) scale(1, 1) rotateX(0deg)",
      opacity: 1,
      transformOrigin: "top",
    },
    "100%": {
      transform: "translate(-50%, 50%)  rotateX(-100deg)",
      opacity: 0,
      transformOrigin: "top",
    },
  },
  animation: isInGame ? "none" : "levelBadgeIdle 7.5s ease-out forwards",
  [theme.breakpoints.down("md")]: {
    top: "50%",
    transform: "translate(-50%,-50%)",
    animation: isInGame
      ? "none"
      : "levelBadgeIdleMobile 7.5s ease-out forwards",
  },
}));
const LevelBadge = ({
  textToRender,
  label,
  content,
  isReceivingExperience,
  isInGame,
}: {
  textToRender: "experience" | "level";
  isReceivingExperience: boolean;
  content: React.ReactNode;
  label: string;
  isInGame: boolean;
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <LevelBadgeContainer isInGame={isInGame} key={textToRender}>
      <GradientText
        sx={{
          position: "relative",
          WebkitTextStrokeWidth: isReceivingExperience ? "4px" : "2px",
          alignItems: "center",
          justifyContent: "center",
          verticalAlign: "middle",
          justifySelf: "center",
          fontSize: "2.4em",
          lineHeight: "1.2",
          [theme.breakpoints.down("md")]: {
            fontSize: "clamp(1em, 8vw, 2em)",
            WebkitTextStrokeWidth: isReceivingExperience ? "2px" : "0.5px",
          },
          [theme.breakpoints.down("sm")]: {
            WebkitTextStrokeWidth: "0px",
            fontSize:
              textToRender === "level"
                ? "clamp(1.2em, 6vw, 2em)"
                : "clamp(0.7em, 6vw, 1.6em)",
            backgroundImage: "linear-gradient(180deg, #FFD700 0, #FFD700 60%)",
          },
          "@keyframes pulse": {
            "0%": {
              filter: "contrast(100%) drop-shadow(2px 2px 2px #222222)",
            },

            "100%": {
              filter: "contrast(175%) drop-shadow(2px 2px 2px #222222)",
            },
          },
          "@keyframes pulseMobile": {
            "0%": {
              filter: "contrast(100%) drop-shadow(1px 1px 1px #222222)",
            },

            "100%": {
              filter: "contrast(175%) drop-shadow(1px 1px 1px #222222)",
            },
          },
          "@keyframes receivingExperience": {
            "0%": {
              backgroundImage: "linear-gradient(180deg, #FFD700 0, #FFD700)",
            },
            "50%": {
              WebkitTextStrokeColor: "#FFD700",
            },
            "100%": {
              WebkitTextStrokeColor: "#89023E",
            },
          },
          animation: isReceivingExperience
            ? "receivingExperience 1s ease-in"
            : isMobile
            ? "pulseMobile 1.4s ease-in infinite alternate"
            : "pulse 1.4s ease-in infinite alternate",
        }}
        lineHeight="1.6"
        variant="h6"
      >
        {content}
      </GradientText>
    </LevelBadgeContainer>
  );
};

export default function ProgressBar() {
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const currentLevelData = useAppSelector(getCurrentLevelData);
  const modals = useAppSelector((state) => state.modal.modals);
  const [textToRender, setTextToRender] = useState<"level" | "experience">(
    "level"
  );
  const isLevelUpModalOpen = modals.find(
    (modal) => modal.componentName === "LevelUp"
  );
  const [className, setClassName] = useState("");
  const [isLevellingUp, setIsLevellingUp] = useState(false);

  const [isReceivingExperience, setIsReceivingExperience] = useState(false);
  const animationCanvasOffsetX =
    -1000 +
    (progression.experience / currentLevelData.experienceRequired) * 1900;

  const isInGame = useAppSelector((state) => state.navigation.isInGame);
  React.useEffect(() => {
    window.setInterval(() => {
      setTextToRender((prev) => (prev === "level" ? "experience" : "level"));
    }, 7500);
  }, []);
  React.useEffect(() => {
    if (isInGame) {
      //visualizer("game");
      //setBaseX(animationCanvasOffsetX);
    } else {
      //visualizer("title");
      //setBaseX(animationCanvasOffsetX);
    }
  }, [isInGame]);
  React.useEffect(() => {
    setIsReceivingExperience(true);

    window.setTimeout(() => {
      setIsReceivingExperience(false);
    }, 500);
  }, [progression.experience]);
  React.useEffect(() => {
    window.setTimeout(() => {
      //setBaseX(animationCanvasOffsetX);
    }, 400);
    if (!currentLevelData.experienceRequired) {
      return;
    }
    setClassName("fadeIn");
    Fireworks({
      x: (progression.experience / currentLevelData.experienceRequired) * 100,
      y: 30,
    });
    const timer = window.setTimeout(() => {
      setClassName("");
    }, 1000);

    if (
      !isLevellingUp &&
      !isLevelUpModalOpen &&
      progression.experience >= currentLevelData.experienceRequired
    ) {
      window.setTimeout(() => {
        dispatch(levelUpStart({ ...currentLevelData }));
        setIsLevellingUp(false);
      }, 1000);
      setIsLevellingUp(true);
    }
    return () => {
      window.clearTimeout(timer);
    };
  }, [progression.experience, currentLevelData.experienceRequired]);

  const experienceProgress =
    (progression.experience / currentLevelData.experienceRequired) * 100;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let experienceTooltip = `${progression.experience}/${
    currentLevelData.experienceRequired
  } experience until level ${progression.level + 1}`;

  const experienceLabel = `${
    isMobile
      ? millify(progression.experience)
      : progression.experience.toLocaleString()
  }/${
    isMobile
      ? millify(currentLevelData.experienceRequired)
      : currentLevelData.experienceRequired.toLocaleString()
  }`;
  return (
    <>
      <Tooltip title={experienceTooltip}>
        <Box
          sx={{ position: "relative", overflow: "hidden", borderRadius: 30 }}
        >
          {/* <canvas
            key="audio-visualizer-canvas"
            style={{
              left: 0,
              top: 0,
              width: "100%",
              position: "absolute",
              height: "60px",
            }}
            id="visualizer-canvas"
          /> */}
          <BorderLinearProgress
            isReceivingExperience={isReceivingExperience}
            className={`${className} experienceProgressBar`}
            variant="determinate"
            sx={{ zIndex: "1" }}
            value={experienceProgress < 100 ? experienceProgress : 100}
          ></BorderLinearProgress>{" "}
          <Box
            sx={{
              top: "1px",
              width: "99%",
              borderRadius: 30,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              opacity: 0.4,
              background: "linear-gradient(white, transparent)",
              height: "42%",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        </Box>
      </Tooltip>
      <canvas
        style={{
          position: "fixed",
          pointerEvents: "none",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",

          zIndex: "10000",
        }}
        height="100%"
        width="100%"
        className="fireworks"
      ></canvas>
      <Box
        sx={{
          overflow: "hidden",
          position: "absolute",
          bottom: 4,
          left: 0,
          right: 0,
          top: 0,
          pointerEvents: "none",
        }}
      >
        <LevelBadge
          isInGame={isInGame}
          isReceivingExperience={isReceivingExperience}
          textToRender={textToRender}
          content={
            textToRender === "experience" && !isInGame ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {experienceLabel}
                <AutoAwesomeIcon
                  fontSize="large"
                  sx={{
                    stroke: "#FFD700",
                    strokeWidth: "1px",
                    path: {
                      fill: `url(#GameIconGradientFill)`,
                    },
                    // textShadow:
                    //   "1px 1px 1px #89023E, 1px -1px 1px #89023E, -1px 1px 1px #89023E, -1px -1px 1px #89023E",
                    // color: "#FFD700",
                    [theme.breakpoints.down("md")]: {
                      width: "0.9em",
                      height: "0.9em",
                    },
                    [theme.breakpoints.down("sm")]: {
                      width: "clamp(0.3em, 4vw, 0.8em)",
                      height: "clamp(0.3em, 4vw, 0.8em)",
                    },
                  }}
                ></AutoAwesomeIcon>
              </Box>
            ) : (
              `Level ${progression.level}`
            )
          }
          label={
            textToRender === "experience" && !isInGame
              ? experienceLabel
              : `Level ${progression.level}`
          }
        ></LevelBadge>
      </Box>
    </>
  );
}
