import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import GradientText from "lib/typography/components/GradientText";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import * as React from "react";
import SignUpPage from "./sign-up";
import SignInPage from "./sign-in";
import GuestPage from "./guest";
import {
  facebookLogin,
  googleLogin,
  signIn,
  twitterLogin,
} from "lib/data/firebase";
import { useAppSelector } from "lib/hooks/react-redux";
import Container from "@mui/material/Container";
import AgilityPage from "./agility";
import Clickable from "lib/audio/components/Clickable";
import theme from "theme";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import AppleIcon from "@mui/icons-material/Apple";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { motion } from "framer-motion";
const Transition = ({ children }: { children: React.ReactNode }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    {children}
  </motion.div>
);
const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box mt={"auto"}>
      <div style={{ marginTop: "auto" }}>
        <Box>
          <Divider
            sx={{
              color: "hsl(0deg 0% 70%)",
              fontSize: "0.9em",
              letterSpacing: "2px",
              textShadow:
                "1px 1px 1px #222, 1px -1px 1px #222, -1px 1px 1px #222, -1px -1px 1px #222",
              marginTop: 2,
              marginBottom: 1,
            }}
          >
            Email
          </Divider>
          <Clickable>
            <Button
              onClick={() => {
                // onClose();
                navigate("sign-up");
              }}
              size="large"
              sx={{
                letterSpacing: "6px",
              }}
              autoFocus
              variant="contained"
              color="success"
            >
              <GradientText
                sx={{
                  fontSize: "clamp(1.2em, 5vw, 3em)",
                  whiteSpace: "nowrap",
                  letterSpacing: "3px",
                  background:
                    "linear-gradient(180deg, #FFD700 35%, transparent)",
                  [theme.breakpoints.down("md")]: {
                    WebkitTextStrokeWidth: "1px",
                  },
                }}
              >
                Sign Up
              </GradientText>
            </Button>
          </Clickable>
          <Clickable>
            <Button
              onClick={() => {
                // onClose();
                navigate("sign-in");
              }}
              size="large"
              sx={{
                letterSpacing: "6px",
              }}
              variant="contained"
              color="primary"
            >
              <GradientText
                sx={{
                  fontSize: "clamp(1.2em, 5vw, 3em)",
                  whiteSpace: "nowrap",
                  letterSpacing: "3px",
                  background:
                    "linear-gradient(180deg, #FFD700 35%, transparent)",
                  [theme.breakpoints.down("md")]: {
                    WebkitTextStrokeWidth: "1px",
                  },
                }}
              >
                Sign In
              </GradientText>
            </Button>
          </Clickable>
        </Box>
        <Divider
          sx={{
            color: "hsl(0deg 0% 70%)",
            fontSize: "0.9em",
            letterSpacing: "2px",
            textShadow:
              "1px 1px 1px #222, 1px -1px 1px #222, -1px 1px 1px #222, -1px -1px 1px #222",
            marginTop: 2,
            marginBottom: 1,
          }}
        >
          Create / Sign In
        </Divider>
        <Clickable>
          <Button
            onClick={() => {
              facebookLogin();
            }}
            size="medium"
            sx={{
              letterSpacing: "2px",
              [theme.breakpoints.down("md")]: {
                width: "50%",
              },
            }}
            variant="contained"
            color="primary"
            startIcon={<FacebookIcon />}
          >
            Facebook
          </Button>
        </Clickable>
        <Clickable>
          <Button
            onClick={() => {
              // onClose();
              // navigate(game.to);
              googleLogin();
            }}
            size="medium"
            sx={{
              letterSpacing: "2px",
              margin: "0 3em",
              [theme.breakpoints.down("md")]: {
                width: "50%",
                mt: 1,
                mb: 1,
              },
            }}
            variant="contained"
            color="primary"
            startIcon={<GoogleIcon />}
          >
            Google
          </Button>
        </Clickable>
        <Clickable>
          <Button
            onClick={() => {
              twitterLogin();
            }}
            size="medium"
            sx={{
              letterSpacing: "2px",
              [theme.breakpoints.down("md")]: {
                width: "50%",
              },
            }}
            variant="contained"
            color="primary"
            startIcon={<TwitterIcon />}
          >
            Twitter
          </Button>
        </Clickable>

        <Clickable>
          <Typography
            mt={6}
            mb={2}
            sx={{
              cursor: "pointer",
              color: "#FFD700",
              fontSize: "0.9em",
              letterSpacing: "2px",
              textShadow:
                "1px 1px 1px #222, 1px -1px 1px #222, -1px 1px 1px #222, -1px -1px 1px #222",
              [theme.breakpoints.down("md")]: { marginTop: 2, marginBottom: 1 },
            }}
            onClick={async () => {
              await signIn({ asGuest: true });
              navigate("/tutorial");
            }}
          >
            - Play as Guest -
          </Typography>
        </Clickable>
      </div>
    </Box>
  );
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function HomePage() {
  const userState = useAppSelector((state) => state.user);
  console.log(userState);
  const navigate = useNavigate();
  const shouldShowBackButton = location.pathname !== "/";
  const query = useQuery();
  const error = query.get("error");

  if (location.pathname === "/guest") {
    return <GuestPage />;
  }
  if (userState.uid) {
    return <AgilityPage />;
  }
  console.log("?");
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        zIndex: 9999,
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
      }}
    >
      <Card
        sx={{
          color: "#FFD700",
          overflow: "visible",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "clamp(40em, 35vw, 50em)",
          height: "40vh",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          background: "transparent",
          borderRadius: "100% 100% 70px 70px",
          [theme.breakpoints.down("md")]: { width: "100vw", height: "50vh" },
          "@keyframes indexLogoAnimate": {
            "0%": {
              filter: "drop-shadow(2px 4px 6px #FFD700)",
            },
            "50%": {
              filter: "drop-shadow(10px 10px 20px #89023E)",
            },
            "100%": {
              filter: "drop-shadow(2px 4px 6px #FFD700)",
            },
          },
        }}
      >
        {shouldShowBackButton && (
          <IconButton
            aria-label="back"
            color="secondary"
            onClick={() => {
              navigate("/");
            }}
            sx={{ position: "absolute", top: 0, left: 0 }}
          >
            <ArrowBackIcon sx={{ fontSize: "3em" }} />
          </IconButton>
        )}
        <Box
          sx={{
            height: "10em",
            marginRight: "auto",
            textAlign: "center",
            top: "-8em",
            position: "absolute",
            width: "auto",

            [theme.breakpoints.down("md")]: {
              height: "auto",
              width: "80vw",
              top: "-5em",
            },
          }}
        >
          <img
            src={"/logo819.png"}
            style={{
              width: "100%",
              animation: "indexLogoAnimate 10s ease-in-out infinite",
              [theme.breakpoints.down("md")]: { height: "auto", width: "90vw" },
            }}
            alt="ThriveMind Logo"
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none",
            overflow: "hidden",
            borderRadius: "100% 100% 70px 70px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            "&:after": {
              zIndex: -1,
              content: '""',
              position: "absolute",
              top: "-250%",
              left: "-200%",
              width: "500%",
              height: "500%",
              opacity: 0.6,
              background:
                "conic-gradient(transparent, #0071b7, transparent,#0071b7, transparent,#0071b7, transparent,#0071b7, transparent,#0071b7, transparent,#0071b7, transparent, #0071b7, transparent,#0071b7, transparent,#0071b7, transparent,#0071b7, transparent,#0071b7, transparent,#0071b7, transparent)",
              "@keyframes homePageBackgroundAnimate": {
                "0%": {
                  transform: "rotate(0deg)",
                  filter: "hue-rotate(0deg)",
                },
                "50%": { filter: "hue-rotate(90deg)" },
                "100%": {
                  transform: "rotate(360deg)",
                  filter: "hue-rotate(0deg)",
                },
              },
              animation: "homePageBackgroundAnimate 20s linear infinite",
            },
          }}
        >
          <Box
            sx={{
              pointerEvents: "all",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <Transition key="home">
                    <LandingPage />
                  </Transition>
                }
              />
              <Route
                path="/sign-up"
                element={
                  <Transition key="sign-up">
                    <SignUpPage
                      onSuccess={() => {
                        navigate("/tutorial");
                      }}
                    />
                  </Transition>
                }
              />
              <Route
                path="/sign-in"
                element={
                  <Transition key="sign-in">
                    <SignInPage />
                  </Transition>
                }
              />
              <Route
                path="/guest"
                element={
                  <Transition key="guest">
                    <GuestPage />
                  </Transition>
                }
              />
            </Routes>
          </Box>
        </Box>{" "}
        {error && (
          <Box
            sx={{
              color: "#fafafa",
              width: "100%",
              position: "absolute",
              bottom: -24,
              background:
                "linear-gradient(to left, transparent, red, transparent)",
            }}
          >
            {error === "UserNotFound" &&
              "There was an error trying to retrieve your account."}
          </Box>
        )}
        <Box
          sx={{
            position: "fixed",
            fontSize: "0.9em",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            gap: 4,
            display: "flex",
            flexWrap: "wrap",

            [theme.breakpoints.down("md")]: {
              gap: 1,
            },
          }}
        >
          <a href="/forgot-password">Forgot Password?</a>
          <a href="mailto:help@thrivemind.app">Email A Human?</a>
          <a target="_blank" href="/terms.html">
            Terms & Conditions
          </a>
          <a target="_blank" href="/privacy.html">
            Privacy Policy
          </a>
        </Box>
      </Card>
    </Box>
  );
}
