import * as React from "react";
import { useAppDispatch } from "lib/hooks/react-redux";
import {
  addCardToHistory,
  charge,
  clearSelectableGames,
  GameTypes,
  goalActionProgress,
  setAchievementProgress,
  setCurrentCardId,
  setZenExperience,
  setZenLevel,
  setZenResetDate,
} from "lib/progression/slice";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { green, blue, teal, red } from "@mui/material/colors";
import { keyframes } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import GradientText from "lib/typography/components/GradientText";
import { useAppSelector } from "lib/hooks/react-redux";
import { randomProperty } from "lib/utils/object";
import { drawerWidth } from "lib/navigation/components/NavBar";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import CountdownAnimation from "lib/game/animation/Countdown";
import {
  setIsInGame,
  setLastGame,
  setIsLeavingGame,
} from "lib/navigation/slice";
import play from "lib/audio/sfx";
import AnswerPad from "lib/input/components/AnswerPad";

import theme from "theme";
import ChallengeProgressTracker from "lib/agility/components/ChallengeProgressTracker";
import StopWatch from "lib/agility/components/StopWatch";
interface GameBasics {
  children: React.ReactNode;
  type: GameTypes;
  isComplete?: number;
  sx?: React.CSSProperties;

  challenges: any[];
  challengeIndex: number;
  stopWatchPercent: number;

  answerPad?: {
    onMousePositionUpdate: ({ x, y }: { x: number; y: number }) => void;
    onUseFreebie: () => void;
    onAnswerSelect: (answer: string) => void;
    options: string[];
  };
}
interface GameWithOnGameReady extends GameBasics {
  onGameReady: () => void;
  skipCountdown?: never;
}
interface GameWithSkipCountdown extends GameBasics {
  onGameReady?: never;
  skipCountdown: boolean;
}
type GameProps = GameWithOnGameReady | GameWithSkipCountdown;

function Game({
  children,
  onGameReady,
  skipCountdown,
  isComplete = 0,
  answerPad,
  challenges,
  challengeIndex,
  stopWatchPercent,
}: GameProps) {
  const dispatch = useAppDispatch();
  const [gameStartCountDown, setGameStartCountDown] = React.useState(4);
  let countdownAnimation;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (!skipCountdown) {
    React.useEffect(() => {
      play("game-countdown-number");
      const GameStartCountDownTimer = () => {
        setGameStartCountDown((gameStartCountDown) => {
          if (gameStartCountDown > 0) {
            if (gameStartCountDown === 2) {
              play("game-countdown-go");
            } else if (gameStartCountDown > 2) {
              play("game-countdown-number");
            }
            setTimeout(GameStartCountDownTimer, 750);

            return gameStartCountDown - 1;
          }
          //play("game-countdown-go");
          onGameReady!();
          CountdownAnimation("");
          return 0;
        });
      };
      window.setTimeout(GameStartCountDownTimer, 750);
      CountdownAnimation("#countdown 3|GO!");
    }, []);

    let backgroundImage;
    let WebkitTextStrokeColor;
    switch (gameStartCountDown) {
      case 4:
        backgroundImage = `linear-gradient(100deg, ${green[200]} 55%, ${green[800]})`;
        WebkitTextStrokeColor = green[800];
        break;
      case 3:
        backgroundImage = `linear-gradient(100deg, ${teal[200]} 55%, ${teal[800]})`;
        WebkitTextStrokeColor = green[800];
        break;
      case 2:
        backgroundImage = `linear-gradient(100deg, ${blue[200]} 55%, ${blue[800]})`;
        WebkitTextStrokeColor = red[200];
        break;
      case 1:
      default:
        backgroundImage = `linear-gradient(100deg, ${red[200]} 55%, ${red[800]})`;
        WebkitTextStrokeColor = red[200];
        break;
    }
  }
  React.useEffect(() => {
    if (isComplete) {
      dispatch(setIsLeavingGame(true));
      if (isComplete === 1) {
        play("fanfare");
      } else {
        play("complete-all-perfect");
      }
      CountdownAnimation("✓");
    }
  }, [isComplete]);
  return (
    <>
      {(!skipCountdown || isComplete) && (
        <Box
          sx={{
            zIndex: 10,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: "50%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            transition: "opacity 0.25s ease-out",
            opacity: gameStartCountDown === 0 && !isComplete ? 0 : 1,
            pointerEvents: "none",
          }}
        >
          <canvas className="countdown-canvas"></canvas>
          <div
            style={{ display: "none" }}
            className="countdown-dependencies"
          ></div>
        </Box>
      )}
      <Box
        sx={{
          transition: "all 0.5s ease-in",
          height: "100%",
          zIndex: 10,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          transformOrigin: "bottom",
          ...(isComplete
            ? { opacity: 0, transform: "scale(0.4) translateY(-50%)" }
            : {}),
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.down("md")]: {
              alignItems: "center",
              marginBottom: "1em",
            },
          }}
        >
          <ChallengeProgressTracker
            sx={{
              position: "relative",
              width: "40%",
              marginBottom: "4em",
              [theme.breakpoints.down("md")]: {
                flex: 1,
                marginBottom: 0,
              },
            }}
            currentChallenge={challengeIndex + 1}
            totalChallenges={challenges.length}
            value={((challengeIndex + 1) / challenges.length) * 100}
          />

          {isMobile && (
            <StopWatch
              isReady={gameStartCountDown === 0 && !isComplete}
              value={stopWatchPercent}
              perfectThreshold={49}
              sx={{
                display: "none",
                [theme.breakpoints.down("md")]: { display: "block" },
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          {children}
          {!isMobile && (
            <StopWatch
              isReady={gameStartCountDown === 0 && !isComplete}
              perfectThreshold={49}
              value={stopWatchPercent}
            />
          )}
        </Box>
        {answerPad && (
          <AnswerPad
            onMousePositionUpdate={answerPad.onMousePositionUpdate}
            onUseFreebie={answerPad.onUseFreebie}
            sx={{
              "@keyframes answerPadEntrance": {
                from: {
                  opacity: 0,
                  transform: "translateY(100%)",
                },
                to: {
                  opacity: 1,
                  transform: "translateY(0)",
                },
              },
              animation: "answerPadEntrance 3s cubic-bezier(0.87, 0.03, 1, 1)",
            }}
            options={answerPad.options}
            onSelect={answerPad.onAnswerSelect}
          />
        )}
      </Box>
    </>
  );
}

export default function GameComponent(props: GameProps) {
  const progression = useAppSelector((state) => state.progression);

  const dispatch = useAppDispatch();
  const games = useAppSelector((state) => state.app.games);
  const currentGame = games.find((game) => game.to === location.pathname);

  const navigate = useNavigate();
  const unlockables = useAppSelector((state) => state.app.unlockable.items);
  const currentCardId = progression.currentCardId;
  React.useEffect(() => {
    dispatch(clearSelectableGames());
    let cardId = currentCardId;

    dispatch(addCardToHistory(cardId));

    dispatch(setIsLeavingGame(false));
    dispatch(setIsInGame(true));
    if (currentGame) {
      dispatch(setLastGame(currentGame));
    } else {
      dispatch(
        setLastGame(
          games.find((game) => game.to === `/agility/${props.type}/1`)!
        )
      );
    }

    const currentCard = unlockables.find((card) => card.id === cardId);
    if (currentCard?.categories?.includes("Forest")) {
      dispatch(
        goalActionProgress({ type: "playCard", requirementMeta: "forest" })
      );
    }

    if (currentCard?.categories?.includes("Mountain")) {
      dispatch(
        goalActionProgress({ type: "playCard", requirementMeta: "mountain" })
      );
    }

    if (currentCard?.categories?.includes("Space")) {
      dispatch(
        goalActionProgress({ type: "playCard", requirementMeta: "space" })
      );
    }

    let achievementProgress = {
      ...progression.achievements.progress,
    };
    achievementProgress.gamesPlayed += 1;
    dispatch(setAchievementProgress(achievementProgress));

    const d = new Date();
    const resetDate = d.getTime() / 1000 + 60 * 60;
    dispatch(setZenResetDate(resetDate));
    if (progression.zen.level == 0) {
      dispatch(setZenLevel(1));
      dispatch(setZenExperience(0));
    }
    return () => {
      dispatch(setIsInGame(false));
    };
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 4,
        mb: 4,
        "&:before": {
          position: "absolute",
          display: "block",
          zIndex: -1,
          content: '""',
          backgroundSize: "cover",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        "&:after": {
          position: "fixed",
          display: "block",
          zIndex: 0,
          content: '""',
          opacity: props.isComplete ? 1 : 0.25,
          transition: "opacity 0.5s ease-out",
          backgroundSize: "cover",
          top: "36px",
          right: 0,
          bottom: 0,
        },
        [theme.breakpoints.down("sm")]: {
          mt: "1em",
        },

        ...(props.sx ? props.sx : {}),
      }}
    >
      <Game {...props} />
    </Container>
  );
}
