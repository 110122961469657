import { getCustomer, getProducts, getUserPayments } from "lib/data/firebase";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { assignState, Consumables } from "lib/progression/slice";
import { RootState } from "store";
import { setProducts } from "./slice";

const consumePayments = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  progression: RootState["progression"],
  uid: string,
  userData: RootState["progression"] | Partial<RootState["progression"]>
) => {
  const products = await getProducts();

  const customer = await getCustomer(uid);

  dispatch(setProducts(products));
  let consumables: Consumables = {};
  const payments = await getUserPayments(uid);

  if (userData) {
    if (!userData.consumables) {
      userData.consumables = {};
    }
    console.log({ customer });
    let packageType = ``;
    //const payments = await getUserPayments(user.uid)
    for (let paymentItem of payments) {
      if (!paymentItem.consumed) {
        const rewards = [paymentItem.metadata.item].map(
          (paymentProductItem: any) => {
            const productIndex = products.findIndex(
              (product) => paymentProductItem in product.prices
            );
            const productItem = products[productIndex];

            // const packageSearch = products.find(
            //   (product) =>
            //     paymentProductItem.description === product.digest.prices
            // );
            packageType = (productItem?.digest.metadata as any).packageType;
            return {
              type: "premium_currency",
              amount: parseInt(
                (productItem?.digest.metadata as any).premiumCurrencyAmount
              ),
              id: paymentItem.id,
            };
          }
        );
        consumables[paymentItem.id] = userData.consumables[paymentItem.id] || {
          rewards,
          source: `payment.${packageType}`,
          hasConsumed: false,
        };
      }
    }
    dispatch(
      assignState(
        Object.assign({ ...progression }, userData, {
          consumables: {
            ...userData.consumables,
            ...consumables,
          },
          achievements: {
            progress: {
              ...progression.achievements.progress,
              ...(userData?.achievements?.progress
                ? userData.achievements.progress
                : {}),
            },
          },
        })
      )
    );
  } else {
    console.log("No progression data found");
  }
};
export default consumePayments;
