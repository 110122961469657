import determineGlowRarity from "lib/agility/determineGlowRarity";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import playSound from "lib/audio/sfx";
import { Reward, Unlockable } from "lib/data/slice";
import { useAppDispatch } from "lib/hooks/react-redux";
import { chanceRewards } from "lib/progression/poolReward";
import { award, goalActionProgress } from "lib/progression/slice";
import generateTokenFromRuleData from "lib/token/generateTokenFromRuleData";

interface PerfectOccurredProps {
  perfectStreak: number;
  setPerfectStreak: React.Dispatch<React.SetStateAction<number>>;
  perfects: number;
  setPerfects: React.Dispatch<React.SetStateAction<number>>;
  currentCard: Unlockable;
  challengeLevelIndex: number;
  setItemsDiscovered: React.Dispatch<React.SetStateAction<Reward[]>>;
  setLastGlowRarity: React.Dispatch<React.SetStateAction<number>>;
  mousePosition: { x: number; y: number };
  itemsDiscovered: Reward[];
  dispatch: ReturnType<typeof useAppDispatch>;
}
export default function perfectOccurred({
  perfectStreak,
  setPerfectStreak,
  perfects,
  setPerfects,
  currentCard,
  challengeLevelIndex,
  setItemsDiscovered,
  setLastGlowRarity,
  mousePosition,
  itemsDiscovered,
  dispatch,
}: PerfectOccurredProps) {
  playSound(`streak-${Math.min(13, perfectStreak + 2)}`);
  setPerfectStreak(perfectStreak + 1);
  setPerfects(perfects + 1);
  dispatch(
    goalActionProgress({
      type: "perfectStreak",
      requirementMeta: perfectStreak + 1,
    })
  );
  const rewards = chanceRewards();

  rewards.push(
    generateTokenFromRuleData(currentCard, "perfect", challengeLevelIndex)
  );

  if (rewards.length > 0) {
    dispatch(
      award({
        rewards: rewards,
        dateString: new Date().toISOString(),
      })
    );
    setItemsDiscovered([...itemsDiscovered, ...rewards]);
    setLastGlowRarity(Math.max(determineGlowRarity(rewards), 1));
    runAwardAnimation(
      rewards,
      "agilityCenter",
      {
        x: mousePosition.x,
        y: mousePosition.y,
      },
      { goToEnd: 30 }
    );
  }
}
