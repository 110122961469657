import { bgcolor } from "@mui/system";

var w = window.innerWidth,
  h = window.innerHeight;
var c = { x: w / 2, y: h / 2 };

interface PointType {
  pos: { x: number; y: number };
  vel: { x: number; y: number };
  frame: number;
  ctx: CanvasRenderingContext2D;
}
function Point(
  this: PointType,
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  vx: number,
  vy: number
) {
  this.ctx = ctx;
  this.pos = { x: startX, y: startY };
  this.vel = { x: vx, y: vy };
  this.frame = 1;
}
Point.prototype.update = function () {
  this.frame += 0.0001;
  this.vel.x *= this.frame;
  this.vel.y *= this.frame;
  this.pos.x += this.vel.x;
  this.pos.y += this.vel.y;

  this.ctx.fillRect(this.pos.x, this.pos.y, this.frame * 1, this.frame * 1);
};
var points: any[][] = [];

var bgColor = "rgba(0, 0, 0, 0.0)";
var pointColor = "white";
function anim({
  idx,
  ctx,
  //canvas,
  startX,
  startY,
  total,
  color,
}: {
  idx: number;
  ctx: CanvasRenderingContext2D;
  //canvas: HTMLCanvasElement;
  startX: number;
  startY: number;
  total: number;
  color: string;
}) {
  const canvas: HTMLCanvasElement | null = document.querySelector(
    `.pack-open-canvas[data-idx="${idx}"]`
  );
  console.log({ canvas });
  if (!canvas) {
    console.log("no tick");
    return;
  }
  const animFrame: number = window.requestAnimationFrame(() =>
    anim({ idx, ctx, startX, startY, total, color })
  );
  console.log("tick");
  ctx.fillStyle = color;
  for (var i = 0; i < points[idx].length; ++i) {
    points[idx][i].update();
    if (
      points[idx][i].pos.y < 0 ||
      points[idx][i].pos.y > canvas.height ||
      points[idx][i].pos.x < 0 ||
      points[idx][i].pos.x > canvas.width
    ) {
      points[idx].splice(i, 1);
    }
  }
  while (points[idx].length < total) {
    points[idx].push(
      new (Point as any)(
        ctx,
        startX,
        startY,
        Math.random() * 2 * (Math.random() > 0.5 ? 1 : -1),
        Math.random() * 2 * (Math.random() > 0.5 ? 1 : -1)
      )
    );
  }

  ctx.fillStyle = bgColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  return animFrame;
}
const clearAnimations = () => {
  document
    .querySelectorAll<HTMLCanvasElement>(".pack-open-canvas")
    .forEach((canvas) => {
      if (canvas.dataset.animFrame) {
        window.cancelAnimationFrame(parseInt(canvas.dataset.animFrame));
      } else {
        console.log("Could not find frame.. potential memory leak");
      }
      delete canvas.dataset.continueAnimating;
      //canvas.remove();
      points = [];
    });
};
export default {
  clearAnimations,
  startPackAnimation: ({
    top,
    left,
    width,
    height,
    color,
  }: {
    top: number;
    left: number;
    width: number;
    height: number;
    color: string;
  }) => {
    const size = height;

    const baseX = w / 2 - w / 4 + size / 2;
    const baseY = h / 2 - h / 4 + size / 2;
    const particleGroups = [
      { startX: baseX, startY: 10 },
      { startX: baseX * 2, startY: 10 },
      { startX: baseX, startY: baseY },
      { startX: baseX * 2, startY: baseY },
      { startX: baseX * 1.5, startY: baseY * 2 },
    ];
    points.push([]);
    const idx = points.length - 1;
    var canvas = document.createElement("canvas") as HTMLCanvasElement;
    canvas.className = "pack-open-canvas";
    const container = document.querySelector(".pack-open-canvas-container");
    if (!container) {
      throw new Error("Couldnt find canvas container, something is wrong");
    }
    container.appendChild(canvas);
    canvas.width = size;
    canvas.height = size;
    canvas.style.pointerEvents = "none";
    canvas.style.zIndex = "10000";
    canvas.style.position = "fixed";
    canvas.style.top = `${top}px`;
    canvas.style.left = `${left}px`;
    canvas.style.borderRadius = "100%";
    canvas.style.transition = "all 1s ease-in-out";

    var ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("No context");
    canvas.dataset.idx = `${idx}`;
    const animFrame = anim({
      idx,
      ctx,
      startX: size / 2,
      startY: size / 2,
      total: 100,
      color,
    });
    canvas.dataset.animFrame = `${animFrame}`;
    return animFrame;
  },
};
