import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Box, Divider, IconProps } from "@mui/material";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "./Modal";
import { Level, Reward } from "lib/data/slice";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import RewardItem from "lib/progression/components/RewardItem";
import Clickable from "lib/audio/components/Clickable";
import GradientText from "lib/typography/components/GradientText";
import playSound from "lib/audio/sfx";
import { NewsItemTypes, setHasSeen } from "lib/welcome-news/slice";
import { grey } from "@mui/material/colors";
import theme from "theme";
import QuizIcon from "@mui/icons-material/Quiz";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import RedditIcon from "@mui/icons-material/Reddit";
import DiscordIcon from "lib/social/icon/discord";
import { checkCanRedeem } from "lib/data/firebase";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TollIcon from "@mui/icons-material/Toll";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import { pushModal, resetModals } from "lib/modal/slice";
import { AutoModeShardIcon } from "lib/design-system/components/AutoModeIcon";
import EmailIcon from "@mui/icons-material/Email";
import FilterIcon from "@mui/icons-material/Filter";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import { useMediaQuery } from "@mui/material";
import {
  markTutorialFlagComplete,
  setTutorialStep,
  tutorialTypes,
  TutorialTypes,
} from "lib/progression/slice";
import { Navigate, useNavigate } from "react-router-dom";
import { setSpotlight } from "lib/navigation/slice";
export interface NewsModalProps {
  onClose: (ev: any) => void | (() => void);
  navTo?: string;
  title: string;
  body: string;
  type: NewsItemTypes;
  image: string;
  expiry?: string;
  rewards: Reward[];
  id: string;
}

export default function NewsModal({
  onClose,
  title,
  body,
  image,
  type,

  id,
  rewards,
}: NewsModalProps) {
  const user = useAppSelector((state) => state.user);
  const progression = useAppSelector((state) => state.progression);
  const [didClaimBonus, setDidClaimBonus] = React.useState(false);
  const [canClaimBonus, setCanClaimBonus] = React.useState(false);
  const dispatch = useAppDispatch();
  const shouldFocusContinueButton = didClaimBonus || !canClaimBonus;
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const assignRedeemEligibility = async () => {
    if (tutorialTypes.includes(type as TutorialTypes)) {
      // claim no matter what

      setCanClaimBonus(true);
    } else {
      setCanClaimBonus(await checkCanRedeem(user.uid, id));
    }
  };
  React.useEffect(() => {
    //playSound("modal-load");
    dispatch(setHasSeen(true));
    assignRedeemEligibility();
  }, []);

  const onCloseHandler =
    type === "tokens2"
      ? (ev: React.MouseEvent) => {
          dispatch(resetModals());
          dispatch(setTutorialStep(11));
          navigate("/");
        }
      : (ev: React.MouseEvent) => {
          onClose({ ...ev, didClaimBonus });
          if (type === "bettingBasic") {
            dispatch(
              setSpotlight({
                queryTarget: isMobile ? ".bottom-bar-play" : `.play-tile`,
                message: {
                  text: `Play another game`,
                  position: isMobile ? "top" : "right",
                },
              })
            );
          } else if (type === "freebies2") {
            dispatch(setTutorialStep(15));
          }
        };
  return (
    <Modal
      dialogProps={{
        sx: {
          ".MuiDialog-container > .MuiPaper-root": {
            background: "transparent",
          },
          [theme.breakpoints.down("md")]: {
            "& .MuiPaper-root": {
              margin: "0",
              padding: "0",
            },
            "& .MuiTypography-root": {
              padding: 1,
              "& .MuiTypography-root": {
                padding: 0,
              },
            },
            "& .MuiDialogContent-root": {
              padding: 1,
            },
          },
        },
      }}
      sx={{
        background: "transparent",
        backdropFilter: "blur( 4px )",
        backgroundImage: `url("${image}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        overflow: "visible",
      }}
      onClose={onCloseHandler}
      dialogTitle={
        <Box
          sx={{
            backdropFilter: "blur( 4px )",
            background: "transparent",
            borderRadius: "20px",
            padding: "0.2em 0.6em",
            fontSize: "1.1em",
            display: "flex",
            alignItems: "center",
            //[theme.breakpoints.down("md")]: { padding: 0 },
          }}
        >
          {renderIcon(type)}
          <GradientText
            sx={{
              fontSize: "1em",

              filter: "drop-shadow(2px 2px 2px #222)",
            }}
          >
            {title}
          </GradientText>
        </Box>
      }
      dialogBodyContent={
        <Box
          sx={{
            filter: "drop-shadow(2px 2px 2px #222)",

            boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.2 )",
            backdropFilter: "blur( 4px )",

            borderRadius: "20px",
            color: "#FFD700",
            padding: "0.2em 1.6em",
            fontSize: "1.8em",

            [theme.breakpoints.down("md")]: {
              fontSize: "clamp(0.9em, 7vw, 1.2em)",
              padding: "0.2em 0.6em",
            },
          }}
        >
          {body}
          {renderContentForType(type)}
        </Box>
      }
      dialogActions={
        <Box
          sx={{
            overflow: "visible",
            gap: 2,
            display: "flex",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              width: "100%",
            },
          }}
        >
          {rewards && rewards.length > 0 && (
            <Button
              onClick={() => {
                if (canClaimBonus) {
                  setDidClaimBonus(true);
                  playSound("award-item");
                  if (tutorialTypes.includes(type as TutorialTypes)) {
                    dispatch(markTutorialFlagComplete(type as TutorialTypes));
                  }
                }
              }}
              sx={{
                letterSpacing: "2px",
                alignSelf: "center",
                transition: "all 1.2s ease-out",
                ...(shouldFocusContinueButton
                  ? {
                      color: grey[300],
                      borderColor: grey[300],
                      pointerEvents: "none",

                      "&:hover": {
                        borderColor: grey[300],
                      },
                    }
                  : {}),

                [theme.breakpoints.down("md")]: { width: "100%" },
              }}
              size="large"
              autoFocus={!shouldFocusContinueButton}
              variant={shouldFocusContinueButton ? "outlined" : "contained"}
              color="secondary"
            >
              {canClaimBonus ? "Collect" : "Already Claimed"}
              {shouldFocusContinueButton && (
                <Box
                  sx={{
                    display: "flex",
                    pointerEvents: "all",
                    position: "relative",
                    height: "10px",
                    alignItems: "center",
                  }}
                >
                  {rewards.map((reward) => (
                    <RewardItem
                      sx={{
                        margin: 0,
                        "& .MuiAvatar-root": {
                          width: 24,
                          height: 24,
                        },
                      }}
                      color="#FFD700"
                      {...reward}
                    />
                  ))}{" "}
                </Box>
              )}
            </Button>
          )}
          <Clickable>
            <Button
              onClick={onCloseHandler}
              sx={{
                letterSpacing: "2px",
              }}
              size="large"
              variant={shouldFocusContinueButton ? "contained" : "outlined"}
              color="secondary"
              autoFocus={shouldFocusContinueButton}
            >
              Continue
            </Button>
          </Clickable>
        </Box>
      }
    />
  );
}
const renderIcon = (type: string) => {
  let IconComponent = null;
  const iconProps: IconProps = {
    color: "secondary",
    sx: {
      background: `radial-gradient(${grey[900]},transparent 80%)`,
      fontSize: "1em",
      stroke: "#FFD700",
      strokeWidth: "1px",
      marginRight: 1,
      fill: `url(#GameIconGradientFill)`,
      [theme.breakpoints.down("md")]: { fontSize: "1.4em" },
    },
  };
  switch (type) {
    case "bettingBasic":
      IconComponent = AcUnitIcon;
      break;
    case "newCardRewards":
      IconComponent = BurstModeIcon;
      break;
    case "premium":
      IconComponent = PremiumCurrencyIcon;
      break;
    case "freebies":
    case "freebies2":
      IconComponent = LocalActivityIcon;
      break;
    case "playMore":
      IconComponent = VideogameAssetIcon;
      break;
    case "tokens":
      IconComponent = TollIcon;
      break;
    case "tokens2":
      IconComponent = SportsScoreOutlinedIcon;
      break;
  }

  if (IconComponent === null) return null;
  // @ts-ignore
  return <IconComponent {...iconProps} />;
};

const renderContentForType = (type: string) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  switch (type) {
    case "betaNews":
      return (
        <p>
          Got something to say?{" "}
          <Tooltip title={"help@thrivemind.app"}>
            <a href={`mailto:help@thrivemind.app?subject=${user.uid}`}>
              Contact us!
            </a>
          </Tooltip>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 8,
              mt: 4,
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              href="https://discord.gg/E9zAtAqAJS"
              target="_blank"
              startIcon={
                <DiscordIcon
                  sx={{
                    svg: {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                />
              }
            >
              Discord
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              href="https://reddit.com/r/thrivemind"
              target="_blank"
              startIcon={<RedditIcon />}
            >
              Reddit
            </Button>
          </Box>
          <Typography
            sx={{
              fontSize: "0.5em",
              textAlign: "center",
              mt: 4,
              mb: 0,
              [theme.breakpoints.down("md")]: { fontSize: "1em" },
            }}
          >
            (Communities have gifts)
          </Typography> */}
        </p>
      );
    case "bettingBasic":
      return (
        <>
          <p>
            Spend{" "}
            <AcUnitIcon
              color="secondary"
              sx={{
                fontSize: "1em",
                marginLeft: "0.1em",
                verticalAlign: "middle",
              }}
            />
            Shards to upgrade your challenge level for more difficult games and
            better rewards
          </p>
        </>
      );
    case "intro":
      return (
        <>
          <p>Play to unlock more ways to level up</p>
        </>
      );
    case "newCardRewards":
      return (
        <>
          <p>
            Use{" "}
            <BurstModeIcon
              color="secondary"
              sx={{
                fontSize: "1em",
                marginLeft: "0.1em",
                verticalAlign: "middle",
              }}
            />
            Collect on the{" "}
            <CalendarViewMonthIcon
              color="secondary"
              sx={{
                fontSize: "1em",
                marginLeft: "0.1em",
                verticalAlign: "middle",
              }}
            />
            Cards screen when you discover new cards for{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              <AutoAwesomeIcon
                color="secondary"
                sx={{
                  fontSize: "1em",
                  marginLeft: "0.1em",
                  verticalAlign: "middle",
                }}
              />
              Experience
            </span>
          </p>{" "}
        </>
      );
    case "premium":
      return (
        <p>
          <PremiumCurrencyIcon
            color="secondary"
            sx={{
              fontSize: "1em",
              marginLeft: "0.1em",
              verticalAlign: "middle",
            }}
          />
          Strands can be used to buy card packs.
        </p>
      );
    case "freebies":
      return (
        <>
          <p>
            <LocalActivityIcon
              color="secondary"
              sx={{
                fontSize: "1em",
                marginLeft: "0.1em",
                verticalAlign: "middle",
              }}
            />
            Freebies can be used in games to answer perfectly
          </p>{" "}
        </>
      );
    case "freebies2":
      return (
        <>
          <p>
            You've got the basics of ThriveMind, reach level 10 for more
            features!
          </p>{" "}
        </>
      );
    case "playMore":
      return (
        <>
          <p>Play more games to unlock new features!</p>{" "}
        </>
      );

    case "tokens":
      return (
        <>
          <p>
            The{" "}
            <FilterIcon
              color="secondary"
              sx={{
                fontSize: "1em",
                marginLeft: "0.1em",
                verticalAlign: "middle",
              }}
            />
            Cards you choose to play on will award different{" "}
            <TollIcon
              color="secondary"
              sx={{
                fontSize: "1em",
                marginLeft: "0.1em",
                verticalAlign: "middle",
              }}
            />
            Tokens
          </p>
        </>
      );
    case "tokens2":
      return (
        <>
          <p>
            You can use{" "}
            <TollIcon
              color="secondary"
              sx={{
                fontSize: "1em",
                marginLeft: "0.1em",
                verticalAlign: "middle",
              }}
            />
            Tokens to complete{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              <SportsScoreOutlinedIcon
                color="secondary"
                sx={{
                  fontSize: "1em",
                  marginLeft: "0.1em",
                  verticalAlign: "middle",
                }}
              />
              Goals
            </span>
            , let's go complete one now
          </p>
        </>
      );
    default:
      return null;
  }
};
