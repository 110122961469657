import * as React from "react";
import Words from "lib/agility/components/Words";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import { shuffleArray } from "lib/utils/array";
import initialize, { startGame } from "lib/capacity/matching/game.js";
import Button from "@mui/material/Button";
import GamePage from "lib/game/components/Game";

export default function AgilityWordsPage() {
  const container = React.useRef(null);
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const [hasInitialized, setHasInitialized] = React.useState(false);
  const [hasStarted, setHasStarted] = React.useState(false);

  const [gameBackground, setGameBackground] = React.useState("");
  const [isComplete, setIsComplete] = React.useState(false);
  React.useEffect(() => {
    if (container.current) {
      setHasInitialized((previousState) => {
        if (previousState === false) {
          initialize(container.current);
        }
        return true;
      });
    }
  }, [container.current, setHasInitialized]);
  return <></>;
  // return (
  //   <GamePage
  //     type="words"
  //     gameBackground={gameBackground}
  //     setGameBackground={setGameBackground}
  //     isComplete={isComplete}
  //     skipCountdown
  //   >
  //     <div
  //       style={{ overflow: "hidden", maxHeight: "80vh" }}
  //       ref={container}
  //     ></div>
  //     {!hasStarted && (
  //       <Button
  //         onClick={async () => {
  //           setHasStarted(true);
  //           const { hits, perfects } = await startGame();
  //           setIsComplete(true);
  //           window.setTimeout(() => {
  //             dispatch(
  //               pushModal({
  //                 componentName: "AssignmentComplete",
  //                 props: {
  //                   hits: {
  //                     amount: hits,
  //                     experience:
  //                       hits *
  //                       appState.challenge.rules.hitRewards.filter(
  //                         (reward) => reward.type === "experience"
  //                       )[0].amount[0],
  //                     currency:
  //                       hits *
  //                       appState.challenge.rules.hitRewards.filter(
  //                         (reward) => reward.type === "basic_currency"
  //                       )[0].amount[0],
  //                   },
  //                   perfects: {
  //                     amount: perfects,
  //                     experience:
  //                       perfects *
  //                       appState.challenge.rules.perfectRewards.filter(
  //                         (reward) => reward.type === "experience"
  //                       )[0].amount[0],
  //                     currency:
  //                       perfects *
  //                       appState.challenge.rules.perfectRewards.filter(
  //                         (reward) => reward.type === "basic_currency"
  //                       )[0].amount[0],
  //                   },
  //                   completion: {
  //                     experience:
  //                       appState.challenge.rules.completionRewards.filter(
  //                         (reward) => reward.type === "experience"
  //                       )[0].amount[0],
  //                     currency:
  //                       appState.challenge.rules.completionRewards.filter(
  //                         (reward) => reward.type === "basic_currency"
  //                       )[0].amount[0],
  //                   },
  //                 },
  //               })
  //             );
  //           }, 1200);
  //         }}
  //         size="large"
  //         sx={{
  //           fontFamily: "'Sriracha', cursive",
  //           fontSize: "2em",
  //           letterSpacing: "6px",
  //           position: "absolute",
  //           bottom: 100,
  //           left: "50%",
  //           transform: "translateX(-50%)",
  //         }}
  //         autoFocus
  //         variant="contained"
  //         color="success"
  //       >
  //         Start
  //       </Button>
  //     )}
  //   </GamePage>
  // );
}
