import * as React from "react";
import Equations from "lib/agility/components/Equations";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import { shuffleArray } from "lib/utils/array";
import { useParams } from "react-router-dom";
import { ChallengePossibility, WeightedReward } from "lib/data/slice";
import { goalActionProgress } from "lib/progression/slice";
import { generateRewardFromSet } from "lib/progression/poolReward";

function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function AgilityEquationsPage() {
  const { challengeLevel } = useParams();
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const progression = useAppSelector((state) => state.progression);
  const [currentChallenge, setCurrentChallenge] = React.useState("");
  const [challenges, setChallenges] = React.useState<ChallengePossibility[]>(
    []
  );
  const [answerPadOptions, setAnswerPadOptions] = React.useState<string[]>([]);
  const unlockables = useAppSelector((state) => state.app.unlockable.items);

  const modalState = useAppSelector((state) => state.modal);
  const challengeLevelIndex = parseInt(challengeLevel || "1") - 1;
  const bonusRewards = useAppSelector(
    (state) => state.app.challenge.rules.bonusRewards
  );

  React.useEffect(() => {
    let generatedChallenges: ChallengePossibility[] = [];
    let options: number[] = [];
    let potentialOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const pluckRandomOption = () =>
      potentialOptions.splice(
        Math.floor(Math.random() * potentialOptions.length),
        1
      )[0];
    switch (challengeLevelIndex) {
      case 0: {
        options = [pluckRandomOption(), pluckRandomOption()];
        for (let i = 0; i < 10; i++) {
          const answer = Math.random() > 0.5 ? options[0] : options[1];
          const leftHand = randomInteger(0, answer);
          const rightHand = answer - leftHand;
          generatedChallenges.push({
            id: `${i}`,
            question: `${leftHand} + ${rightHand}`,
            answer: `${leftHand + rightHand}`,
          });
        }

        break;
      }
      case 1:
        options = [
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
        ];
        for (let i = 0; i < 10; i++) {
          const answer = options[(options.length * Math.random()) << 0];
          const leftHand = randomInteger(answer, 9);
          const rightHand = leftHand - answer;

          generatedChallenges.push({
            id: `${i}`,
            question: `${leftHand} - ${rightHand}`,
            answer: `${leftHand - rightHand}`,
          });
        }

        break;
      case 2:
        options = [
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
        ];
        for (let i = 0; i < 10; i++) {
          const operand = Math.random() < 0.5 ? "-" : "+";
          let leftHand;
          let rightHand;

          const answer = options[(options.length * Math.random()) << 0];
          if (operand === "+") {
            leftHand = randomInteger(0, answer);
            rightHand = answer - leftHand;
          } else {
            leftHand = randomInteger(answer, 9);
            rightHand = leftHand - answer;
          }

          generatedChallenges.push({
            id: `${i}`,
            question: `${leftHand} ${operand} ${rightHand}`,
            answer: `${
              operand === "+" ? leftHand + rightHand : leftHand - rightHand
            }`,
          });
        }

        break;
      case 3: {
        potentialOptions = [4, 6, 8, 9, 20, 50, 60];
        let potentialQuestionStart = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25];
        options = [
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
        ];
        for (let i = 0; i < 15; i++) {
          const answer = options[(options.length * Math.random()) << 0];
          const operand = "*";
          let leftHand;
          let rightHand;
          shuffleArray(potentialQuestionStart);
          for (let potentialQuestion of potentialQuestionStart) {
            if (
              answer % potentialQuestion === 0 &&
              answer / potentialQuestion > 1
            ) {
              leftHand = potentialQuestion;
              rightHand = answer / potentialQuestion;
              break;
            }
          }
          generatedChallenges.push({
            id: `${i}`,
            question: `${leftHand} × ${rightHand}`,
            answer: `${answer}`,
          });
        }
        break;
      }
      case 4: {
        potentialOptions = [4, 6, 8, 9, 10, 12, 15, 18, 20, 22];
        let potentialQuestionStart = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25];
        options = [
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
        ];
        for (let i = 0; i < 20; i++) {
          const operands = ["/", "*"];
          const opIndex = Math.round(Math.random() * (operands.length - 1));
          const operand = operands[opIndex];
          let leftHand;
          let rightHand;

          const answer = options[(options.length * Math.random()) << 0];
          shuffleArray(potentialQuestionStart);
          switch (operand) {
            case "/":
              for (let potentialQuestion of potentialQuestionStart) {
                if (answer * potentialQuestion < 100) {
                  rightHand = potentialQuestion;
                  leftHand = answer * rightHand;
                  break;
                }
              }
              break;
            case "*":
              for (let potentialQuestion of potentialQuestionStart) {
                if (
                  answer % potentialQuestion === 0 &&
                  answer / potentialQuestion > 1
                ) {
                  leftHand = potentialQuestion;
                  rightHand = answer / potentialQuestion;
                  break;
                }
              }
              break;
          }

          generatedChallenges.push({
            id: `${i}`,
            question: `${leftHand} ${operand === "*" ? "×" : "÷"} ${rightHand}`,
            answer: `${answer}`,
          });
        }

        break;
      }
      case 5:
        potentialOptions = [4, 6, 8, 9, 10, 12, 15, 18, 20, 22];
        let potentialQuestionStart = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25]; // Multiply/divide only
        options = [
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
          pluckRandomOption(),
        ];
        const operands = ["+", "-", "*", "/"];
        for (let i = 0; i < 25; i++) {
          const opIndex = Math.round(Math.random() * (operands.length - 1));
          const operand = operands[opIndex];
          let leftHand;
          let rightHand;

          const answer = options[(options.length * Math.random()) << 0];
          switch (operand) {
            case "+":
              leftHand = randomInteger(0, answer);
              rightHand = answer - leftHand;
              break;
            case "-":
              leftHand = randomInteger(answer, Math.max(...options) * 2);
              rightHand = leftHand - answer;
              break;
            case "*":
              for (let potentialQuestion of potentialQuestionStart) {
                if (
                  answer % potentialQuestion === 0 &&
                  answer / potentialQuestion > 1
                ) {
                  leftHand = potentialQuestion;
                  rightHand = answer / potentialQuestion;
                  break;
                }
              }

              break;
            case "/":
              for (let potentialQuestion of potentialQuestionStart) {
                if (answer * potentialQuestion < 100) {
                  rightHand = potentialQuestion;
                  leftHand = answer * rightHand;
                  break;
                }
              }
              break;
          }

          generatedChallenges.push({
            id: `${i}`,
            question: `${leftHand} ${
              operand === "*" ? "×" : operand === "/" ? "÷" : operand
            } ${rightHand}`,
            answer: `${answer}`,
          });
        }

        break;
    }
    //shuffleArray(rawChallenges);
    setChallenges(generatedChallenges);
    setCurrentChallenge(generatedChallenges[0].id);
    setAnswerPadOptions(
      options.sort((a, b) => a - b).map((option) => `${option}`)
    );
    dispatch(goalActionProgress({ type: "playEquations" }));
  }, []);

  return (
    <Equations
      challengeLevelIndex={challengeLevelIndex}
      answerPadOptions={answerPadOptions}
      challenges={challenges}
      currentChallenge={currentChallenge}
      onNextChallenge={() => {
        const index = challenges.findIndex(
          (challenge) => challenge.id === currentChallenge
        );

        setCurrentChallenge(challenges[index + 1].id);
      }}
      onChallengeSuccess={({
        hits,
        perfects,
        itemsDiscovered,
        gameBackground,
      }) => {
        const currentCard = unlockables.find(
          (unlockable) => unlockable.id === gameBackground
        )!;
        const index = challenges.findIndex(
          (challenge) => challenge.id === currentChallenge
        );
        if (
          modalState.modals.find(
            (modal) => modal.componentName === "AssignmentComplete"
          )
        ) {
          return;
        }
        if (index === challenges.length - 1) {
          /** Bonus Reward */
          const bonusRewardsFormatted = bonusRewards.map((reward) => {
            let type = reward.type;
            if (type === "token_currency") {
              const availableTokenTypes = currentCard.categories;
              type = `token_currency_${
                availableTokenTypes[
                  (availableTokenTypes.length * Math.random()) << 0
                ]
              }`;
            }

            return {
              ...reward,

              type,
            };
          });

          const winningReward = generateRewardFromSet(bonusRewardsFormatted);

          const otherRewards = [];
          let currentPool = [
            ...bonusRewardsFormatted.filter(
              (item: WeightedReward) => item.id != winningReward.id
            ),
          ];
          while (otherRewards.length < 4) {
            const otherReward = generateRewardFromSet(currentPool);
            currentPool.filter(
              (item: WeightedReward) => item.id != otherReward.id
            );
            otherRewards.push({
              ...otherReward,
              amount:
                otherReward.amount[
                  otherReward.type === "token_currency"
                    ? currentCard.rarity
                    : challengeLevelIndex
                ],
            });
          }

          dispatch(
            pushModal({
              componentName: "AssignmentComplete",
              props: {
                title: `${
                  unlockables.find(
                    (unlockable) => unlockable.id === gameBackground
                  )!.name
                }`,
                hits: {
                  amount: hits,
                  experience: Math.round(
                    hits *
                      appState.challenge.rules.hitRewards.filter(
                        (reward) => reward.type === "experience"
                      )[0].amount[challengeLevelIndex] +
                      appState.challenge.rules.hitRewards.filter(
                        (reward) => reward.type === "experience"
                      )[0].amount[challengeLevelIndex] *
                        (progression.betAmount / 10000)
                  ),
                  currency:
                    hits *
                    appState.challenge.rules.hitRewards.filter(
                      (reward) => reward.type === "basic_currency"
                    )[0].amount[challengeLevelIndex],
                },
                perfects: {
                  amount: perfects,
                  experience: Math.round(
                    perfects *
                      appState.challenge.rules.perfectRewards.filter(
                        (reward) => reward.type === "experience"
                      )[0].amount[challengeLevelIndex] +
                      appState.challenge.rules.perfectRewards.filter(
                        (reward) => reward.type === "experience"
                      )[0].amount[challengeLevelIndex] *
                        (progression.betAmount / 10000)
                  ),
                  currency:
                    perfects *
                    appState.challenge.rules.perfectRewards.filter(
                      (reward) => reward.type === "basic_currency"
                    )[0].amount[challengeLevelIndex],
                },
                completion: {
                  experience: Math.round(
                    appState.challenge.rules.completionRewards.filter(
                      (reward) => reward.type === "experience"
                    )[0].amount[challengeLevelIndex] +
                      appState.challenge.rules.completionRewards.filter(
                        (reward) => reward.type === "experience"
                      )[0].amount[challengeLevelIndex] *
                        (progression.betAmount / 10000)
                  ),
                  currency: appState.challenge.rules.completionRewards.filter(
                    (reward) => reward.type === "basic_currency"
                  )[0].amount[challengeLevelIndex],
                },
                itemsDiscovered,
                bonusReward: {
                  win: {
                    ...winningReward,
                    amount:
                      winningReward.amount[
                        winningReward.type === "token_currency"
                          ? currentCard.rarity
                          : challengeLevelIndex
                      ] ?? 1,
                  },
                  otherRewards,
                },
              },
            })
          );
        }
      }}
    />
  );
}
