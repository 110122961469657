import * as React from "react";
import Words from "lib/agility/components/Words";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import { shuffleArray } from "lib/utils/array";
import { useParams } from "react-router-dom";
import { ChallengePossibility, WeightedReward } from "lib/data/slice";
import { randomWord } from "lib/data/static/word-association-service";
import { goalActionProgress } from "lib/progression/slice";
import { generateRewardFromSet } from "lib/progression/poolReward";
import { searchWord } from "lib/data/static/word-association-service";

export default function AgilityWordsPage() {
  const { challengeLevel } = useParams();
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const modalState = useAppSelector((state) => state.modal);
  const progression = useAppSelector((state) => state.progression);
  const [currentChallenge, setCurrentChallenge] = React.useState("");
  const [challenges, setChallenges] = React.useState<ChallengePossibility[]>(
    []
  );

  const unlockables = useAppSelector((state) => state.app.unlockable.items);
  const challengeLevelIndex = parseInt(challengeLevel || "1") - 1;

  const [answerPadOptions, setAnswerPadOptions] = React.useState<string[]>([]);
  const bonusRewards = useAppSelector(
    (state) => state.app.challenge.rules.bonusRewards
  );
  React.useEffect(() => {
    let potentialOptions = [
      ...appState.challenge.source.agilityWords.possibility[challengeLevelIndex]
        .items,
    ];
    const pluckRandomOption = () =>
      potentialOptions.splice(
        Math.floor(Math.random() * potentialOptions.length),
        1
      )[0];
    let rawChallenges = [
      ...appState.challenge.source.agilityWords.possibility[
        challengeLevelIndex
      ].items.map((question, idx) => ({ question, answer: "-", id: `${idx}` })),
    ];
    shuffleArray(rawChallenges);
    let options: string[] = [];

    let challengeAmount = 10;
    let wordOptionAmount = 2;
    switch (challengeLevelIndex) {
      case 2:
        wordOptionAmount = 3;
        break;
      case 3:
        challengeAmount = 15;
        wordOptionAmount = 4;
        break;
      case 4:
        challengeAmount = 20;
        wordOptionAmount = 5;
        break;
      case 5:
        challengeAmount = 25;
        wordOptionAmount = 7;
        break;
      case 6:
        challengeAmount = 30;
        wordOptionAmount = 9;
        break;
    }

    while (options.length < wordOptionAmount) {
      options.push(pluckRandomOption());
    }

    let challenges = [];
    const getWordChallenges = (async () => {
      let results: string[] = [];
      let searchResults: string[][] = [];
      for (let i = 0; i < wordOptionAmount; i++) {
        const searchResult = await searchWord(options[i]);
        //shuffleArray(searchResult);
        searchResults[i] = searchResult;
      }
      while (results.length < challengeAmount) {
        let randomWordOptionIndex = Math.floor(
          Math.random() * wordOptionAmount
        );
        let randomWordIndex = Math.floor(
          Math.random() * searchResults[randomWordOptionIndex].length
        );
        let randomWord = searchResults[randomWordOptionIndex][randomWordIndex];
        //debugger;
        if (
          results.indexOf(randomWord) === -1 &&
          options.indexOf(randomWord) === -1
        ) {
          results.push(randomWord);
        }
      }

      return [...new Set(results)];
    })();
    getWordChallenges.then((wordChallenges: string[]) => {
      shuffleArray(wordChallenges as string[]);

      challenges = wordChallenges
        .slice(0, challengeAmount)
        .map((chal, idx) => ({
          question: chal,
          answer: "-",
          id: `${chal}-${idx}`,
        }));

      setChallenges(challenges);
      setCurrentChallenge(challenges[0].id);
    });

    setAnswerPadOptions(options.sort((a, b) => a.length - b.length));

    dispatch(goalActionProgress({ type: "playWord" }));
  }, []);

  return (
    <Words
      challengeLevelIndex={challengeLevelIndex}
      answerPadOptions={answerPadOptions}
      challenges={challenges}
      currentChallenge={currentChallenge}
      onNextChallenge={() => {
        const index = challenges.findIndex(
          (challenge) => challenge.id === currentChallenge
        );
        setCurrentChallenge(challenges[index + 1].id);
      }}
      onChallengeSuccess={({
        hits,
        perfects,
        totalWordsLength,
        itemsDiscovered,
        gameBackground,
      }) => {
        const index = challenges.findIndex(
          (challenge) => challenge.id === currentChallenge
        );

        if (
          modalState.modals.find(
            (modal) => modal.componentName === "AssignmentComplete"
          )
        ) {
          return;
        }
        if (index === challenges.length - 1) {
          /** Main Reward from performance */
          const wordLengthMultiplier = totalWordsLength / 100;
          const baseHitExp =
            hits *
            appState.challenge.rules.hitRewards.filter(
              (reward) => reward.type === "experience"
            )[0].amount[challengeLevelIndex];
          const basePerfectExp =
            perfects *
            appState.challenge.rules.perfectRewards.filter(
              (reward) => reward.type === "experience"
            )[0].amount[challengeLevelIndex];

          /** Bonus Reward */
          const bonusRewardsFormatted = bonusRewards.map((reward) => {
            let type = reward.type;
            if (type === "token_currency") {
              const availableTokenTypes = unlockables.find(
                (unlockable) => unlockable.id === gameBackground
              )!.categories;
              type = `token_currency_${
                availableTokenTypes[
                  (availableTokenTypes.length * Math.random()) << 0
                ]
              }`;
            }

            return {
              ...reward,
              type,
            };
          });
          const winningReward = generateRewardFromSet(bonusRewardsFormatted);

          const otherRewards = [];
          let currentPool = [
            ...bonusRewardsFormatted.filter(
              (item: WeightedReward) => item.id != winningReward.id
            ),
          ];
          while (otherRewards.length < 4) {
            const otherReward = generateRewardFromSet(currentPool);
            currentPool.filter(
              (item: WeightedReward) => item.id != otherReward.id
            );
            otherRewards.push({
              ...otherReward,
              amount: otherReward.amount[challengeLevelIndex],
            });
          }

          dispatch(
            pushModal({
              componentName: "AssignmentComplete",
              props: {
                title: `${
                  unlockables.find(
                    (unlockable) => unlockable.id === gameBackground
                  )!.name
                }`,
                hits: {
                  amount: hits,
                  experience:
                    baseHitExp +
                    Math.round(
                      baseHitExp *
                        wordLengthMultiplier *
                        (progression.betAmount / 10000)
                    ),
                  currency:
                    hits *
                    appState.challenge.rules.hitRewards.filter(
                      (reward) => reward.type === "basic_currency"
                    )[0].amount[challengeLevelIndex],
                },
                perfects: {
                  amount: perfects,
                  experience:
                    basePerfectExp +
                    Math.round(
                      basePerfectExp *
                        wordLengthMultiplier *
                        (progression.betAmount / 10000)
                    ),
                  currency:
                    perfects *
                    appState.challenge.rules.perfectRewards.filter(
                      (reward) => reward.type === "basic_currency"
                    )[0].amount[challengeLevelIndex],
                },
                completion: {
                  experience: Math.round(
                    appState.challenge.rules.completionRewards.filter(
                      (reward) => reward.type === "experience"
                    )[0].amount[challengeLevelIndex] +
                      appState.challenge.rules.completionRewards.filter(
                        (reward) => reward.type === "experience"
                      )[0].amount[challengeLevelIndex] *
                        (progression.betAmount / 10000)
                  ),
                  currency: appState.challenge.rules.completionRewards.filter(
                    (reward) => reward.type === "basic_currency"
                  )[0].amount[challengeLevelIndex],
                },

                itemsDiscovered,
                bonusReward: {
                  win: {
                    ...winningReward,
                    amount: winningReward.amount[challengeLevelIndex],
                  },
                  otherRewards,
                },
              },
            })
          );
        }
      }}
    />
  );
}
