import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import HourglassTopTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import { AvatarGroup, Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Clickable from "lib/audio/components/Clickable";
import CardTile from "lib/card/components/CardTile";
import CategoryBadge from "lib/card/components/CategoryBadge";
import CategoryFilter from "lib/card/components/CategoryFilter";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { setCurrentCardId } from "lib/progression/slice";
import GradientText from "lib/typography/components/GradientText";
import { CustomScrollbarsVirtualList } from "pages/card";
import { useNavigate } from "react-router-dom";
import { FixedSizeGrid as Grid } from "react-window";
import Modal from "./Modal";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

export interface CardSelectorModalProps {
  onClose: (ev: any) => void | (() => void);
}

export default function CardSelectorModal({ onClose }: CardSelectorModalProps) {
  const preferences = useAppSelector((state) => state.preferences);
  const navigate = useNavigate();
  const appState = useAppSelector((state) => state.app);
  const { games } = appState;
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const [selectedFilters, setSelectedFilters] = React.useState<number[]>([]);

  const [selectedCardId, setSelectedCardId] = React.useState<string>("");

  const unlockables = [...appState.unlockable.items]
    .sort((a, b) => (a?.unlock?.level ?? 9999) - (b?.unlock?.level ?? 9999))
    .sort(
      (a, b) =>
        (progression.unlocks[a.id] ? 0 : 1) -
        (progression.unlocks[b.id] ? 0 : 1)
    )
    .filter((unlockable) => {
      if (unlockable.id in progression.unlocks) {
        if (selectedFilters.length === 0) {
          return true;
        }
        if (selectedFilters.includes(unlockable.rarity)) {
          return true;
        }
      }
      return false;
    });

  const selectedCard = unlockables.find((u) => u.id === selectedCardId);

  const GridCell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: any;
  }) => {
    const unlockableIndex = columnIndex + Math.floor(rowIndex * 6);

    if (unlockableIndex > unlockables.length - 1) {
      return null;
    }
    const unlockable = unlockables[unlockableIndex];
    const unlockDate = progression.unlocks[unlockable.id]?.dateUnlocked;
    const width = 100;
    const height = 88;
    const isOnCooldown = progression.cardHistory.includes(unlockable.id);
    const [showCooldownTip, setShowCooldownTip] = React.useState(false);
    return (
      <div style={style}>
        <Box
          sx={{
            width: width,
            height: height,
            position: "relative",
          }}
        >
          <Tooltip title={showCooldownTip ? "On cooldown" : unlockable.name}>
            <CardTile
              onClick={() => {
                if (isOnCooldown) {
                  setShowCooldownTip(true);
                } else {
                  setSelectedCardId(unlockable.id);
                }
              }}
              unlockable={unlockable}
              unlockDate={unlockDate}
              sx={{
                filter: isOnCooldown ? "grayscale(100%)" : "none",
                width: width,
                height: height,
                "&:after": {
                  width: width,
                  height: height,
                  //    borderWidth: 20
                },
              }}
            />
          </Tooltip>
          <AvatarGroup
            max={6}
            sx={{
              display: "inline-flex",
              marginLeft: "auto",
              zIndex: -1,
              position: "absolute",
              top: 2,
            }}
          >
            {unlockable.categories.map((category) => (
              <CategoryBadge size="small" name={category} />
            ))}
          </AvatarGroup>
          {isOnCooldown && (
            <HourglassTopTwoToneIcon
              fontSize="large"
              sx={{
                position: "absolute",
                transform: "translate(-50%, -50%)",
                left: "50%",
                top: "50%",
                background: "radial-gradient(#89023E 50%, transparent)",
                width: width / 2,
                height: width / 2,
                borderRadius: "50%",
              }}
            />
          )}
          {selectedCardId === unlockable.id && (
            <DoneOutlineRoundedIcon
              color="success"
              fontSize="large"
              sx={{
                position: "absolute",
                transform: "translate(-50%, -50%)",
                left: "50%",
                top: "50%",
                background: "radial-gradient(#FFD700 50%, transparent)",
                width: width / 2,
                height: width / 2,
                borderRadius: "50%",
              }}
            />
          )}
        </Box>
      </div>
    );
  };
  return (
    <Modal
      onClose={onClose}
      dialogTitle={"Change Upcoming Card"}
      dialogProps={{ maxWidth: "md", fullWidth: true }}
      dialogBodyContent={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex" }}>
            <CategoryFilter
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </Box>
          <Box sx={{ position: "absolute", right: 0 }}></Box>
          <Grid
            columnCount={6}
            columnWidth={140}
            height={600}
            rowCount={Math.ceil(unlockables.length / 6)}
            rowHeight={136}
            outerElementType={CustomScrollbarsVirtualList}
            width={840}
            style={{}}
          >
            {
              // @ts-ignore
              GridCell
            }
          </Grid>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {selectedCard && (
              <GradientText dark>{selectedCard.name}</GradientText>
            )}
            <Clickable>
              <Button
                disabled={selectedCardId === ""}
                onClick={(ev) => {
                  dispatch(setCurrentCardId(selectedCardId));
                  onClose(ev);
                }}
                size="large"
                sx={{
                  marginLeft: "auto",
                  letterSpacing: "6px",
                  marginTop: "1em",
                }}
                autoFocus
                variant="contained"
                color="primary"
              >
                <GradientText
                  sx={{
                    fontSize: "3em",
                    filter: selectedCardId === "" ? "grayscale(100%)" : "none",
                  }}
                >
                  Confirm
                </GradientText>
              </Button>
            </Clickable>
          </Box>
        </Box>
      }
    />
  );
}
