import * as React from "react";

import Typography, { TypographyProps } from "@mui/material/Typography";
import theme from "theme";
interface GradientTextProps extends TypographyProps {
  children: React.ReactNode;
  dark?: boolean;
}
export default function GradientText({
  children,
  dark,
  ...rest
}: GradientTextProps) {
  const background = dark
    ? "linear-gradient(180deg, #89023E 0%, #FFD700)"
    : "linear-gradient(180deg, #FFD700 35%, #89023E)";
  const WebkitTextStrokeColor = dark ? "#89023E" : "#FFD700";
  return (
    <Typography
      variant="body1"
      {...rest}
      sx={{
        display: "block",
        background,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        WebkitTextStrokeWidth: "2px",
        WebkitTextStrokeColor,
        pointerEvents: "none",
        fontSize: "2em",
        userSelect: "none",
        [theme.breakpoints.down("md")]: {
          WebkitTextStrokeWidth: "1px",
        },
        ...rest.sx,
      }}
    >
      {children}
    </Typography>
  );
}
