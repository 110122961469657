import * as React from "react";

import GradientText from "lib/typography/components/GradientText";
import { Box, styled, Typography } from "@mui/material";
import theme from "theme";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import ChallengeProgressTracker from "lib/agility/components/ChallengeProgressTracker";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { grey } from "@mui/material/colors";
import BuyButton from "lib/purchase/components/BuyButton";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { AutoAwesome } from "@mui/icons-material";
import { animate, easeIn } from "popmotion";
import {
  setZenExperience,
  setZenLastSeenExperience,
  setZenLevel,
  setZenResetDate,
} from "lib/progression/slice";
import useAnimationEffect from "lib/animation/useAnimationEffect";
import playSound from "lib/audio/sfx";
import runAwardAnimation from "lib/animation/runAwardAnimation";
const StyledImage = styled("img")``;

const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);
const formatOrdinals = (n: number) => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};
let zenExperienceOnChangeTimer;
let zenBarLabelInterval = 0;
export default function ZenTile() {
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const gameData = useAppSelector((state) => state.app);
  const zenLevel = progression.zen.level;
  const zenExperience = progression.zen.experience;
  const [isLevellingUp, setIsLevellingUp] = React.useState(false);
  const [zenExperienceAmount, setZenExperienceAmount] = React.useState(
    progression.zen.lastSeenExperience
  );
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const [zenBarShowExperience, setZenBarShowExperience] = React.useState(true);
  const zenBarLabel = zenBarShowExperience
    ? `${Math.min(zenExperienceAmount, 100)} / 100`
    : `Level ${zenLevel}`;
  const [previousZenExperienceAmount, setPreviousZenExperienceAmount] =
    React.useState(0);
  const nextZenLevelPrice = 1;
  const zenBonusXP = 100 + gameData.zen.xpBoostPercentage[zenLevel];

  const checkLevelUp = () => {
    if (progression.zen.experience >= 100) {
      setIsLevellingUp(true);
      playSound("zen-level-up");
      window.setTimeout(() => {
        dispatch(setZenLevel(Math.min(zenLevel + 1, 10)));
        dispatch(setZenExperience(0));
        dispatch(setZenLastSeenExperience(0));
        setIsLevellingUp(false);
      }, 1000);
    }
  };

  React.useEffect(() => {
    zenBarLabelInterval = window.setInterval(() => {
      setZenBarShowExperience((val) => !val);
    }, 3000);
    return () => {
      window.clearInterval(zenBarLabelInterval);
    };
  }, []);
  useAnimationEffect(() => {
    const duration = 1500;

    zenExperienceOnChangeTimer = window.setTimeout(() => {
      if (progression.zen.lastSeenExperience > progression.zen.experience) {
        //dispatch(setZenLastSeenExperience(0));
        setZenExperienceAmount(progression.zen.experience);
        checkLevelUp();
        return;
      } else if (
        progression.zen.experience > progression.zen.lastSeenExperience
      ) {
        playSound("in-game-found");
      }

      animate({
        from: progression.zen.lastSeenExperience,
        to: progression.zen.experience,
        duration,

        ease: easeIn,
        //type: "spring",
        onUpdate: (latest: number) => {
          setZenExperienceAmount(Math.round(latest));
          //playSound("zen-experience-gain");
        },
        onComplete: () => {
          if (
            progression.zen.experience != progression.zen.lastSeenExperience
          ) {
            dispatch(setZenLastSeenExperience(progression.zen.experience));
          }
          setHasLoaded(true);
          checkLevelUp();
        },
      });
    }, 100);

    setPreviousZenExperienceAmount(progression.zen.experience);
  }, [progression.zen.experience]);

  return (
    <Box
      onClick={() => {
        setShowMoreInfo((info) => !info);
      }}
      className="ZenTile"
      //onClick={onClick}
      key={zenLevel}
      sx={{
        cursor: "pointer",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        filter: isLevellingUp ? "contrast(10)" : "none",
        transition: isLevellingUp ? "filter 1s ease-in" : "",
        [theme.breakpoints.down("md")]: {
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "space-between",
          // gap: 2,
        },
      }}
    >
      <GradientText
        sx={{
          opacity: zenLevel === 0 ? 0.4 : 1,
          fontSize: "2em",
          whiteSpace: "nowrap",
          textAlign: "center",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          marginBottom: "auto",
          "&:before": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "0",
            background: "linear-gradient(#222 5%,#FFD700 5%, transparent 100%)",
          },
          [theme.breakpoints.down("md")]: {
            padding: 0,
            fontSize: "clamp(0.8rem, 5vw, 1em)",
            WebkitTextStrokeWidth: "0.5px",
          },
        }}
      >
        <SelfImprovementIcon
          color="secondary"
          sx={{
            fontSize: "1em",
            background: `radial-gradient(#22222225,transparent 80%)`,

            // stroke: "#FFD700",
            // strokeWidth: "1px",

            // fill: `url(#GameIconGradientFill)`,
          }}
        />
        {zenLevel > 0 && formatOrdinals(progression.zen.prestige)} Zen{" "}
        {zenLevel === 0 && "Level"}
      </GradientText>

      <Typography
        sx={{
          opacity: zenLevel === 0 ? 0 : 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: grey[50],
          fontSize: "1em",
          letterSpacing: "2px",
          [theme.breakpoints.down("md")]: {
            fontSize: showMoreInfo ? "0.8em" : "1em",
          },
        }}
      >
        <AutoAwesome color="secondary" sx={{ fontSize: "1.1em" }}></AutoAwesome>{" "}
        {zenBonusXP}% {showMoreInfo && "XP Gain"}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flex: "0 0 60%",
          maxHeight: "60%",

          [theme.breakpoints.down("md")]: {
            flex: "0 0 40%",
            maxHeight: "40%",
          },
        }}
      >
        <Box
          sx={{
            background: `url("assets/hero/zen-level/${
              zenLevel === 0 ? 1 : zenLevel
            }.png")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            maxHeight: "100%",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: zenLevel === 0 ? 0.5 : 1,
            transition: "opacity 2s ease-in",
            [theme.breakpoints.down("md")]: { maxHeight: "4em" },
          }}
        />
      </Box>

      {zenLevel > 0 && (
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            color: "hsl(51deg 100% 50%)",
            border: "1px solid hsl(51deg 100% 50%)",
            borderRadius: "0em 0em 2em 2em",
            flexGrow: 1,
            textShadow:
              "1px 1px 1px hsl(51deg 100% 20%), -1px 1px 1px hsl(51deg 100% 20%), -1px -1px 1px hsl(51deg 100% 20%), 1px -1px 1px hsl(51deg 100% 20%)",
            background:
              zenLevel === 10
                ? "hsl(51deg 100% 50%)"
                : zenExperienceAmount > 0
                ? `linear-gradient(90deg, hsl(51deg 100% 50%) 0%, hsl(51deg 100% 50%) ${
                    zenExperienceAmount - 1
                  }%, hsl(51deg 100% 20%) ${zenExperienceAmount}%, hsl(51deg 100% 20%) 100%)`
                : "hsl(51deg 100% 20%) ",
            transition: "background-image .75s ease-in-out",
            [theme.breakpoints.down("md")]: {
              fontSize: showMoreInfo ? "0.7em" : "1em",
            },
          }}
        >
          {(() => {
            if (showMoreInfo) {
              return "Gain Zen Playing Games";
            }

            return (
              <Box
                key={zenBarLabel}
                sx={{
                  "@keyframes ZenBarLabelEntrance": {
                    "0%": { opacity: 0 },
                    "5%": { opacity: 1 },
                    "50%": { opacity: 1 },
                    "95%": { opacity: 1 },
                    "100%": { opacity: 0 },
                  },

                  animation: `ZenBarLabelEntrance 3s ease-in-out forwards`,
                }}
              >
                {isLevellingUp
                  ? "Zen Increased!"
                  : zenLevel === 10
                  ? "Prestige Ready!"
                  : zenBarLabel}
              </Box>
            );
          })()}
        </Box>
      )}
      {zenLevel == -1 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <BuyButton
            sx={{ fontSize: "1em", padding: 0 }}
            onClick={(ev) => {}}
            label={
              <>
                {nextZenLevelPrice.toLocaleString()}{" "}
                <PremiumCurrencyIcon color={"primary"} fontSize="medium" />
              </>
            }
          ></BuyButton>
        </Box>
      )}
      {zenLevel === 0 && (
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: grey[50],
            flexGrow: 1,
          }}
        >
          <SelfImprovementIcon sx={{ color: "white" }}></SelfImprovementIcon>
          {"Ready in 1 Game"}
        </Typography>
      )}
    </Box>
  );
}
