import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { award, levelUpStart } from "lib/progression/slice";
import { AssignmentCompleteModalProps } from "./components/AssignmentComplete";
import { BuyBasicCurrencyModalProps } from "./components/BuyBasicCurrency";
import { BuyPremiumCurrencyModalProps } from "./components/BuyPremiumCurrency";
import { BuyLevelUpModalProps } from "./components/BuyLevelUp";
import { GameTutorialModalProps } from "./components/GameTutorial";
import { LevelUpModalProps } from "./components/LevelUp";
import { UnlockableDetailsModalProps } from "./components/UnlockableDetails";
import { RewardModalProps } from "./components/Reward";
import { SettingsModalProps } from "./components/Settings";
import { CardSelectorModalProps } from "./components/CardSelector";
import { BuyPacksModalProps } from "./components/BuyPacks";
import { BuyFreebiesModalProps } from "./components/BuyFreebies";
import { RecycleCardsModalProps } from "./components/RecycleCards";
import { ChestRewardModalProps } from "./components/ChestReward";
import { GoalRefreshModalProps } from "./components/GoalRefresh";
import { TokenRelationshipModalProps } from "./components/TokenRelationship";
import { UpgradeAccountModalProps } from "./components/UpgradeAccount";
import { NewsModalProps } from "./components/News";
import { FatalErrorModalProps } from "./components/FatalError";
import { CurrentGoalsModalProps } from "./components/CurrentGoals";
import { GameSelectModalProps } from "./components/GameSelect";

type ModalComponent =
  | "AssignmentComplete"
  | "LevelUp"
  | "GameTutorial"
  | "BuyLevelUp"
  | "BuyBasicCurrency"
  | "BuyPremiumCurrency"
  | "UnlockableDetails"
  | "Reward"
  | "Settings"
  | "BuyPacks"
  | "BuyFreebies"
  | "RecycleCards"
  | "ChestReward"
  | "CardSelector"
  | "GoalRefresh"
  | "UpgradeAccount"
  | "TokenRelationship"
  | "News"
  | "FatalError"
  | "CurrentGoals"
  | "GameSelect";

const propertiesNotRequiredToPushModal = "onClose";
interface ModalEntry {
  id: string;
}
type Modal = ModalEntry &
  (
    | {
        componentName: "AssignmentComplete";
        props: Omit<
          AssignmentCompleteModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "LevelUp";
        props: Omit<LevelUpModalProps, typeof propertiesNotRequiredToPushModal>;
      }
    | {
        componentName: "GameTutorial";
        props: Omit<
          GameTutorialModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "BuyLevelUp";
        props: Omit<
          BuyLevelUpModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "BuyFreebies";
        props: Omit<
          BuyFreebiesModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "BuyBasicCurrency";
        props: Omit<
          BuyBasicCurrencyModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "BuyPremiumCurrency";
        props: Omit<
          BuyPremiumCurrencyModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "UnlockableDetails";
        props: Omit<
          UnlockableDetailsModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "Reward";
        props: Omit<RewardModalProps, typeof propertiesNotRequiredToPushModal>;
      }
    | {
        componentName: "Settings";
        props: Omit<
          SettingsModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "CardSelector";
        props: Omit<
          CardSelectorModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "BuyPacks";
        props: Omit<
          BuyPacksModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "RecycleCards";
        props: Omit<
          RecycleCardsModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "ChestReward";
        props: Omit<
          ChestRewardModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "GoalRefresh";
        props: Omit<
          GoalRefreshModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "TokenRelationship";
        props: Omit<
          TokenRelationshipModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "UpgradeAccount";
        props: Omit<
          UpgradeAccountModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "News";
        props: Omit<NewsModalProps, typeof propertiesNotRequiredToPushModal>;
      }
    | {
        componentName: "FatalError";
        props: Omit<
          FatalErrorModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "CurrentGoals";
        props: Omit<
          CurrentGoalsModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
    | {
        componentName: "GameSelect";
        props: Omit<
          GameSelectModalProps,
          typeof propertiesNotRequiredToPushModal
        >;
      }
  );

interface ModalState {
  modals: Modal[];
}

// Define the initial state using that type
const initialState: ModalState = {
  modals: [],
};

let modalIndex = 0;
export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    pushModal: (
      state,
      action: PayloadAction<{ componentName: ModalComponent; props: any }>
    ) => {
      modalIndex++;
      state.modals.push({ ...action.payload, id: `${modalIndex}` });
    },
    popModal: (state) => {
      state.modals.pop();
    },
    resetModals: (state) => {
      state.modals = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(levelUpStart, (state, action) => {
      modalIndex++;
      state.modals.push({
        // @ts-ignore
        componentName: "LevelUp",
        props: action.payload,
        id: `${modalIndex}`,
      });
    });

    builder.addCase(award, (state, action) => {
      modalIndex++;

      // const reduxStore = store.getState();
      // console.log(state);
      // for (let reward of action.payload.rewards) {
      //   if (reward.id && !(reward.id in reduxStore.progression.unlocks)) {
      //     const unlockable = reduxStore.app.unlockable.items.find(
      //       (unlockable) => unlockable.id === reward.id
      //     );
      //     if (unlockable) {
      //       state.modals.push({
      //         // @ts-ignore
      //         componentName: "UnlockableDetails",
      //         props: {
      //           unlockable,
      //         },
      //         id: `${modalIndex}`,
      //       });
      //     }
      //   }
      // }
    });
  },
});

// Action creators are generated for each case reducer function
export const { pushModal, popModal, resetModals } = modalSlice.actions;

export default modalSlice.reducer;
