import { PlaylistAddOutlined } from "@mui/icons-material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentData } from "firebase/firestore";
import { Level, Reward } from "lib/data/slice";

export interface Price {
  active: boolean;
  billing_scheme: string;
  currency: string;
  description: null | string;
  interval: null | number;
  interval_count: null | number;
  metadata: {};
  product: string;
  recurring: null | number;
  tax_behavior: string;
  tiers: null | number;
  tiers_mode: null | number;
  transform_quantity: null | number;
  trial_period_days: null | number;
  type: string;
  unit_amount: number;
}

export interface ProductDigest {
  active: boolean;
  description: string;
  images: [];
  metadata: {};
  name: string;
  role: null | string;
  tax_code: null | string;
}

interface Product {
  digest: ProductDigest;
  prices: { [key: string]: Price };
}

interface Payment {
  products: Product[];
}

// Define the initial state using that type
const initialState: Payment = { products: [] };

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProducts } = paymentSlice.actions;

export default paymentSlice.reducer;
