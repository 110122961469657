import { initializeApp, getApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  Firestore,
  onSnapshot,
  DocumentData,
  doc,
  updateDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInAnonymously,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { isSupported } from "firebase/messaging";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import {
  getDatabase,
  connectDatabaseEmulator,
  ref,
  set,
  get,
  child,
  update,
} from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { RootState } from "store";
import appData from "./static/app.json";
import { Price, ProductDigest } from "lib/payment/slice";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { NewsItem } from "lib/welcome-news/slice";
import { AppMetaData, AppState, Reward } from "./slice";
import { RedeemableItems } from "lib/redeemable/slice";
import { PaymentIntent } from "@stripe/stripe-js";
import { Progression } from "lib/progression/slice";

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
console.log(process.env.REACT_APP_FIRESTORE_API_KEY);
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRESTORE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRESTORE_APP_ID,
  measurementId: process.env.REACT_APP_FIRESTORE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);

let messaging = null;

isSupported().then((isMessagingSupported) => {
  if (isMessagingSupported) {
    messaging = getMessaging(app);
    console.log(messaging);
  }
});

const functions = getFunctions(getApp());
if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
// const helloWorld = httpsCallable(functions, "helloWorld");
// helloWorld().then((result) => {
//   console.log(result);
//   // Read result of the Cloud Function.
//   /** @type {any} */
// });

export const getBillingProfiles: (
  data: any
) => Promise<{ billingProfiles: any[] }> = async (data: any) => {
  const getBillingProfiles = httpsCallable(functions, "getBillingProfiles");
  const result = await getBillingProfiles(data);
  if (result) {
    return result.data as {
      billingProfiles: any[];
    };
  }

  return { billingProfiles: [] };
};

interface PaymentIntentPayload {}
export const createPaymentIntent: (
  data: any
) => Promise<{ clientSecret: string }> = async (data: any) => {
  const createPaymentIntent = httpsCallable(functions, "createPaymentIntent");
  const result = await createPaymentIntent(data);
  if (result) {
    return result.data as {
      clientSecret: string;
    };
  }

  return { clientSecret: "?" };
};
export const requestNotificationPermission = async () => {
  // getToken(messaging, {
  //   vapidKey:
  //     "BN4yNi1F5w1c-INH50Vq9vnpNRCLfbMO2-6Y1thUuVZj2uOXSxBY0bDiAE3_6qSKYQb44XUvir7lQ5P6OJKqPQw",
  // })
  //   .then((currentToken) => {
  //     if (currentToken) {
  //       // Send the token to your server and update the UI if necessary
  //       // ...
  //       console.log(currentToken);
  //     } else {
  //       // Show permission request UI
  //       console.log(
  //         "No registration token available. Request permission to generate one."
  //       );
  //       // ...
  //     }
  //   })
  //   .catch((err) => {
  //     console.log("An error occurred while retrieving token. ", err);
  //     // ...
  //   });
};

// onMessage(messaging, (payload) => {
//   debugger;
//   console.log("Message received. ", payload);
//   // ...
// });

//const db = getDatabase();
if (location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  //  connectDatabaseEmulator(db, "localhost", 9000);
}

interface WriteUserDataPayload extends Partial<Progression> {
  lastPlayed: number;
  created?: number;
}
export async function writeUserData(
  userId: string,
  data: WriteUserDataPayload
) {
  //const db = getDatabase();

  //set(ref(db, "users/" + userId), data);
  console.log("writing user", { data });
  await setDoc(doc(firestore, "users", userId), data);
  return true;
}

// export async function updateUserData(userId: string, data: Partial<RootState>) {
//   const db = getDatabase();

//   return update(ref(db), { [`users/${userId}`]: data });
// }

export async function getUserData(
  userId: string
): Promise<Partial<RootState["progression"]>> {
  const docRef = doc(firestore, "users", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    //authSignOut();
    //window.location.assign("/");
    return {};
  }

  // const dbRef = ref(getDatabase());
  // return new Promise((resolve, reject) => {
  //   get(child(dbRef, `users/${userId}`))
  //     .then((snapshot) => {
  //       if (snapshot.exists()) {
  //         resolve(snapshot.val());
  //       } else {
  //         console.log("No user data available");
  //         resolve({});
  //       }
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
}

export async function getAppVersion(): Promise<Partial<AppMetaData>> {
  const docRef = doc(firestore, "meta", "version");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    throw new Error("Could not get app details");
  }

  // const dbRef = ref(getDatabase());
  // return new Promise((resolve, reject) => {
  //   get(child(dbRef, `users/${userId}`))
  //     .then((snapshot) => {
  //       if (snapshot.exists()) {
  //         resolve(snapshot.val());
  //       } else {
  //         console.log("No user data available");
  //         resolve({});
  //       }
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
}

export async function getUserRedeemables(userId: string): Promise<any> {
  const redeemablesCol = collection(firestore, "users", userId, "redeemables");
  const redeemablesSnapshot = await getDocs(redeemablesCol);
  const redeemables: RedeemableItems = {};
  await Promise.all(
    redeemablesSnapshot.docs.map(async (doc) => {
      const data = doc.data() as { rewards: Reward[] };
      redeemables[doc.id] = data;
    })
  );

  return redeemables;
}

interface Payment extends PaymentIntent {
  consumed: boolean;
  metadata: {
    item: string;
  };
}

export async function getUserPayments(userId: string) {
  const paymentsCol = collection(firestore, "users", userId, "payments");
  const paymentsSnapshot = await getDocs(paymentsCol);
  const payments: Payment[] = [];
  await Promise.all(
    paymentsSnapshot.docs.map(async (doc) => {
      const data = doc.data() as Payment;
      payments.push(data);
    })
  );

  return payments;
}

export async function markUserPaymentConsumed(
  userId: string,
  paymentId: string
) {
  const docRef = doc(firestore, "users", userId, "payments", paymentId);
  await updateDoc(docRef, { consumed: true });
}

export async function checkCanRedeem(
  userId: string,
  redeemId: string
): Promise<boolean> {
  const docRef = doc(firestore, "users", userId, "redeemables", redeemId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    return !data.hasClaimed;
  } else {
    return true;
  }
}

export async function checkRedeemableExists(
  redeemId: string
): Promise<{ rewards?: Reward[] }> {
  const docRef = doc(firestore, "redeemables", redeemId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data() as { rewards: Reward[] };
    return data;
  } else {
    return {};
  }
}

export async function redeemItem(userId: string, redeemId: string) {
  await setDoc(doc(firestore, "users", userId, "redeemables", redeemId), {
    hasClaimed: true,
  });
}

// export async function writeAppData() {
//   const db = getDatabase();
//   await set(ref(db, "appData"), appData);
// }

export async function getAppData(): Promise<AppState> {
  const docRef = doc(firestore, "static", "initializationSettings");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data() as AppState;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    //authSignOut();
    //window.location.assign("/");
    throw new Error("No app data available");
    // const dbRef = ref(getDatabase());
    // return new Promise((resolve, reject) => {
    //   get(child(dbRef, `appData`))
    //     .then((snapshot) => {
    //       if (snapshot.exists()) {
    //         resolve(snapshot.val());
    //       } else {
    //         console.log("No app data available");
    //       }
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     });
    // });
  }
}

export function createUser(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      // Signed in
      const user = userCredential.user;
      return user;
      // ...
    }
  );
}

interface SignInEmailInput {
  email: string;
  password: string;
}
interface SignInGuestInput {
  asGuest: true;
}
type SignInEmail = Partial<SignInGuestInput> & SignInEmailInput;

type SignInGuest = SignInGuestInput & Partial<SignInEmailInput>;

type SignInInput = SignInGuest | SignInEmail;

export function signIn({ email, password, asGuest }: SignInInput) {
  return asGuest
    ? signInAnonymously(auth)
        .then(() => {
          // Signed in..
        })
        .catch((error) => {
          throw error;
          // ...
        })
    : signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          return user;
          // ...
        })
        .catch((error) => {
          throw error;
          // ...
        });
}

export function listenAuthState(callback: (user: any) => void) {
  return onAuthStateChanged(auth, callback);
}

export async function getNews() {
  const newsCol = collection(firestore, "news");
  const newsSnapshot = await getDocs(newsCol);
  const newsList = await Promise.all(
    newsSnapshot.docs.map(async (doc) => {
      const digest = doc.data() as Omit<NewsItem, "id">;

      return { id: doc.id, ...digest };
    })
  );

  return newsList;
}

export async function getProducts() {
  const productsCol = collection(firestore, "products");
  const productSnapshot = await getDocs(productsCol);
  const productList = await Promise.all(
    productSnapshot.docs.map(async (doc) => {
      const digest = doc.data() as ProductDigest;

      const priceSnapshot = await getDocs(collection(doc.ref, "prices"));

      let priceList: { [x: string]: Price } = {};
      priceSnapshot.docs.forEach((doc) => {
        priceList[doc.id] = doc.data() as Price;
      });
      return { digest, prices: priceList };
    })
  );

  return productList.filter((product) => product.digest.active);
}

export async function getCustomer(userId: string) {
  const checkoutSessionCol = collection(
    firestore,
    `customers/${userId}/checkout_sessions`
  );
  const checkoutSessionSnapshot = await getDocs(checkoutSessionCol);
  const checkoutSessions = checkoutSessionSnapshot.docs.map((doc) =>
    doc.data()
  );

  const paymentCol = collection(firestore, `customers/${userId}/payments`);
  const paymentSnapshot = await getDocs(paymentCol);
  const payments = paymentSnapshot.docs.map((doc) => doc.data());

  return { checkoutSessions, payments };
}

export async function markPaymentConsumed(userId: string, paymentId: string) {
  const paymentCol = collection(firestore, `customers/${userId}/payments`);
  const paymentRef = doc(paymentCol, paymentId);
  await updateDoc(paymentRef, { consumed: true });
}

export async function addPayment(userId: string, price: string) {
  const customerCol = collection(
    firestore,
    `customers/${userId}/checkout_sessions`
  );
  const customerSnapshot = await addDoc(customerCol, {
    mode: "payment",
    price: price, // One-time price created in Stripe
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });

  onSnapshot(customerSnapshot, (snap) => {
    const { error, url }: any = snap.data();
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url);
    }
  });

  //const customer = customerSnapshot.docs.map((doc) => doc.data());
  console.log({ customerSnapshot });
}

export async function authSignOut() {
  signOut(auth)
    .then(() => {
      return true;
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
}

export async function googleLogin() {
  const provider = new GoogleAuthProvider();

  signInWithRedirect(auth, provider);
}
export async function facebookLogin() {
  const provider = new FacebookAuthProvider();

  signInWithRedirect(auth, provider);
}

export async function twitterLogin() {
  const provider = new TwitterAuthProvider();

  signInWithRedirect(auth, provider);
}
getRedirectResult(auth)
  .then((result) => {
    if (result) {
      // This gives you a Google Access Token. You can use it to access Google APIs.
      let credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential) {
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
      } else {
        credential = FacebookAuthProvider.credentialFromResult(result);
        if (credential) {
          const token = credential.accessToken;

          // The signed-in user info.
          const user = result.user;
        } else {
          credential = TwitterAuthProvider.credentialFromResult(result);
          if (credential) {
            const token = credential.accessToken;
          } else {
            console.log("no credential");
          }
        }
      }
    } else {
      console.log("no redirect result");
    }
  })
  .catch((error) => {
    console.log("there was an error", error);
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });
