import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Box, Chip, IconButton, useMediaQuery } from "@mui/material";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Clickable from "lib/audio/components/Clickable";
import { AutoModePremiumCurrencyIcon } from "lib/design-system/components/AutoModeIcon";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import BuyButton from "lib/purchase/components/BuyButton";
import GradientText from "lib/typography/components/GradientText";
import * as React from "react";
import theme from "theme";
import { pushModal } from "../slice";
import Modal from "./Modal";
import { award, setLastPremiumClaim } from "lib/progression/slice";
import moment from "moment";
import { setNavCurrencyPeekThrough } from "lib/navigation/slice";
import NavBar from "lib/navigation/components/NavBar";
import millify from "millify";
import { getCurrentTime } from "lib/utils/date";
import playSound from "lib/audio/sfx";
export interface BuyBasicCurrencyModalProps {
  onClose: (ev: any) => void | (() => void);
}
export default function BuyBasicCurrencyModal({
  onClose,
}: BuyBasicCurrencyModalProps) {
  const dispatch = useAppDispatch();
  const basicCurrencyItems = useAppSelector((state) => state.app.store.basic);
  const progression = useAppSelector((state) => state.progression);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const lastPremiumClaim = progression.lastPremiumClaim * 1000;
  const backgroundId = "759e14ff-4a7b-4c41-abe2-6cdfb8bf32b5";
  const canClaimPremium = moment(lastPremiumClaim).isBefore(
    moment().subtract(1, "hours")
  );

  const nextPremiumClaimDuration = (): moment.Moment => {
    const currentTime = getCurrentTime() * 1000;
    const hour = 60 * 60 * 1000;
    const diffTime = currentTime - lastPremiumClaim;

    const duration = moment(hour).subtract(
      moment.duration(diffTime, "milliseconds")
    );

    return duration;
  };
  const [nextPremiumClaim, setNextPremiumClaim] = React.useState(
    nextPremiumClaimDuration().format("mm:ss")
  );

  let intervalCallback: NodeJS.Timer;
  React.useEffect(() => {
    dispatch(setNavCurrencyPeekThrough(true));
    intervalCallback = setInterval(() => {
      const duration = nextPremiumClaimDuration();
      setNextPremiumClaim(`${duration.format("mm:ss")}`);
    }, 1000);
    return () => {
      dispatch(setNavCurrencyPeekThrough(false));
      clearInterval(intervalCallback);
    };
  }, []);
  return (
    <Modal
      dialogProps={{
        maxWidth: "lg",
        fullWidth: true,
        sx: {
          ".MuiDialog-container > .MuiPaper-root": {
            background: "transparent",
          },
          [theme.breakpoints.down("md")]: {
            "& .MuiPaper-root": {
              margin: "0",
            },
          },
        },
      }}
      sx={{
        background: "transparent",
        backdropFilter: "blur( 4px )",
        backgroundImage: `url("assets/background/market.jpg")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        overflow: "visible",
      }}
      onClose={onClose}
      dialogHeaderContent={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontFeatureSettings: '"tnum"',
                fontVariantNumeric: "tabular-nums lining-nums",
                fontSize: "0.8em",
                color: grey[50],
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                alignItems: "stretch",
                mb: -1,
                width: "50px",
                opacity: canClaimPremium ? 0 : 1,
              }}
            >
              {[...nextPremiumClaim].map((char, index) => (
                <Box sx={{ padding: "0 0.1em" }}>{char}</Box>
              ))}
            </Box>
            <IconButton
              disabled={!canClaimPremium}
              sx={{
                fontSize: "3em",
                filter: canClaimPremium ? "none" : "grayscale(1)",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  display: canClaimPremium ? "block" : "none",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  borderRadius: "100%",
                  background:
                    "radial-gradient(transparent, #FFD70050, transparent 100%)",
                  boxShadow: "0px 0px 4px #FFD700",
                  "@keyframes BuyBasicCurrencyIconButton": {
                    "0%": {
                      transform: "scale(0.5)",
                      opacity: 0,
                    },
                    "10%": {
                      opacity: 1,
                    },
                    "50%": {
                      transform: "scale(1.1)",
                      opacity: 0,
                    },
                    "100%": {
                      transform: "scale(1.2)",
                      opacity: 0,
                    },
                  },
                  animation: "BuyBasicCurrencyIconButton 2s infinite",
                },
              }}
              aria-label="collect"
              onClick={() => {
                if (
                  moment(lastPremiumClaim).isBefore(
                    moment().subtract(1, "hours")
                  )
                ) {
                  console.log("not in");
                } else {
                  console.log("in");
                }

                playSound("collect-free");
                dispatch(setLastPremiumClaim(getCurrentTime()));
                dispatch(
                  award({
                    rewards: [{ type: "premium_currency", amount: 1 }],
                    dateString: new Date().toISOString(),
                    source: "redeemable",
                  })
                );
              }}
            >
              <AutoModePremiumCurrencyIcon
                color="primary"
                sx={{
                  fontSize: "3em",
                  stroke: "#FFD700",
                  strokeWidth: "1px",
                  strokeOpacity: 0.2,
                  marginRight: 1,
                  color: "#FFD700",
                }}
              />
            </IconButton>
          </Box>
        </>
      }
      dialogBodyContent={
        <Grid
          spacing={10}
          container
          sx={{
            [theme.breakpoints.down("md")]: {
              flexDirection: "column-reverse",
              alignItems: "stretch",
            },
          }}
        >
          {basicCurrencyItems.map((item, idx) => (
            <Grid key={item.id} item xs={isMobile ? 12 : 4} sx={{}}>
              <Paper
                variant="outlined"
                sx={{
                  background: "transparent",
                  boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.2 )",
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                  },
                }}
              >
                <Grid
                  direction="column"
                  alignItems="center"
                  justifyContent="space-around"
                  container
                  sx={{
                    background: "rgba( 0, 0, 0, 0.37 )",
                    backdropFilter: "blur( 4px )",
                    padding: "0.6em 0.4em 1.2em",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                >
                  <Grid item xs={4}>
                    <GradientText
                      sx={{
                        fontSize: "2em",
                        WebkitTextStrokeWidth: "1px",
                      }}
                    >
                      {item.name}
                    </GradientText>
                  </Grid>
                  <Grid item xs={4}>
                    <img src={item.closedAsset} style={{ maxHeight: "10em" }} />
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <GradientText
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "3em",
                      }}
                    >
                      {item.amount.toLocaleString("en-US")}
                      <AcUnitIcon
                        color="secondary"
                        sx={{
                          fontSize: "1em",
                          marginRight: "0.1em",
                          background: `radial-gradient(${grey[900]},transparent 80%)`,

                          stroke: "#FFD700",
                          strokeWidth: "1px",

                          fill: `url(#GameIconGradientFill)`,
                        }}
                      />
                    </GradientText>
                  </Grid>

                  <Grid item xs={4}>
                    <Clickable>
                      <BuyButton
                        label={
                          <>
                            {item.premiumCurrencyPrice.toLocaleString()}{" "}
                            <PremiumCurrencyIcon
                              color={"primary"}
                              fontSize="medium"
                            />
                          </>
                        }
                        onClick={(ev) => {
                          if (
                            progression.currency.premium >=
                            item.premiumCurrencyPrice
                          ) {
                            onClose(ev);
                            dispatch(
                              pushModal({
                                componentName: "ChestReward",
                                props: {
                                  chest: {
                                    type: item.type,
                                    name: `${item.name} Chest`,
                                    currencyType: "shards",
                                  },
                                },
                              })
                            );
                          } else {
                            dispatch(
                              pushModal({
                                componentName: "BuyPremiumCurrency",
                                props: {},
                              })
                            );
                          }
                        }}
                        sx={{
                          fontSize: "1em",
                          letterSpacing: "2px",
                          borderRadius: "1.5em",

                          left: 2,
                        }}
                      ></BuyButton>
                    </Clickable>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
}
