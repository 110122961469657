import { shuffleArray } from "lib/utils/array";
import store from "store";
// @ts-ignore
import rwc from "random-weighted-choice";
import { Unlockable, WeightedReward } from "lib/data/slice";
import { generatePack } from "lib/pack/generatePack";
import { updatePity, updatePityBatch } from "./slice";

const getRewardFromPool = (elligibleRewards: Unlockable[]) => {
  const pity = store.getState().progression.pity;
  let rewardId = rwc(
    elligibleRewards.map((unlockable) => {
      let weight: number = 0;
      switch (unlockable.rarity) {
        case 0:
          weight = 10000;
          break;
        case 1:
          weight = 5000;
          break;
        case 2:
          weight = 1500 + pity["2"];
          break;
        case 3:
          weight = 250 + pity["3"];
          break;
        case 4:
          weight = 1 + pity["4"];
          break;
        default:
          weight = 0;
          break;
      }

      return {
        id: unlockable.id,
        weight,
      };
    })
  );
  return store
    .getState()
    .app.unlockable.items.find((unlockable) => unlockable.id === rewardId);
};

export function chanceRewards(maxAmount = 5) {
  const state = store.getState();

  let rewards = [];

  /** Give a max of free 5 Cards */
  while (Math.random() > 0.95 && rewards.length < maxAmount) {
    const elligibleRewards = state.app.unlockable.items.filter(
      (unlockable) =>
        unlockable.unlock.level &&
        unlockable.unlock.level <= state.progression.level
    );

    const reward = getRewardFromPool(elligibleRewards);
    if (reward?.id) {
      rewards.push({
        type: "background",
        id: reward.id,
        amount: 1,
      });
    }
  }

  /** No cards? Try to give something special */
  if (rewards.length === 0 && Math.random() >= 0.9) {
    rewards.push(
      Math.random() > 0.5
        ? { type: "freebies", amount: 1 }
        : Math.random() > 0.5
        ? { type: "premium_currency", amount: 1 }
        : generatePack()
    );
  }

  rewards.push({ type: "zen_experience", amount: 5 });

  return rewards;
}

export function guaranteedRewards(amount: number) {
  let rewards = [];

  const state = store.getState();
  while (rewards.length < amount) {
    const elligibleRewards = state.app.unlockable.items
      .filter(
        (unlockable) =>
          unlockable.unlock.level &&
          unlockable.unlock.level <= state.progression.level
      )
      .sort((a, b) => b.rarity - a.rarity);

    // const reward =
    //   elligibleRewards[Math.floor(Math.random() * elligibleRewards.length)];

    const reward = getRewardFromPool(elligibleRewards);
    if (reward?.id) {
      handleRewardPityResults([reward]);
      rewards.push({
        type: "background",
        id: reward.id,
        amount: 1,
      });
    }
  }
  return rewards;
}

export const generateRewardFromSet = (
  rewardSet: WeightedReward[]
): WeightedReward => {
  let winningId: string = rwc(rewardSet);
  const winningReward = rewardSet.find((reward) => reward.id === winningId);
  if (winningReward === undefined) {
    throw new TypeError("Reward not found");
  }
  return winningReward;
};

function handleRewardPityResults(rewards: Unlockable[]) {
  for (let reward of rewards) {
    if (reward.type === "background") {
      const pityTypes = [2, 3, 4];

      // Increment pity timer for all
      store.dispatch(
        updatePityBatch(
          pityTypes.map((type) => ({
            rarity: type as 2 | 3 | 4,
            value: store.getState().progression.pity[type as 2 | 3 | 4] + 1,
          }))
        )
      );
      // for (const pityType of pityTypes) {
      //   store.dispatch(
      //     updatePity({
      //       rarity: pityType as 2 | 3 | 4,
      //       value: store.getState().progression.pity[pityType as 2 | 3 | 4] + 1,
      //     })
      //   );
      // }

      // Reset pity timer once earned
      if (pityTypes.includes(reward.rarity)) {
        store.dispatch(
          updatePity({
            rarity: reward.rarity as 2 | 3 | 4,
            value: 0,
          })
        );
      }
    }
  }
}
