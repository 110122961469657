import * as React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ACUnitIcon from "@mui/icons-material/AcUnitRounded";
import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  CardActionArea,
  CardActions,
  Divider,
  Tooltip,
} from "@mui/material";
import cardCover from "../../../assets/images/agility.svg";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";

import NavigationCard from "lib/navigation/components/NavigationCard";

import GameRoom from "lib/game/components/GameRoom";
import GradientText from "lib/typography/components/GradientText";
import { pushModal } from "lib/modal/slice";
import {
  grey,
  green,
  blue,
  purple,
  yellow,
  pink,
  brown,
  red,
  orange,
  lightBlue,
  blueGrey,
  deepOrange,
  lightGreen,
} from "@mui/material/colors";
import NordicWalkingIcon from "@mui/icons-material/NordicWalking";
import SnowmobileTwoToneIcon from "@mui/icons-material/SnowmobileTwoTone";
import ForestTwoToneIcon from "@mui/icons-material/ForestTwoTone";
import LandscapeTwoToneIcon from "@mui/icons-material/LandscapeTwoTone";
import BeachAccessTwoToneIcon from "@mui/icons-material/BeachAccessTwoTone";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import StarTwoToneIcon from "@mui/icons-material/StarTwoTone";
import AnchorIcon from "@mui/icons-material/Anchor";
import EmojiNatureTwoToneIcon from "@mui/icons-material/EmojiNatureTwoTone";
import WaterTwoToneIcon from "@mui/icons-material/WaterTwoTone";
import ParaglidingTwoToneIcon from "@mui/icons-material/ParaglidingTwoTone";
import LensBlurTwoToneIcon from "@mui/icons-material/LensBlurTwoTone";
import DarkModeTwoToneIcon from "@mui/icons-material/DarkModeTwoTone";
import SatelliteAltTwoToneIcon from "@mui/icons-material/SatelliteAltTwoTone";
import RocketLaunchTwoToneIcon from "@mui/icons-material/RocketLaunchTwoTone";
import CloudQueueTwoToneIcon from "@mui/icons-material/CloudQueueTwoTone";
import SpaTwoToneIcon from "@mui/icons-material/SpaTwoTone";
import WaterfallChartTwoToneIcon from "@mui/icons-material/WaterfallChartTwoTone";
import VolcanoTwoToneIcon from "@mui/icons-material/VolcanoTwoTone";
import FireplaceTwoToneIcon from "@mui/icons-material/FireplaceTwoTone";
import SailingTwoToneIcon from "@mui/icons-material/SailingTwoTone";
import YardTwoToneIcon from "@mui/icons-material/YardTwoTone";
import BiotechTwoToneIcon from "@mui/icons-material/BiotechTwoTone";
import LocalFloristTwoToneIcon from "@mui/icons-material/LocalFloristTwoTone";
import GrassTwoToneIcon from "@mui/icons-material/GrassTwoTone";
import HotTubTwoToneIcon from "@mui/icons-material/HotTubTwoTone";
import WbTwilightTwoToneIcon from "@mui/icons-material/WbTwilightTwoTone";
import PanoramaHorizontalTwoToneIcon from "@mui/icons-material/PanoramaHorizontalTwoTone";
import LocalFireDepartmentTwoToneIcon from "@mui/icons-material/LocalFireDepartmentTwoTone";
import StarsTwoToneIcon from "@mui/icons-material/StarsTwoTone";
import WavesTwoToneIcon from "@mui/icons-material/WavesTwoTone";
import ElectricBoltTwoToneIcon from "@mui/icons-material/ElectricBoltTwoTone";
import RowingTwoToneIcon from "@mui/icons-material/RowingTwoTone";
import HikingTwoToneIcon from "@mui/icons-material/HikingTwoTone";
import AutoAwesomeTwoToneIcon from "@mui/icons-material/AutoAwesomeTwoTone";
import IcecreamTwoToneIcon from "@mui/icons-material/IcecreamTwoTone";
import FollowTheSignsTwoToneIcon from "@mui/icons-material/FollowTheSignsTwoTone";
import GrainTwoToneIcon from "@mui/icons-material/GrainTwoTone";
import BubbleChartTwoToneIcon from "@mui/icons-material/BubbleChartTwoTone";
import FitbitTwoToneIcon from "@mui/icons-material/FitbitTwoTone";
import CycloneTwoToneIcon from "@mui/icons-material/CycloneTwoTone";

const textShadow = (color: string) =>
  `1px 1px 1px ${color}50, -1px -1px 1px ${color}50`;
const sharedIconProps = {
  padding: "8px",
  borderRadius: "100%",
  background: grey[300],
  color: grey[900],
  textShadow: textShadow(grey[100]),
};

export const iconsByCategoryName = {
  Tundra: {
    component: SnowmobileTwoToneIcon,
    defaultProps: {
      sx: { ...sharedIconProps, background: blueGrey[300] },
      textShadow: textShadow(blueGrey[200]),
    },
  },
  Forest: {
    component: ForestTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: green[100],
        color: green[500],
        textShadow: textShadow(green[200]),
      },
    },
  },
  Desert: {
    component: LocalFireDepartmentTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: yellow[300],
        color: deepOrange[700],
        textShadow: textShadow(deepOrange[200]),
      },
    },
  },
  Mountain: {
    component: LandscapeTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        color: brown[500],
        textShadow: textShadow(brown[200]),
      },
    },
  },
  Beach: {
    component: BeachAccessTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: blue[500],
        color: yellow[500],
        textShadow: textShadow(yellow[200]),
      },
    },
  },
  Summer: {
    component: WbSunnyTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: deepOrange[500],
        color: yellow[500],
        textShadow: textShadow(yellow[200]),
      },
    },
  },
  Spring: {
    component: EmojiNatureTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: green[800],
        color: pink[200],
        textShadow: textShadow(pink[200]),
      },
    },
  },
  River: {
    component: WaterTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: green[800],
        color: blue[200],
        textShadow: textShadow(blue[200]),
      },
    },
  },
  Sky: {
    component: ParaglidingTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: blue[800],
        color: blueGrey[200],
        textShadow: textShadow(blueGrey[200]),
      },
    },
  },
  Fog: {
    component: LensBlurTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: grey[800],
        color: blue[200],
        textShadow: textShadow(blue[200]),
      },
    },
  },
  Dusk: {
    component: DarkModeTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: deepOrange[900],
        color: grey[500],
        textShadow: textShadow(grey[200]),
      },
    },
  },
  Space: {
    component: SatelliteAltTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: grey[900],
        color: grey[100],
        textShadow: textShadow(grey[200]),
      },
    },
  },
  Galaxy: {
    component: RocketLaunchTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: grey[900],
        color: purple[500],
        textShadow: textShadow(purple[200]),
      },
    },
  },
  Cloud: {
    component: CloudQueueTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: blueGrey[200],
        textShadow: textShadow(blueGrey[200]),
      },
    },
  },
  Fall: {
    component: SpaTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: brown[600],
        color: orange[500],
        textShadow: textShadow(orange[200]),
      },
    },
  },
  Waterfall: {
    component: WaterfallChartTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: grey[800],
        color: blue[200],
        textShadow: textShadow(blue[200]),
      },
    },
  },
  Volcano: {
    component: VolcanoTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: brown[700],
        color: red[500],
        textShadow: textShadow(red[200]),
      },
    },
  },
  Winter: {
    component: FireplaceTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: blue[300],
        color: red[700],
        textShadow: textShadow(red[200]),
      },
    },
  },
  Ocean: {
    component: SailingTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: blue[900],
        color: blue[200],
        textShadow: textShadow(blue[200]),
      },
    },
  },
  Leaf: {
    component: YardTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: lightGreen[900],
        color: lightGreen[500],
        textShadow: textShadow(lightGreen[200]),
      },
    },
  },
  Micro: {
    component: BiotechTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: purple[200],
        color: blue[900],
        textShadow: textShadow(blue[200]),
      },
    },
  },
  Flower: {
    component: LocalFloristTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: yellow[500],
        color: pink[300],
        textShadow: textShadow(pink[200]),
      },
    },
  },
  Grasslands: {
    component: GrassTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: lightGreen[100],
        color: green[500],
        textShadow: textShadow(green[200]),
      },
    },
  },
  Geyser: {
    component: HotTubTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: yellow[200],
        color: lightBlue[900],
        textShadow: textShadow(lightBlue[200]),
      },
    },
  },
  Cliff: {
    component: NordicWalkingIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: brown[300],
        color: lightBlue[900],
        textShadow: textShadow(lightBlue[200]),
      },
    },
  },
  Aurora: {
    component: WbTwilightTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: blue[900],
        color: green[300],
        "@keyframes categoryBadgeHue": {
          from: { filter: "hue-rotate(0deg)" },
          to: { filter: "hue-rotate(-100deg)" },
        },
        animation: "1s categoryBadgeHue infinite alternate ease-in",
        textShadow: textShadow(green[200]),
      },
    },
  },
  Plains: {
    component: PanoramaHorizontalTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: yellow[800],
        color: yellow[400],
        textShadow: textShadow(yellow[200]),
      },
    },
  },
  Icon: {
    component: StarsTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: red[500],
        color: yellow[700],
        textShadow: textShadow(yellow[200]),
      },
    },
  },
  Lake: {
    component: WavesTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        color: lightBlue[500],
        textShadow: textShadow(lightBlue[200]),
      },
    },
  },
  Lightning: {
    component: ElectricBoltTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: grey[900],
        color: yellow[500],
        textShadow: textShadow(yellow[200]),
      },
    },
  },
  Island: {
    component: AnchorIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: blue[900],
        color: grey[500],
        textShadow: textShadow(grey[200]),
      },
    },
  },
  Hill: {
    component: HikingTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: green[200],
        color: red[800],
        textShadow: textShadow(red[200]),
      },
    },
  },
  Special: {
    component: AutoAwesomeTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: yellow[200],
        color: red[900],
        textShadow: textShadow(red[100]),
      },
    },
  },
  Glacier: {
    component: IcecreamTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: blue[900],
        color: blueGrey[100],
        textShadow: textShadow(blueGrey[200]),
      },
    },
  },
  Canyon: {
    component: FollowTheSignsTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: yellow[200],
        color: brown[500],
        textShadow: textShadow(brown[200]),
      },
    },
  },
  Field: {
    component: GrainTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: green[600],
        color: yellow[200],
        textShadow: textShadow(yellow[200]),
      },
    },
  },
  Swamp: {
    component: BubbleChartTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: green[800],
        color: green[300],
        textShadow: textShadow(green[200]),
      },
    },
  },
  Valley: {
    component: FitbitTwoToneIcon,
    defaultProps: {
      sx: {
        ...sharedIconProps,
        background: lightBlue[700],
        color: yellow[300],
        textShadow: textShadow(yellow[200]),
      },
    },
  },
} as const;

export type Categories = keyof typeof iconsByCategoryName;
interface CategoryBadgeProps extends AvatarProps {
  name: Categories;
  size: "small" | "medium" | "large";
}
export default function CategoryBadge({ name, size, sx }: CategoryBadgeProps) {
  const currentIcon = iconsByCategoryName[name] || {
    component: CycloneTwoToneIcon,
    defaultProps: { sx: { color: blue[400] } },
  };

  const RenderableComponent = currentIcon.component;
  const avatarSize = size === "small" ? 20 : size === "medium" ? 40 : 64;
  return (
    <Tooltip title={name}>
      <Avatar
        sx={{
          ...sx,
          height: avatarSize,
          width: avatarSize,
          backgroundColor: "#222",
        }}
      >
        {
          <RenderableComponent
            sx={{
              ...currentIcon.defaultProps.sx,
              padding: 0,
              fontSize: size === "small" ? "0.8em" : "2em",
            }}
          />
        }
      </Avatar>
    </Tooltip>
  );
}
