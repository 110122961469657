import * as React from "react";
import ModalUnstyled from "@mui/base/ModalUnstyled";

import { styled } from "@mui/material/styles";
import clsx from "clsx";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className?: string; onClick: (ev: any) => void }
>((props, ref) => {
  const { open, className, onClick, ...other } = props;
  return (
    <div
      onClick={onClick}
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  -webkit-tap-highlight-color: transparent;
`;

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default function UnstyledModal({
  onClose,
  children,
}: {
  onClose: (ev: any) => void | (() => void);
  children: React.ReactNode;
}) {
  return (
    <Modal open onClose={onClose}>
      <>
        <Backdrop open onClick={onClose} />
        {children}
      </>
    </Modal>
  );
}
