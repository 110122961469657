import AcUnitIcon from "@mui/icons-material/AcUnit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import MenuIcon from "@mui/icons-material/Menu";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import SideNav from "lib/navigation/components/SideNav";
import ProgressBar from "lib/progression/components/ProgressBar";
import millify from "millify";

import * as React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Clickable from "lib/audio/components/Clickable";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import theme from "theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { animate, easeIn } from "popmotion";

import { Link } from "react-router-dom";
export const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isInGame?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isInGame",
})<AppBarProps>(({ theme, open, isInGame }) => ({
  zIndex: 8,
  background: isInGame ? "rgba(137, 2, 62, 0.3)" : "rgba(137, 2, 62, 1)",
  backdropFilter: "blur( 4px )",
  width: `calc(100% - ${drawerWidth}px)`,
  maxWidth: "1200px",
  transform: "translateX(-50%)",
  left: "50%",
  justifySelf: "center",
  alignSelf: "center",
  //maxWidth: isInGame ? "1200px" : "auto",
  transition: theme.transitions.create(
    ["transform", "width", "margin", "background"],
    {
      easing: theme.transitions.easing.easeOut,
      duration: 2000,
    }
  ),
  [theme.breakpoints.down("md")]: {
    maxWidth: "auto",
    width: "100%",
    left: 0,
    transform: "none",
  },
  ...(open && {
    //marginLeft: drawerWidth,
    transition: theme.transitions.create(["transform", "width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: 2000,
    }),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100%",
      left: 0,
      transform: "none",
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: "transparent",

  backdropFilter: "blur( 4px )",
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "transparent",

    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
let basicCurrencyOnChangeTimer;
let premiumCurrencyOnChangeTimer;
export default function NavBar() {
  const [open, setOpen] = React.useState(true);

  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const levels = useAppSelector((state) => state.app.levels);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [basicCurrencyAmount, setBasicCurrency] = React.useState(0);
  const [previousBasicCurrencyAmount, setPreviousBasicCurrencyAmount] =
    React.useState(0);
  const basicCurrencyLabel = isMobile
    ? millify(Math.floor(basicCurrencyAmount))
    : Math.floor(basicCurrencyAmount).toLocaleString("en-US");

  const [premiumCurrencyAmount, setPremiumCurrency] = React.useState(0);
  const [previousPremiumCurrencyAmount, setPreviousPremiumCurrencyAmount] =
    React.useState(0);
  const premiumCurrencyLabel = isMobile
    ? millify(Math.floor(premiumCurrencyAmount))
    : Math.floor(premiumCurrencyAmount).toLocaleString("en-US");

  React.useEffect(() => {
    basicCurrencyOnChangeTimer = window.setTimeout(() => {
      const duration =
        previousBasicCurrencyAmount === 0
          ? 1
          : Math.min(
              7000,
              Math.max(
                100,
                (progression.currency.basic - previousBasicCurrencyAmount) * 9
              )
            );

      animate({
        from: previousBasicCurrencyAmount,
        to: progression.currency.basic,
        duration,

        ease: easeIn,
        //type: "spring",
        onUpdate: (latest: number) => {
          setBasicCurrency(latest);
        },
      });
    }, 1000);

    setPreviousBasicCurrencyAmount(progression.currency.basic);
  }, [progression.currency.basic]);

  React.useEffect(() => {
    premiumCurrencyOnChangeTimer = window.setTimeout(() => {
      animate({
        from: previousPremiumCurrencyAmount,
        to: progression.currency.premium,
        duration: previousPremiumCurrencyAmount === 0 ? 1 : 2000,
        stiffness: 20,
        //type: "spring",
        onUpdate: (latest: number) => {
          setPremiumCurrency(Math.round(latest));
        },
      });
    }, 1000);

    setPreviousPremiumCurrencyAmount(progression.currency.premium);
  }, [progression.currency.premium]);
  const store = useAppSelector((state) => state.app.store);
  const fullState = useAppSelector((state) => state);
  const hasClaimableConsumables =
    useAppSelector((state) =>
      Object.values(state.progression.consumables).filter(
        (c) => c.hasConsumed === false
      )
    ).length > 0;

  const nextLevelCost =
    store.fastTrackProgression.xpCost *
    (levels[progression.level].experienceRequired - progression.experience);
  const canAffordLevelUp = nextLevelCost <= progression.currency.basic;

  const isInGame = useAppSelector((state) => state.navigation.isInGame);
  React.useEffect(() => {
    setOpen(!isInGame);
  }, [isInGame]);
  return (
    <>
      <AppBar position="fixed" open={open} isInGame={isInGame}>
        <Toolbar
          sx={{
            pr: "16px", // keep right padding when drawer closed

            [theme.breakpoints.down("md")]: {
              padding: "0 4px",
              height: "4em",
            },
          }}
        >
          <Tooltip
            title={progression.currency.basic.toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })}
          >
            <Chip
              sx={{
                minWidth: "7.5em",
                justifyContent: "flex-start",
                ".MuiChip-label": {
                  textOverflow: "clip",
                },
                [theme.breakpoints.down("md")]: {
                  minWidth: "auto",
                  width: "6.5em",
                },
              }}
              color="secondary"
              label={basicCurrencyLabel}
              onClick={() => {
                dispatch(
                  pushModal({ componentName: "BuyBasicCurrency", props: {} })
                );
              }}
              icon={<AcUnitIcon sx={{ pointerEvents: "none" }} />}
              variant="outlined"
            />
          </Tooltip>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{
              flexGrow: 1,
              maxWidth: "70vw",
              alignSelf: "center",
              margin: "auto 1.2em",
              position: "relative",
              [theme.breakpoints.down("md")]: {
                margin: "0.2em",
              },
            }}
          >
            <ProgressBar />
          </Typography>
          {/* <Clickable>
            <IconButton
              disabled={!canAffordLevelUp}
              onClick={() => {
                dispatch(pushModal({ componentName: "BuyLevelUp", props: {} }));
              }}
              color="inherit"
            >
              <Badge variant={canAffordLevelUp ? "dot" : undefined}>
                <NextPlanIcon />
              </Badge>
            </IconButton>
          </Clickable> */}
          <Tooltip
            title={progression.currency.premium.toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })}
          >
            <Chip
              sx={{
                minWidth: "7.5em",
                justifyContent: "flex-start",

                ".MuiChip-label": {
                  textOverflow: "clip",
                },
                [theme.breakpoints.down("md")]: {
                  minWidth: "auto",
                  width: "5em",
                },
              }}
              color="secondary"
              label={premiumCurrencyLabel}
              onClick={() => {
                dispatch(
                  pushModal({ componentName: "BuyPremiumCurrency", props: {} })
                );
              }}
              icon={<PremiumCurrencyIcon sx={{ pointerEvents: "none" }} />}
              variant="outlined"
            />
          </Tooltip>
          <Clickable>
            <IconButton
              onClick={() => {
                dispatch(pushModal({ componentName: "Settings", props: {} }));
              }}
              color="inherit"
            >
              <SettingsIcon />
            </IconButton>
          </Clickable>
        </Toolbar>
      </AppBar>
    </>
  );
}
