import Container from "@mui/material/Container";
import * as React from "react";
import DailyWheel from "lib/daily/DailyWheel";
import Button from "@mui/material/Button";
import GradientText from "lib/typography/components/GradientText";
import Tilt from "react-parallax-tilt";
import {
  grey,
  red,
  green,
  blue,
  orange,
  pink,
  yellow,
  purple,
} from "@mui/material/colors";
import {
  addBasicCurrency,
  addPremiumCurrency,
  buyPack,
  Pack,
  setTutorialStep,
} from "lib/progression/slice";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import { guaranteedRewards } from "lib/progression/poolReward";
import Typography from "@mui/material/Typography";
import QuizIcon from "@mui/icons-material/Quiz";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import Clickable from "lib/audio/components/Clickable";
import Grid from "@mui/material/Grid";
import PageTransitionWrapper from "lib/animation/components/PageTransitionWrapper";
import GameRoom from "lib/game/components/GameRoom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import theme from "theme";
import CardActionArea from "@mui/material/CardActionArea";
import { setCanAnimate, setSpotlight } from "lib/navigation/slice";
import { progress } from "popmotion";
import { useMediaQuery } from "@mui/material";
import useAnimationEffect from "lib/animation/useAnimationEffect";
import { generatePack } from "lib/pack/generatePack";
export default function PacksPage() {
  const dispatch = useAppDispatch();
  const packs = useAppSelector((state) => state.progression.packs);
  const progression = useAppSelector((state) => state.progression);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  //const [packs, setPacks] = React.useState(currentPacks);
  React.useEffect(() => {
    // let newPacks: Pack[] = [];
    // for (let pack of currentPacks) {
    //   if (!packs.some((i) => i.id === pack.id)) {
    //     newPacks.push(pack);
    //   }
    // }
    // if (newPacks.length > 0) {
    //   setPacks((packs) => [...packs, ...newPacks]);
    // }

    if (progression.tutorialStep === 8) {
      dispatch(setCanAnimate(true));
    }
    if (progression.tutorialStep === 7) {
      if (packs.length === 0) {
        dispatch(
          buyPack({
            pack: Array.from({ length: 1 }, (x, i) => {
              return generatePack();
            }),
            premium: 0,
          })
        );
      }
      dispatch(
        setSpotlight({
          queryTarget: `.packs-container`,
          offset: isMobile
            ? { left: 0, top: 0, size: 100 }
            : { left: 0, top: 0, size: 0 },
          message: {
            text: `Open a pack!`,
            position: "top",
          },
        })
      );
    }
  }, []);

  useAnimationEffect(() => {
    if (progression.tutorialStep === 8) {
      dispatch(setCanAnimate(false));
      dispatch(
        setSpotlight({
          queryTarget: isMobile ? ".bottom-bar-dashboard" : `.back-button`,
          offset: { left: 0, top: 0, size: 0 },
          message: {
            text: `Head back to the lobby for some more games`,
            position: isMobile ? "top" : "right",
          },
        })
      );
    }
  }, [progression.tutorialStep]);
  return (
    <GameRoom
      withBackButton
      noScrollX
      title={
        <Box
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: { flexDirection: "column" },
          }}
        >
          <GradientText sx={{ fontSize: "3em", display: "flex" }}>
            Packs
          </GradientText>
          <Clickable>
            <Button
              sx={{
                marginLeft: "auto",
                letterSpacing: "6px",

                [theme.breakpoints.down("md")]: { marginLeft: 0 },
              }}
              onClick={() => {
                dispatch(pushModal({ componentName: "BuyPacks", props: {} }));
              }}
              variant="contained"
              color="primary"
            >
              <GradientText sx={{ fontSize: "3em", whiteSpace: "nowrap" }}>
                Buy Packs
              </GradientText>
            </Button>
          </Clickable>
        </Box>
      }
      scrollType="horizontal"
      contentHeight="70vh"
    >
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          mt: 4,
          mb: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",

          alignItems: "center",
          height: "50vh",
          [theme.breakpoints.down("md")]: { mt: 0 },
        }}
      >
        <Grid
          direction="row"
          container
          sx={{ position: "relative", flex: "100%" }}
        >
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {packs.length === 0 ? (
              <Grid
                item
                direction="column"
                alignItems={"center"}
                justifyContent="center"
                sx={{
                  width: "300px",
                  height: "500px",
                  border: "1em dashed #FFD700",
                  cursor: "pointer",
                  display: "flex",
                  opacity: 0.5,
                  "&:hover": {
                    opacity: 1,
                  },
                  filter: "drop-shadow(0px 0px 10px #89023E)",

                  [theme.breakpoints.down("md")]: {
                    width: "90vw",
                    height: "50vh",
                  },
                }}
                onClick={() => {
                  dispatch(pushModal({ componentName: "BuyPacks", props: {} }));
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2em",
                    transform: "rotate(15deg)",
                    color: "#FFD700",
                  }}
                >
                  Out of Packs
                </Typography>
              </Grid>
            ) : (
              packs.map((pack, idx) => {
                const unitOffset = idx > 15 ? 15 : idx;
                return (
                  <Grid
                    key={pack.id}
                    className="packs-container"
                    item
                    sx={{
                      position: "absolute",
                      left: `${unitOffset * 4}px`,
                      top: `${unitOffset * 4}px`,
                      transform: `translateX(-50%) rotate(${unitOffset}deg)`,
                    }}
                  >
                    <Tilt
                      tiltReverse
                      tiltMaxAngleX={4}
                      perspective={1200}
                      tiltMaxAngleY={4}
                    >
                      <Clickable>
                        <Card
                          sx={{
                            width: "30vh",
                            height: "50vh",
                            background: 'url("assets/pack_back.png")',
                            cursor: "pointer",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => {
                            dispatch(
                              pushModal({
                                componentName: "Reward",
                                props: {
                                  rewards: pack.rewards,
                                  packId: pack.id,
                                },
                              })
                            );

                            if (progression.tutorialStep === 7) {
                              dispatch(
                                setSpotlight({
                                  queryTarget: ``,

                                  message: {
                                    text: `Open a pack!`,
                                    position: "top",
                                  },
                                })
                              );
                              dispatch(setCanAnimate(false));
                              dispatch(setTutorialStep(8));
                            }
                          }}
                        ></Card>
                      </Clickable>
                    </Tilt>
                  </Grid>
                );
              })
            )}
          </div>
        </Grid>
        <Clickable>
          <Button
            sx={{
              letterSpacing: "6px",
              filter: packs.length === 0 ? "grayscale(100%)" : "grayscale(0%)",

              display: packs.length <= 1 ? "none" : "block",
            }}
            onClick={() => {
              dispatch(
                pushModal({
                  componentName: "Reward",
                  props: {
                    rewards: [...packs.map((pack) => pack.rewards)].reduce(
                      (a, b) => a.concat(b),
                      []
                    ),
                    packId: [...packs.map((pack) => pack.id)],
                  },
                })
              );
            }}
            variant="contained"
            color="primary"
            disabled={packs.length === 0}
          >
            <GradientText sx={{ fontSize: "3em", whiteSpace: "nowrap" }}>
              Open All
            </GradientText>
          </Button>
        </Clickable>
      </Container>
    </GameRoom>
  );
}
