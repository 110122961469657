const localStorageRaw = window.localStorage.getItem("persist:root") || "{}";
const localStorage = JSON.parse(localStorageRaw);
let globalVolume =
  localStorage?.preferences && JSON.parse(localStorage.preferences).sfx ? 1 : 0;

let soundsInitialized: { [key: string]: HTMLAudioElement } = {};
export const setGlobalVolume = (newVolume: number) => {
  globalVolume = newVolume;
  for (let sound of Object.values(soundsInitialized)) {
    sound.volume = globalVolume;
  }
};

export default function playSound(name: string) {
  if (soundsInitialized[name]) {
    //soundsInitialized[name].play();
    const audio = soundsInitialized[name].cloneNode(true);
    // @ts-ignore
    audio.volume = globalVolume;
    // @ts-ignore
    audio.play();
    return soundsInitialized[name];
  } else {
    const sound = new Audio(`/assets/audio/${name}.wav`);
    sound.preload = "auto";
    sound.volume = globalVolume;
    sound.play();
    soundsInitialized[name] = sound;
    return sound;
  }
}

export function stopSound(name: string) {
  if (soundsInitialized[name]) {
    soundsInitialized[name].pause();
    soundsInitialized[name].currentTime = 0;
  }
}
