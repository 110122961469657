import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "./Modal";
import { Level, Reward } from "lib/data/slice";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import RewardItem from "lib/progression/components/RewardItem";
import Clickable from "lib/audio/components/Clickable";
import GradientText from "lib/typography/components/GradientText";
import { recycleExtraCards } from "lib/progression/slice";
import runAward from "lib/animation/RewardCanvas";

import playSound from "lib/audio/sfx";
import { Box } from "@mui/material";
import { IconButton, useMediaQuery } from "@mui/material";
import theme from "theme";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

export interface RecycleCardsModalProps extends Level {
  onClose: ((ev: any) => void) | (() => void);
  unlockableIds: string[];
}

interface CardRenderable {
  id: string;
  name: string;
  amount: number;
}

export default function RecycleCardsModal({
  onClose,
  unlockableIds,
}: RecycleCardsModalProps) {
  let cardsByRarity: {
    common: CardRenderable[];
    uncommon: CardRenderable[];
    rare: CardRenderable[];
    epic: CardRenderable[];
  } = { common: [], uncommon: [], rare: [], epic: [] };
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const progression = useAppSelector((state) => state.progression);
  const unlockables = useAppSelector((state) => state.app.unlockable.items);
  const recycleRarityCurrencyReward = useAppSelector(
    (state) => state.app.unlockable.recycleRarityCurrencyReward
  );
  let basicCurrencyAward = 0;
  for (let unlockableId of unlockableIds) {
    const unlockable = unlockables.find((item) => item.id === unlockableId);
    if (unlockable) {
      let rarity: "common" | "uncommon" | "rare" | "epic" = "common";
      switch (unlockable.rarity) {
        case 0:
          rarity = "common";
          break;
        case 1:
          rarity = "uncommon";
          break;
        case 2:
          rarity = "rare";
          break;
        case 3:
          rarity = "epic";
          break;
      }

      const progressionUnlockable = progression.unlocks[unlockableId];
      basicCurrencyAward +=
        recycleRarityCurrencyReward[unlockable.rarity] *
        progressionUnlockable.amount;
      cardsByRarity[rarity].push({
        id: unlockable.id,
        name: unlockable.name,
        amount: progressionUnlockable.amount,
      });
    }
  }

  React.useEffect(() => {
    //playSound("modal-load");
  }, []);
  return (
    <Modal
      onClose={onClose}
      imageSrc="/assets/hero/recycle-cards.png"
      dialogTitle={"Recycle Extra Cards"}
      dialogBodyContent={
        <>
          <Grid justifyContent="space-evenly" alignItems="center" container>
            {Object.values(cardsByRarity).map((unlockables) =>
              unlockables.map((unlockable) => (
                <Grid key={`${unlockable.id}`} item sx={{ fontSize: "3em" }}>
                  <RewardItem
                    type="background"
                    id={unlockable.id}
                    amount={unlockable.amount}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </>
      }
      dialogActions={
        <Clickable>
          <Button
            onClick={(ev) => {
              onClose(ev);
              dispatch(
                recycleExtraCards({ unlockableIds, basicCurrencyAward })
              );
              runAward(
                basicCurrencyAward,
                "/assets/particle/basic_currency_particle.svg",
                undefined,
                undefined,
                undefined,
                isMobile
              );
            }}
            sx={{
              letterSpacing: "2px",
            }}
            size="large"
            variant="contained"
            autoFocus
            color={"primary"}
          >
            <GradientText
              sx={{
                fontSize: "3em",
                display: "flex",
                alignItems: "flex-end",
                gap: 2,
                [theme.breakpoints.down("md")]: {
                  fontSize: "clamp(1.2em, 6vw, 2em)",
                  whiteSpace: "nowrap",
                  WebkitTextStrokeWidth: "1px",
                },
              }}
            >
              Receive{" "}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AcUnitIcon
                  color="secondary"
                  sx={{
                    fontSize: "1em",
                    stroke: "#FFD700",
                    strokeWidth: "1px",
                    fill: `url(#GameIconGradientFill)`,
                  }}
                />
                {basicCurrencyAward.toLocaleString("en-US")}
              </Box>
            </GradientText>
          </Button>
        </Clickable>
      }
    />
  );
}
