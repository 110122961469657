import * as React from "react";
import { createSvgIcon, IconProps, SvgIconProps } from "@mui/material";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Box } from "@mui/system";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PremiumCurrencyIcon from "./PremiumCurrencyIcon";
import FilterIcon from "@mui/icons-material/Filter";
const AutoModeIcon = createSvgIcon(
  <>
    <path d="M19.03 3.56c-1.67-1.39-3.74-2.3-6.03-2.51v2.01c1.73.19 3.31.88 4.61 1.92l1.42-1.42zM11 3.06V1.05c-2.29.2-4.36 1.12-6.03 2.51l1.42 1.42C7.69 3.94 9.27 3.25 11 3.06zM4.98 6.39 3.56 4.97C2.17 6.64 1.26 8.71 1.05 11h2.01c.19-1.73.88-3.31 1.92-4.61zM20.94 11h2.01c-.21-2.29-1.12-4.36-2.51-6.03l-1.42 1.42c1.04 1.3 1.73 2.88 1.92 4.61zM7 12l3.44" />
    <path d="M12 21c-3.11 0-5.85-1.59-7.46-4H7v-2H1v6h2v-2.7c1.99 2.84 5.27 4.7 9 4.7 4.87 0 9-3.17 10.44-7.56l-1.96-.45C19.25 18.48 15.92 21 12 21z" />
  </>,
  "AutoModeShardIcon"
);

export const AutoModeShardIcon = (props: SvgIconProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <AutoModeIcon {...props} />
      <AcUnitIcon
        {...props}
        sx={{
          transform: "scale(0.5)",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          ...props.sx,
        }}
      />
    </Box>
  );
};

export const AutoModeChallengeIcon = (props: SvgIconProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <AutoModeIcon {...props} />
      <FitnessCenterIcon
        {...props}
        sx={{
          transform: "scale(0.5)",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          ...props.sx,
        }}
      />
    </Box>
  );
};

export const AutoModeCardIcon = (props: SvgIconProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <AutoModeIcon {...props} />
      <FilterIcon
        {...props}
        sx={{
          transform: "scale(0.5)",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          ...props.sx,
        }}
      />
    </Box>
  );
};

export const AutoModePremiumCurrencyIcon = (props: SvgIconProps) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AutoModeIcon {...props} />
      <PremiumCurrencyIcon
        {...props}
        sx={{
          transform: "scale(0.5)",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          ...props.sx,
        }}
      />
    </Box>
  );
};

export default AutoModeIcon;
