import * as React from "react";
import Equations from "lib/agility/components/Equations";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import { shuffleArray } from "lib/utils/array";
import { useParams } from "react-router-dom";
import { ChallengePossibility, WeightedReward } from "lib/data/slice";
import {
  GameTypes,
  goalActionProgress,
  markTutorialFlagComplete,
  setCurrentCardId,
} from "lib/progression/slice";
import { generateRewardFromSet } from "lib/progression/poolReward";
import GameTutorialModal from "lib/modal/components/GameTutorial";
import { setGlobalVolume as setParticleVolume } from "lib/animation/RewardCanvas";

import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { setGlobalVolume as setClickVolume } from "lib/audio/sfx";
import visualizer, { setGlobalVolume } from "lib/audio/visualizer";
import Button from "@mui/material/Button";
import GradientText from "lib/typography/components/GradientText";
import AgilityWordsPage from "pages/agility/words";
import AgilityEquationsPage from "pages/agility/equations";
import AgilityLettersPage from "pages/agility/letters";
import AgilityNumbersPage from "pages/agility/numbers";
import theme from "theme";
import Box from "@mui/material/Box";
import Clickable from "lib/audio/components/Clickable";
import DialogActions from "@mui/material/DialogActions";
import Paper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import CardMedia from "@mui/material/CardMedia";
import DialogTitle from "@mui/material/DialogTitle";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TimerIcon from "@mui/icons-material/Timer";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import WarningIcon from "@mui/icons-material/Warning";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import GameSelectCard from "lib/card/components/GameSelectCard";
import { setMusic, setSFX } from "lib/preferences/slice";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});
const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  -webkit-tap-highlight-color: transparent;
`;

function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function TutorialPage() {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const [currentChallenge, setCurrentChallenge] = React.useState("");
  const [sideFlipped, setSideFlipped] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(true);
  const unlockables = useAppSelector((state) => state.app.unlockable.items);
  const challengeLevelIndex = 0;
  const modalState = useAppSelector((state) => state.modal);
  const preferences = useAppSelector((state) => state.preferences);
  const [preferredGame, setPreferredGame] = React.useState<null | GameTypes>(
    null
  );
  switch (preferredGame) {
    case "words":
      return <AgilityWordsPage />;
    case "equations":
      return <AgilityEquationsPage />;
    case "letters":
      return <AgilityLettersPage />;
    case "numbers":
      return <AgilityNumbersPage />;
    default:
      return (
        <Modal open onClose={() => {}} components={{ Root: Backdrop }}>
          <Box
            sx={{
              position: "absolute",
              top: "0vh",
              left: "0",
              height: "100vh",
              padding: "0 5vw",
              width: "100%",
              zIndex: 11,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: "2px",
            }}
          >
            <Box
              sx={{
                color: "white",
                position: "absolute",
                right: 0,
                [theme.breakpoints.down("md")]: { display: "none" },
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "flex-end",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: 1,
                }}
                onClick={() => {
                  setGlobalVolume(preferences.music ? 0 : 1);
                  dispatch(setMusic(!preferences.music));
                }}
              >
                <Typography>Music</Typography>
                {preferences.music ? <VolumeUpIcon /> : <VolumeMuteIcon />}
              </Box>
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "flex-end",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: 1,
                }}
                onClick={() => {
                  setParticleVolume(preferences.sfx ? 0 : 1);
                  setClickVolume(preferences.sfx ? 0 : 1);
                  dispatch(setSFX(!preferences.sfx));
                }}
              >
                <Typography>SFX</Typography>
                {preferences.sfx ? <VolumeUpIcon /> : <VolumeMuteIcon />}
              </Box>
            </Box>
            <GradientText
              sx={{
                fontSize: "clamp(1.5em, 6vw, 2.1em)",
                mb: 4,
                whiteSpace: "nowrap",
                [theme.breakpoints.down("md")]: {
                  mb: 1,
                },
              }}
            >
              Select Your First Game
            </GradientText>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                height: "100%",
                gap: 1,
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                },
              }}
            >
              <GameSelectCard
                onClose={() => {
                  setPreferredGame("words");

                  dispatch(
                    setCurrentCardId("320aa3cf-2d36-4da7-9733-54eda2ef7473")
                  );
                }}
                isMobile={false}
                hasLoaded={false}
                game={{
                  id: "ftue_words",
                  type: "words" as GameTypes,
                  cardId: "320aa3cf-2d36-4da7-9733-54eda2ef7473",
                }}
                idx={0}
                description="Choose the best word for the prompt"
              />

              <GameSelectCard
                onClose={() => {
                  setPreferredGame("equations");

                  dispatch(
                    setCurrentCardId("b549399f-b6be-4277-8437-8c92dca74cfe")
                  );
                }}
                isMobile={false}
                hasLoaded={false}
                game={{
                  id: "ftue_equations",
                  type: "equations" as GameTypes,
                  cardId: "b549399f-b6be-4277-8437-8c92dca74cfe",
                }}
                idx={0}
                description="Solve the equation"
              />
              <GameSelectCard
                onClose={() => {
                  setPreferredGame("letters");

                  dispatch(
                    setCurrentCardId("b549399f-b6be-4277-8437-8c92dca74cfe")
                  );
                }}
                isMobile={false}
                hasLoaded={false}
                game={{
                  id: "ftue_letters",
                  type: "letters" as GameTypes,
                  cardId: "04fd9225-5aee-4ac3-88f6-6b77e56e7440",
                }}
                idx={0}
                description="Find the missing letter"
              />
              <GameSelectCard
                onClose={() => {
                  setPreferredGame("numbers");
                  dispatch(
                    setCurrentCardId("04fd9225-5aee-4ac3-88f6-6b77e56e7440")
                  );
                }}
                isMobile={false}
                hasLoaded={false}
                game={{
                  id: "ftue_numbers",
                  type: "numbers" as GameTypes,
                  cardId: "8caee49b-abb7-432f-85d5-504f510b0fff",
                }}
                idx={0}
                description="Pick the correct number in the pattern"
              />
            </Box>
          </Box>
        </Modal>
      );
  }
}
