import * as React from "react";

import Container from "@mui/material/Container";
import { Scrollbar } from "react-scrollbars-custom";
import GradientText from "lib/typography/components/GradientText";
import { Box, Button, ButtonProps, setRef, useMediaQuery } from "@mui/material";
import PageTransitionWrapper from "lib/animation/components/PageTransitionWrapper";
import theme from "theme";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
export default function GameRoom({
  scrollType,
  children,
  title,
  onLoadScroll,
  subtitle,
  containerMaxWidth,
  contentHeight = "25em",
  noScrollY = false,
  noScrollX = false,
  withBackButton = false,
  backButtonColor = "secondary",
  mobileScrollHeight = "calc(100vh - 262px)",
}: {
  scrollType: "vertical" | "horizontal" | "none";
  children: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  onLoadScroll?: { index: number; total: number };
  containerMaxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  contentHeight?: string;
  noScrollY?: boolean;
  noScrollX?: boolean;
  withBackButton?: boolean;
  backButtonColor?: ButtonProps["color"];
  mobileScrollHeight?: string;
}) {
  const navigate = useNavigate();
  containerMaxWidth = containerMaxWidth || "lg";
  /*Vertical*/
  const [fadeTop, setFadeTop] = React.useState(false);
  const [fadeBottom, setFadeBottom] = React.useState(true);
  /*Horizontal*/
  const [fadeLeft, setFadeLeft] = React.useState(false);
  const [fadeRight, setFadeRight] = React.useState(true);

  const [scrollerRef, setScrollerRef] = React.useState<any>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const renderer =
    scrollType === "vertical"
      ? React.useCallback(
          (props: any) => {
            let maskImage;
            if (!noScrollY) {
              if (fadeTop && fadeBottom) {
                maskImage =
                  "linear-gradient(to bottom, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%)";
              } else if (fadeTop) {
                maskImage =
                  "linear-gradient(to top, black calc(100% - 48px), transparent 100%)";
              } else if (fadeBottom) {
                maskImage =
                  "linear-gradient(to bottom, black calc(100% - 48px), transparent 100%)";
              }

              props.style = {
                ...props.style,
                maskImage,
                WebkitMaskImage: maskImage,
              };
            }
            const { elementRef, ...restProps } = props;
            return <span {...restProps} ref={elementRef} />;
          },
          [fadeTop, fadeBottom]
        )
      : React.useCallback(
          (props: any) => {
            let maskImage;
            if (!noScrollX) {
              if (fadeLeft && fadeRight) {
                maskImage =
                  "linear-gradient(to right, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%)";
              } else if (fadeLeft) {
                maskImage =
                  "linear-gradient(to left, black calc(100% - 48px), transparent 100%)";
              } else if (fadeRight) {
                maskImage =
                  "linear-gradient(to right, black calc(100% - 48px), transparent 100%)";
              }
              props.style = {
                ...props.style,
                maskImage,
                WebkitMaskImage: maskImage,
              };
            }
            const { elementRef, ...restProps } = props;
            return <span {...restProps} ref={elementRef} />;
          },
          [fadeLeft, fadeRight]
        );

  React.useEffect(() => {
    if (scrollerRef && onLoadScroll) {
      window.setTimeout(() => {
        scrollerRef.scrollerElement.scrollLeft =
          (scrollerRef.scrollValues.contentScrollWidth / onLoadScroll.total) *
          onLoadScroll.index;
      }, 1);
    }
  }, [scrollerRef]);
  const renderable =
    scrollType === "none" ? (
      children
    ) : (
      <Scrollbar
        ref={(ref: any) => {
          if (scrollType === "horizontal" && ref && ref.scrollerElement) {
            ref.scrollerElement.addEventListener("wheel", (evt: WheelEvent) => {
              evt.preventDefault();
              ref.scrollerElement.scrollLeft += evt.deltaY;
            });
            setScrollerRef(ref);
          }
        }}
        noScrollY={noScrollY || scrollType === "horizontal"}
        noScrollX={noScrollX}
        disableTrackYMousewheelScrolling={scrollType === "horizontal"}
        style={{
          marginTop:
            scrollType === "horizontal" ? (isMobile ? "1em" : "4em") : "1em",
          width: "100%",
          height:
            scrollType === "vertical"
              ? isMobile
                ? mobileScrollHeight
                : "calc(100vh - 262px)"
              : contentHeight,
          maskImage:
            scrollType === "horizontal"
              ? "linear-gradient(to right, black calc(100% - 48px), transparent 100%)"
              : "linear-gradient(to bottom, black calc(100% - 48px), transparent 100%)",
        }}
        onScroll={(event) => {
          let scrollValues = event as {
            scrollTop: number;
            scrollLeft: number;
            contentScrollHeight: number;
            clientHeight: number;
            contentScrollWidth: number;
            clientWidth: number;
          };
          if (scrollValues.scrollTop) {
            setFadeTop(scrollValues.scrollTop > 10);

            setFadeBottom(
              scrollValues.contentScrollHeight - scrollValues.scrollTop >
                scrollValues.clientHeight
            );
          }

          if (scrollValues.scrollLeft) {
            setFadeLeft(scrollValues.scrollLeft > 10);

            setFadeRight(
              scrollValues.contentScrollWidth - scrollValues.scrollLeft >
                scrollValues.clientWidth
            );
          }
        }}
        scrollerProps={{
          renderer,
        }}
      >
        {children}
      </Scrollbar>
    );

  return (
    <PageTransitionWrapper>
      {withBackButton && (
        <Button
          className="back-button"
          color={backButtonColor}
          onClick={() => {
            navigate("/");
          }}
          sx={{
            position: "absolute",
            left: 0,
            width: "calc(calc(100vw - 1200px) / 2)",
            cursor: "pointer",
            display: "flex",
            textTransform: "uppercase",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            letterSpacing: "3px",
            opacity: 0.4,
            ".BackLabel": {
              opacity: 0,
            },
            "&:hover": {
              opacity: 1,
              ".BackLabel": {
                opacity: 1,
              },
            },
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          }}
        >
          <ChevronLeftIcon sx={{ fontSize: "4em" }} />
          <Box className="BackLabel">Back</Box>
        </Button>
      )}
      <Container maxWidth={containerMaxWidth} sx={{ mt: 4, mb: 4 }}>
        {typeof title === "string" ? (
          <GradientText sx={{ fontSize: "3em" }} dark>
            {title}
          </GradientText>
        ) : (
          title
        )}
        {subtitle}
        {renderable}
      </Container>
    </PageTransitionWrapper>
  );
}
