import * as React from "react";
import Tilt from "react-parallax-tilt";
import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent, { DialogContentProps } from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import GradientText from "lib/typography/components/GradientText";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import theme from "theme";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { grey } from "@mui/material/colors";
import {
  pauseRewardAnimations,
  resumeRewardAnimations,
} from "lib/animation/RewardCanvas";
import { setCanAnimate } from "lib/navigation/slice";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { modalSlice } from "../slice";
import store from "store";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});
const Backdrop = styled(BackdropUnstyled)`
  z-index: 10000;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  box-shadow: inset 0 8px 32px 0 rgba(255, 255, 255, 0.37);
  -webkit-tap-highlight-color: transparent;
`;

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

let hasScrolled = false;
export default function Modal({
  onClose,
  imageSrc,
  dialogTitle,
  dialogHeaderContent,
  dialogBodyContent,
  dialogBodyContentProps,
  dialogActions,
  sx,
  tilt,
  borderRadius,
  dialogProps,
  image,
}: {
  onClose: (ev: any) => void | (() => void);
  dialogTitle?: React.ReactNode;
  imageSrc?: string;
  dialogHeaderContent?: React.ReactNode;
  dialogBodyContent: React.ReactNode;
  dialogBodyContentProps?: DialogContentProps;
  dialogActions?: React.ReactNode;
  borderRadius?: string;
  sx?: DialogProps["sx"];
  tilt?: boolean;
  dialogProps?: Partial<DialogProps>;

  image?: React.ReactNode;
}) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  let content = (
    <Paper sx={{ ...sx }}>
      <DialogTitle
        ref={(ref) => {
          if (ref) {
            window.setTimeout(() => {
              if (!hasScrolled) {
                hasScrolled = true;
                ref.scrollIntoView({ block: "start", behavior: "smooth" });
              }
            }, 100);
          }
        }}
      >
        <GradientText dark sx={{ fontSize: "clamp(1em, 5vw, 2em)" }}>
          {dialogTitle}
        </GradientText>
      </DialogTitle>

      {dialogHeaderContent && (
        <DialogContent>{dialogHeaderContent}</DialogContent>
      )}
      {imageSrc && (
        <CardMedia
          component="img"
          image={imageSrc}
          alt="success"
          height={300}
          sx={{ objectPosition: "top", objectFit: "contain" }}
        />
      )}
      {image}
      <DialogContent
        {...dialogBodyContentProps}
        sx={{ overflow: "visible" }}
        dividers
      >
        {dialogBodyContent}
      </DialogContent>
      {dialogActions && (
        <DialogActions
          sx={{ [theme.breakpoints.down("md")]: { flexWrap: "wrap" } }}
        >
          {dialogActions}
        </DialogActions>
      )}
    </Paper>
  );

  if (tilt) {
    content = (
      <Tilt tiltReverse tiltMaxAngleX={4} perspective={1200} tiltMaxAngleY={4}>
        {content}
      </Tilt>
    );
  }

  console.log({
    ...(dialogProps?.sx ?? {}),
    // ...(borderRadius
    //   ? {
    //       ".MuiPaper-root ": {
    //         borderRadius,
    //       },
    //     }
    //   : {}),
  });

  React.useEffect(() => {
    pauseRewardAnimations();
    dispatch(setCanAnimate(false));
    return () => {
      window.setTimeout(() => {
        const modals = store.getState().modal.modals;
        if (modals.length > 0) return;
        resumeRewardAnimations();
        dispatch(setCanAnimate(true));
      }, 50);
    };
  }, []);
  return (
    <>
      <Dialog
        components={{ Root: Backdrop }}
        {...dialogProps}
        sx={{
          animation: "modalEntrance 0.25s ease-in forwards",
          "@keyframes modalEntrance": {
            "0%": {
              opacity: 0.4,
              filter: "blur(6px)",
              transform: `scale(1.15) skew(${randomIntFromInterval(
                -2,
                2
              )}deg, ${randomIntFromInterval(-2, 2)}deg)`,
            },
            "100%": {
              opacity: 1,
              filter: "blur(0px)",
              transform: "scale(1) skew(0deg)",
            },
          },
          ".MuiDialog-container > .MuiPaper-root": {
            background: "rgba( 255, 255, 255, 0.5 )",
            boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
            backdropFilter: "blur( 100px )",
            border: `1px solid ${grey[50]}`,
            borderRadius: "1em",
            overflow: "hidden",
            overflowY: "auto",
          },
          ...(dialogProps?.sx ?? {}),
          // ...(borderRadius
          //   ? {
          //       ".MuiPaper-root ": {
          //         borderRadius,
          //       },
          //     }
          //   : {}),
        }}
        open
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {content}
      </Dialog>
    </>
  );
}
