// @ts-nocheck
const c =
  document.getElementById("dashboard-tile-reveal-canvas") ||
  document.createElement("canvas");
c.id = "dashboard-tile-reveal-canvas";
c.style =
  "inset: 0px; position: absolute; z-index: -1;pointer-events:none;transition:opacity 0.5s ease-in-out;opacity:0;";
document.body.appendChild(c);
var w = (c.width = window.innerWidth),
  h = (c.height = window.innerHeight),
  ctx = c.getContext("2d"),
  opts = {
    lines: 50,
    splitDistX: 0.1,
    templateColor: "hsla(hue,80%,50%,.4)",
    baseVelX: 0.05,
    addedVelX: 0.05,
    baseVelY: -0.05,
    addedVelY: 0.05,
    baseRotSpeed: -0.515,
    addedRotSpeed: 30.535,
    baseLen: 1,
    addedLen: 1,
    baseLenDecrement: 0.1,
    addedLenDecrement: 0.2,
  },
  lines = [];
ctx.fillStyle = "rgba(0,0,0,0)";
let start = { x: 0, y: 0 };
let end = { x: 0, y: 0 };
function Line() {
  this.reset();
}
Line.prototype.reset = function () {
  this.x = start.x;
  this.y = start.y;
  this.vx = opts.baseVelX + opts.addedVelX * Math.random();
  this.vy = opts.baseVelY + opts.addedVelY * Math.random();

  this.len = opts.baseLen + opts.addedLen * Math.random();
  var rotSpeed = opts.baseRotSpeed + opts.addedRotSpeed * Math.random();

  if (Math.random() < 0.5) rotSpeed *= -1;

  this.sin = Math.sin(rotSpeed);
  this.cos = Math.cos(rotSpeed);

  this.x2 = start.x;
  this.y2 = start.x;
};
Line.prototype.step = function () {
  this.x += this.vx;
  this.y += this.vy;

  var x2 = this.x2;
  this.x2 = this.x2 * this.cos - this.y2 * this.sin;
  this.y2 = this.y2 * this.cos + x2 * this.sin;

  ctx.strokeStyle = opts.templateColor.replace("hue", (this.x / w) * 360);
  ctx.beginPath();
  ctx.moveTo(this.x, this.y);
  ctx.lineTo(this.x + this.x2, this.y + this.y2);
  ctx.stroke();

  if (this.y < -this.len) this.y = h + this.len;
  else if (this.y > h + this.len) this.y = -this.len;

  if (this.x > w + this.len) this.reset();
};
let animationRequestId = 0;
function anim() {
  animationRequestId = window.requestAnimationFrame(anim);

  //ctx.globalCompositeOperation = 'source-over';
  //ctx.fillStyle = 'rgba(0,0,0,.04)';
  //ctx.fillRect( 0, 0, w, h );

  update();
}
function update() {
  ctx.globalCompositeOperation = "lighter";

  if (lines.length < opts.lines && Math.random() < 0.1) lines.push(new Line());

  lines.map(function (line) {
    line.step();
  });
}

ctx.fillRect(0, 0, w, h);

for (var i = 0; i < 200; ++i) update();

window.addEventListener("click", function () {});
window.addEventListener("resize", function () {
  w = c.width = window.innerWidth;
  h = c.height = window.innerHeight;
  ctx.fillRect(0, 0, w, h);
  lines.length = 0;
  ctx.fillStyle = "rgba(0,0,0,0)";
});

export default {
  reset: ({ style, width, height }) => {
    c.style = style;
    c.width = width;
    c.height = height;
    ctx.fillStyle = "rgba(0,0,0,0)";
  },
  start: ({ start: { x: startX, y: startY }, end: { x: endX, y: endY } }) => {
    start = { x: startX, y: startY };
    end = { x: endX, y: endY };
    c.style.opacity = "1";
    anim();
  },
  stop: () => {
    ctx.globalCompositeOperation = "source-over"; // thanks AmaanC, had completely forgot
    ctx.fillRect(0, 0, w, h);
    lines.length = 0;
    cancelAnimationFrame(animationRequestId);
    c.style.opacity = "0";
  },
};
