import * as React from "react";

import PageTransitionWrapper from "lib/animation/components/PageTransitionWrapper";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkRedeemableExists,
  checkCanRedeem,
  redeemItem,
} from "lib/data/firebase";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import { Reward } from "lib/data/slice";
import { Box, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "lib/modal/components/Modal";
import Button from "@mui/material/Button";
import RewardItem from "lib/progression/components/RewardItem";
import { award } from "lib/progression/slice";
import RedeemableModal from "lib/redeemable/components/RedeemableModal";
export default function FreePage() {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [redeemableItems, setRedeemableItems] = React.useState<Reward[]>([]);
  const [hasClaimed, setHasClaimed] = React.useState(false);
  const { code } = useParams();

  if (code) {
    return (
      <RedeemableModal
        code={code}
        onClose={() => {
          navigate("/agility");
        }}
      />
    );
  } else {
    return <div>Invalid Code</div>;
  }
}
