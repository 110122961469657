import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import clsx from "clsx";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import GenericModal from "./Modal";
import UnstyledModal from "./UnstyledModal";
import Grid from "@mui/material/Grid";
import BuyButton from "lib/purchase/components/BuyButton";
import GradientText from "lib/typography/components/GradientText";

import {
  addPayment,
  createPaymentIntent,
  getBillingProfiles,
} from "lib/data/firebase";
import Clickable from "lib/audio/components/Clickable";
import theme from "theme";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
//import payment from "lib/payment/payment";
import UpgradeAccountModal from "./UpgradeAccount";

export interface BuyPremiumCurrencyModalProps {
  onClose: (ev: any) => void | (() => void);
}
import CheckoutForm from "lib/payment/components/CheckoutForm";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import consumePayments from "lib/payment/consumePayments";
import store from "store";
import ModalUnstyled from "@mui/base/ModalUnstyled";
const stripeKey =
  process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN === "thrivemind.app"
    ? "pk_live_51LAbS0C1XJbZeWCBNiFLuCSFJYTda3rkykonHruRmkTJVQKC4fYClNgcS1Vir2QzE7IhHwuU4iFgx32ZOZ9V2AH6001QHcHc4K"
    : "pk_test_51LAbS0C1XJbZeWCBSTJWFHqyZyIzAjuD4L8kufPkWGtdMbWyvkE7SvWA8oLIFHFSh8CAnJiTs6VC7sKQSX5Vb51o0050cdb6CX";
const stripePromise = loadStripe(stripeKey) as
  | Stripe
  | PromiseLike<Stripe | null>
  | null;
const PaymentScreen = () => {
  React.useEffect(() => {
    //payment();
  }, []);
  return (
    <form id="payment-method-form">
      <button>Submit</button>
    </form>
  );
};

interface SelectedPackage {
  priceKey: string;
  priceText: string;
  name: string;
}
const PackageSelectionScreen = ({
  onPackageSelected,
}: {
  onPackageSelected: (selectedPackage: SelectedPackage) => void;
}) => {
  const payment = useAppSelector((state) => state.payment);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        [theme.breakpoints.down("md")]: {
          maxHeight: "100vh",
          overflow: "scroll",
        },
      }}
    >
      <Grid
        spacing={10}
        container
        sx={{
          pointerEvents: "none",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "stretch",
          },
        }}
      >
        {payment.products.map((product, idx) => {
          const priceData = Object.values(product.prices)[0];
          const priceKey = Object.keys(product.prices)[0];
          const priceText = `$${new Intl.NumberFormat("en-US", {
            //style: "currency",
            //currency: priceData.currency,
          }).format(parseFloat((priceData.unit_amount / 100).toFixed(2)))}`;

          return (
            <Grid
              item
              xs={isMobile ? 12 : 4}
              sx={{ pointerEvents: "none", flexDirection: "column" }}
            >
              <Paper
                onClick={() => {
                  onPackageSelected({
                    priceKey,
                    priceText,
                    name: product.digest.name,
                  });
                }}
                variant="outlined"
                sx={{
                  pointerEvents: "all",
                  background: "rgba(0,0,0,0.2)",
                  backdropFilter: "blur(5px)",
                  "&:hover": {
                    cursor: "pointer",
                    background: "rgba(0,0,0,0.3)",
                    border: "1px solid rgba(255,255,255,0.2)",
                  },
                }}
              >
                <Grid
                  direction="column"
                  alignItems="center"
                  justifyContent="space-around"
                  container
                  sx={{
                    padding: "0.6em 0.4em 1.2em",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <Grid item xs={4}>
                    <GradientText
                      sx={{
                        fontSize: "clamp(1em, 7vw, 2em)",
                        WebkitTextStrokeWidth: "1px",
                      }}
                    >
                      {product.digest.name}
                    </GradientText>
                  </Grid>
                  <Grid item xs={4}>
                    <img
                      src={`assets/store/package/gems_closed_${
                        (product.digest.metadata as any).packageType
                      }_full.png`}
                      style={{
                        maxHeight: "25em",
                        objectFit: "contain",
                        maxWidth: "100%",
                        pointerEvents: "none",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <GradientText
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "3em",
                      }}
                    >
                      {parseInt(
                        (product.digest.metadata as any).premiumCurrencyAmount
                      ).toLocaleString("en-US")}
                      <PremiumCurrencyIcon
                        color="secondary"
                        sx={{
                          fontSize: "1em",
                          marginRight: "0.1em",
                        }}
                      />
                    </GradientText>
                  </Grid>
                  <Grid item xs={4}>
                    <Clickable>
                      <BuyButton
                        sx={{
                          fontSize: "1em",
                          letterSpacing: "2px",
                          borderRadius: "1.5em",

                          left: 2,
                        }}
                        animationDelay={idx * 0.3}
                        label={`${priceText}`}
                      ></BuyButton>
                    </Clickable>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
interface BillingProfile {
  cardNumber: string;
  id: string;
}
export default function BuyPremiumCurrencyModal({
  onClose,
}: BuyPremiumCurrencyModalProps) {
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);

  const [screen, setScreen] = React.useState(0);
  const user = useAppSelector((state) => state.user);
  const [selectedPackage, setSelectedPackage] =
    React.useState<SelectedPackage | null>(null);
  const [clientSecret, setClientSecret] = React.useState("");
  const [newCard, setNewCard] = React.useState(false);
  const [billingProfiles, setBillingProfiles] = React.useState<
    BillingProfile[]
  >([]);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [paymentMessage, setPaymentMessage] = React.useState("");
  const [processingPayment, setProcessingPayment] = React.useState(false);
  React.useEffect(() => {
    getBillingProfiles({ uid: user.uid, currency: "cad" }).then((profiles) => {
      console.log({ profiles });
      if (profiles.billingProfiles.length === 0) {
        setHasLoaded(true);
        setNewCard(true);
        return;
      }
      // @ts-ignore
      if (profiles.billingProfiles.data.length > 0) {
        setBillingProfiles([
          {
            // @ts-ignore
            cardNumber: `**** **** **** ${profiles.billingProfiles.data[0].card.last4}`,
            // @ts-ignore
            id: profiles.billingProfiles.data[0].id,
          },
        ]);
        setHasLoaded(true);
      } else {
        setHasLoaded(true);
        setNewCard(true);
        return;
      }
    });
  }, []);
  React.useEffect(() => {
    if (hasLoaded && billingProfiles.length === 0 && selectedPackage) {
      createPaymentIntent({
        currency: "cad",
        item: selectedPackage.priceKey,
        uid: user.uid,
      }).then((response) => {
        console.log("?", response);
        setClientSecret(response.clientSecret);
      });
    }
  }, [selectedPackage, hasLoaded]);
  React.useEffect(() => {
    if (processingPayment) {
      onClose({});
    }
  }, [Object.keys(progression.consumables).length]);
  if (user.isAnonymous) {
    return (
      <UpgradeAccountModal
        onClose={(ev) => {
          console.log(ev);
          if (ev.type === "click") {
            onClose(ev);
          }
        }}
      />
    );
  }

  const Wrapper = clientSecret ? Elements : React.Fragment;

  const wrapperProps = clientSecret
    ? {
        options: {
          clientSecret,
          appearance: {
            theme: "night" as const,
            variables: {
              fontFamily: "Sriracha, cursive",
              colorText: "#FFD700",
            },
          },
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Prompt:wght@100;300;400;600;700&family=Sriracha&display=swap",
            },
          ],
        },
        stripe: stripePromise,
      }
    : { stripe: null };
  return (
    <UnstyledModal onClose={onClose}>
      <>
        <PackageSelectionScreen
          onPackageSelected={(currentPackage) => {
            //setScreen(1);
            setSelectedPackage(currentPackage);
            //addPayment(user.uid, price);
          }}
        />
        {selectedPackage && billingProfiles.length > 0 && (
          <GenericModal
            onClose={onClose}
            dialogProps={{}}
            sx={{ background: "#222" }}
            dialogTitle={
              <GradientText sx={{ fontSize: "1em" }}>
                Buy {selectedPackage.name}
              </GradientText>
            }
            dialogBodyContent={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    color: "#FFD700",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {billingProfiles.map((billingProfile) => (
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        fontSize: "2em",
                      }}
                      onClick={() => {
                        setProcessingPayment(true);
                        createPaymentIntent({
                          currency: "cad",
                          item: selectedPackage.priceKey,
                          uid: user.uid,
                          paymentMethod: billingProfile.id,
                        }).then((response) => {
                          console.log("?", response);
                          setClientSecret(response.clientSecret);
                          let attempts = 0;
                          const maxAttempts = 5;
                          const checkTimer = () => {
                            attempts++;
                            consumePayments(
                              dispatch,
                              progression,
                              user.uid,
                              store.getState().progression
                            );
                            if (attempts < maxAttempts) {
                              window.setTimeout(checkTimer, 1000);
                            } else {
                              setPaymentMessage(
                                "There was an error processing your request, please try again later."
                              );
                            }
                          };
                          window.setTimeout(checkTimer, 1000);
                        });
                      }}
                    >
                      {billingProfile.cardNumber}
                    </Button>
                  ))}
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ borderStyle: "dashed" }}
                    onClick={() => {
                      createPaymentIntent({
                        currency: "cad",
                        item: selectedPackage.priceKey,
                        uid: user.uid,
                      }).then((response) => {
                        console.log("?", response);
                        setClientSecret(response.clientSecret);
                        setNewCard(true);
                      });
                    }}
                  >
                    Add New Card
                  </Button>
                </Box>
                <GradientText sx={{ fontSize: "3em", mt: 6 }}>
                  {selectedPackage.priceText}
                </GradientText>
              </Box>
            }
          />
        )}
        {selectedPackage && (billingProfiles.length === 0 || newCard) && (
          <GenericModal
            onClose={onClose}
            dialogProps={{}}
            sx={{ background: "#222" }}
            dialogTitle={
              <GradientText sx={{ fontSize: "1em" }}>
                Enter payment details
              </GradientText>
            }
            dialogBodyContent={
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Elements {...wrapperProps}>
                  {stripePromise && <CheckoutForm />}
                </Elements>
              </Box>
            }
          />
        )}
        {processingPayment && (
          <GenericModal
            onClose={onClose}
            dialogProps={{}}
            sx={{ background: "#222" }}
            dialogTitle={
              <GradientText sx={{ fontSize: "1em" }}>
                Processing Payment
              </GradientText>
            }
            dialogBodyContent={
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {paymentMessage ? (
                  paymentMessage
                ) : (
                  <CircularProgress color="secondary" />
                )}
              </Box>
            }
          />
        )}
        {/* {selectedPackage && (
        <Modal
          onClose={onClose}
          dialogProps={{}}
          dialogTitle="Loading secure payment page"
          dialogBodyContent={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          }
        />
      )} */}
      </>
    </UnstyledModal>
  );
}
