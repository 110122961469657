import { Box, IconButton, Tooltip } from "@mui/material";
import { blue, green, grey, purple, yellow } from "@mui/material/colors";
import React from "react";
import rarityBasedFrames from "../rarityBasedFrames";
import FilterIcon from "@mui/icons-material/Filter";
import theme from "theme";
export default function CategoryFilter({
  selectedFilters,
  setSelectedFilters,
}: {
  selectedFilters: number[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: "1em",
        [theme.breakpoints.down("md")]: {
          justifyContent: "flex-start",
          paddingTop: 1,
          paddingBottom: 1,
          background:
            "linear-gradient(to right, rgba(255,255,255,1) 70%,  transparent)",
        },
      }}
    >
      {rarityBasedFrames.map((frame, index) => {
        const color =
          index === 0
            ? grey[600]
            : index === 1
            ? green[400]
            : index === 2
            ? blue[400]
            : index === 3
            ? purple[400]
            : yellow[700];

        const tooltipTitle =
          index === 0
            ? "Common"
            : index === 1
            ? "Rare"
            : index === 2
            ? "Epic"
            : index === 3
            ? "Legendary"
            : "Mythic";
        return (
          <Tooltip title={tooltipTitle} key={index}>
            <IconButton
              onClick={() => {
                setSelectedFilters((currentFilters) => {
                  const newFilters = [...currentFilters];
                  if (newFilters.includes(index)) {
                    newFilters.splice(newFilters.indexOf(index), 1);
                  } else {
                    newFilters.push(index);
                  }
                  return newFilters;
                });
              }}
              sx={{
                color,
                marginLeft: "0.4em",
                opacity:
                  selectedFilters.length === 0 ||
                  selectedFilters.includes(index)
                    ? 1
                    : 0.5,
                "&:after": {
                  content: "''",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  ...frame,
                  borderImageWidth: "10px",
                  borderRadius: "2px",
                },
              }}
            >
              <FilterIcon />
            </IconButton>
          </Tooltip>
        );
      })}
    </Box>
  );
}
