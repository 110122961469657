import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";

import Grid from "@mui/material/Grid";
import BuyButton from "lib/purchase/components/BuyButton";
import GradientText from "lib/typography/components/GradientText";

import {
  addPayment,
  createPaymentIntent,
  getUserPayments,
} from "lib/data/firebase";
import Clickable from "lib/audio/components/Clickable";
import theme from "theme";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
//import payment from "lib/payment/payment";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { assignState, Consumables } from "lib/progression/slice";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});
const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  -webkit-tap-highlight-color: transparent;
`;
const stripePromise = loadStripe(
  "pk_test_51LAbS0C1XJbZeWCBSTJWFHqyZyIzAjuD4L8kufPkWGtdMbWyvkE7SvWA8oLIFHFSh8CAnJiTs6VC7sKQSX5Vb51o0050cdb6CX"
);
function PurchaseCompletePage() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const stripe = useStripe();
  const progresison = useAppSelector((state) => state.progression);
  const elements = useElements();
  const navigate = useNavigate();
  const products = useAppSelector((state) => state.payment.products);
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  //   const checkPayment = async () => {
  //     const payments = await getUserPayments(user.uid);

  //     let consumables: Consumables = {};
  //     let packageType = ``;
  //     for (let paymentItem of payments) {
  //       if (!paymentItem.consumed) {
  //         const rewards = [paymentItem.metadata.item].map(
  //           (paymentProductItem: any) => {
  //             const productIndex = products.findIndex(
  //               (product) => paymentProductItem in product.prices
  //             );
  //             const productItem = products[productIndex];

  //             // const packageSearch = products.find(
  //             //   (product) =>
  //             //     paymentProductItem.description === product.digest.prices
  //             // );
  //             packageType = (productItem?.digest.metadata as any).packageType;
  //             return {
  //               type: "premium_currency",
  //               amount: parseInt(
  //                 (productItem?.digest.metadata as any).premiumCurrencyAmount
  //               ),
  //               id: paymentItem.id,
  //             };
  //           }
  //         );
  //         consumables[paymentItem.id] = userData.progression.consumables[
  //           paymentItem.id
  //         ] || {
  //           rewards,
  //           source: `payment.${packageType}`,
  //           hasConsumed: false,
  //         };
  //       }
  //     }
  //     dispatch(
  //       assignState(
  //         Object.assign({ ...progression }, userData.progression, {
  //           consumables: {
  //             ...userData.progression.consumables,
  //             ...consumables,
  //           },
  //           achievements: {
  //             progress: {
  //               ...progression.achievements.progress,
  //               ...userData.progression.achievements.progress,
  //             },
  //           },
  //         })
  //       )
  //     );
  //   };
  React.useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        throw new Error("No payment intent");
      }
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/purchase-complete`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(`${error.message}`);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    //layout: "tabs",
  };

  return (
    <Modal open onClose={() => {}} components={{ Root: Backdrop }}>
      {message ? (
        <Box
          sx={{
            height: "50vh",
            width: "50vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            outline: "none!important",
          }}
        >
          <Box
            sx={{
              background: `url("assets/hero/purchase-complete.png")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              maxHeight: "100%",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transition: "opacity 2s ease-in",
              [theme.breakpoints.down("md")]: { maxHeight: "4em" },
            }}
          />
          <GradientText sx={{ fontSize: "2em" }}>{message}</GradientText>
          <Button
            onClick={() => {
              navigate("/");
            }}
            variant="contained"
            color="secondary"
          >
            Continue
          </Button>
        </Box>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </Modal>
  );
}

export default function ConnectedPurchaseCompletePage() {
  return (
    <Elements stripe={stripePromise}>
      <PurchaseCompletePage />
    </Elements>
  );
}
