import { CardActionArea, Chip, Divider, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { grey } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/system/Box";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

import Tilt from "react-parallax-tilt";
import { pushModal } from "lib/modal/slice";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Clickable from "lib/audio/components/Clickable";
import GradientText from "lib/typography/components/GradientText";
import theme from "theme";
import playSound from "lib/audio/sfx";

export default function NavigationCard({
  id,
  to,
  imageSrc,
  title,
  description,
  asTutorial,
  challengeRating,
  locked,
  cost,
  unlockLevel,
}: {
  id: string;
  to: string;
  imageSrc: string;
  title: string;
  description: string;
  asTutorial?: boolean;
  challengeRating?: number;
  cost?: { basic?: number; premium?: number };
  locked?: boolean;
  unlockLevel?: number;
}) {
  const navigate = useNavigate();
  const progression = useAppSelector((state) => state.progression);
  const preferences = useAppSelector((state) => state.preferences);
  const shouldRenderCost = !locked && cost && typeof cost.basic !== "undefined";
  let canAfford = true;
  if (shouldRenderCost) {
    canAfford =
      typeof cost.basic !== "undefined" &&
      cost.basic <= progression.currency.basic;
  }
  const actionText = !canAfford
    ? "You cannot afford to play this"
    : asTutorial
    ? "Press here to learn more."
    : "Press here to begin.";
  const dispatch = useAppDispatch();

  let challengeTip = "";
  switch (challengeRating) {
    case 1:
      challengeTip = "This challenge looks extremely easy.";
      break;
    case 2:
      challengeTip = "This challenge looks very easy.";
      break;
    case 3:
      challengeTip = "This challenge looks easy.";
      break;
    case 4:
      challengeTip = "This challenge looks average.";
      break;
    case 5:
      challengeTip = "This challenge looks hard.";
      break;
    case 6:
      challengeTip = "This challenge looks very hard.";
      break;
    case 7:
      challengeTip = "This challenge looks extremely hard.";
      break;
    case 8:
      challengeTip = "This challenge is difficult.";
      break;
    case 9:
      challengeTip = "This challenge will require great skill.";
      break;
    case 10:
      challengeTip = "This challenge will require mastery.";
      break;
  }
  return (
    <Box
      sx={{
        "@keyframes navigationCardEntrance": {
          from: {
            filter: "blur(10px)",
            opacity: 0.4,
          },
          to: {
            filter: "blur(0)",
            opacity: 1,
          },
        },
        animation: "navigationCardEntrance 0.4s ease-out forwards",
      }}
    >
      <Tilt tiltReverse tiltMaxAngleX={4} perspective={1200} tiltMaxAngleY={4}>
        <Clickable>
          <Link
            {...(asTutorial && canAfford && !locked
              ? {
                  onClick: (ev: React.MouseEvent<HTMLAnchorElement>) => {
                    if (preferences.gameTutorial) {
                      dispatch(
                        pushModal({
                          componentName: "GameTutorial",
                          props: {
                            gameId: id,
                          },
                        })
                      );
                    } else {
                      ev.preventDefault();

                      navigate(to);
                    }
                  },
                }
              : !canAfford && !locked
              ? {
                  onClick: () => {
                    dispatch(
                      pushModal({
                        componentName: "BuyBasicCurrency",
                        props: {},
                      })
                    );
                  },
                }
              : {})}
            to={locked || asTutorial || !canAfford ? "" : to}
            style={{
              position: "relative",
              textDecoration: "none",
              color: "inherit",
              transform: "translateZ(10000px)",
            }}
          >
            <Card
              sx={{
                width: "20vw",
                height: "40vh",
                boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
                backdropFilter: "blur( 4px )",
                background: canAfford
                  ? "rgba( 255, 255, 255, 0.85 )"
                  : "linear-gradient(135deg, #22222280, #22222225 10%, transparent 20%)",
                overflow: "visible",
                [theme.breakpoints.down("md")]: {
                  width: "88vw",
                  height: "300px",
                },
              }}
            >
              <Box sx={{ position: "absolute", left: "0.2em", top: "0.2em" }}>
                {shouldRenderCost && (
                  <Tooltip
                    disableFocusListener
                    sx={{
                      position: "relative",
                      zIndex: 1000,
                      transform: "translateZ(1000px)",
                    }}
                    arrow
                    title={"The cost to play the game."}
                  >
                    <Chip
                      color={canAfford ? "primary" : "error"}
                      variant="outlined"
                      size="medium"
                      icon={
                        <AcUnitIcon
                          color={canAfford ? "primary" : "error"}
                          fontSize="medium"
                        />
                      }
                      label={(cost.basic ?? "").toLocaleString("en-US")}
                    ></Chip>
                  </Tooltip>
                )}
              </Box>
              <Box sx={{ position: "absolute", right: "0.2em", top: "0.2em" }}>
                {!locked && (
                  <Tooltip
                    disableFocusListener
                    sx={{
                      position: "relative",
                      zIndex: 1000,
                      transform: "translateZ(1000px)",
                    }}
                    arrow
                    title={challengeTip}
                  >
                    <Chip
                      color="success"
                      variant="outlined"
                      size="medium"
                      icon={
                        <FitnessCenterIcon
                          color={"success"}
                          fontSize="medium"
                        />
                      }
                      label={challengeRating}
                    ></Chip>
                  </Tooltip>
                )}
              </Box>
              <CardActionArea
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  //[theme.breakpoints.down("md")]: { height: "100%" },
                }}
              >
                <CardMedia
                  component="img"
                  image={locked ? "/assets/panel/locked.svg" : imageSrc}
                  alt="agility cover"
                  sx={{
                    objectPosition: "top",
                    height: "18em",
                    width: "auto",
                    flex: "1 1 auto",
                    [theme.breakpoints.down("md")]: {
                      objectPosition: "center top",
                      margin: "auto",
                      height: "50%",
                      width: "auto",
                    },
                  }}
                />
                <CardContent sx={{ width: "100%" }}>
                  <GradientText sx={{ WebkitTextStrokeWidth: "1px" }} dark>
                    {locked ? "Locked" : title}
                  </GradientText>
                  <Typography variant="body2" color="text.secondary">
                    {locked ? "" : description}
                  </Typography>
                  <Divider sx={{ margin: "1em 0" }} />
                  <Typography variant="body1" color="text.secondary">
                    {locked ? `Unlocks at level ${unlockLevel}` : actionText}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Clickable>
      </Tilt>
    </Box>
  );
}
