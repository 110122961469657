import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import GradientText from "lib/typography/components/GradientText";
import Clickable from "lib/audio/components/Clickable";
import { setGameTutorial } from "lib/preferences/slice";
import theme from "theme";
import playSound from "lib/audio/sfx";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

interface Reward {
  currency: number;
}

export interface GameTutorialModalProps {
  gameId: string;
  onClose: (ev: any) => void | (() => void);
  redirect?: boolean;
}
export default function GameTutorialModal({
  onClose,
  gameId,
  redirect = true,
}: GameTutorialModalProps) {
  const navigate = useNavigate();
  const games = useAppSelector((state) => state.app.games);
  const game = games.find((game) => game.id === gameId)!;
  const dispatch = useAppDispatch();
  return (
    <Modal
      onClose={onClose}
      imageSrc={`assets/tutorial/${game.subtype}.gif`}
      dialogTitle={game.title}
      dialogHeaderContent={game.description}
      dialogBodyContent={
        <Typography variant="h6" gutterBottom>
          {game.help}
        </Typography>
      }
      dialogActions={
        <>
          <FormControlLabel
            sx={{
              marginRight: "auto",
              marginLeft: "0.5rem",
              [theme.breakpoints.down("md")]: {
                display: "block",
              },
            }}
            control={<Checkbox defaultChecked />}
            onChange={(ev, value) => {
              dispatch(setGameTutorial(value));
            }}
            label="Always show this tutorial"
          />
          <Clickable>
            <Button
              onMouseDown={() => {
                playSound("game-complete-collect");
              }}
              onClick={(ev) => {
                onClose(ev);
                if (redirect) {
                  navigate(game.to);
                }
              }}
              size="large"
              sx={{
                letterSpacing: "6px",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                  borderRadius: "0 0 10px 10px",
                },
              }}
              autoFocus
              variant="contained"
              color="primary"
            >
              <GradientText sx={{ fontSize: "3em" }}>Begin</GradientText>
            </Button>
          </Clickable>
        </>
      }
    />
  );
}
