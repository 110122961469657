import { PlaylistAddOutlined } from "@mui/icons-material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentData } from "firebase/firestore";
import { Level, Reward } from "lib/data/slice";

import { User } from "@firebase/auth";
// Define the initial state using that type
type UserState = {
  sfx: boolean;
  music: boolean;
  gameTutorial: boolean;
  hasAutoPlayedBefore: boolean;
};
const initialState: UserState = {
  sfx: true,
  music: true,
  gameTutorial: true,
  hasAutoPlayedBefore: false,
};

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    setSFX: (state, action: PayloadAction<boolean>) => {
      state.sfx = action.payload;
    },
    setMusic: (state, action: PayloadAction<boolean>) => {
      state.music = action.payload;
    },
    setGameTutorial: (state, action: PayloadAction<boolean>) => {
      state.gameTutorial = action.payload;
    },
    setHasAutoPlayedBefore: (state, action: PayloadAction<boolean>) => {
      state.hasAutoPlayedBefore = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMusic, setSFX, setGameTutorial, setHasAutoPlayedBefore } =
  preferencesSlice.actions;

export default preferencesSlice.reducer;
