import * as React from "react";
import Sequencing from "lib/capacity/components/Sequencing";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import { shuffleArray } from "lib/utils/array";
import { ChallengePossibility } from "lib/data/slice";

export default function AgilitySequencingPage() {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const [currentChallenge, setCurrentChallenge] = React.useState(0);
  const [challenges, setChallenges] = React.useState<ChallengePossibility[]>(
    []
  );
  React.useEffect(() => {
    // let rawChallenges = [
    //   ...appState.challenge.source.capacitySequencing.possibility,
    // ];
    // shuffleArray(rawChallenges);
    // setChallenges(rawChallenges);
  }, []);

  return (
    <Sequencing
      challenges={challenges}
      currentChallenge={currentChallenge}
      onNextChallenge={() => {
        setCurrentChallenge(currentChallenge + 1);
      }}
      onChallengeSuccess={({ hits, perfects }) => {
        if (currentChallenge === challenges.length - 1) {
          dispatch(
            pushModal({
              componentName: "AssignmentComplete",
              props: {
                hits: {
                  amount: hits,
                  experience:
                    hits *
                    appState.challenge.rules.hitRewards.filter(
                      (reward) => reward.type === "experience"
                    )[0].amount[0],
                  currency:
                    hits *
                    appState.challenge.rules.hitRewards.filter(
                      (reward) => reward.type === "basic_currency"
                    )[0].amount[0],
                },
                perfects: {
                  amount: perfects,
                  experience:
                    perfects *
                    appState.challenge.rules.perfectRewards.filter(
                      (reward) => reward.type === "experience"
                    )[0].amount[0],
                  currency:
                    perfects *
                    appState.challenge.rules.perfectRewards.filter(
                      (reward) => reward.type === "basic_currency"
                    )[0].amount[0],
                },
                completion: {
                  experience: appState.challenge.rules.completionRewards.filter(
                    (reward) => reward.type === "experience"
                  )[0].amount[0],
                  currency: appState.challenge.rules.completionRewards.filter(
                    (reward) => reward.type === "basic_currency"
                  )[0].amount[0],
                },
              },
            })
          );
        }
      }}
    />
  );
}
