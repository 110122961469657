import { PlaylistAddOutlined } from "@mui/icons-material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AchievementTypes, Level, Reward } from "lib/data/slice";
import { iconsByCategoryName } from "lib/card/components/CategoryBadge";
import { setIsInGame } from "lib/navigation/slice";
import { getCurrentTime } from "lib/utils/date";
// Define a type for the slice state
export interface Pack {
  id: string;
  name: string;
  rewards: Reward[];
  type: "pack";
  amount: 1;
}
export interface Consumables {
  [key: string]: { hasConsumed: boolean; rewards: Reward[]; source: string };
}

export type GoalType =
  | "perfectStreak"
  | "playWord"
  | "playEquations"
  | "freeClaim"
  | "playCard"
  | "exchangeTokens";
export interface Goal {
  type: GoalType;
  progress: number;
  requiredAmount: number;
  requirementMeta?: string | number;
  rewards: Reward[];
  hasClaimed: boolean;
}
export const tutorialTypes = [
  "initialPreference",
  "bettingBasic",
  "newCardRewards",
  "premium",
  "freebies",
  "freebies2",
  "recycling",
  "tokens",
  "tokens2",
] as const;

export type GameTypes = "words" | "equations" | "letters" | "numbers";

export interface SelectableGame {
  id: string;
  type: GameTypes;
  cardId: string;
}
export type TutorialTypes = typeof tutorialTypes[number];
export type ZenState = {
  level: number;
  experience: number;
  resetDate: number;
  lastSeenExperience: number;
  prestige: number;
};
export type ChallengeLevel = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export interface Progression {
  experience: number;
  level: number;
  betAmount: number;
  currency: {
    basic: number;
    premium: number;
    token: {
      [key in keyof typeof iconsByCategoryName]: number;
    };
    basicSpent: number;
    basicEarned: number;
    premiumSpent: number;
    premiumEarned: number;
  };
  tutorial: { [key in TutorialTypes]?: boolean };
  tutorialStep: number;
  dailyBonus: { lastClaimed: string };
  packs: Pack[];
  freebies: number;
  unlocks: { [key: string]: { dateUnlocked: string; amount: number } };
  consumables: Consumables;
  goals: { current: Goal[]; completeAmount: number };
  cardHistory: string[];
  selectableGames: SelectableGame[];
  challengeLevel: ChallengeLevel;
  currentCardId: string;
  lastPremiumClaim: number;
  pity: {
    2: number;
    3: number;
    4: number;
  };
  zen: ZenState;
  achievements: {
    progress: { [key in AchievementTypes]: number };
  };
}
const currentTime = getCurrentTime();
// Define the initial state using that type
const initialState: Progression = {
  experience: 0,
  level: 1,
  betAmount: 100,
  packs: [],
  consumables: {
    "b549399f-b6be-4277-8437-8c92dca74cfe": {
      hasConsumed: false,
      rewards: [{ type: "lookup", amount: 50 }],
      source: "newCard",
    },
    "320aa3cf-2d36-4da7-9733-54eda2ef7473": {
      hasConsumed: false,
      rewards: [{ type: "lookup", amount: 50 }],
      source: "newCard",
    },
    "04fd9225-5aee-4ac3-88f6-6b77e56e7440": {
      hasConsumed: false,
      rewards: [{ type: "lookup", amount: 50 }],
      source: "newCard",
    },
    "8caee49b-abb7-432f-85d5-504f510b0fff": {
      hasConsumed: false,
      rewards: [{ type: "lookup", amount: 50 }],
      source: "newCard",
    },
  },
  tutorial: {},
  tutorialStep: 0,
  dailyBonus: {
    lastClaimed: new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toISOString(),
  },
  lastPremiumClaim: currentTime,
  pity: {
    2: 0,
    3: 0,
    4: 0,
  },
  currency: {
    basic: 500,
    premium: 0,
    basicEarned: 500,
    basicSpent: 0,
    premiumEarned: 0,
    premiumSpent: 0,
    token: Object.keys(iconsByCategoryName)
      .map((key) => ({ [key]: 0 }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {}) as {
      [key in keyof typeof iconsByCategoryName]: number;
    },
  },
  unlocks: {
    "b549399f-b6be-4277-8437-8c92dca74cfe": {
      dateUnlocked: new Date().toISOString(),
      amount: 1,
    },
    "320aa3cf-2d36-4da7-9733-54eda2ef7473": {
      dateUnlocked: new Date().toISOString(),
      amount: 1,
    },
    "04fd9225-5aee-4ac3-88f6-6b77e56e7440": {
      dateUnlocked: new Date().toISOString(),
      amount: 1,
    },
    "8caee49b-abb7-432f-85d5-504f510b0fff": {
      dateUnlocked: new Date().toISOString(),
      amount: 1,
    },
  },
  freebies: 10,
  goals: {
    current: [
      {
        type: "freeClaim",
        progress: 1,
        requiredAmount: 1,
        hasClaimed: false,
        rewards: [
          { type: "basic_currency", amount: 100 },
          { type: "experience", amount: 50 },
        ],
      },
    ],
    completeAmount: 0,
  },
  cardHistory: [],
  selectableGames: [],
  challengeLevel: 1,
  currentCardId: "b549399f-b6be-4277-8437-8c92dca74cfe",
  zen: {
    level: 1,
    experience: 0,
    resetDate: currentTime,
    lastSeenExperience: 0,
    prestige: 1,
  },
  achievements: {
    progress: { discover: 0, spendBasic: 0, completeGoal: 0, gamesPlayed: 0 },
  },
};

export const progressionSlice = createSlice({
  name: "progression",
  initialState,
  reducers: {
    addExperience: (state, action: PayloadAction<number>) => {
      state.experience += action.payload;
    },
    addBasicCurrency: (state, action: PayloadAction<number>) => {
      state.currency.basic += action.payload;

      state.currency.basicEarned += action.payload;
    },
    addPremiumCurrency: (state, action: PayloadAction<number>) => {
      state.currency.premium += action.payload;
    },
    setBetAmount: (state, action: PayloadAction<number>) => {
      state.betAmount = action.payload;
    },
    charge: (
      state,
      action: PayloadAction<{ basic?: number; premium?: number }>
    ) => {
      if (action.payload.basic) {
        state.currency.basic -= action.payload.basic;

        state.currency.basicSpent += action.payload.basic;
      }
      if (action.payload.premium) {
        state.currency.premium -= action.payload.premium;
        state.currency.premiumSpent += action.payload.premium;
      }
    },
    assignState: (state, action: PayloadAction<Progression>) => {
      return action.payload;
    },
    levelUpStart: (state, action: PayloadAction<Level>) => {},
    levelUpFinish: (
      state,
      action: PayloadAction<{ levelData: Level; dateString: string }>
    ) => {
      state.experience =
        state.experience - action.payload.levelData.experienceRequired;
      state.level++;
      for (let reward of action.payload.levelData.rewards) {
        if (reward.type === "basic_currency") {
          state.currency.basic += reward.amount;

          state.currency.basicEarned += reward.amount;
        } else if (reward.type === "premium_currency") {
          state.currency.premium += reward.amount;

          state.currency.premiumEarned += reward.amount;
        } else if (reward.id) {
          if (state.unlocks[reward.id]) {
            state.unlocks[reward.id].amount += reward.amount;
          } else {
            state.unlocks[reward.id] = {
              amount: reward.amount,
              dateUnlocked: action.payload.dateString,
            };
          }
        }
      }
    },
    award: (
      state,
      action: PayloadAction<{
        rewards: Reward[];
        dateString: string;
        packId?: string | string[];
        source?: string;
      }>
    ) => {
      for (let reward of action.payload.rewards) {
        if (reward.id && reward.id in state.consumables) {
          delete state.consumables[reward.id];
        }
        if (reward.type === "basic_currency") {
          state.currency.basic += reward.amount;
          state.currency.basicEarned += reward.amount;
        } else if (reward.type === "premium_currency") {
          state.currency.premium += reward.amount;
          state.currency.premiumEarned += reward.amount;
        } else if (reward.type === "pack" && reward.rewards) {
          state.packs.push({
            id: `${reward.id}`,
            name: `${reward.name}`,
            rewards: reward.rewards,
            type: "pack",
            amount: 1,
          });
        } else if (reward.type === "experience") {
          state.experience += reward.amount;
        } else if (reward.type === "zen_experience") {
          state.zen.experience += reward.amount;
        } else if (reward.type === "freebies") {
          state.freebies += reward.amount;
        } else if (reward.type.indexOf("token_currency") > -1) {
          const tokenType = reward.type.split(
            "token_currency_"
          )[1] as keyof typeof iconsByCategoryName;
          state.currency.token[tokenType] += reward.amount;
          for (let goal of state.goals.current) {
            if (
              goal.type === "exchangeTokens" &&
              goal.requirementMeta === tokenType
            ) {
              goal.progress += reward.amount;
            }
          }
        } else if (reward.id) {
          if (state.unlocks[reward.id]) {
            state.unlocks[reward.id].amount += reward.amount;
          } else {
            state.unlocks[reward.id] = {
              amount: reward.amount,
              dateUnlocked: action.payload.dateString,
            };
            state.consumables[reward.id] = {
              hasConsumed: false,
              rewards: [{ type: "lookup", amount: 50 }],
              source: "newCard",
            };
          }
        }
      }

      if (action.payload.packId) {
        state.packs = state.packs.filter((pack) =>
          Array.isArray(action.payload.packId)
            ? action.payload.packId.indexOf(pack.id) === -1
            : pack.id !== action.payload.packId
        );
      }

      if (action.payload.source === "dailySpin") {
        state.dailyBonus.lastClaimed = action.payload.dateString;
      }

      if (action.payload.source && action.payload.source.indexOf("goal") >= 0) {
        const goalIndex = parseInt(action.payload.source.split(".")[1]);
        state.goals.current[goalIndex].hasClaimed = true;
        state.goals.completeAmount++;
      }
    },
    buyPack: (
      state,
      action: PayloadAction<{
        pack: Pack | Pack[];
        basic?: number;
        premium?: number;
      }>
    ) => {
      if (Array.isArray(action.payload.pack)) {
        state.packs = state.packs.concat(action.payload.pack);
      } else {
        state.packs.push(action.payload.pack);
      }
      if (action.payload.basic) {
        state.currency.basic -= action.payload.basic;
        state.currency.basicSpent += action.payload.basic;
      }
      if (action.payload.premium) {
        state.currency.premium -= action.payload.premium;
        state.currency.premiumSpent += action.payload.premium;
      }
    },
    awardPacks: (
      state,
      action: PayloadAction<{ packs: Pack[]; source: string }>
    ) => {
      for (let pack of action.payload.packs) {
        state.packs.push(pack);
      }
    },

    buyFreebie: (
      state,
      action: PayloadAction<{ freebies: number; premium: number }>
    ) => {
      state.freebies += action.payload.freebies;
      state.currency.premium -= action.payload.premium;
      state.currency.premiumSpent += action.payload.premium;
    },
    useFreebie: (state) => {
      state.freebies--;
    },
    buyBasicCurrency: (
      state,
      action: PayloadAction<{ type: "small" | "medium" | "large" }>
    ) => {
      if (action.payload.type === "small" && state.currency.premium >= 1) {
        state.currency.basic += 1000;
        state.currency.premium -= 1;
        state.currency.basicEarned += 1000;
        state.currency.premiumSpent += 1;
      } else if (
        action.payload.type === "medium" &&
        state.currency.premium >= 5
      ) {
        state.currency.basic += 7500;
        state.currency.premium -= 5;

        state.currency.basicEarned += 7500;
        state.currency.premiumSpent += 5;
      } else if (
        action.payload.type === "large" &&
        state.currency.premium >= 10
      ) {
        state.currency.basic += 15000;
        state.currency.premium -= 10;

        state.currency.basicEarned += 15000;
        state.currency.premiumSpent += 10;
      }
    },
    recycleExtraCards: (
      state,
      action: PayloadAction<{
        unlockableIds: string[];
        basicCurrencyAward: number;
      }>
    ) => {
      for (let unlockableId of action.payload.unlockableIds) {
        if (state.unlocks[unlockableId]) {
          state.unlocks[unlockableId].amount = 1;
        }
      }

      state.currency.basic += action.payload.basicCurrencyAward;
      state.currency.basicEarned += action.payload.basicCurrencyAward;
    },
    refreshGoal: (
      state,
      action: PayloadAction<{
        goalIndex: number;
        goalData: Goal;
        freebiePrice?: number;
      }>
    ) => {
      state.goals.current[action.payload.goalIndex] = action.payload.goalData;
      if (action.payload.freebiePrice) {
        state.freebies -= action.payload.freebiePrice;
      }
    },
    goalActionProgress: (
      state,
      action: PayloadAction<{
        type: GoalType;
        requirementMeta?: string | number;
      }>
    ) => {
      for (let goal of state.goals.current) {
        if (
          goal.type === action.payload.type &&
          (!action.payload.requirementMeta ||
            goal.requirementMeta === action.payload.requirementMeta)
        ) {
          if (goal.type === "perfectStreak") {
            goal.progress = goal.requiredAmount;
          } else {
            goal.progress++;
          }
        }
      }
    },
    addCardToHistory: (state, action: PayloadAction<string>) => {
      state.cardHistory.push(action.payload);
      if (state.cardHistory.length > 9) {
        state.cardHistory.shift();
      }
    },
    setCurrentCardId: (state, action: PayloadAction<string>) => {
      state.currentCardId = action.payload;
    },
    updatePity(
      state,
      action: PayloadAction<{ rarity: 2 | 3 | 4; value: number }>
    ) {
      state.pity[action.payload.rarity] = action.payload.value;
    },
    updatePityBatch(
      state,
      action: PayloadAction<{ rarity: 2 | 3 | 4; value: number }[]>
    ) {
      for (let pity of action.payload) {
        state.pity[pity.rarity] = pity.value;
      }
    },
    setSelectableGames: (state, action: PayloadAction<SelectableGame[]>) => {
      state.selectableGames = action.payload;
    },
    clearSelectableGames: (state) => {
      state.selectableGames = [];
    },
    setChallengeLevel: (state, action: PayloadAction<ChallengeLevel>) => {
      state.challengeLevel = action.payload;
    },
    markTutorialFlagComplete(state, action: PayloadAction<TutorialTypes>) {
      state.tutorial[action.payload] = true;
    },
    setLastPremiumClaim: (state, action: PayloadAction<number>) => {
      state.lastPremiumClaim = action.payload;
    },
    setZenExperience: (state, action: PayloadAction<number>) => {
      state.zen.experience = action.payload;
    },
    setZenLevel: (state, action: PayloadAction<number>) => {
      state.zen.level = action.payload;
    },
    setZenResetDate: (state, action: PayloadAction<number>) => {
      state.zen.resetDate = action.payload;
    },
    setZenLastSeenExperience: (state, action: PayloadAction<number>) => {
      state.zen.lastSeenExperience = action.payload;
    },
    setAchievementProgress: (
      state,
      action: PayloadAction<{
        discover: number;
        spendBasic: number;
        completeGoal: number;
        gamesPlayed: number;
      }>
    ) => {
      state.achievements.progress = action.payload;
    },
    setTutorialStep: (state, action: PayloadAction<number>) => {
      state.tutorialStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setIsInGame, (state, action) => {
      if (action.payload === false) {
        state.currentCardId = "";
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  addExperience,
  addBasicCurrency,
  addPremiumCurrency,
  setBetAmount,
  assignState,
  levelUpStart,
  levelUpFinish,
  award,
  charge,
  buyPack,
  awardPacks,
  buyFreebie,
  useFreebie,
  buyBasicCurrency,
  recycleExtraCards,
  refreshGoal,
  goalActionProgress,
  addCardToHistory,
  setCurrentCardId,
  updatePity,
  updatePityBatch,
  setSelectableGames,
  clearSelectableGames,
  setChallengeLevel,
  markTutorialFlagComplete,
  setLastPremiumClaim,
  setZenExperience,
  setZenLevel,
  setZenResetDate,
  setZenLastSeenExperience,
  setAchievementProgress,
  setTutorialStep,
} = progressionSlice.actions;

export default progressionSlice.reducer;
