import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { popModal, pushModal } from "lib/modal/slice";

import NavBar from "lib/navigation/components/NavBar";
import {
  addBasicCurrency,
  addExperience,
  assignState,
  award,
  Consumables,
  levelUpFinish,
  setAchievementProgress,
} from "lib/progression/slice";
import * as React from "react";

import { useRoutes } from "react-router-dom";

import AgilityEquationsPage from "./pages/agility/equations";
import AgilityWordsPage from "./pages/agility/words";
import AgilityLettersPage from "./pages/agility/letters";
import AgilityNumbersPage from "./pages/agility/numbers";
import CapacityPage from "./pages/capacity";
import CapacityMatchingPage from "./pages/capacity/matching";
import CapacitySequencingPage from "./pages/capacity/sequencing";
import CardPage from "./pages/card";
import DailyPage from "./pages/daily";
import DashboardPage from "./pages/dashboard";
import FreeRedeemablePage from "./pages/free";
import GuestPage from "./pages/guest";
import HomePage from "./pages/index";
import PacksPage from "./pages/packs";
import SignInPage from "./pages/sign-in";
import SignUpPage from "./pages/sign-up";
import TokensPage from "./pages/tokens";
import TutorialPage from "./pages/tutorial";
import PurchaseCompletePage from "./pages/purchase-complete";

import Backdrop from "@mui/material/Backdrop";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { User } from "firebase/auth";
import { AnimatePresence } from "framer-motion";
import runAward from "lib/animation/RewardCanvas";
import {
  createPaymentIntent,
  getAppData,
  getCustomer,
  getNews,
  getProducts,
  getUserData,
  getUserPayments,
  listenAuthState,
  markPaymentConsumed,
  markUserPaymentConsumed,
  redeemItem,
  writeUserData,
} from "lib/data/firebase";
import { AchievementTypes, setAppData } from "lib/data/slice";
import { initialize } from "lib/data/static/word-association-service";
import AssignmentCompleteModal from "lib/modal/components/AssignmentComplete";
import BuyBasicCurrencyModal from "lib/modal/components/BuyBasicCurrency";
import BuyFreebiesModal from "lib/modal/components/BuyFreebies";
import BuyLevelUpModal from "lib/modal/components/BuyLevelUp";
import BuyPacksModal from "lib/modal/components/BuyPacks";
import BuyPremiumCurrencyModal from "lib/modal/components/BuyPremiumCurrency";
import CardSelectorModal from "lib/modal/components/CardSelector";
import ChestRewardModal from "lib/modal/components/ChestReward";
import GameTutorialModal from "lib/modal/components/GameTutorial";
import GoalRefreshModal from "lib/modal/components/GoalRefresh";
import LevelUpModal from "lib/modal/components/LevelUp";
import RecycleCardsModal from "lib/modal/components/RecycleCards";
import RewardModal from "lib/modal/components/Reward";
import SettingsModal from "lib/modal/components/Settings";
import TokenRelationshipModal from "lib/modal/components/TokenRelationship";
import UnlockableDetailsModal from "lib/modal/components/UnlockableDetails";
import BottomBar from "lib/navigation/components/BottomBar";
import { setProducts } from "lib/payment/slice";
import { setUser } from "lib/user/slice";
import { useLocation, useNavigate } from "react-router-dom";
import store from "store";
import "./App.css";

import CancelIcon from "@mui/icons-material/Cancel";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { IconButton, Chip, useMediaQuery } from "@mui/material";
import { setGlobalVolume as setParticleVolume } from "lib/animation/RewardCanvas";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import { setGlobalVolume as setClickVolume } from "lib/audio/sfx";
import visualizer, { setGlobalVolume } from "lib/audio/visualizer";
import NewsModal from "lib/modal/components/News";
import FatalErrorModal from "lib/modal/components/FatalError";
import CurrentGoalsModal from "lib/modal/components/CurrentGoals";
import GameSelectModal from "lib/modal/components/GameSelect";
import {
  setHasAutoPlayedBefore,
  setMusic,
  setSFX,
} from "lib/preferences/slice";
import { setNewsItems } from "lib/welcome-news/slice";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import theme from "theme";
import millify from "millify";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import { setBackgroundTransition } from "lib/navigation/slice";
import consumePayments from "lib/payment/consumePayments";
declare global {
  interface Window {
    StarDict: any;
  }
}

let spotlightHandler: null | ((ev: MouseEvent) => any) = null;

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const fullState = useAppSelector((state) => state);
  const modal = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const [userId, setUserId] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingProgress, setLoadingProgress] = React.useState(0);
  const [isNewUser, setIsNewUser] = React.useState(false);
  const progression = useAppSelector((state) => state.progression);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const preferences = useAppSelector((state) => state.preferences);
  const isInGame = useAppSelector((state) => state.navigation.isInGame);
  const navigation = useAppSelector((state) => state.navigation);

  const isLeavingGame = useAppSelector(
    (state) => state.navigation.isLeavingGame
  );
  const currentCardId = useAppSelector(
    (state) => state.progression.currentCardId
  );
  const basicCurrencyLabel = isMobile
    ? millify(Math.floor(progression.currency.basic))
    : Math.floor(progression.currency.basic).toLocaleString("en-US");
  const premiumCurrencyLabel = isMobile
    ? millify(Math.floor(progression.currency.premium))
    : Math.floor(progression.currency.premium).toLocaleString("en-US");
  if (
    !isLoading &&
    !progression.tutorial.initialPreference &&
    location.pathname !== "/tutorial" &&
    userId
  ) {
    navigate("/tutorial");
  }
  const routes = [
    { path: "/", element: <DashboardPage key="Home" /> },
    {
      path: "/sign-up",
      element: (
        <SignUpPage key="Signup" onSuccess={() => navigate("/tutorial")} />
      ),
    },
    { path: "/sign-in", element: <SignInPage key="SignIn" /> },
    { path: "dashboard", element: <DashboardPage key="Dashboard" /> },
    {
      path: "purchase-complete",
      element: <PurchaseCompletePage key="PurchaseComplete" />,
    },
    { path: "cards", element: <CardPage key="Card" /> },
    { path: "tokens", element: <TokensPage key="Card" /> },
    { path: "daily", element: <DailyPage key="Daily" /> },
    { path: "packs", element: <PacksPage key="Packs" /> },

    { path: "tutorial", element: <TutorialPage key="Tutorial" /> },
    { path: "guest", element: <GuestPage key="Guest" /> },

    //{path:"press-pass", element: <PressPassPage key="PressPass" />}

    { path: "capacity", element: <CapacityPage key="Capacity" /> },
    {
      path: "capacity/matching",
      element: <CapacityMatchingPage key="CapacityMatching" />,
    },
    {
      path: "capacity/sequencing",
      element: <CapacitySequencingPage key="CapacitySequencing" />,
    },

    {
      path: "agility/equations/:challengeLevel",
      element: <AgilityEquationsPage key="AgilityEquations" />,
    },

    {
      path: "agility/words/:challengeLevel",
      element: <AgilityWordsPage key="AgilityWords" />,
    },
    {
      path: "agility/letters/:challengeLevel",
      element: <AgilityLettersPage key="AgilityLetters" />,
    },
    {
      path: "agility/numbers/:challengeLevel",
      element: <AgilityNumbersPage key="AgilityNumbers" />,
    },
    {
      path: "free/:code",
      element: <FreeRedeemablePage key="FreeRedeemable" />,
    },
    {
      path: "*",
      element: <DashboardPage key="Home" />,

      // element: Navigate({
      //   to: "/dashboard",
      //   replace: true,
      // }),
    },
  ];

  const renderableRoutes = useRoutes(routes);
  const hydrate = async () => {
    //const response = await signIn({ asGuest: true });
  };
  React.useEffect(() => {
    if (window.StarDict) {
      initialize();
    } else {
      (
        document.getElementById("word-association-lib") as HTMLScriptElement
      ).addEventListener("load", () => {
        // DTM is loaded

        initialize();
      });
    }
  }, [window?.StarDict]);
  React.useEffect(() => {
    try {
      screen.orientation.lock("portrait");
    } catch (e) {
      console.log("Could not lock screen orientation");
    }
    setLoadingProgress(25);
    hydrate();

    listenAuthState(async (user: User) => {
      if (user) {
        setIsNewUser(false);
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        const {
          uid,
          email,
          providerId,
          emailVerified,
          isAnonymous,
          providerData,
        } = user;
        dispatch(
          setUser({
            uid,
            email,
            providerId,
            emailVerified,
            isAnonymous,
            providerData,
          })
        );
        setLoadingProgress(50);
        const userData = await getUserData(uid);
        console.log("[RTDB] Loaded user:", userData);

        setUserId(uid);

        const news = await getNews();

        dispatch(setNewsItems(news));
        await consumePayments(dispatch, progression, uid, userData);

        setLoadingProgress(75);

        if (window.location.hostname === "localhost") {
          //await writeAppData();
        }

        setLoadingProgress(100);
        dispatch(setAppData(await getAppData()));
        setIsLoading(false);

        // ...
      } else {
        setIsNewUser(true);
        dispatch(
          setUser({
            uid: "",
            email: "",
            providerId: "",
            emailVerified: false,
            isAnonymous: true,
            providerData: [],
          })
        );
        // User is signed out
        // ...
      }
    });
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      // dispatch(addExperience(100));
      // dispatch(addPremiumCurrency(1000));
      if (progression.consumables) {
        for (let consumable in progression.consumables) {
          if (progression.consumables[consumable].hasConsumed) {
            continue;
          }

          const rewards = progression.consumables[consumable].rewards.map(
            (data) => ({
              ...data,
              id: consumable,
            })
          );
          if (
            progression.consumables[consumable].source.indexOf("payment") > -1
          ) {
            const packageType =
              progression.consumables[consumable].source.split(".")[1];
            let name = "reward";
            switch (packageType) {
              case "small":
                name = "Mini Strand";
                break;
              case "medium":
                name = "Mega Strand";
                break;
              case "large":
                name = "Whopping Strand";
                break;
            }
            dispatch(
              pushModal({
                componentName: "ChestReward",
                props: {
                  consumableId: consumable,
                  chest: {
                    type: packageType,
                    name,
                    currencyType: "gems",
                  },
                },
              })
            );
            // dispatch(
            //   pushModal({
            //     componentName: "Reward",
            //     props: { rewards },
            //   })
            // );
          }
        }
      }
      // runAward(
      //   5,
      //   "assets/unlockable/thumbnail/b549399f-b6be-4277-8437-8c92dca74cfe.jpg"
      // );

      window.setTimeout(() => {
        //runAwardAnimation([{ type: "token_currency_Mountain", amount: 100 }]);
        //runAwardAnimation([{ type: "basic_currency", amount: 10000 }]);
      }, 2000);
      // runAward(100, "/assets/premium_currency_particle.svg");
    }
  }, [isLoading, Object.keys(progression.consumables || {}).length]);

  const sync = async (userId: string, isNewUser: boolean) => {
    const thing = await writeUserData(userId, {
      ...progression,
      lastPlayed: Math.round(Date.now() / 1000),
      ...(isNewUser ? { created: Math.round(Date.now() / 1000) } : {}),
    });
  };
  React.useEffect(() => {
    if (userId && isLoading === false) {
      sync(userId, isNewUser);
    }
  }, [progression, userId, isLoading]);

  React.useEffect(() => {
    if (!preferences.hasAutoPlayedBefore) {
      const handleGesture = () => {
        dispatch(setHasAutoPlayedBefore(true));
        visualizer();
        // Wait a few frames in case the first click is actually to turn off music
        window.setTimeout(() => {
          const currentPreferences = store.getState().preferences;
          setGlobalVolume(currentPreferences.music ? 1 : 0);
          setClickVolume(currentPreferences.sfx ? 1 : 0);
          setParticleVolume(currentPreferences.sfx ? 1 : 0);
        }, 10);
        window.removeEventListener("click", handleGesture);
      };
      window.addEventListener("click", handleGesture);
    }
    if (!navigation.isMusicPlaying && preferences.hasAutoPlayedBefore) {
      visualizer();
    }
  }, []);

  React.useEffect(() => {
    window.setTimeout(
      () =>
        dispatch(
          setBackgroundTransition({
            transitionState: "exiting",
          })
        ),
      200
    );
  }, [navigation.backgroundTransition.transitionState]);
  const [spotlightProperties, setSpotlightProperties] = React.useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    right: 0,
    bottom: 0,
  });

  React.useEffect(() => {
    let queryTarget: Element | null;

    if (navigation.spotlight.queryTarget) {
      queryTarget = document.querySelector(navigation.spotlight.queryTarget);
      spotlightHandler = (e: MouseEvent) => {
        const isParentOf =
          queryTarget &&
          e.target &&
          (queryTarget.contains(e.target as Node) ||
            (e.target as Node).contains(queryTarget));
        if (queryTarget && e.target !== queryTarget && !isParentOf) {
          e.stopPropagation();
          e.preventDefault();
          return false;
        }
      };
      document.addEventListener("click", spotlightHandler, true);

      const { top, left, width, height, right, bottom } =
        queryTarget?.getBoundingClientRect() || {
          top: 0,
          left: -1,
          right: 0,
          width: 0,
          height: 0,
          bottom: 0,
        };
      setSpotlightProperties({ top, left, width, height, right, bottom });
    } else {
      if (spotlightHandler) {
        document.removeEventListener("click", spotlightHandler, true);
      }
      setSpotlightProperties({
        top: 0,
        left: -1,
        width: 0,
        height: 0,
        right: 0,
        bottom: 0,
      });
    }
    return () => {
      if (spotlightHandler) {
        document.removeEventListener("click", spotlightHandler, true);
      }
    };
  }, [navigation.spotlight]);
  const loadingScreen = (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          pointerEvents: "all",
          zIndex: 99999,
          color: "white",
          display: isNewUser || isLoading ? "block" : "none",

          //transition: "all 3s cubic-bezier(0.03, 0.55, 0, 1.08)",
        }}
      >
        <Box
          sx={{
            display: "flex",

            justifyContent: "flex-end",
            alignItems: "center",
            cursor: "pointer",
            padding: 1,
          }}
          onClick={() => {
            setGlobalVolume(preferences.music ? 0 : 1);
            dispatch(setMusic(!preferences.music));
          }}
        >
          <Typography>Music</Typography>
          {preferences.music ? <VolumeUpIcon /> : <VolumeMuteIcon />}
        </Box>
        <Box
          sx={{
            display: "flex",

            justifyContent: "flex-end",
            alignItems: "center",
            cursor: "pointer",
            padding: 1,
          }}
          onClick={() => {
            setParticleVolume(preferences.sfx ? 0 : 1);
            setClickVolume(preferences.sfx ? 0 : 1);
            dispatch(setSFX(!preferences.sfx));
          }}
        >
          <Typography>SFX</Typography>
          {preferences.sfx ? <VolumeUpIcon /> : <VolumeMuteIcon />}
        </Box>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "none",
          position: "absolute",
          transition: "all 3s cubic-bezier(0.03, 0.55, 0, 1.08)",
          opacity: isNewUser || isLoading ? 1 : 0,
          pointerEvents: "none",
          zIndex: 999,
          overflow: "hidden",
          background: "rgba(0,0,0,0.87)",
          top: 0,
        }}
      >
        <Box
          component="img"
          sx={{
            height: "100%",
            width: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "none",
          }}
          alt="Splash screen"
          src="/assets/background/splash.jpeg"
        />

        <Backdrop
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#fff",
            gap: 10,
            //background: "rgba(0,0,0,0.87)",
            justifyContent: "flex-start",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open
        >
          {!isNewUser && (
            <>
              <img
                src="/assets/logo.png"
                style={{
                  maxHeight: "40%",
                  marginTop: "10%",
                }}
              />

              <LinearProgress
                variant="determinate"
                color="secondary"
                sx={{
                  width: "10vw",
                  [theme.breakpoints.down("md")]: { width: "80vw" },
                }}
                value={loadingProgress}
              />
            </>
          )}
        </Backdrop>
      </Box>
    </>
  );

  if (isLoading && !isNewUser) {
    return loadingScreen;
  }

  if (!renderableRoutes) {
    return null;
  }
  const clone = React.cloneElement(renderableRoutes, {
    key: renderableRoutes.props.children.key,
  });
  let routeBasedBackground = location.pathname;
  if (routeBasedBackground.indexOf("agility") > -1) {
    routeBasedBackground = "/dashboard";
  }

  let backgroundPosition = "center";
  switch (location.pathname) {
    case "/":
      routeBasedBackground = "/dashboard";
      //backgroundPosition = "top";
      break;
  }
  if (location.pathname.indexOf("/free") > -1) {
    routeBasedBackground = "/dashboard";
  }
  let background = isInGame
    ? `url("/assets/unlockable/${
        isMobile ? "mobileTall" : "large"
      }/${currentCardId}.jpg")`
    : `url("/assets/background/${routeBasedBackground}.jpg")`;

  const AuthView = (
    <Box
      sx={{
        display: "flex",
        "&:before": {
          position: "absolute",
          display: "block",
          zIndex: -2,
          content: '""',
          background: "linear-gradient(123deg, #000, #111)",
          backgroundSize: "cover",

          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          transition: "background 1.5s ease-in-out",
        },
        "&:after": {
          content: '""',
          zIndex: -1,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: isInGame && isLeavingGame ? 1 : 0.45,
          background,
          backgroundPosition,
          backgroundSize: "cover",
          transition: "opacity 0.25s ease-in, background-image 1.5s ease-out",
        },
      }}
    >
      {!isInGame && (
        <Box
          sx={{
            "&:before": {
              content: '""',
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: "blur( 10px )",

              WebkitMask:
                "radial-gradient(circle, #0000 50%, rgba(0, 0, 0, 0.9) 80%)",
            },
          }}
        ></Box>
      )}
      <CssBaseline />
      <NavBar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          position: "relative",
          overflow: "hidden",
          marginTop: "62px",
        }}
      >
        <AnimatePresence
          exitBeforeEnter
          onExitComplete={() => console.log("exit complete")}
        >
          {clone}
        </AnimatePresence>
        <BottomBar></BottomBar>
        {modal.modals.length > 0 &&
          modal.modals.map((modalComponent, idx) => {
            const onModalClose = (ev: any) => {
              if (ev && ev.stopPropagation) {
                (ev as MouseEvent).stopPropagation();
              }
              dispatch(popModal());
              if (
                "rewards" in modalComponent.props &&
                modalComponent.props.rewards
              ) {
                if (modalComponent.componentName === "News") {
                  if (ev.didClaimBonus) {
                    redeemItem(`${userId}`, modalComponent.props.id);
                    runAwardAnimation(
                      modalComponent.props.rewards,
                      undefined,
                      undefined,
                      undefined,
                      isMobile
                    );
                    dispatch(
                      award({
                        rewards: modalComponent.props.rewards,
                        dateString: new Date().toISOString(),
                        source: "news",
                      })
                    );
                  }
                } else {
                  runAwardAnimation(
                    modalComponent.props.rewards,
                    undefined,
                    undefined,
                    undefined,
                    isMobile
                  );

                  if (modalComponent.componentName === "LevelUp") {
                    dispatch(
                      levelUpFinish({
                        levelData: modalComponent.props,
                        dateString: new Date().toISOString(),
                      })
                    );
                  } else if (modalComponent.componentName === "Reward") {
                    if (modalComponent.props.source === "payment") {
                      markUserPaymentConsumed(
                        `${userId}`,
                        modalComponent.props.rewards[0].id!
                      );
                    } else if (
                      (modalComponent.props?.source ?? "").indexOf(
                        "achievements"
                      ) > -1
                    ) {
                      let achievementProgress = {
                        ...progression.achievements.progress,
                      };
                      const achievementsToIncrement = (
                        modalComponent.props?.source ?? ""
                      )
                        .split(":")[1]
                        .split(",");

                      for (let achievementType of achievementsToIncrement) {
                        achievementProgress[
                          achievementType as AchievementTypes
                        ] += 1;
                      }
                      dispatch(setAchievementProgress(achievementProgress));
                    }

                    dispatch(
                      award({
                        rewards: modalComponent.props.rewards,
                        dateString: new Date().toISOString(),
                        packId: modalComponent.props.packId,
                        source: modalComponent.props.source,
                      })
                    );
                  } else if (
                    modalComponent.componentName === "UnlockableDetails" &&
                    modalComponent.props.rewards
                  ) {
                    dispatch(
                      award({
                        rewards: modalComponent.props.rewards,
                        dateString: new Date().toISOString(),
                      })
                    );
                  }
                  if (modalComponent.componentName === "Reward") {
                    if (modalComponent.props.navTo) {
                      navigate(modalComponent.props.navTo);
                    }
                  }
                }
              }

              if (modalComponent.componentName === "AssignmentComplete") {
                const currencyAwardAmount =
                  modalComponent.props.hits.currency +
                  modalComponent.props.perfects.currency +
                  modalComponent.props.completion.currency;
                runAward(
                  currencyAwardAmount,
                  "/assets/particle/basic_currency_particle.svg",
                  undefined,
                  undefined,
                  undefined,
                  isMobile
                );
                const experienceAwardAmount =
                  modalComponent.props.hits.experience +
                  modalComponent.props.perfects.experience +
                  modalComponent.props.completion.experience;
                dispatch(addExperience(experienceAwardAmount));
                dispatch(addBasicCurrency(currencyAwardAmount));
                if (modalComponent.props.bonusReward.win && ev?.didClaimBonus) {
                  runAwardAnimation(
                    [modalComponent.props.bonusReward.win],
                    undefined,
                    undefined,
                    undefined,
                    isMobile
                  );
                  dispatch(
                    award({
                      rewards: [modalComponent.props.bonusReward.win],
                      dateString: new Date().toISOString(),
                    })
                  );
                }
                navigate("/dashboard");
              }
            };
            return (
              <React.Fragment key={`${modalComponent.id}`}>
                {modalComponent.componentName === "AssignmentComplete" && (
                  <AssignmentCompleteModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "LevelUp" && (
                  <LevelUpModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "UnlockableDetails" && (
                  <UnlockableDetailsModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "GameTutorial" && (
                  <GameTutorialModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "BuyLevelUp" && (
                  <BuyLevelUpModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "BuyFreebies" && (
                  <BuyFreebiesModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "BuyBasicCurrency" && (
                  <BuyBasicCurrencyModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "BuyPremiumCurrency" && (
                  <BuyPremiumCurrencyModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "Reward" && (
                  <RewardModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "Settings" && (
                  <SettingsModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "GoalRefresh" && (
                  <GoalRefreshModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "CardSelector" && (
                  <CardSelectorModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "BuyPacks" && (
                  <BuyPacksModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "RecycleCards" && (
                  <RecycleCardsModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "ChestReward" && (
                  <ChestRewardModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}{" "}
                {modalComponent.componentName === "TokenRelationship" && (
                  <TokenRelationshipModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "News" && (
                  <NewsModal {...modalComponent.props} onClose={onModalClose} />
                )}
                {modalComponent.componentName === "FatalError" && (
                  <FatalErrorModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "CurrentGoals" && (
                  <CurrentGoalsModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {modalComponent.componentName === "GameSelect" && (
                  <GameSelectModal
                    {...modalComponent.props}
                    onClose={onModalClose}
                  />
                )}
                {isMobile &&
                  modalComponent.componentName !== "UnlockableDetails" && (
                    <Box
                      sx={{
                        position: "fixed",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        top: 0,
                        right: 0,
                        transform: "translate3d(0,0,0)",
                        zIndex: 11111,
                      }}
                    >
                      <IconButton
                        onClick={onModalClose}
                        sx={{
                          alignSelf: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        <CancelIcon
                          sx={{
                            fontSize: "1em",
                            background: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    </Box>
                  )}
              </React.Fragment>
            );
          })}

        {navigation.showNavCurrencyPeekThrough && (
          <>
            {" "}
            {(() => {
              const [basicCurrencyChip, premiumCurrencyChip] =
                document.querySelectorAll(
                  "header .MuiToolbar-root .MuiChip-root"
                );
              const basicCurrencyBoundingRect =
                basicCurrencyChip.getBoundingClientRect();
              const premiumCurrencyBoundingRect =
                premiumCurrencyChip.getBoundingClientRect();
              const basicCurrencyChipCoordinates = {
                x: basicCurrencyBoundingRect.left,
                y: basicCurrencyBoundingRect.top,
              };
              const premiumCurrencyChipCoordinates = {
                x: premiumCurrencyBoundingRect.left,
                y: premiumCurrencyBoundingRect.top,
              };
              return (
                <>
                  <Chip
                    sx={{
                      minWidth: "7.5em",
                      position: "fixed",
                      justifyContent: "flex-start",
                      zIndex: 10002,
                      left: basicCurrencyChipCoordinates.x,
                      top: basicCurrencyChipCoordinates.y,
                      ".MuiChip-label": {
                        textOverflow: "clip",
                      },
                      [theme.breakpoints.down("md")]: {
                        minWidth: "auto",
                        width: "6.5em",
                      },
                    }}
                    color="secondary"
                    label={basicCurrencyLabel}
                    icon={<AcUnitIcon sx={{ pointerEvents: "none" }} />}
                    variant="outlined"
                  />{" "}
                  <Chip
                    sx={{
                      minWidth: "7.5em",
                      left: premiumCurrencyChipCoordinates.x,
                      top: premiumCurrencyChipCoordinates.y,
                      position: "fixed",
                      justifyContent: "flex-start",
                      zIndex: 10001,
                      ".MuiChip-label": {
                        textOverflow: "clip",
                      },
                      [theme.breakpoints.down("md")]: {
                        minWidth: "auto",
                        width: "5em",
                      },
                    }}
                    color="secondary"
                    label={premiumCurrencyLabel}
                    icon={
                      <PremiumCurrencyIcon sx={{ pointerEvents: "none" }} />
                    }
                    variant="outlined"
                  />
                </>
              );
            })()}
          </>
        )}

        <Box
          sx={{
            zIndex: 99999,
            position: "fixed",
            backgroundImage: `radial-gradient(
                circle at ${
                  spotlightProperties.left +
                  navigation.spotlight.offset.left +
                  spotlightProperties.width / 2
                }px ${
              spotlightProperties.top +
              navigation.spotlight.offset.top +
              spotlightProperties.height / 2
            }px,
                transparent ${
                  isMobile
                    ? 50 + navigation.spotlight.offset.size
                    : 160 + navigation.spotlight.offset.size
                }px,
                rgba(0, 0, 0, 0.85) ${
                  isMobile
                    ? 80 + navigation.spotlight.offset.size
                    : 200 + navigation.spotlight.offset.size
                }px
            );`,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,

            opacity: spotlightProperties.left === -1 ? 0 : 1,
            transition:
              spotlightProperties.left === -1 ? "none" : "all 0.5s ease-in-out",
            pointerEvents: "none",
            //backgroundPosition: `${spotlightProperties.left}px ${spotlightProperties.top}px`,
            //backgroundSize: `${spotlightProperties.width}px ${spotlightProperties.height}px`,

            //...spotlightProperties,
          }}
        >
          <Box
            sx={{
              color: "white",
              position: "absolute",
              fontSize: "clamp(1em, 8vw, 2.5em)",
              pointerEvents: "none",
              textShadow:
                "1px 1px 2px #222, -1px -1px 2px #222, 1px -1px 2px #222, -1px 1px 2px #222",
              left:
                navigation.spotlight.message.position === "top" ||
                navigation.spotlight.message.position === "bottom"
                  ? `${spotlightProperties.left}px`
                  : `${
                      spotlightProperties.left + spotlightProperties.width + 50
                    }px`,
              textAlign:
                navigation.spotlight.message.position === "top" ||
                navigation.spotlight.message.position === "bottom"
                  ? "center"
                  : "right",
              top:
                navigation.spotlight.message.position === "top"
                  ? `${Math.max(0, spotlightProperties.top - 100)}px`
                  : navigation.spotlight.message.position === "bottom"
                  ? `${spotlightProperties.bottom}px`
                  : `${
                      spotlightProperties.top + spotlightProperties.height / 2
                    }px`,
              [theme.breakpoints.down("md")]: {
                left: 0,
                width: "100%",
                textAlign: "left",
              },
            }}
          >
            {navigation.spotlight.message.text}
          </Box>
        </Box>
        {navigation.backgroundTransition.backgroundId && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              zIndex: "100000",
              backdropFilter: "blur(10px)",
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: `url("/assets/unlockable/${
                  isMobile ? "mobileTall" : "large"
                }/${navigation.backgroundTransition.backgroundId}.jpg")`,
                backgroundSize: "cover",
                "@keyframes backgroundImageTransitionEnter": {
                  from: {
                    transform: `scale(${
                      1 -
                      navigation.backgroundTransition.initialPosition.width /
                        window.innerWidth
                    }, ${
                      1 -
                      navigation.backgroundTransition.initialPosition.height /
                        window.innerHeight
                    })`,
                  },
                  to: {
                    transform: `scale(1)`,
                  },
                },
                //animation: `backgroundImageTransitionEnter 2s cubic-bezier(0.4, 0, 1, 1) forwards`,
              },

              transition:
                navigation.backgroundTransition.transitionState === "exiting"
                  ? "opacity 2s ease-in"
                  : "auto",
              //transform: `translate(${navigation.backgroundTransition.initialPosition.left}px, ${navigation.backgroundTransition.initialPosition.top}px)`,
              transformOrigin: "0 0",
              opacity:
                navigation.backgroundTransition.transitionState === "exiting"
                  ? 0
                  : 1,
              transform: `translate(${
                navigation.backgroundTransition.initialPosition.left
              }px, ${
                navigation.backgroundTransition.initialPosition.top
              }px) scale(${
                navigation.backgroundTransition.initialPosition.width /
                window.innerWidth
              }, ${
                navigation.backgroundTransition.initialPosition.height /
                window.innerHeight
              })`,
              //transformOrigin: `${navigation.backgroundTransition.initialPosition.left}px ${navigation.backgroundTransition.initialPosition.top}px`,
              "@keyframes backgroundWrapperTransitionEnter": {
                "0%": {
                  opacity: 0.1,
                  transform: `translate(${
                    navigation.backgroundTransition.initialPosition.left
                  }px, ${
                    navigation.backgroundTransition.initialPosition.top
                  }px) scale(${
                    navigation.backgroundTransition.initialPosition.width /
                    window.innerWidth
                  }, ${
                    navigation.backgroundTransition.initialPosition.height /
                    window.innerHeight
                  })`,
                },
                "50%": {
                  opacity: 0.2,
                },
                "100%": {
                  opacity: 0.1,
                  transform: `translate(0px, 0px) scale(1)`,
                },
              },

              "@keyframes backgroundWrapperTransitionExit": {
                from: {
                  opacity: 0.1,
                  transform: `translate(0px, 0px) scale(1)`,
                },
                to: { opacity: 0, transform: `translate(0px, 0px) scale(1)` },
              },
              animation:
                navigation.backgroundTransition.transitionState === "entering"
                  ? `backgroundWrapperTransitionEnter .2s cubic-bezier(0.4, 0, 1, 1) forwards`
                  : "backgroundWrapperTransitionExit .2s cubic-bezier(0.4, 0, 1, 1) forwards",
            }}
          ></Box>
        )}
        <canvas
          id="rewardCanvas"
          style={{
            position: "absolute",
            pointerEvents: "none",
            zIndex: 9999,
            top: 0,
            left: 0,
            background: "transparent",
          }}
        ></canvas>

        <svg
          width="0"
          height="0"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id="GameIconGradientFill"
              x1="0"
              x2="0"
              y1="0"
              y2="1"
            >
              <stop stopColor="#FFD700" offset="0%" />
              <stop stopColor="#FFD700" offset="10%" />
              <stop stopColor="#89023E" offset="100%" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
    </Box>
  );

  return (
    <>
      {loadingScreen}
      {isNewUser ? <HomePage /> : AuthView}
    </>
  );
}
