import * as React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Divider } from "@mui/material";
import cardCover from "../../../assets/images/agility.svg";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Grid from "@mui/material/Grid";
import NavigationCard from "lib/navigation/components/NavigationCard";
import GameRoom from "lib/game/components/GameRoom";

export default function AgilityPage() {
  const appState = useAppSelector((state) => state.app);
  const { games } = appState;
  const capacityGames = games
    .filter((game) => game.type === "capacity")
    .sort((a, b) => a.unlockLevel - b.unlockLevel);
  const progression = useAppSelector((state) => state.progression);

  return (
    <GameRoom title="Capacity Games" scrollType="horizontal">
      <Grid container spacing={8}>
        {capacityGames.map((game) => (
          <Grid item key={game.id}>
            <NavigationCard
              id={game.id}
              to={game.to}
              imageSrc={game.imageSrc}
              title={game.title}
              description={game.description}
              challengeRating={game.challengeRating}
              locked={progression.level < game.unlockLevel}
              unlockLevel={game.unlockLevel}
              asTutorial={true}
              cost={game.cost}
            />
          </Grid>
        ))}
      </Grid>
    </GameRoom>
  );
}
