import { styled } from "@mui/material/styles";
import * as React from "react";

import TableRow from "@mui/material/TableRow";

import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "./Modal";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// @ts-ignore
import Tooltip from "@mui/material/Tooltip";
import CardTile from "lib/card/components/CardTile";
import { iconsByCategoryName } from "lib/card/components/CategoryBadge";
import { CustomScrollbarsVirtualList } from "pages/card";
import { useNavigate } from "react-router-dom";
import { FixedSizeGrid as Grid } from "react-window";
import { pushModal } from "../slice";
import { Box } from "@mui/material";
import theme from "theme";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

export interface TokenRelationshipModalProps {
  onClose: (ev: any) => void | (() => void);
  token: keyof typeof iconsByCategoryName;
}

export default function RewardModal({
  onClose,
  token,
}: TokenRelationshipModalProps) {
  const preferences = useAppSelector((state) => state.preferences);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const progression = useAppSelector((state) => state.progression);
  const unlockables = [...appState.unlockable.items]
    .sort((a, b) => (a?.unlock?.level ?? 9999) - (b?.unlock?.level ?? 9999))
    .sort(
      (a, b) =>
        (progression.unlocks[a.id] ? 0 : 1) -
        (progression.unlocks[b.id] ? 0 : 1)
    )
    .filter((unlockable) => {
      if (unlockable.id in progression.unlocks) {
        if (unlockable.categories.includes(token)) {
          return true;
        }
      }
      return false;
    });

  let description = "";
  switch (token) {
    case "Tundra":
      description =
        "A vast, flat, treeless Arctic region of Europe, Asia, and North America in which the subsoil is permanently frozen";
      break;
    case "Forest":
      description = "A large area covered chiefly with trees and undergrowth";
      break;
    case "Desert":
      description =
        "A dry, barren area of land, especially one covered with sand, that is characteristically desolate, waterless, and without vegetation";
      break;
    case "Mountain":
      description =
        "A large natural elevation of the earth's surface rising abruptly from the surrounding level";
      break;
    case "Beach":
      description =
        "A narrow strip of land separating a body of water from inland areas";
      break;
    case "Summer":
      description = "The warmest season of the year";
      break;
    case "Spring":
      description =
        "The season after winter and before summer, in which vegetation begins to appear";
      break;
    case "River":
      description =
        "A large natural stream of water flowing in a channel to the sea, a lake, or another such stream";
      break;
    case "Sky":
      description =
        "The region of the atmosphere and outer space seen from the earth";
      break;
    case "Fog":
      description =
        "A thick cloud of tiny water droplets suspended in the atmosphere at or near the earth's surface which obscures or restricts visibility";
      break;
    case "Dusk":
      description = "The darker stage of twilight";
      break;
    case "Space":
      description =
        "A continuous area or expanse which is free, available, or unoccupied";
      break;
    case "Galaxy":
      description =
        "A system of millions or billions of stars, together with gas and dust, held together by gravitational attraction";
      break;
    case "Cloud":
      description =
        "A visible mass of condensed water vapor floating in the atmosphere, typically high above the ground";
      break;
    case "Fall":
      description =
        "Season of the year between summer and winter during which temperatures gradually decrease";
      break;
    case "Waterfall":
      description =
        "A cascade of water falling from a height, formed when a river or stream flows over a precipice or steep incline";
      break;
    case "Volcano":
      description =
        "A mountain or hill, typically conical, having a crater or vent through which lava, rock fragments, hot vapor, and gas are being or have been erupted from the earth's crust";
      break;
    case "Winter":
      description = "The coldest season of the year";
      break;
    case "Ocean":
      description =
        "A very large expanse of sea, in particular each of the main areas into which the sea is divided geographically";
      break;
    case "Leaf":
      description =
        "A flattened structure of a higher plant, typically green and blade-like, that is attached to a stem directly or via a stalk";
      break;
    case "Micro":
      description = "An intimate and detailed description or study";
      break;
    case "Flower":
      description =
        "The seed-bearing part of a plant, consisting of reproductive organs (stamens and carpels) that are typically surrounded by a brightly colored petals";
      break;
    case "Grasslands":
      description =
        "Area in which the vegetation is dominated by a nearly continuous cover of grasses";
      break;
    case "Geyser":
      description =
        "A hot spring in which water intermittently boils, sending a tall column of water and steam into the air";
      break;
    case "Cliff":
      description = "A steep rock face, especially at the edge of the sea";
      break;
    case "Aurora":
      description =
        "A natural electrical phenomenon characterized by the appearance of streamers of reddish or greenish light in the sky";
      break;
    case "Plains":
      description = "A large area of flat land with few trees";
      break;
    case "Icon":
      description =
        "A thing regarded as a representative symbol or as worthy of veneration";
      break;
    case "Lake":
      description = "A large body of water surrounded by land";
      break;
    case "Lightning":
      description =
        "The occurrence of a natural electrical discharge of very short duration and high voltage between a cloud and the ground or within a cloud";
      break;
    case "Island":
      description = "A piece of land surrounded by water";
      break;
    case "Hill":
      description =
        "A naturally raised area of land, not as high or craggy as a mountain";
      break;
    case "Special":
      description = "Earned through special events or limited-time offers";
      break;
    case "Glacier":
      description =
        "A slowly moving mass or river of ice formed by the accumulation and compaction of snow on mountains or near the poles";
      break;
    case "Canyon":
      description =
        "A deep gorge, typically one with a river flowing through it";
      break;
    case "Field":
      description =
        "An area of land marked by the presence of particular objects or features";
      break;
    case "Swamp":
      description =
        "An area of low-lying, uncultivated ground where water collects; a bog or marsh";
      break;
    case "Valley":
      description =
        "A low area of land between hills or mountains, typically with a river or stream flowing through it";
      break;
  }
  const GridCell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: any;
  }) => {
    const unlockableIndex = columnIndex + Math.floor(rowIndex * 6);

    if (unlockableIndex > unlockables.length - 1) {
      return null;
    }
    const unlockable = unlockables[unlockableIndex];
    const unlockDate = progression.unlocks[unlockable.id]?.dateUnlocked;
    const width = 100;
    const height = 88;
    return (
      <div style={style}>
        <Tooltip title={unlockable.name}>
          <CardTile
            onClick={() => {
              dispatch(
                pushModal({
                  componentName: "UnlockableDetails",
                  props: { unlockable },
                })
              );
            }}
            unlockable={unlockable}
            unlockDate={unlockDate}
            sx={{
              width: width,
              height: height,
              "&:after": {
                width: width,
                height: height,
                //    borderWidth: 20
              },
            }}
          />
        </Tooltip>
      </div>
    );
  };
  return (
    <Modal
      onClose={onClose}
      dialogTitle={`${token} Cards`}
      dialogProps={{ maxWidth: "md", fullWidth: true }}
      dialogBodyContent={
        <>
          <Box sx={{ fontSize: "1em", borderBottom: "1px solid #222", mb: 2 }}>
            {description}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              gap: 2,
            }}
          >
            {unlockables.map((unlockable) => (
              <Box sx={{ width: "100%" }}>
                {" "}
                <CardTile
                  sx={{
                    [theme.breakpoints.down("md")]: { width: "100%" },
                  }}
                  unlockable={unlockable}
                  unlockDate={"true"}
                  withBadges={false}
                />
              </Box>
            ))}
          </Box>
        </>
      }
    />
  );
}
