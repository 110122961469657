import * as React from "react";

import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FlashCard from "lib/agility/components/FlashCard";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import playSound from "lib/audio/sfx";
import { ChallengePossibility, Reward } from "lib/data/slice";
import Game from "lib/game/components/Game";
import perfectOccurred from "lib/game/perfectOccurred";
import useCommonGameControls from "lib/game/useCommonGameControls";
import { useAppDispatch } from "lib/hooks/react-redux";
import { award, useFreebie } from "lib/progression/slice";
import generateTokenFromRuleData from "lib/token/generateTokenFromRuleData";
import GradientText from "lib/typography/components/GradientText";
import theme from "theme";
import determineGlowRarity from "../determineGlowRarity";
interface EquationsProps {
  challenges: ChallengePossibility[];
  currentChallenge: string;
  onNextChallenge: () => void;
  onChallengeSuccess: (params: {
    hits: number;
    perfects: number;
    itemsDiscovered: Reward[];
    gameBackground: string;
  }) => void;
  answerPadOptions: string[];
  challengeLevelIndex: number;
}

const ChallengeInput = styled(TextField)(({ theme }) => ({}));

let completionTimeout = 0;
export default function Equations({
  challenges,
  currentChallenge,
  onChallengeSuccess,
  onNextChallenge,
  answerPadOptions,
  challengeLevelIndex,
}: EquationsProps) {
  const {
    navigate,
    perfectStreak,
    setPerfectStreak,
    progression,
    mousePosition,
    setMousePosition,
    gameReady,
    setGameReady,
    stopWatchPercent,
    setStopWatchPercent,
    isProcessingRound,
    setIsProcessingRound,
    hits,
    setHits,
    perfects,
    setPerfects,
    itemsDiscovered,
    setItemsDiscovered,
    totalWordsLength,
    setTotalWordsLength,
    isMobile,
    isComplete,
    setIsComplete,
    renderableChallenges,
    setRenderableChallenges,
    validAnswer,
    setValidAnswer,
    lastValidAnswer,
    setLastValidAnswer,
    lastGlowRarity,
    setLastGlowRarity,
    currentCard,
  } = useCommonGameControls();

  const dispatch = useAppDispatch();

  const index = challenges.findIndex(
    (challenge) => challenge.id === currentChallenge
  );

  if (!currentCard) {
    navigate("/");
    return null;
  }

  React.useEffect(() => {
    if (gameReady === true) {
      if (renderableChallenges.length === 0) {
        setRenderableChallenges(challenges.slice(0, 2));
      }
      let timer = window.setInterval(() => {
        if (stopWatchPercent > 0) {
          setStopWatchPercent((time) => time - 2);
        } else {
          setIsProcessingRound(true);
        }
      }, 100);
      if (isProcessingRound) {
        window.clearInterval(timer);
        const index = challenges.findIndex(
          (challenge) => challenge.id === currentChallenge
        );
        if (index < challenges.length - 1) {
          window.setTimeout(() => {
            setRenderableChallenges(challenges);
            onNextChallenge();
            setStopWatchPercent(100);
            setIsProcessingRound(false);
            setLastGlowRarity(-1);
          }, 250);
        }
      }
      return () => {
        window.clearInterval(timer);
        completionTimeout = 0;
      };
    }
  }, [
    gameReady,
    stopWatchPercent,
    isProcessingRound,
    hits,
    perfects,
    itemsDiscovered,
    currentChallenge,
    challenges.length,
    renderableChallenges,
  ]);
  React.useEffect(() => {
    if (
      completionTimeout === 0 &&
      isProcessingRound &&
      index === challenges.length - 1
    ) {
      setIsComplete(perfects === challenges.length ? 2 : 1);
      completionTimeout = window.setTimeout(() => {
        onChallengeSuccess({
          hits,
          perfects,
          itemsDiscovered,
          gameBackground: progression.currentCardId,
        });
      }, 2000);
    }
  }, [index, challenges.length, isProcessingRound]);

  const equationsPerfectOccurred = () => {
    perfectOccurred({
      perfectStreak,
      setPerfectStreak,
      perfects,
      setPerfects,
      currentCard,
      challengeLevelIndex,
      setItemsDiscovered,
      setLastGlowRarity,
      mousePosition,
      itemsDiscovered,
      dispatch,
    });
  };

  return (
    <Game
      challenges={challenges}
      challengeIndex={index}
      stopWatchPercent={stopWatchPercent}
      type="equations"
      answerPad={{
        onMousePositionUpdate: ({ x, y }) => {
          setMousePosition({ x, y });
        },
        onUseFreebie: () => {
          if (progression.freebies > 0 && gameReady && !isProcessingRound) {
            dispatch(useFreebie());
            equationsPerfectOccurred();

            setIsProcessingRound(true);
          }
        },
        options: answerPadOptions,
        onAnswerSelect: (number) => {
          const challenge = challenges[index];

          const input = document.querySelector(`#challenge-${index}`);

          // @ts-ignore
          var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            "value"
          ).set;

          nativeInputValueSetter!.call(input, `${number}`);

          var ev2 = new Event("input", { bubbles: true });
          input!.dispatchEvent(ev2);
          //handleOnChange(challenge)(ev2 as React.ChangeEvent<HTMLInputElement>);
        },
      }}
      isComplete={isComplete}
      onGameReady={() => setGameReady(true)}
    >
      {" "}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          flex: 1,
          maxWidth: isMobile ? "100%" : "60%",
        }}
      >
        {challenges.map((challenge, idx) => {
          let answerRenderable;
          const index = challenges.findIndex(
            (chal) => chal.id === currentChallenge
          );
          if (challenge.id === currentChallenge) {
            answerRenderable = (
              <ChallengeInput
                key={challenge.id}
                id={`challenge-${idx}`}
                variant="standard"
                autoComplete="off"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  if ((ev.nativeEvent as InputEvent).data == ";") {
                    ev.target.value = "";
                    return;
                  }
                  if (ev.target.value === challenge.answer) {
                    if (stopWatchPercent >= 80) {
                      equationsPerfectOccurred();
                    } else {
                      setPerfectStreak(0);
                      playSound(`streak-1`);
                      if (currentCard) {
                        const rewards = [
                          generateTokenFromRuleData(
                            currentCard,
                            "hit",
                            challengeLevelIndex
                          ),
                        ];
                        dispatch(
                          award({
                            rewards: rewards,
                            dateString: new Date().toISOString(),
                          })
                        );
                        setItemsDiscovered([...itemsDiscovered, ...rewards]);
                        setLastGlowRarity(determineGlowRarity(rewards));
                        runAwardAnimation(
                          rewards,
                          "agilityCenter",
                          {
                            x: mousePosition.x,
                            y: mousePosition.y,
                          },
                          { goToEnd: 30 }
                        );
                      }

                      setHits(hits + 1);
                    }
                    ev.target.value = "";

                    setIsProcessingRound(true);
                  } else {
                    setPerfectStreak(0);
                    ev.target.value = "";
                    setIsProcessingRound(true);
                  }
                }}
                sx={{
                  width: "60px",
                  fontSize: "4em",
                  "& .MuiInputLabel-root, input": {
                    fontSize: "4em",
                  },
                  "& .MuiInput-root:before": {
                    borderBottomColor: "rgba(255, 255, 255, 0.42)",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            );
          } else {
            answerRenderable = (
              <Typography
                variant="h5"
                sx={{
                  width: "30px",
                  fontSize: "4em",
                  color: "#fafafa",
                }}
              >
                {challenge.answer}
              </Typography>
            );
          }

          if (idx > index) {
            return null;
          }

          return (
            <FlashCard
              isLeaving={challenge.id !== currentChallenge}
              key={`${challenge.id}`}
              elevation={1}
              glowRarity={isProcessingRound ? lastGlowRarity : -1}
              hiding={!gameReady}
            >
              <Typography gutterBottom variant="h5" component="div">
                <Grid container justifyContent={"center"}>
                  <Grid
                    item
                    sx={{
                      alignItems: "center",
                      fontSize: "clamp(1rem, 20vw, 4em)",
                      display: "flex",
                      //[theme.breakpoints.down("md")]: { fontSize: "2em" },
                    }}
                  >
                    <GradientText
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "1em",
                        background:
                          "linear-gradient(180deg, #fafafa 35%, #222)",
                        WebkitTextStrokeColor: "#fafafa",
                      }}
                    >
                      {challenge.question} =&nbsp;
                    </GradientText>
                  </Grid>
                  <Grid
                    item
                    sx={{ [theme.breakpoints.down("md")]: { display: "none" } }}
                  >
                    {answerRenderable}
                  </Grid>
                </Grid>
              </Typography>
            </FlashCard>
          );
        })}
      </Box>
    </Game>
  );
}
