import { styled } from "@mui/material/styles";
import * as React from "react";

import { Box, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";

import ModalUnstyled from "@mui/base/ModalUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import GoalCard, { GoalCardProps } from "lib/dashboard/components/GoalCard";
import GradientText from "lib/typography/components/GradientText";
import theme from "theme";
import { grey } from "@mui/material/colors";
import RewardItem from "lib/progression/components/RewardItem";
import { generateGoal } from "lib/goal/generateGoal";
import { refreshGoal, setTutorialStep } from "lib/progression/slice";
import { setCanAnimate, setSpotlight } from "lib/navigation/slice";
import { useMediaQuery } from "@mui/material";

export interface CurrentGoalsModalProps {
  onClose: (ev: any) => void | (() => void);
  navTo?: string;
  goals: GoalCardProps[];
}
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className, "BackdropRoot")}
      ref={ref}
      {...other}
    />
  );
});
const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-tap-highlight-color: transparent;
`;
export default function CurrentGoalsModal({ onClose }: CurrentGoalsModalProps) {
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const goals = progression.goals.current;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const freebieRefreshPrice = useAppSelector(
    (state) => state.app.goal.freebieRefreshPrice
  );
  React.useEffect(() => {
    let i = goals.length;

    while (i < 3) {
      const goalData = generateGoal();
      dispatch(
        refreshGoal({
          //freebiePrice: freebieRefreshPrice,
          goalIndex: i,
          goalData: {
            type: goalData.type,
            requiredAmount: goalData.requiredAmount,
            progress: goalData.type === "freeClaim" ? 1 : goalData.progress,
            rewards: goalData.rewards,
            hasClaimed: false,
            requirementMeta: goalData.requirementMeta || "",
          },
        })
      );
      i++;
    }

    goals.forEach((goal, idx) => {
      if (goal.hasClaimed) {
        window.setTimeout(() => {
          const goalData = generateGoal();
          dispatch(
            refreshGoal({
              //freebiePrice: freebieRefreshPrice,
              goalIndex: idx,
              goalData: {
                type: goalData.type,
                requiredAmount: goalData.requiredAmount,
                progress: goalData.type === "freeClaim" ? 1 : goalData.progress,
                rewards: goalData.rewards,
                hasClaimed: false,
                requirementMeta: goalData.requirementMeta || "",
              },
            })
          );
        }, 1000);
      }
    });
  }, [goals]);

  React.useEffect(() => {
    dispatch(setCanAnimate(false));
    if (progression.tutorialStep === 12) {
      dispatch(
        setSpotlight({
          queryTarget: `.goal-card`,
          offset: isMobile
            ? { top: -100, size: 100, left: 0 }
            : { top: -200, left: 0, size: 0 },
          message: {
            text: `Claim this Goal's reward`,
            position: isMobile ? "top" : "right",
          },
        })
      );
    }

    return () => {
      if (progression.tutorialStep != 12) {
        dispatch(setCanAnimate(true));
      }
    };
  }, []);
  console.log({ goals });
  return (
    <Modal
      open
      onClose={onClose}
      onClick={(ev) => {
        console.log({ ev });

        //onClose(ev);
      }}
      components={{ Root: Backdrop }}
    >
      <>
        <Box
          sx={{
            position: "absolute",
            top: "0vh",
            left: "0",
            height: "100vh",
            padding: "1em 5vw 0",
            width: "100%",
            zIndex: 11,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            transformOrigin: "top",
            [theme.breakpoints.down("md")]: {
              paddingTop: "2em",
            },

            "@keyframes CurrentGoalsModalEntrance": {
              from: {
                transform: "scale(0.8) translateY(20%)",
                opacity: 0.4,
              },
              to: {
                transform: "scale(1) translateY(0%)",
                opacity: 1,
              },
            },
            animation: "CurrentGoalsModalEntrance 0.3s ease-out",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: "0em",
              top: "0em",

              zIndex: 11111,
              [theme.breakpoints.down("md")]: { display: "none" },
            }}
          >
            <IconButton
              sx={{ color: grey[50], padding: "2em" }}
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          <GradientText
            sx={{
              display: "inline-block",
              [theme.breakpoints.up("md")]: { fontSize: "2.5em" },
            }}
          >
            Goals
          </GradientText>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flex: 1,
              flexDirection: "column",
              gap: 8,
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              [theme.breakpoints.up("md")]: { gap: 4 },
            }}
          >
            {goals.map((goal, idx) => (
              <Grid
                className={idx === 0 ? "goal-card" : ""}
                onClick={() => {
                  if (progression.tutorialStep === 12) {
                    dispatch(setTutorialStep(13));
                    dispatch(
                      setSpotlight({
                        queryTarget: ``,
                        message: {
                          text: ``,
                          position: isMobile ? "top" : "right",
                        },
                      })
                    );
                  }
                }}
                sx={{
                  justifyContent: "center",
                  display: "flex",

                  width: "100%",
                  maxWidth: "50em",
                  position: "relative",
                  flex: "1 1 0",
                  marginBottom: 2,
                  maxHeight: "25vh",
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0.8,

                    backdropFilter: "blur(4px)",
                    background: "url('/assets/goal/background.jpg')",
                    backgroundPosition: `center ${25 + idx * 25}%`,
                    backgroundSize: "cover",
                  },
                  [theme.breakpoints.up("md")]: {
                    // maxWidth: "33%",
                    // maxHeight: "20vw",
                  },
                }}
                item
                key={`${idx}${goal.hasClaimed}${goal.type}`}
              >
                <GoalCard {...goal} refreshIndex={idx} />
              </Grid>
            ))}
          </Box>
        </Box>
      </>
    </Modal>
  );
}
