import * as React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ACUnitIcon from "@mui/icons-material/AcUnitRounded";
import {
  AvatarGroup,
  Badge,
  Box,
  Button,
  CardActionArea,
  CardActions,
  Divider,
  IconButton,
  Pagination,
} from "@mui/material";
import cardCover from "../../../assets/images/agility.svg";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import FilterIcon from "@mui/icons-material/Filter";
import theme from "theme";
import NavigationCard from "lib/navigation/components/NavigationCard";

// @ts-ignore
import { FixedSizeGrid as Grid, FixedSizeGridProps } from "react-window";
import GameRoom from "lib/game/components/GameRoom";
import GradientText from "lib/typography/components/GradientText";
import { pushModal } from "lib/modal/slice";
import { grey, green, blue, purple, yellow } from "@mui/material/colors";
import Clickable from "lib/audio/components/Clickable";
import { recycleExtraCards, setTutorialStep } from "lib/progression/slice";
import rarityBasedFrames from "lib/card/rarityBasedFrames";
import { Scrollbars } from "rc-scrollbars";
import CardTile from "lib/card/components/CardTile";
import CategoryFilter from "lib/card/components/CategoryFilter";
import CategoryBadge from "lib/card/components/CategoryBadge";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import moment from "moment";
import { connectFirestoreEmulator } from "firebase/firestore";
import { AutoModeShardIcon } from "lib/design-system/components/AutoModeIcon";
import { setCanAnimate, setSpotlight } from "lib/navigation/slice";
import useAnimationEffect from "lib/animation/useAnimationEffect";

const isMobile = screen.availWidth < 1150;
const ITEMS_PER_PAGE = 20;
export const CustomScrollbars = ({
  onScroll,
  forwardedRef,
  style,
  children,
}: {
  onScroll: any;
  forwardedRef: any;
  style?: any;
  children?: React.ReactNode;
}) => {
  const refSetter = React.useCallback((scrollbarsRef: any) => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.scrollerElement);
    } else {
      forwardedRef(null);
    }
  }, []);
  const [fadeTop, setFadeTop] = React.useState(false);
  const [fadeBottom, setFadeBottom] = React.useState(true);

  return (
    <Scrollbars
      ref={refSetter}
      style={{
        marginTop: "1em",
        ...style,
        overflow: "hidden",
      }}
      onScroll={onScroll}
      renderTrackHorizontal={() => <div style={{ display: "none" }} />}
    >
      {children}
    </Scrollbars>
  );
};

export const CustomScrollbarsVirtualList = React.forwardRef<
  any,
  { onScroll: () => void }
>((props, ref) => <CustomScrollbars {...props} forwardedRef={ref} />);

export default function CardPage() {
  const appState = useAppSelector((state) => state.app);
  const { games } = appState;
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const [selectedFilters, setSelectedFilters] = React.useState<number[]>([]);
  const fullState = useAppSelector((state) => state);

  const hasClaimableConsumables =
    useAppSelector((state) =>
      Object.values(state.progression.consumables).filter(
        (c) => c.hasConsumed === false
      )
    ).length > 0;
  const unlockables = [...appState.unlockable.items]
    .filter((unlockable) => {
      if (progression.unlocks[unlockable.id]?.amount >= 1) {
        if (selectedFilters.length === 0) {
          return true;
        }
        if (selectedFilters.includes(unlockable.rarity)) {
          return true;
        }
      }
      return false;
    })
    .sort((a, b) => (a?.unlock?.level ?? 9999) - (b?.unlock?.level ?? 9999))
    .sort(
      (a, b) => {
        if (progression.unlocks[a.id] && progression.unlocks[b.id]) {
          return (
            new Date(progression.unlocks[b.id].dateUnlocked).getTime() -
            new Date(progression.unlocks[a.id].dateUnlocked).getTime()
          );
        }
        return 0;
      }
      // (progression.unlocks[a.id]
      //   ? new Date(progression.unlocks[a.id].dateUnlocked).getTime()
      //   : 1) -
      // (progression.unlocks[b.id]
      //   ? new Date(progression.unlocks[a.id].dateUnlocked).getTime()
      //   : 1)
    );
  const [page, setPage] = React.useState(1);

  const pageStart = (page - 1) * ITEMS_PER_PAGE;
  const pageEnd = page * ITEMS_PER_PAGE;
  console.log(unlockables.length);
  console.log({ pageStart, pageEnd });
  const unlockablesToRender = unlockables.slice(pageStart, pageEnd);
  const canRecycle = Object.values(progression.unlocks).find(
    (unlockable) => unlockable.amount > 1
  );

  React.useEffect(() => {
    if (progression.tutorialStep === 5) {
      dispatch(
        setSpotlight({
          queryTarget: `.collect-button`,
          message: {
            text: `Collect rewards for the new cards you discovered`,
            position: isMobile ? "bottom" : "right",
          },
        })
      );
    } else if (progression.tutorialStep === 6) {
      dispatch(setCanAnimate(true));
    }

    return () => {
      if (progression.tutorialStep < 6) {
        dispatch(setTutorialStep(6));
      }
    };
  }, []);

  useAnimationEffect(() => {
    if (progression.tutorialStep === 6) {
      dispatch(
        setSpotlight({
          queryTarget: isMobile ? `.bottom-bar-dashboard` : `.back-button`,
          message: {
            text: `Head back to the lobby for a treat`,
            position: isMobile ? "top" : "right",
          },
        })
      );
    }
  }, [progression.tutorialStep]);

  return (
    <>
      <GameRoom
        mobileScrollHeight="35vh"
        backButtonColor="secondary"
        withBackButton
        title={
          <Box
            sx={{
              display: "flex",
              [theme.breakpoints.down("md")]: {
                justifyContent: "flex-start",
                flexWrap: "wrap",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                [theme.breakpoints.down("md")]: { width: "100%" },
              }}
            >
              <GradientText
                sx={{
                  fontSize: "3em",
                  display: "flex",
                }}
              >
                Cards
              </GradientText>

              <Clickable>
                <Button
                  className="collect-button"
                  color="secondary"
                  variant="contained"
                  sx={{
                    marginLeft: "auto",
                    height: "3em",
                    alignSelf: "center",
                    letterSpacing: " 1.2px",
                    [theme.breakpoints.up("lg")]: { margin: "auto 1em" },
                  }}
                  disabled={!hasClaimableConsumables}
                  onClick={() => {
                    if (progression.tutorialStep === 5) {
                      dispatch(setCanAnimate(false));
                      dispatch(setTutorialStep(6));
                      dispatch(
                        setSpotlight({
                          queryTarget: "",
                          message: {
                            text: "",
                            position: isMobile ? "top" : "right",
                          },
                        })
                      );
                    }
                    const unlockables = fullState.app.unlockable.items.filter(
                      (u) => u.id in progression.consumables
                    );
                    const rewards = [];
                    for (let unlockable of unlockables) {
                      if (!progression.consumables[unlockable.id].hasConsumed) {
                        rewards.push({
                          type: "experience",
                          amount:
                            fullState.app.unlockable
                              .unlockRarityExperienceReward[
                              unlockable?.rarity ?? 0
                            ],
                          id: unlockable.id,
                        });
                      }
                    }
                    dispatch(
                      pushModal({
                        componentName: "UnlockableDetails",
                        props: {
                          rewards: rewards,
                          unlockable: unlockables,
                        },
                      })
                    );
                  }}
                  startIcon={
                    <Badge
                      variant={hasClaimableConsumables ? "dot" : undefined}
                      color="primary"
                    >
                      <BurstModeIcon
                        sx={{
                          color: hasClaimableConsumables ? "#222" : grey[700],
                        }}
                      />
                    </Badge>
                  }
                >
                  Collect
                </Button>
              </Clickable>
            </Box>
            <CategoryFilter
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />

            <Clickable>
              <Button
                onClick={() => {
                  const unlockableIds = Object.entries(progression.unlocks)
                    .filter(([_idx, unlockable]) => unlockable.amount > 1)
                    //@ts-ignore
                    .reduce((a, b) => {
                      return [...a, b[0]];
                    }, []);
                  //const thing = unlockableIds

                  dispatch(
                    pushModal({
                      componentName: "RecycleCards",
                      props: { unlockableIds },
                    })
                  );
                  //dispatch(recycleExtraCards());
                }}
                size="large"
                sx={{
                  marginLeft: "auto",
                  letterSpacing: "6px",
                  display: canRecycle ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  ...(canRecycle ? {} : { filter: "grayscale(100%)" }),

                  [theme.breakpoints.down("md")]: {
                    marginLeft: 0,
                    marginTop: 2,
                    width: "100%",
                  },
                }}
                autoFocus
                variant="contained"
                disabled={!canRecycle}
                color={"primary"}
              >
                <AutoModeShardIcon
                  color={canRecycle ? "secondary" : "primary"}
                  sx={{ fontSize: "3em", marginRight: "0.1em" }}
                />
                <GradientText
                  dark={!canRecycle}
                  sx={{
                    fontSize: "3em",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "clamp(1em, 6vw, 2em)",
                      whiteSpace: "nowrap",
                      WebkitTextStrokeWidth: "1px",
                    },
                  }}
                >
                  Recycle Extras
                </GradientText>
              </Button>
            </Clickable>
          </Box>
        }
        scrollType="vertical"
      >
        <Box
          sx={{
            display: "flex",
            height: "calc(100vh - 262px)",
            flexWrap: "wrap",
            alignItems: "flex-start",
            gap: 4,
            [theme.breakpoints.down("md")]: {
              height: "35vh",
            },
          }}
        >
          {(() => {
            let lastUnlockDate = 0;
            return unlockablesToRender.map((unlockable) => {
              const unlockDate =
                progression.unlocks[unlockable.id]?.dateUnlocked;
              const unlockDay = new Date(unlockDate).getDate();
              let prefixContent = null;
              if (unlockDay !== lastUnlockDate) {
                lastUnlockDate = unlockDay;
                const momentDay = moment(new Date());
                let label = "Acquired ";
                const dateUnlocked = new Date(unlockDate);

                const yesterday = moment().subtract(1, "day");
                if (momentDay.isSame(dateUnlocked, "day")) {
                  label += "Today";
                } else if (yesterday.isSame(dateUnlocked, "day")) {
                  label += "Yesterday";
                } else {
                  label += moment(dateUnlocked).format("MMMM Do");
                }
                prefixContent = (
                  <>
                    <Box
                      sx={{
                        color: grey[50],
                        width: "100%",
                        textTransform: "uppercase",
                        fontSize: "1.4em",
                        letterSpacing: "2px",
                        textShadow: `1px 1px 2px ${grey[900]}`,
                        height: "2em",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "clamp(0.9em, 5vw, 1.4em)",
                        },
                      }}
                    >
                      {label}
                    </Box>
                  </>
                );
              }
              return (
                <>
                  {prefixContent}
                  <Box
                    key={unlockable.id}
                    sx={{
                      [theme.breakpoints.down("md")]: { maxWidth: "49%" },
                      [theme.breakpoints.down("sm")]: { maxWidth: "40%" },
                    }}
                  >
                    <CardTile
                      unlockable={unlockable}
                      unlockDate={unlockDate}
                      withBadges={typeof unlockDate !== "undefined"}
                    />
                  </Box>
                </>
              );
            });
          })()}
        </Box>
      </GameRoom>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          color="secondary"
          sx={{
            "& .MuiPaginationItem-root:not(.Mui-selected)": { color: grey[50] },
          }}
          onChange={(ev, newPage) => {
            setPage(newPage);
          }}
          size="large"
          count={Math.ceil(unlockables.length / ITEMS_PER_PAGE)}
        ></Pagination>
      </Box>
    </>
  );
}
