import * as React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CardActionArea,
  CardActions,
  Divider,
  IconButton,
  Slider,
} from "@mui/material";
import cardCover from "../../../assets/images/agility.svg";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Grid from "@mui/material/Grid";
import NavigationCard from "lib/navigation/components/NavigationCard";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import GameRoom from "lib/game/components/GameRoom";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import {
  setCurrentCardId,
  setBetAmount as storeSetBetAmount,
} from "lib/progression/slice";
import { randomProperty } from "lib/utils/object";
import RewardItem from "lib/progression/components/RewardItem";
import { pushModal } from "lib/modal/slice";
import theme from "theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { grey, purple, red } from "@mui/material/colors";
import GradientText from "lib/typography/components/GradientText";
import playSound from "lib/audio/sfx";
import { animate, easeIn } from "popmotion";
export default function AgilityPage() {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);

  const { games } = appState;
  const agilityGames = games
    .filter((game) => game.type === "agility")
    .sort((a, b) => b.cost.basic - a.cost.basic);

  const progression = useAppSelector((state) => state.progression);
  const [betAmount, setBetAmount] = React.useState(progression.betAmount);
  const [renderableBetAmount, setRenderableBetAmount] = React.useState(
    progression.betAmount
  );
  const [previousBetAmount, setPreviousBetAmount] = React.useState(
    progression.betAmount
  );
  const onLoadScrollIndex = agilityGames.findIndex((agilityGame) =>
    progression.level > 3 ? agilityGame.unlockLevel >= progression.level : 0
  );

  const preferences = useAppSelector((state) => state.preferences);

  const nextCard = appState.unlockable.items.find(
    (u) => u.id === progression.currentCardId
  );
  const gameTypes = ["equations", "words", "numbers", "letters"];
  React.useEffect(() => {
    if (!progression.currentCardId) {
      dispatch(
        setCurrentCardId(
          randomProperty(
            Object.keys(progression.unlocks).length > 9
              ? Object.keys(progression.unlocks).filter(
                  (property) => !progression.cardHistory.includes(property)
                )
              : Object.keys(progression.unlocks)
          )
        )
      );
    }
  }, []);
  const betThresholds = [100, 200, 400, 750, 1000, 1500, 2000, 3000, 5000];
  const maxBetAmounts = [
    199, 199, 399, 399, 749, 749, 999, 999, 1499, 1499, 1999, 1999, 2999, 2999,
    4999, 4999, 9999, 9999,
  ];
  let maxBetAmount =
    maxBetAmounts[Math.min(progression.level, maxBetAmounts.length - 1)];
  React.useEffect(() => {
    const prevBetAmountIndex = betThresholds.findIndex(function (number) {
      return number > previousBetAmount;
    });
    const newBetAmountIndex = betThresholds.findIndex(function (number) {
      return number > betAmount;
    });

    if (prevBetAmountIndex !== newBetAmountIndex) {
      playSound(`bet-${newBetAmountIndex}`);
    }
    setPreviousBetAmount(betAmount);
    animate({
      from: previousBetAmount,
      to: betAmount,
      duration: 200,

      ease: easeIn,
      //type: "spring",
      onUpdate: (latest: number) => {
        setRenderableBetAmount(Math.round(latest));
      },
    });
    dispatch(storeSetBetAmount(betAmount));
  }, [betAmount]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <GameRoom
      containerMaxWidth="xl"
      title="Agility Games"
      subtitle={
        <>
          <Alert
            severity="error"
            iconMapping={{
              error: (
                <QueuePlayNextIcon
                  fontSize="inherit"
                  sx={{
                    alignSelf: "center",
                    height: 50,
                    width: 50,
                    [theme.breakpoints.down("md")]: {
                      alignSelf: "flex-start",
                    },
                  }}
                />
              ),
            }}
            sx={{
              marginTop: 0,
              backdropFilter: "blur( 4px )",
              background:
                "linear-gradient(90deg, rgba(229, 246, 253, 0.3) 75%, transparent)",
              filter: "drop-shadow(2px 4px 6px #22222245)",
            }}
          >
            <AlertTitle
              sx={{
                textTransform: "uppercase",
                letterSpacing: "4px",
                display: "inline-flex",
                fontSize: "2em",
                alignItems: "center",
                verticalAlign: "middle",
                marginBottom: 0,
                marginRight: "2em",
                [theme.breakpoints.down("md")]: {
                  fontSize: "clamp(1em, 6vw, 1.5em)",
                  whiteSpace: "nowrap",
                  letterSpacing: "2px",
                },
              }}
            >
              Upcoming Card
            </AlertTitle>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                flexWrap: "no-wrap",
                fontSize: "1.5em",
              }}
            >
              {nextCard?.id && (
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": { filter: "drop-shadow(2px 4px 6px #22222230)" },
                  }}
                  onClick={(ev) => {
                    console.log("push");
                    ev.stopPropagation();
                    dispatch(
                      pushModal({ componentName: "CardSelector", props: {} })
                    );
                  }}
                >
                  {nextCard?.name} —{" "}
                  <RewardItem
                    onClick={() => {}}
                    sx={{
                      display: "inline-flex",
                      "& .MuiAvatar-root": {
                        display: "inline-flex",
                        borderRadius: 1,
                        height: 64,
                        width: 64,
                      },
                    }}
                    {...nextCard}
                    amount={1}
                  />
                </Box>
              )}
            </Box>
          </Alert>
          <Box
            sx={{
              marginTop: isMobile ? 2 : 2,
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setBetAmount((currentBetAmount) => {
                  for (let threshold of betThresholds) {
                    if (currentBetAmount <= threshold) {
                      const thresholdIndex = betThresholds.indexOf(threshold);
                      return betThresholds[Math.max(thresholdIndex - 1, 0)];
                    }
                  }
                  return currentBetAmount;
                });
              }}
              sx={{
                background: "rgba(255,255,255,0.2)",
                border: `1px solid ${grey[700]}`,
                color: grey[800],
                borderRadius: "100%",
                height: "1.5em",
                width: "1.5em",
                "&:hover": {
                  color: grey[200],
                  background: grey[800],
                },
              }}
              aria-label="decrement"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <Slider
              sx={{
                paddingTop: "2em",
                marginLeft: "1em",
                marginRight: "1em",
                ".MuiSlider-mark": {
                  backgroundColor: "#89023E",
                  height: "1em",
                },
              }}
              aria-label="Cost to entry"
              defaultValue={1}
              valueLabelDisplay="auto"
              marks={
                isMobile
                  ? []
                  : betThresholds.map((threshold) => ({
                      value: threshold,
                      label: "",
                    }))
              }
              min={100}
              step={10}
              color="secondary"
              value={betAmount}
              onChange={(ev, newValue) => {
                setBetAmount(newValue as number);
              }}
              max={maxBetAmount}
            />
            <IconButton
              onClick={() => {
                setBetAmount((currentBetAmount) => {
                  const reversedThresholds = [...betThresholds].reverse();
                  for (let threshold of reversedThresholds) {
                    if (currentBetAmount >= threshold) {
                      const thresholdIndex = betThresholds.indexOf(threshold);
                      return Math.min(
                        maxBetAmount,
                        betThresholds[
                          Math.min(thresholdIndex + 1, reversedThresholds[0])
                        ]
                      );
                    }
                  }
                  return currentBetAmount;
                });
              }}
              sx={{
                background: "rgba(255,255,255,0.2)",
                border: `1px solid ${grey[700]}`,
                color: grey[800],
                borderRadius: "100%",
                height: "1.5em",
                width: "1.5em",
                "&:hover": {
                  color: grey[200],
                  background: grey[800],
                },
              }}
              aria-label="increment"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
          <Typography
            sx={{
              letterSpacing: "4px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "center",
              marginTop: -2,
              textShadow:
                "1px 1px 1px #222, 1px -1px 1px #222, -1px 1px 1px #222, -1px -1px 1px #222",
              color: "#FFD700",
            }}
          >
            Wager Amount
          </Typography>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba( 255, 255, 255, 0.25 )",
              boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
              backdropFilter: "blur( 4px )",
              border: "1px solid rgba(255,255,255,0.4)",
              borderRadius: "40px",
              position: "relative",
              left: "50%",
              transform: "translateX(-50%)",
              textShadow: `-2px -2px 0.4em ${red[900]}, 2px -2px 0.4em ${red[900]}, -2px 2px 0.4em ${red[900]}, 2px 2px 0.4em ${red[900]}`,
            }}
          >
            <Box
              sx={{
                "@keyframes wagerBreathing": {
                  "0%": { filter: "contrast(100%)" },
                  "50%": {
                    transform: "scalex(1.05) scaleY(1.01)",
                    filter: "contrast(200%)",
                  },
                  "100%": {
                    transform: "scalex(1) scaleY(1)",
                    filter: "contrast(100%)",
                  },
                },
                animation: "wagerBreathing 1.33s infinite forwards",
                transformOrigin: "center",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.2em 1.2em 0.2em 0.6em",
              }}
            >
              <Typography
                sx={{ display: "inline-block", fontSize: "3em" }}
              ></Typography>
              <AcUnitIcon
                color="secondary"
                sx={{
                  width: "2em",
                  height: "2em",
                  verticalAlign: "middle",
                  stroke: red[900],
                }}
              />
              <GradientText sx={{ fontSize: "3em" }}>
                {renderableBetAmount.toLocaleString("en-US")}
              </GradientText>
            </Box>
          </Box>
        </>
      }
      //onLoadScroll={{ index: onLoadScrollIndex, total: agilityGames.length }}
      scrollType={isMobile ? "vertical" : "horizontal"}
    >
      <Grid
        sx={{ flexWrap: "nowrap", gap: "8em" }}
        container
        direction={isMobile ? "column" : "row"}
        spacing={8}
      >
        {gameTypes.map((gameType) => {
          const game = agilityGames.find(
            (game) => game.subtype === gameType && game.cost.basic <= betAmount
          )!;

          return (
            <Grid
              item
              key={gameType}
              sx={{ width: "20vw", marginRight: 12, height: "40vh" }}
            >
              <Box sx={{ position: "absolute" }}>
                <NavigationCard
                  key={game.id}
                  id={game.id}
                  to={game.to}
                  imageSrc={game.imageSrc}
                  title={game.title}
                  description={game.description}
                  challengeRating={game.challengeRating}
                  locked={progression.level < game.unlockLevel}
                  unlockLevel={game.unlockLevel}
                  asTutorial={preferences.gameTutorial}
                  cost={{ basic: renderableBetAmount }}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </GameRoom>
  );
}
