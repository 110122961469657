import * as React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import play from "lib/audio/sfx";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

export default function Clickable({
  children,
}: {
  children: React.ReactElement;
}) {
  const onClickWithSound = () => {
    play("button-click");
  };

  const onMouseEnterWithSound = () => {
    play("mouse-enter");
  };

  let onClick;
  let onMouseEnter;
  if (children.props.onClick || children.props.to) {
    onClick = (ev: any) => {
      onClickWithSound();
      if (children.props.onClick) {
        children.props.onClick(ev);
      }
    };

    onMouseEnter = (ev: any) => {
      onMouseEnterWithSound();
      if (children.props.onMouseEnter) {
        children.props.onMouseEnter(ev);
      }
    };
  }

  return React.cloneElement(children, { onClick, onMouseEnter });
}
