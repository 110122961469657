import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Clickable from "lib/audio/components/Clickable";
import { Reward, WeightedReward } from "lib/data/slice";
import Box from "@mui/material/Box";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import GradientText from "lib/typography/components/GradientText";
import {
  chanceRewards,
  generateRewardFromSet,
} from "lib/progression/poolReward";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import RewardItem from "lib/progression/components/RewardItem";
import AvatarGroup from "@mui/material/AvatarGroup";
import Grid from "@mui/material/Grid";
import ApiTwoToneIcon from "@mui/icons-material/ApiTwoTone";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import {
  blue,
  green,
  grey,
  orange,
  purple,
  red,
  yellow,
} from "@mui/material/colors";
import { award } from "lib/progression/slice";
import theme from "theme";
import playSound from "lib/audio/sfx";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  pauseRewardAnimations,
  resumeRewardAnimations,
} from "lib/animation/RewardCanvas";
import store from "store";
import { setCanAnimate } from "lib/navigation/slice";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});
const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  box-shadow: inset 0 8px 32px 0 rgba(255, 255, 255, 0.37);
  -webkit-tap-highlight-color: transparent;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

interface AssignmentCompleteReward {
  experience: number;
  currency: number;
}
interface Result extends AssignmentCompleteReward {
  amount: number;
}

export interface AssignmentCompleteModalProps {
  title: string;
  hits: Result;
  perfects: Result;
  completion: AssignmentCompleteReward;
  onClose: (ev: any) => void | (() => void);
  itemsDiscovered?: Reward[];
  bonusReward: {
    win: Reward;
    otherRewards: Reward[];
  };
}

export default function AssignmentCompleteModal({
  title,
  onClose,
  hits,
  perfects,
  completion,
  itemsDiscovered,
  bonusReward,
}: AssignmentCompleteModalProps) {
  const rows = [
    { name: "Hits", ...hits },
    { name: "Perfects", ...perfects },
    { name: "Completion", amount: "-", ...completion },
    {
      name: "Total",
      amount: "-",
      experience: hits.experience + perfects.experience + completion.experience,
      currency: hits.currency + perfects.currency + completion.currency,
    },
  ];
  const dispatch = useAppDispatch();
  const [bonusRewardRevealed, setBonusRewardRevealed] = React.useState(-1);
  const [otherRewardsRevealed, setOtherRewardsRevealed] = React.useState(false);
  let renderableItemsDiscovered: { tokens: any; items: Reward[] } = {
    tokens: {},
    items: [],
  };

  if (itemsDiscovered) {
    for (let item of Object.values(itemsDiscovered)) {
      if (item.type.indexOf("token_currency") === 0) {
        renderableItemsDiscovered.tokens[item.type] = 0;
      }
    }
    itemsDiscovered.forEach((item) => {
      if (item.type.indexOf("token_currency") === 0) {
        renderableItemsDiscovered.tokens[item.type] += item.amount;
      } else if (item.type != "zen_experience") {
        renderableItemsDiscovered.items.push(item);
      }
    });
  }

  const zenExperienceAwarded = itemsDiscovered
    ? itemsDiscovered.reduce(function (a, b) {
        if (b.type === "zen_experience") {
          return a + b.amount;
        }
        return a;
      }, 0)
    : 0;
  //renderableItems = itemsDiscovered
  React.useEffect(() => {
    pauseRewardAnimations();
    dispatch(setCanAnimate(false));
    playSound("modal-load");
    return () => {
      window.setTimeout(() => {
        const modals = store.getState().modal.modals;
        console.log("?", modals.length);
        if (modals.length > 0) return;
        resumeRewardAnimations();
        dispatch(setCanAnimate(true));
      }, 50);
    };
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Modal
        open
        onClose={(ev) =>
          onClose({ ...ev, didClaimBonus: bonusRewardRevealed !== -1 })
        }
        components={{ Root: Backdrop }}
        sx={{
          animation: "modalEntrance 0.25s ease-in forwards",
        }}
      >
        <Paper
          sx={{
            minWidth: "50vw",
            display: "flex",
            flexDirection: "column",
            background: "rgba( 255, 255, 255, 0.5 )",
            boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
            backdropFilter: "blur( 100px )",
            border: `1px solid ${grey[50]}`,

            borderRadius: "1em",
            [theme.breakpoints.down("md")]: { maxWidth: "100vw" },
          }}
        >
          <DialogContent
            dividers
            sx={{
              opacity: 0,
              overflow: "hidden",
              "@keyframes youFoundTitleEntrance": {
                from: {
                  transform: "translateX(5em)",
                  filter: "blur(2px)",
                  opacity: 0.4,
                },
                to: {
                  transform: "translateX(0)",
                  filter: "blur(0)",
                  opacity: 1,
                },
              },
              "@keyframes discoveredItemEntrance": {
                from: {
                  transform: "scale(1.4)",
                  filter: "blur(2px)",
                  opacity: 0.4,
                },
                to: {
                  transform: "scale(1)",
                  filter: "blur(0)",
                  opacity: 1,
                },
              },
              animation: "youFoundTitleEntrance .4s ease-out forwards",
              [theme.breakpoints.down("md")]: {
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
          >
            <DialogTitle>
              <GradientText
                dark
                sx={{
                  fontSize: "clamp(1rem, 7vw, 3em)",
                  whiteSpace: "nowrap",
                  background:
                    "linear-gradient(180deg, #89023E 35%, transparent)",
                }}
              >
                {title}
              </GradientText>
            </DialogTitle>
            {Object.keys(renderableItemsDiscovered.tokens).length > 0 && (
              <>
                <DialogTitle
                  sx={{
                    [theme.breakpoints.down("md")]: { paddingTop: 0 },
                  }}
                >
                  <GradientText
                    sx={{
                      fontSize: "clamp(1rem, 40vw, 1.3em)",
                      letterSpacing: "4px",
                      WebkitTextStrokeWidth: "1px",

                      filter: "drop-shadow(2px 4px 2px #222)",
                    }}
                  >
                    You Found
                  </GradientText>
                </DialogTitle>
                <Grid container gap={2} alignItems="center">
                  <RewardItem
                    type="zen_experience"
                    dark
                    verbose
                    amount={zenExperienceAwarded}
                    sx={{}}
                  />
                  {renderableItemsDiscovered.items.length > 0 &&
                    renderableItemsDiscovered.items.map((item, idx) => (
                      <RewardItem
                        sx={{
                          display: "inline-flex",
                          opacity: 0,

                          animation:
                            "discoveredItemEntrance .4s ease-out forwards",
                          animationDelay: `${1 + idx * 0.1}s`,
                        }}
                        dark
                        key={idx}
                        {...item}
                      />
                    ))}
                  {Object.entries(
                    Object.entries(renderableItemsDiscovered.tokens)
                  ).map(([idx, [category, amount]]) => {
                    return (
                      <RewardItem
                        dark
                        sx={{
                          opacity: 0,
                          animation: `discoveredItemEntrance ${0.4}s ease-out forwards`,
                          animationDelay: `${
                            1 +
                            renderableItemsDiscovered.items.length * 0.1 +
                            parseInt(idx) * 0.4
                          }s`,
                        }}
                        key={`${category}-${idx}`}
                        amount={amount as unknown as number}
                        type={`${category}`}
                      />
                    );
                  })}
                </Grid>
              </>
            )}
            {bonusReward && (
              <></>
              // <TableContainer component={Paper}>
              //   <Table aria-label="customized table">
              //     <TableHead>
              //       <TableRow>
              //         <StyledTableCell>
              //           <GradientText dark>Type</GradientText>
              //         </StyledTableCell>
              //         <StyledTableCell align="right">
              //           <GradientText dark>Amount</GradientText>
              //         </StyledTableCell>
              //       </TableRow>
              //     </TableHead>
              //     <TableBody></TableBody>
              //   </Table>
              // </TableContainer>
            )}
          </DialogContent>
          <DialogContent
            dividers
            sx={{
              filter: "blur(10px)",
              opacity: 0.4,

              background: "rgba(183, 28, 28, 0.5 )",
              "@keyframes bonusRewardPanelEntrance": {
                from: {
                  filter: "blur(10px)",
                  opacity: 0.4,
                },
                to: {
                  filter: "blur(0)",
                  opacity: 1,
                },
              },
              animation: "bonusRewardPanelEntrance 0.4s ease-out forwards",
              animationDelay: `${
                renderableItemsDiscovered.items.length > 0
                  ? 1 +
                    renderableItemsDiscovered.items.length * 0.1 +
                    Object.keys(renderableItemsDiscovered.tokens).length * 0.1
                  : 0
              }s`,

              [theme.breakpoints.down("md")]: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
          >
            <DialogTitle
              sx={{
                [theme.breakpoints.down("md")]: { paddingTop: 0 },
              }}
            >
              <GradientText
                sx={{
                  fontSize: "clamp(1rem, 40vw, 1.3em)",
                  letterSpacing: "4px",
                  WebkitTextStrokeWidth: "1px",
                  "@keyframes chooseBonusTitleText": {
                    from: {
                      filter: "drop-shadow(2px 4px 2px #222)",
                    },
                    to: {
                      filter: "drop-shadow(2px 4px 6px #222)",
                    },
                  },
                  animation:
                    "chooseBonusTitleText 2s ease-in-out infinite alternate",
                }}
              >
                Choose A Bonus
              </GradientText>
            </DialogTitle>

            {bonusReward && (
              <Grid
                container
                spacing={2}
                sx={{ justifyContent: "space-around" }}
              >
                {Array.from(
                  Array(bonusReward.otherRewards.length - 1).keys()
                ).map((idx) => {
                  const isWinningReward: boolean = bonusRewardRevealed === idx;
                  let reward = {} as Reward;

                  if (isWinningReward) {
                    reward = bonusReward.win;
                  } else if (otherRewardsRevealed) {
                    reward =
                      bonusReward.otherRewards[
                        idx > bonusRewardRevealed ? idx - 1 : idx
                      ];
                  }
                  console.log({ reward });
                  const color = [
                    red[800],
                    red[800],
                    red[800],
                    red[800],
                    red[800],
                  ][idx];
                  return (
                    <Grid item>
                      <Paper
                        sx={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          justifyContent: "space-around",
                          "&:hover": {
                            boxShadow:
                              "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
                          },
                          [theme.breakpoints.down("md")]: {
                            width: "20vw",
                            height: "20vw",
                          },
                        }}
                        onClick={() => {
                          if (bonusRewardRevealed !== -1) {
                            if (otherRewardsRevealed) {
                              console.log("getem");
                              if (!isWinningReward) {
                                dispatch(
                                  award({
                                    rewards: [reward],
                                    dateString: new Date().toISOString(),
                                  })
                                );
                              }
                            }
                            return;
                          }

                          setBonusRewardRevealed(idx);
                          playSound("bonus-revealed");
                          window.setTimeout(() => {
                            playSound("locked-bonus-revealed");
                            setOtherRewardsRevealed(true);
                          }, 400);
                        }}
                      >
                        {!isWinningReward && !otherRewardsRevealed && (
                          <ApiTwoToneIcon
                            sx={{
                              fontSize: "clamp(1rem, 15vw, 5em)",
                              color,
                            }}
                          />
                        )}
                        {isWinningReward && <RewardItem dark {...reward} />}
                        {!isWinningReward && otherRewardsRevealed && (
                          <>
                            <Box
                              sx={{
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                opacity: 0,
                                background: `radial-gradient(circle, transparent, transparent 50%, ${color}90 55%, ${color} 80%)`,
                                "@keyframes revealOtherRewardLock": {
                                  from: {
                                    opacity: 0.4,
                                    filter: "blur(20px)",
                                  },
                                  to: {
                                    opacity: 1,
                                    filter: "blur(0px)",
                                  },
                                },
                                animation:
                                  "revealOtherRewardLock .4s ease-in forwards",
                              }}
                            >
                              <LockTwoToneIcon
                                color="secondary"
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                }}
                              />
                              <RewardItem
                                sx={{ opacity: 0.5 }}
                                dark
                                {...reward}
                              />
                            </Box>
                          </>
                        )}
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </DialogContent>
          <DialogContent
            dividers
            sx={{
              opacity: 0.4,

              filter: "blur(10px)",
              "@keyframes completionRewardsPanelEntrance": {
                from: {
                  opacity: 0.4,
                  filter: "blur(10px)",
                },
                to: {
                  opacity: 1,
                  filter: "blur(0px)",
                },
              },
              animation:
                "completionRewardsPanelEntrance 0.4s ease-out forwards",
              animationDelay: `${
                renderableItemsDiscovered.items.length > 0
                  ? 1.4 +
                    renderableItemsDiscovered.items.length * 0.1 +
                    Object.keys(renderableItemsDiscovered.tokens).length * 0.1
                  : 0.4
              }s`,

              [theme.breakpoints.down("md")]: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
          >
            <DialogTitle
              sx={{
                [theme.breakpoints.down("md")]: {
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  textAlign: "center",
                },
              }}
            >
              <GradientText
                sx={{
                  fontSize: "clamp(1rem, 40vw, 1.3em)",
                  letterSpacing: "4px",
                  WebkitTextStrokeWidth: "1px",
                  whiteSpace: "nowrap",
                  filter: "drop-shadow(2px 4px 2px #222)",
                  [theme.breakpoints.down("md")]: {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
              >
                Completion Rewards
              </GradientText>
            </DialogTitle>
            <Grid container direction="column" alignItems="center">
              <RewardItem
                type="basic_currency"
                verbose
                amount={hits.currency + perfects.currency + completion.currency}
                sx={{
                  fontSize: "clamp(1rem, 10vw, 3em)",
                  background: `linear-gradient(180deg, ${green[900]} 35%, ${green[100]} 75%)`,
                  WebkitTextStrokeColor: green[100],
                  color: green[100],
                  filter: `drop-shadow(2px 4px 2px ${green[900]})`,
                }}
              />
              <RewardItem
                type="experience"
                dark
                verbose
                amount={
                  hits.experience + perfects.experience + completion.experience
                }
                sx={{
                  fontSize: "clamp(1rem, 10vw, 3em)",
                  background: `linear-gradient(180deg, ${green[900]} 35%, ${green[100]} 75%)`,
                  WebkitTextStrokeColor: green[100],
                  color: green[100],
                  filter: `drop-shadow(2px 4px 2px ${green[900]})`,
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginTop: "auto" }}>
            <Button
              //onClick={onClose}
              onMouseDown={() => {
                playSound("game-complete-collect");
              }}
              onClick={(ev) => {
                onClose({ ...ev, didClaimBonus: bonusRewardRevealed !== -1 });
              }}
              size="large"
              sx={{
                width: "100%",
                letterSpacing: "6px",
                borderBottomLeftRadius: "1em",
                borderBottomRightRadius: "1em",
              }}
              autoFocus
              variant="contained"
              color="primary"
            >
              <GradientText
                sx={{
                  fontSize: "clamp(1rem, 10vw, 4em)",
                  whiteSpace: "nowrap",
                  letterSpacing: "6px",
                }}
              >
                Collect
              </GradientText>
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
