import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Clickable from "lib/audio/components/Clickable";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Badge from "@mui/material/Badge";
import { pushModal } from "lib/modal/slice";
import * as React from "react";
import theme from "theme";
import { useMediaQuery } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TimerIcon from "@mui/icons-material/Timer";
import { Link, useLocation } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import RedeemIcon from "@mui/icons-material/Redeem";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import TollIcon from "@mui/icons-material/Toll";
import IconButton from "@mui/material/IconButton";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import { grey } from "@mui/material/colors";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { Tooltip } from "@mui/material";
import { TILE_REVEAL_MOBILE } from "lib/dashboard/components/Dashboard";
import useAnimationEffect from "lib/animation/useAnimationEffect";
import { recycleExtraCards, setTutorialStep } from "lib/progression/slice";
export const drawerWidth: number = 240;
const NavLink = (props: React.ComponentProps<typeof Link>) => (
  <Link
    {...props}
    style={{ textDecoration: "none", color: "inherit", ...props.style }}
  />
);
export default function BottomBar() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const isInGame = useAppSelector((state) => state.navigation.isInGame);
  const fullState = useAppSelector((state) => state);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const shouldShowFreebies = false;
  const claimableConsumables =
    useAppSelector((state) =>
      Object.values(state.progression.consumables).filter(
        (c) => c.hasConsumed === false
      )
    ).length > 0;
  const hasClaimableConsumables =
    progression.tutorialStep < TILE_REVEAL_MOBILE.cards.tutorialStep
      ? false
      : claimableConsumables;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 12,
        width: "100%",
        //paddingLeft: `${drawerWidth}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        left: 0,
        "@keyframes bottomBarInGame": {
          "0%": {
            transform: "translateY(0)",
          },
          "100%": {
            transform: "translateY(200%)",
          },
        },

        animation: isInGame ? "bottomBarInGame 2s forwards" : "",

        [theme.breakpoints.down("md")]: {
          bottom: 0,
          left: 0,
          animation: isInGame ? "bottomBarInGame 2s forwards" : "none",
          width: "100%",
          transform: "none",
          background: "#222",
          padding: 0,
        },
      }}
    >
      <Box
        sx={{
          display: shouldShowFreebies ? "flex" : "none",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <Clickable>
          <Chip
            sx={{
              width: "5em",
              height: "2em",
              fontSize: "2em",
            }}
            onClick={() => {
              dispatch(pushModal({ componentName: "BuyFreebies", props: {} }));
            }}
            color={progression.freebies > 0 ? "secondary" : "error"}
            label={progression.freebies}
            variant={isInGame ? "outlined" : "filled"}
            icon={
              <LocalActivityIcon
                sx={{ pointerEvents: "none", fontSize: "1em" }}
              />
            }
          />
        </Clickable>
      </Box>
      <Grid
        container
        direction="row"
        sx={{
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        }}
      >
        <Grid item xs={2}>
          <Clickable>
            <NavLink to="dashboard">
              <ListItemButton
                className="bottom-bar-dashboard"
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  justifyContent: "center",
                  opacity:
                    progression.tutorialStep <
                    TILE_REVEAL_MOBILE.cards.tutorialStep
                      ? 0
                      : 1,
                }}
                selected={location.pathname.includes("dashboard")}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <DashboardIcon
                    sx={{
                      color: location.pathname.includes("dashboard")
                        ? "#89023E"
                        : "#fafafa",
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </NavLink>
          </Clickable>
        </Grid>

        <Grid item xs={2}>
          <Clickable>
            <NavLink
              onClick={() => {
                if (
                  progression.tutorialStep ===
                  TILE_REVEAL_MOBILE.cards.tutorialStep
                ) {
                  dispatch(setTutorialStep(5));
                }
              }}
              to="cards"
            >
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      padding: "4px 8px",
                      marginBottom: "1px!important",
                      bgcolor: "rgba(255,255,255,0.4)",
                      border: "1px solid #fafafa",
                      "& .MuiTooltip-arrow": {
                        color: "#fafafa",
                      },
                    },
                  },
                }}
                open={isMobile && hasClaimableConsumables && !isInGame}
                arrow
                title={<BurstModeIcon color="primary" />}
              >
                <ListItemButton
                  className="bottom-bar-cards"
                  sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    justifyContent: "center",

                    opacity:
                      progression.tutorialStep <
                      TILE_REVEAL_MOBILE.cards.tutorialStep
                        ? 0
                        : 1,
                  }}
                  selected={location.pathname.includes("cards")}
                >
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    <CalendarViewMonthIcon
                      sx={{
                        color: location.pathname.includes("cards")
                          ? "#89023E"
                          : "#fafafa",
                      }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </NavLink>
          </Clickable>
        </Grid>
        <Grid item xs={2}>
          {/* <Clickable>
            <IconButton
              disabled={!hasClaimableConsumables}
              onClick={() => {
                const unlockables = fullState.app.unlockable.items.filter(
                  (u) => u.id in progression.consumables
                );
                const rewards = [];
                for (let unlockable of unlockables) {
                  if (!progression.consumables[unlockable.id].hasConsumed) {
                    rewards.push({
                      type: "experience",
                      amount:
                        fullState.app.unlockable.unlockRarityExperienceReward[
                          unlockable?.rarity ?? 0
                        ],
                      id: unlockable.id,
                    });
                  }
                }
                dispatch(
                  pushModal({
                    componentName: "UnlockableDetails",
                    props: {
                      rewards: rewards,
                      unlockable: unlockables,
                    },
                  })
                );
              }}
              color="inherit"
            >
              <Badge
                variant={hasClaimableConsumables ? "dot" : undefined}
                color="secondary"
              >
                <BurstModeIcon
                  sx={{
                    color: hasClaimableConsumables ? "#fafafa" : grey[700],
                  }}
                />
              </Badge>
            </IconButton>
          </Clickable> */}
        </Grid>
        <Grid item xs={2} sx={{}}>
          <Clickable>
            <Box
              sx={{
                position: "absolute",
                width: "0em",
                height: "5em",
                top: "-1.6em",
                "&:before": {
                  content: "''",
                  opacity:
                    progression.tutorialStep === 1 ||
                    progression.tutorialStep === 3
                      ? 1
                      : 0,
                  position: "absolute",
                  borderRadius: "100%",
                  left: "50%",

                  top: "50%",
                  width: "5em",
                  height: "5em",
                  transform: "translate(-50%, -50%)",
                  background:
                    "radial-gradient(transparent, #FFD70050, transparent 100%)",
                  boxShadow: "0px 0px 4px #FFD700",
                  zIndex: 11111,
                  transformOrigin: "center",
                  "@keyframes PlayGameIconButton": {
                    "0%": {
                      transform: "translate(-50%, -50%) scale(0.5)",
                      opacity: 0,
                    },
                    "10%": {
                      opacity: 1,
                    },
                    "50%": {
                      transform: "translate(-50%, -50%) scale(1.5)",
                      opacity: 0,
                    },
                    "100%": {
                      transform: "translate(-50%, -50%) scale(2)",
                      opacity: 0,
                    },
                  },
                  animation: "PlayGameIconButton 1.5s infinite ease-in-out",
                },
              }}
              onClick={() =>
                dispatch(pushModal({ componentName: "GameSelect", props: {} }))
              }
            >
              <ListItemButton
                className="bottom-bar-play"
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  justifyContent: "center",
                  border: "5px solid #222",
                  borderRadius: "100%",
                  background: "#FFD700",
                  position: "absolute",
                  padding: "12px 6px",
                  fontSize: "2em",
                  left: "50%",
                  transform: "translateX(-50%)",
                  "&.Mui-selected": {
                    backgroundColor: "#ccad00",
                  },
                  "&:hover.Mui-selected": {
                    backgroundColor: "#FFD700",
                  },
                }}
                selected={location.pathname.includes("agility")}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <SportsEsportsIcon
                    sx={{
                      color: location.pathname.includes("agility")
                        ? "#222"
                        : "#222",
                      fontSize: 40,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </Box>
          </Clickable>
        </Grid>
        <Grid item xs={2}>
          <Clickable>
            <NavLink
              onClick={() => {
                if (
                  progression.tutorialStep ===
                  TILE_REVEAL_MOBILE.packs.tutorialStep
                ) {
                  dispatch(setTutorialStep(7));
                }
              }}
              to="packs"
            >
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      padding: "4px 8px",
                      marginBottom: "1px!important",
                      bgcolor: "rgba(255,255,255,0.4)",
                      border: "1px solid #fafafa",

                      "& .MuiTooltip-arrow": {
                        color: "#fafafa",
                      },
                    },
                  },
                }}
                open={
                  isMobile &&
                  progression.tutorialStep >
                    TILE_REVEAL_MOBILE.cards.tutorialStep &&
                  progression.packs.length > 0 &&
                  !isInGame
                }
                arrow
                title={<FiberNewIcon color="primary" />}
              >
                <ListItemButton
                  className="bottom-bar-packs"
                  sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    justifyContent: "center",
                    opacity:
                      progression.tutorialStep <
                      TILE_REVEAL_MOBILE.cards.tutorialStep
                        ? 0
                        : 1,
                  }}
                  selected={location.pathname.includes("packs")}
                >
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    <RedeemIcon
                      sx={{
                        color: location.pathname.includes("packs")
                          ? "#89023E"
                          : "#fafafa",
                      }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </NavLink>
          </Clickable>
        </Grid>
        <Grid item xs={2}>
          <Clickable>
            <NavLink
              className="bottom-bar-tokens"
              onClick={() => {
                if (
                  progression.tutorialStep ===
                  TILE_REVEAL_MOBILE.tokens.tutorialStep
                ) {
                  dispatch(setTutorialStep(10));
                }
              }}
              to="tokens"
            >
              <ListItemButton
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  justifyContent: "center",
                  opacity:
                    progression.tutorialStep <
                    TILE_REVEAL_MOBILE.cards.tutorialStep
                      ? 0
                      : 1,
                }}
                selected={location.pathname.includes("tokens")}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <TollIcon
                    sx={{
                      color: location.pathname.includes("tokens")
                        ? "#89023E"
                        : "#fafafa",
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </NavLink>
          </Clickable>
        </Grid>
      </Grid>
    </Box>
  );
}
