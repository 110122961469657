import * as React from "react";
import { createUser } from "lib/data/firebase";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import GradientText from "lib/typography/components/GradientText";
import Clickable from "lib/audio/components/Clickable";
import { Alert, AlertTitle, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "theme";
import SignUpForm from "lib/auth/components/SignUpForm";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: ["Sriracha", "cursive"].join(","),
  },
});
interface State {
  password: string;
  showPassword: boolean;
}
interface SignUpPageProps {
  hideBackButton?: boolean;
  onSuccess: () => void;
}
export default function SignUpPage({
  hideBackButton,
  onSuccess,
}: SignUpPageProps) {
  const navigate = useNavigate();
  const [values, setValues] = React.useState<State>({
    password: "",
    showPassword: false,
  });
  const [error, setError] = React.useState<string | undefined>();
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <SignUpForm onSuccess={onSuccess} sx={{}} />
    </>
  );
}
