import { blue, green, grey, orange, purple } from "@mui/material/colors";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const inputEntrance = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(100px) scale(0.8);
    
  }
  to {
    opacity: 1;
    transform: translateY(0px) scale(1) ease-out;
    
  }
`;
const FlashCard = styled(Paper)<{
  isLeaving?: boolean;
  glowRarity: number;
  hiding?: boolean;
}>(({ theme, isLeaving, glowRarity, hiding }) => {
  let animation = `${inputEntrance} forwards 0.5s`;
  let glow = "";
  if (isLeaving) {
    animation = "leave forwards 0.5s";
  } else if (glowRarity > -1) {
    animation =
      "flashCardShake forwards 0.4s cubic-bezier(0, 0.62, 0.67, 1.15)";
    switch (glowRarity) {
      case 0:
        glow = grey[500];
        break;
      case 1:
        glow = green[500];
        break;
      case 2:
        glow = blue[500];
        break;
      case 3:
        glow = purple[500];
        break;
      case 4:
        glow = orange[500];
        break;
    }
  }
  if (hiding) {
    animation = "none";
  }
  return {
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    padding: "1em 2em",
    width: "100%",
    lineHeight: "60px",
    animation,
    display: "inline-block",
    marginBottom: "2em",
    position: isLeaving ? "absolute" : "relative",
    zIndex: 999999,
    opacity: hiding ? 0 : 1,
    background: "rgba( 255, 255, 255, 0.25 )",
    boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
    backdropFilter: "blur( 4px )",
    "@keyframes leave": {
      "0%": { transform: "translateY(0) scale(1)", opacity: 1 },

      // "25%": {
      //   background: `${
      //     lastAnswerState === "perfect"
      //       ? green[100]
      //       : lastAnswerState === "hit"
      //       ? blue[100]
      //       : lastAnswerState === "wrong"
      //       ? red[100]
      //       : grey[100]
      //   }`,
      // },

      "100%": {
        transform: "translateY(-200px) scale(0.4)",
        opacity: 0,
        display: "none",
      },
    },
    "@keyframes flashCardShake": {
      "0%": { filter: "contrast(100%)" },

      "50%": {
        filter: "contrast(150%)",
      },
      "100%": {
        filter: "contrast(100%)",
      },
    },
    "&:after": {
      pointerEvents: "none",
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: glowRarity > -1 ? 1 : 0,
      boxShadow: `0 0 10px 10px ${glow}`,
      borderRadius: "inherit",
      transition: "opacity 0.5s",
    },

    [theme.breakpoints.down("md")]: {
      width: "95%",
      height: "13em",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
  };
});

export default FlashCard;
