import * as React from "react";
import Numbers from "lib/agility/components/Numbers";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import { shuffleArray } from "lib/utils/array";
import { useParams } from "react-router-dom";
import { ChallengePossibility, WeightedReward } from "lib/data/slice";
import { randomWord } from "lib/data/static/word-association-service";
import { goalActionProgress } from "lib/progression/slice";
import { generateRewardFromSet } from "lib/progression/poolReward";
import { searchWord } from "lib/data/static/word-association-service";
import { v4 as uuidv4 } from "uuid";

export default function AgilityNumbersPage() {
  const { challengeLevel } = useParams();
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const modalState = useAppSelector((state) => state.modal);
  const progression = useAppSelector((state) => state.progression);
  const [currentChallenge, setCurrentChallenge] = React.useState("");
  const [challenges, setChallenges] = React.useState<ChallengePossibility[]>(
    []
  );

  const unlockables = useAppSelector((state) => state.app.unlockable.items);
  // TODO: Fix this
  const challengeLevelIndex = 0; // parseInt(challengeLevel || "1") - 1;

  const [answerPadOptions, setAnswerPadOptions] = React.useState<string[]>([]);
  const bonusRewards = useAppSelector(
    (state) => state.app.challenge.rules.bonusRewards
  );
  React.useEffect(() => {
    // Pristine list
    let potentialChallenges = [
      ...Object.values(
        appState.challenge.source.agilityNumbers.possibility[
          challengeLevelIndex
        ].items
      ),
    ];
    console.log({ potentialChallenges });
    // List gets spliced from
    let challengeCandidates = [...potentialChallenges];

    let rawChallenges = [
      ...appState.challenge.source.agilityWords.possibility[
        challengeLevelIndex
      ].items.map((question, idx) => ({ question, answer: "-", id: `${idx}` })),
    ];
    shuffleArray(rawChallenges);
    let options: string[] = [];

    let challenges: ChallengePossibility[] = [];
    let challengeAmount = 10;
    let numberOptionAmount = 2;
    // switch (challengeLevelIndex) {
    //   case 1:
    //     challengeAmount = 12;
    //     numberOptionAmount = 3;
    //     break;
    //   case 2:
    //     challengeAmount = 15;
    //     numberOptionAmount = 4;
    //     break;
    //   case 3:
    //     challengeAmount = 20;
    //     numberOptionAmount = 5;
    //     break;
    //   case 4:
    //     challengeAmount = 25;
    //     numberOptionAmount = 7;
    //     break;
    //   case 5:
    //     challengeAmount = 30;
    //     numberOptionAmount = 9;
    //     break;
    // }

    const pluckRandomChallenges: () => ChallengePossibility[] = () => {
      let challengeValid = false;
      let currentChallenges: ChallengePossibility[] = [];
      const challengeCandidate = challengeCandidates.splice(
        Math.floor(Math.random() * challengeCandidates.length),
        1
      )[0];

      if (!challengeCandidate) {
        debugger;
      }
      for (let idx in challengeCandidate) {
        const challengeCandidateNumber = challengeCandidate[idx].toString();

        if (options.includes(challengeCandidateNumber)) {
          continue;
        }
        //challengeCandidate[idx] = "_";
        currentChallenges = [
          {
            question: `${challengeCandidate
              .map((n) => (n.toString() === challengeCandidateNumber ? "_" : n))
              .join(", ")}`,
            answer: challengeCandidateNumber,
            id: uuidv4(),
          },
        ];
        for (let potentialChallenge of potentialChallenges) {
          const compareA = [...potentialChallenge].sort().toString();
          const compareB = [...challengeCandidate].sort().toString();
          if (
            compareA !== compareB &&
            potentialChallenge.includes(parseInt(challengeCandidateNumber)) &&
            currentChallenges.length < challengeAmount / numberOptionAmount
          ) {
            currentChallenges.push({
              question: `${potentialChallenge
                .map((n) =>
                  n.toString() === challengeCandidateNumber ? "_" : n
                )
                .join(", ")}`,
              answer: challengeCandidateNumber,
              id: uuidv4(),
            });
          }
        }
        if (currentChallenges.length >= challengeAmount / numberOptionAmount) {
          options.push(challengeCandidateNumber);
          challengeValid = true;
          break;
        }
      }

      if (!challengeValid) {
        return pluckRandomChallenges();
      }

      return currentChallenges;
    };

    while (challenges.length < challengeAmount) {
      challenges = challenges.concat(pluckRandomChallenges());
    }
    shuffleArray(challenges);
    setChallenges(challenges);
    console.log({ challenges });
    setCurrentChallenge(challenges[0].id);

    setAnswerPadOptions(options.sort((a, b) => a.length - b.length));

    dispatch(goalActionProgress({ type: "playWord" }));
  }, []);

  return (
    <Numbers
      challengeLevelIndex={challengeLevelIndex}
      answerPadOptions={answerPadOptions}
      challenges={challenges}
      currentChallenge={currentChallenge}
      onNextChallenge={() => {
        const index = challenges.findIndex(
          (challenge) => challenge.id === currentChallenge
        );
        setCurrentChallenge(challenges[index + 1].id);
      }}
      onChallengeSuccess={({
        hits,
        perfects,

        itemsDiscovered,
        gameBackground,
      }) => {
        const index = challenges.findIndex(
          (challenge) => challenge.id === currentChallenge
        );

        if (
          modalState.modals.find(
            (modal) => modal.componentName === "AssignmentComplete"
          )
        ) {
          return;
        }
        if (index === challenges.length - 1) {
          /** Main Reward from performance */
          const wordLengthMultiplier = 1 / 100;
          const baseHitExp =
            hits *
            appState.challenge.rules.hitRewards.filter(
              (reward) => reward.type === "experience"
            )[0].amount[challengeLevelIndex];
          const basePerfectExp =
            perfects *
            appState.challenge.rules.perfectRewards.filter(
              (reward) => reward.type === "experience"
            )[0].amount[challengeLevelIndex];

          /** Bonus Reward */
          const bonusRewardsFormatted = bonusRewards.map((reward) => {
            let type = reward.type;
            if (type === "token_currency") {
              const availableTokenTypes = unlockables.find(
                (unlockable) => unlockable.id === gameBackground
              )!.categories;
              type = `token_currency_${
                availableTokenTypes[
                  (availableTokenTypes.length * Math.random()) << 0
                ]
              }`;
            }

            return {
              ...reward,
              type,
            };
          });
          const winningReward = generateRewardFromSet(bonusRewardsFormatted);

          const otherRewards = [];
          let currentPool = [
            ...bonusRewardsFormatted.filter(
              (item: WeightedReward) => item.id != winningReward.id
            ),
          ];
          while (otherRewards.length < 4) {
            const otherReward = generateRewardFromSet(currentPool);
            currentPool.filter(
              (item: WeightedReward) => item.id != otherReward.id
            );
            otherRewards.push({
              ...otherReward,
              amount: otherReward.amount[challengeLevelIndex],
            });
          }

          dispatch(
            pushModal({
              componentName: "AssignmentComplete",
              props: {
                title: `${
                  unlockables.find(
                    (unlockable) => unlockable.id === gameBackground
                  )!.name
                }`,
                hits: {
                  amount: hits,
                  experience:
                    baseHitExp +
                    Math.round(
                      baseHitExp *
                        wordLengthMultiplier *
                        (progression.betAmount / 10000)
                    ),
                  currency:
                    hits *
                    appState.challenge.rules.hitRewards.filter(
                      (reward) => reward.type === "basic_currency"
                    )[0].amount[challengeLevelIndex],
                },
                perfects: {
                  amount: perfects,
                  experience:
                    basePerfectExp +
                    Math.round(
                      basePerfectExp *
                        wordLengthMultiplier *
                        (progression.betAmount / 10000)
                    ),
                  currency:
                    perfects *
                    appState.challenge.rules.perfectRewards.filter(
                      (reward) => reward.type === "basic_currency"
                    )[0].amount[challengeLevelIndex],
                },
                completion: {
                  experience: Math.round(
                    appState.challenge.rules.completionRewards.filter(
                      (reward) => reward.type === "experience"
                    )[0].amount[challengeLevelIndex] +
                      appState.challenge.rules.completionRewards.filter(
                        (reward) => reward.type === "experience"
                      )[0].amount[challengeLevelIndex] *
                        (progression.betAmount / 10000)
                  ),
                  currency: appState.challenge.rules.completionRewards.filter(
                    (reward) => reward.type === "basic_currency"
                  )[0].amount[challengeLevelIndex],
                },

                itemsDiscovered,
                bonusReward: {
                  win: {
                    ...winningReward,
                    amount: winningReward.amount[challengeLevelIndex],
                  },
                  otherRewards,
                },
              },
            })
          );
        }
      }}
    />
  );
}
