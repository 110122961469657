import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import SignUpForm from "lib/auth/components/SignUpForm";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import GradientText from "lib/typography/components/GradientText";
import * as React from "react";
import Modal from "./Modal";

export interface UpgradeAccountModalProps {
  onClose: (ev: any) => void | (() => void);
}
export default function UpgradeAccountModal({
  onClose,
}: UpgradeAccountModalProps) {
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const [spendAmount, setSpendAmount] = React.useState(1);
  const levels = useAppSelector((state) => state.app.levels);
  const store = useAppSelector((state) => state.app.store);
  const nextLevel = progression.level + 1;
  const cost =
    store.fastTrackProgression.xpCost *
    (levels[progression.level].experienceRequired - progression.experience);
  return (
    <Modal
      dialogProps={{
        sx: {
          ".MuiDialog-container > .MuiPaper-root": {
            background: "transparent",
            overflow: "visible",
          },
        },
      }}
      onClose={onClose}
      sx={{
        background: "rgba(0,0,0,0.3)",
        color: "#FFD700",
        boxShadow: "-2px -2px 0px 2px #ffffff34",
      }}
      dialogTitle={
        <GradientText
          sx={{
            fontSize: "clamp(1em, 6vw, 3rem)",
            background: "linear-gradient(180deg, #FFD700 35%, #89023E)",
            WebkitTextStrokeColor: "#FFD700",
          }}
        >
          Link Account
        </GradientText>
      }
      dialogHeaderContent={
        <>
          <Typography
            sx={{
              verticalAlign: "middle",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            Upgrading to a full account is free and guarantees you will be able
            to access your account from any device.
          </Typography>
        </>
      }
      dialogBodyContentProps={{
        sx: { overflow: "visible" },
      }}
      dialogBodyContent={
        <Card
          sx={{
            background: "transparent",
            color: "#FFD700",
            overflow: "visible",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <SignUpForm
            onSuccess={() => {
              onClose({});
            }}
          ></SignUpForm>
        </Card>
      }
    />
  );
}
