import { PlaylistAddOutlined } from "@mui/icons-material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentData } from "firebase/firestore";
import { Level, Reward } from "lib/data/slice";

import { User } from "@firebase/auth";
// Define the initial state using that type
type UserState = Pick<
  User,
  | "uid"
  | "email"
  | "providerId"
  | "emailVerified"
  | "isAnonymous"
  | "providerData"
>;
const initialState: UserState = {
  uid: "",
  email: "",
  providerId: "",
  emailVerified: false,
  isAnonymous: true,
  providerData: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
