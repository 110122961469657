import * as React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ACUnitIcon from "@mui/icons-material/AcUnitRounded";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  Divider,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";
import cardCover from "../../../assets/images/agility.svg";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import FilterIcon from "@mui/icons-material/Filter";

import NavigationCard from "lib/navigation/components/NavigationCard";

import GameRoom from "lib/game/components/GameRoom";
import GradientText from "lib/typography/components/GradientText";
import { pushModal } from "lib/modal/slice";
import { grey, green, blue, purple, yellow } from "@mui/material/colors";
import Clickable from "lib/audio/components/Clickable";
import { recycleExtraCards } from "lib/progression/slice";
import { iconsByCategoryName } from "lib/card/components/CategoryBadge";
import { setSpotlight } from "lib/navigation/slice";
import { useMediaQuery } from "@mui/material";
import theme from "theme";
export default function TokenPage() {
  const appState = useAppSelector((state) => state.app);
  const { games } = appState;
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  React.useEffect(() => {
    if (progression.tutorialStep === 10) {
      dispatch(
        setSpotlight({
          queryTarget: `.first-token`,
          offset: isMobile
            ? { left: 40, top: 0, size: 0 }
            : { top: 0, left: 0, size: 0 },
          message: {
            text: `Select a token to see what cards you've unlocked so far`,
            position: isMobile ? "top" : "right",
          },
        })
      );
    }
  }, []);
  return (
    <GameRoom
      withBackButton
      title={
        <Box sx={{ display: "flex" }}>
          <GradientText sx={{ fontSize: "3em", display: "flex" }}>
            Tokens
          </GradientText>
        </Box>
      }
      scrollType="vertical"
    >
      <Grid container>
        {Object.entries(iconsByCategoryName).map(([name, currentIcon]) => {
          const RenderableComponent = currentIcon.component;
          const amount =
            progression.currency.token[
              name as keyof typeof iconsByCategoryName
            ];
          if (amount === 0) {
            return null;
          }
          return (
            <Grid
              className={"first-token"}
              key={name}
              item
              xs={4}
              sm={2}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                marginBottom: "3em",
                cursor: "pointer",
              }}
              onClick={() => {
                if (progression.tutorialStep === 10) {
                  dispatch(
                    setSpotlight({
                      queryTarget: "",
                      offset: { left: 0, top: 0, size: 0 },
                      message: { text: "", position: "right" },
                    })
                  );
                  window.setTimeout(() => {
                    dispatch(
                      pushModal({
                        componentName: "News",
                        props: {
                          type: "tokens2",
                          title: "Goals",
                          image: "assets/news/tokens2.jpg",
                        },
                      })
                    );
                  }, 1500);
                }
                dispatch(
                  pushModal({
                    componentName: "TokenRelationship",
                    props: { token: name },
                  })
                );
              }}
            >
              <Tooltip title={name}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "100%",
                      display: "flex",
                      width: "5em",
                      height: "5em",
                      background: "#222",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px dotted gray",
                    }}
                  >
                    <RenderableComponent
                      sx={{
                        ...currentIcon.defaultProps.sx,

                        animation: "none",
                        fontSize: "4em",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: grey[100],
                      textShadow:
                        "1px 1px 2px #222, -1px -1px 2px #222, 1px -1px 2px #222, -1px 1px 2px #222",
                      textTransform: "uppercase",
                      letterSpacing: "4px",
                      //background: `linear-gradient(transparent 0%, ${sxProps.background} 30%, ${sxProps.background} 70%, transparent 100%)`,
                      padding: "0.1em 0.45em",
                      textAlign: "center",
                      borderRadius: "0.8em",
                      fontSize: "0.8em",
                      marginTop: "-1em",
                    }}
                  >
                    {name}
                  </Typography>
                  <GradientText>{amount.toLocaleString("en-US")}</GradientText>
                </Box>
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </GameRoom>
  );
}
