import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Clickable from "lib/audio/components/Clickable";
import playSound from "lib/audio/sfx";
import { Level } from "lib/data/slice";
import { useAppSelector } from "lib/hooks/react-redux";
import RewardItem from "lib/progression/components/RewardItem";
import GradientText from "lib/typography/components/GradientText";
import Modal from "./Modal";
import theme from "theme";
import { Box, useMediaQuery } from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { grey } from "@mui/material/colors";
import EmojiEventsSharpIcon from "@mui/icons-material/EmojiEventsSharp";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

export interface LevelUpModalProps extends Level {
  onClose: (ev: any) => void | (() => void);
}

export default function LevelUpModal({ onClose, rewards }: LevelUpModalProps) {
  const progression = useAppSelector((state) => state.progression);
  const appState = useAppSelector((state) => state.app);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const unlockableAwarded = rewards.find(
    (reward) => reward.type === "background"
  );
  console.log({ rewards });
  const upcomingUnlocks = useAppSelector(
    (state) => state.app.unlockable.items
  ).filter((unlockable) => unlockable.unlock.level === progression.level + 1);
  const unlockedGames = appState.games.filter(
    (game) => game.unlockLevel === progression.level + 1
  );
  React.useEffect(() => {
    playSound("level-up");
  }, []);
  const navigate = useNavigate();
  return (
    <Modal
      dialogProps={{
        maxWidth: "lg",
        fullWidth: true,
        sx: {
          ".MuiDialog-container > .MuiPaper-root": {
            background: "transparent",
          },
          [theme.breakpoints.down("md")]: {
            "& .MuiPaper-root": {
              margin: "0",
            },
          },
        },
      }}
      sx={{
        background: "transparent",

        backdropFilter: "blur( 4px )",
        "&:before": {
          background: "transparent",
          opacity: 0.3,
          backdropFilter: "blur( 4px )",
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundImage: `url("assets/unlockable/${
            isMobile ? "mobileTall" : "large"
          }/${unlockableAwarded?.id}.jpg")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          "@keyframes levelUpHue": {
            "0%": {
              filter: "hue-rotate(0deg)",
            },
            "15%": {
              filter: "hue-rotate(360deg)",
            },
            "100%": {
              filter: "hue-rotate(360deg)",
            },
          },
          animation: "levelUpHue 4s infinite ease-in-out",
        },

        overflow: "visible",
      }}
      onClose={onClose}
      imageSrc="/assets/hero/level-up.png"
      dialogTitle={
        <Box
          sx={{
            background: "transparent",
            borderRadius: "20px",
            padding: "0.2em 0.6em",
            fontSize: "1.1em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CelebrationIcon
            color="secondary"
            sx={{
              background: `radial-gradient(${grey[900]},transparent 80%)`,
              fontSize: "1.6em",
              stroke: "#FFD700",
              strokeWidth: "1px",
              marginRight: 1,
              fill: `url(#GameIconGradientFill)`,
              [theme.breakpoints.down("md")]: { fontSize: "1.4em" },
            }}
          />
          <GradientText
            sx={{
              fontSize: "1.6em",

              filter: "drop-shadow(2px 2px 2px #222)",
            }}
          >
            Level up!
          </GradientText>
        </Box>
      }
      dialogHeaderContent={
        <Box sx={{ height: "4em" }}>
          <EmojiEventsSharpIcon
            sx={{
              fontSize: "8em",
              filter: "drop-shadow(2px 2px 2px #222)",
              stroke: "#89023E60",
              strokeWidth: "1px",
              marginRight: 1,
              color: "#FFD700",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1,
            }}
          />
          <GradientText
            dark
            sx={{
              fontSize: "4em",
              zIndex: 2,
              lineHeight: "1",
              textAlign: "center",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0.2em)",
            }}
          >
            {progression.level + 1}
          </GradientText>
        </Box>
      }
      dialogBodyContent={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#FFD700",
              display: "inline-block",
              textTransform: "uppercase",
              fontSize: "3em",
              textAlign: "center",
              letterSpacing: "6px",
              borderBottom: "1px solid #FFD700",
            }}
          >
            Rewards
          </Typography>
          <Grid justifyContent="center" alignItems="center" container>
            {rewards.map((reward) => {
              return (
                <Grid key={`${reward.type}${reward.id}`} item>
                  <RewardItem color="#FFD700" {...reward} />
                </Grid>
              );
            })}
          </Grid>
          {/* 
          {upcomingUnlocks.length > 0 && (
            <>
              <Typography variant="body1">You can find</Typography>
              <Grid justifyContent="space-evenly" alignItems="center" container>
                {upcomingUnlocks.map((unlockable) => {
                  return (
                    <Grid key={unlockable.id} item>
                      <Tooltip title={unlockable.name}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 0,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              maxWidth: 50,
                              borderRadius: 50,
                              marginRight: 4,
                            }}
                            src={`/assets/unlockable/thumbnail/${unlockable.id}.jpg`}
                          />
                        </Typography>
                      </Tooltip>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )} */}
        </Box>
      }
      dialogActions={
        <Clickable>
          <Button
            onClick={onClose}
            sx={{
              letterSpacing: "2px",
            }}
            size="large"
            variant="contained"
            autoFocus
          >
            Claim
          </Button>
        </Clickable>
      }
    />
  );
}
