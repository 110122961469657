import { Reward } from "lib/data/slice";
import store from "store";

export default function determineGlowRarity(rewards: Reward[]) {
  const unlockables = store.getState().app.unlockable.items;
  let rarity = 0;
  for (let reward of rewards) {
    switch (reward.type) {
      case "premium_currency":
      case "pack":
        rarity = 4;
        break;
      case "freebies":
        rarity = 2;
        break;
      case "background":
        rarity = unlockables.find((u) => u.id === reward.id)?.rarity ?? 0;
        break;
    }
  }
  return rarity;
}
