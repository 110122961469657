import * as React from "react";
import { motion } from "framer-motion";

export default ({ children }: { children: React.ReactNode }) => {
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          filter: "grayscale(100%)",
          transform: "translateX(-3em)",
        },
        shown: {
          opacity: 1,
          filter: "grayscale(0%)",
          transform: "translateX(0em)",
        },
      }}
      initial="hidden"
      animate="shown"
      exit="hidden"
      transition={{ type: "spring", damping: 30, stiffness: 600 }}
    >
      {children}
    </motion.div>
  );
};
