import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";

import clsx from "clsx";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Clickable from "lib/audio/components/Clickable";
import * as React from "react";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import GradientText from "lib/typography/components/GradientText";
import CategoryBadge, {
  iconsByCategoryName,
} from "lib/card/components/CategoryBadge";
import { Game, Reward, Unlockable } from "lib/data/slice";
import AvatarGroup from "@mui/material/AvatarGroup";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, IconProps, Tooltip } from "@mui/material";
import {
  ChallengeLevel,
  markTutorialFlagComplete,
  Progression,
  SelectableGame,
  setCurrentCardId,
} from "lib/progression/slice";
import {
  buyBasicCurrency,
  setSelectableGames,
  clearSelectableGames,
  setChallengeLevel,
  charge,
} from "lib/progression/slice";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import theme from "theme";
import { useMediaQuery } from "@mui/material";

import playSound from "lib/audio/sfx";
import generateSelectableGames from "lib/progression/generateSelectableGames";
import rarityBasedFrames from "lib/card/rarityBasedFrames";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { green, grey, lightGreen, red } from "@mui/material/colors";
import { Link, LinkProps, useNavigate } from "react-router-dom";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import AbcOutlinedIcon from "@mui/icons-material/AbcOutlined";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import { AutoModeCardIcon } from "lib/design-system/components/AutoModeIcon";
import UploadIcon from "@mui/icons-material/Upload";
import NumbersIcon from "@mui/icons-material/Numbers";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { setNavCurrencyPeekThrough } from "lib/navigation/slice";
import { setBackgroundTransition } from "lib/navigation/slice";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className, "BackdropRoot")}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-tap-highlight-color: transparent;
`;

const _CardLink = React.forwardRef(
  (props: LinkProps, ref?: React.Ref<HTMLAnchorElement>) => (
    <Link {...props} ref={ref} />
  )
);
const CardLink = styled(_CardLink)``;

export interface GameSelectCardPropsGeneric {
  isMobile: boolean;
  game: SelectableGame;

  hasLoaded: boolean;
  idx: number;
  onClose: (ev: React.MouseEvent) => void;
}

export interface PropsForGame extends GameSelectCardPropsGeneric {
  unlockables: Unlockable[];
  description?: never;
  progression: Progression;
  hasGoalProgress: boolean;
}

interface PropsForGenericType extends GameSelectCardPropsGeneric {
  unlockables?: never;
  progression?: never;
  description: string;
  hasGoalProgress?: never;
}

type GameSelectCardProps = PropsForGame | PropsForGenericType;
export default function GameSelectCard({
  unlockables,
  game,
  idx,
  isMobile,
  progression,
  onClose,
  hasLoaded,
  description,
  hasGoalProgress = false,
}: GameSelectCardProps) {
  const dispatch = useAppDispatch();
  hasLoaded = true;
  const ref = React.useRef<HTMLAnchorElement>(null);
  const [currentGameId, setCurrentGameId] = React.useState(game.cardId);
  const unlockable = unlockables
    ? unlockables.find((u) => u.id === currentGameId)!
    : null;
  const games = useAppSelector((state) => state.app.games);

  const iconProps: IconProps = {
    color: "secondary",
    sx: {
      position: "absolute",
      top: 4,
      right: 6,
      background: `radial-gradient(#89023E, transparent 80%)`,
      fontSize: "2em",
      borderRadius: "100%",
      color: "#89023E#FFD700",
      opacity: hasLoaded ? 1 : 0,
      filter: "drop-shadow(0px 0px 2px #222)",

      "@keyframes GameSelectGameHeaderEntrance": {
        "0%": {
          opacity: 0,
          //    transform: "translateY(20%)",
        },
        "100%": {
          //  transform: "translateY(0%)",
          opacity: 1,
        },
      },
      animation: hasLoaded
        ? "none"
        : "GameSelectGameHeaderEntrance 0.5s cubic-bezier(0, 0, 0.47, 1) forwards",
      animationDelay: `${(idx + 1) * 0.2}s`,

      [theme.breakpoints.down("md")]: { fontSize: "1.4em" },
    },
  };

  const boxContainerProps = unlockable
    ? {
        sx: {
          border: rarityBasedFrames[unlockable.rarity].border,
          borderImage: rarityBasedFrames[unlockable.rarity].borderImage,
          borderImageWidth:
            rarityBasedFrames[unlockable.rarity].borderImageWidth,
        },
      }
    : {};

  const cardLinkProps = progression
    ? {
        to: `/agility/${game.type}/${progression.challengeLevel}`,
        onClick: (ev: React.MouseEvent) => {
          //ev.preventDefault();
          dispatch(setChallengeLevel(1));
          dispatch(setCurrentCardId(game.cardId));
          playSound(`select-${unlockable?.categories[0]}`);
          const { left, top, width, height } =
            ref.current!.getBoundingClientRect();
          // dispatch(
          //   setBackgroundTransition({
          //     initialPosition: { left, top, width, height },
          //     backgroundId: game.cardId,
          //     transitionState: "entering",
          //   })
          // );
          // window.setTimeout(() => {
          //   dispatch(setBackgroundTransition({}));
          // }, 1000);
          onClose(ev);
        },
      }
    : {
        to: "",
        onClick: (ev: React.MouseEvent) => {
          dispatch(setCurrentCardId(game.cardId));
          dispatch(markTutorialFlagComplete("initialPreference"));
          playSound(`select-${unlockable?.categories[0]}`);
          onClose(ev);
        },
      };

  const cardTitle = progression
    ? games.find(
        (gameData) =>
          gameData.subtype === game.type &&
          gameData.challengeRating === progression.challengeLevel
      )!.title
    : game.type;

  const preloadAndSetCurrentGame = async () => {
    await fetch(
      `/assets/unlockable/${isMobile ? "mobileTall" : "large"}/${
        game.cardId
      }.jpg`
    );
    setCurrentGameId(game.cardId);
  };
  React.useEffect(() => {
    preloadAndSetCurrentGame();
  }, [game.cardId]);

  React.useEffect(() => {
    if (progression) {
      dispatch(setNavCurrencyPeekThrough(true));
    }

    return () => {
      dispatch(setNavCurrencyPeekThrough(false));
    };
  }, []);
  return (
    <CardLink
      ref={ref}
      key={game.id}
      sx={{
        textDecoration: "none",
        width: "100%",
        position: "relative",
        flex: "1 1 0",
        marginBottom: 2,
        [theme.breakpoints.up("md")]: {
          maxWidth: "33%",
          maxHeight: "30vw",
        },
      }}
      {...cardLinkProps}
    >
      <Box
        {...boxContainerProps}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "0.2em 0.6em 0.4em 0.6em",

          background: `url(/assets/unlockable/${
            isMobile ? "mobileWide" : "large"
          }/${currentGameId}.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: hasLoaded ? 1 : 0,
          transition: "background 0.5s ease-in-out",
          "@keyframes GameSelectGameEntrance": {
            "0%": {
              opacity: 0.1,
              transform: "translateX(80%)",
            },
            "100%": {
              transform: "translateX(0%)",
              opacity: 1,
            },
          },
          animation: hasLoaded
            ? "none"
            : "GameSelectGameEntrance 0.3s cubic-bezier(0, 0, 0.47, 1) forwards",

          animationDelay: `${idx * 0.2}s`,

          [theme.breakpoints.up("md")]: {
            padding: "0.6em 2em 0.4em 2em",
            //animationDelay: `${(2 - idx) * 0.2}s`,
          },
          ...boxContainerProps.sx,
        }}
      >
        {(() => {
          let IconComponent = FitnessCenterIcon;
          switch (game.type) {
            case "equations":
              IconComponent = CalculateOutlinedIcon;
              break;
            case "words":
              IconComponent = SubtitlesOutlinedIcon;
              break;
            case "letters":
              IconComponent = AbcOutlinedIcon;
              break;
            case "numbers":
              IconComponent = NumbersIcon;
              break;
          }

          // @ts-ignore
          return <IconComponent {...iconProps} />;
        })()}
        {hasGoalProgress && (
          <SportsScoreIcon
            color="secondary"
            sx={{
              ...iconProps.sx,
              animationDelay: `${(idx + 1) * 0.4}s`,
              top: 60,
            }}
          />
        )}
        <GradientText
          sx={{
            filter: "drop-shadow(2px 2px 2px #222)",
            textTransform: "capitalize",
            fontSize: cardTitle.length > 15 ? "1.2em" : "1.5em",
            display: "flex",
            alignItems: "center",
            opacity: hasLoaded ? 1 : 0,
            animation: hasLoaded
              ? "none"
              : "GameSelectGameHeaderEntrance 0.5s cubic-bezier(0, 0, 0.47, 1) forwards",
            animationDelay: `${(idx + 2) * 0.2}s`,
            whiteSpace: "nowrap",
            [theme.breakpoints.up("md")]: {
              fontSize: cardTitle.length > 16 ? "2em" : "2.9em",
            },
          }}
        >
          {cardTitle}
        </GradientText>
        {description && (
          <Typography
            sx={{
              fontSize: "1em",
              color: "white",
              textShadow: "2px 2px 2px #222",
              opacity: hasLoaded ? 1 : 0,
              animation: hasLoaded
                ? "none"
                : "GameSelectGameHeaderEntrance 0.9s cubic-bezier(0, 0, 0.47, 1) forwards",
              animationDelay: `${(idx + 4) * 0.2}s`,
              [theme.breakpoints.up("md")]: { fontSize: "1.2em" },
            }}
          >
            {description}
          </Typography>
        )}
        {unlockable && (
          <>
            <Typography
              sx={{
                color: "white",
                textShadow: "2px 2px 2px #222",
                opacity: hasLoaded ? 1 : 0,
                animation: hasLoaded
                  ? "none"
                  : "GameSelectGameHeaderEntrance 0.9s cubic-bezier(0, 0, 0.47, 1) forwards",
                animationDelay: `${(idx + 4) * 0.2}s`,
                [theme.breakpoints.up("md")]: {
                  fontSize: unlockable.name.length > 15 ? "1.8rem" : "2.4em",
                },
              }}
            >
              {unlockable.name}
            </Typography>{" "}
            <Box
              sx={{
                display: "flex",
                gap: 3,
                marginTop: "auto",
              }}
            >
              {unlockable.categories.map((category, categoryIdx) => {
                const textShadow = `1px 1px 2px #222, -1px -1px 2px #222, 1px -1px 2px #222, -1px 1px 2px #222`;
                return (
                  <Box
                    key={category}
                    sx={{
                      opacity: hasLoaded ? 1 : 0,
                      "@keyframes GameSelectCategoryBadgeEntrance": {
                        "0%": {
                          transform: "translateY(1em)",
                          opacity: 0,
                        },
                        "100%": {
                          transform: "translateY(0)",
                          opacity: 1,
                        },
                      },
                      animation: hasLoaded
                        ? "none"
                        : "GameSelectCategoryBadgeEntrance 1s cubic-bezier(0, 0, 0.47, 1) forwards",

                      animationDelay: `${(idx + 6) * 0.2}s`,
                      [theme.breakpoints.up("md")]: {},
                    }}
                  >
                    <CategoryBadge
                      size={isMobile ? "small" : "large"}
                      name={category}
                      sx={{
                        margin: "auto",
                      }}
                    />
                    <Typography
                      sx={{
                        color: grey[100],
                        textShadow: textShadow,
                        textTransform: "uppercase",
                        letterSpacing: "4px",
                        //background: `linear-gradient(transparent 0%, ${sxProps.background} 30%, ${sxProps.background} 70%, transparent 100%)`,
                        padding: "0.1em 0.45em",
                        textAlign: "center",
                        borderRadius: "0.8em",
                        fontSize: "0.8em",
                        marginTop: "-1em",
                        position: "relative",
                        zIndex: 1,
                        "@keyframes GameSelectCategoryBadgeLabelEntrance": {
                          "0%": {
                            transform: "scale(1.2)",
                            opacity: 0,
                          },
                          "100%": {
                            transform: "scale(1)",
                            opacity: 1,
                          },
                        },
                        animation: hasLoaded
                          ? "none"
                          : "GameSelectCategoryBadgeLabelEntrance 0.3s cubic-bezier(0, 0, 0.47, 1) forwards",

                        [theme.breakpoints.down("md")]: {
                          letterSpacing: "1px",
                          fontSize: "0.6em",
                          marginTop: "-0.5em",
                        },
                      }}
                    >
                      {category}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </CardLink>
  );
}
