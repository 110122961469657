import { Application, Loader, Sprite, Texture, Spritesheet } from "pixi.js";
import { shuffleArray } from "lib/utils/array";
import { chanceRewards } from "lib/progression/poolReward";

import { award, useFreebie } from "lib/progression/slice";
import runAward from "lib/animation/RewardCanvas";
import store from "store";
import { WindowSharp } from "@mui/icons-material";
const randomDeckNumber = () => randomNumber(0, 52);

const randomNumber = (max, min) =>
  Math.floor(Math.random() * (max - min)) + min;

const generateFileName = () => {
  let number = randomDeckNumber();
  return `symbol${number}.png`;
};

const getDeck = (numberOfCards) => {
  return new Array(numberOfCards).fill(0).map(generateFileName);
};

let hasLoaded = false;
let cards = [];

export default function initialize(container) {
  const { innerWidth: width, innerHeight: height } = window;
  const backgroundColor = { backgroundColor: 0x2a1d32 };

  const applicationConfig = {
    width,
    height,
    antialias: true,
    backgroundAlpha: 0,
    resolution: 1,
    backgroundColor,
  };

  const app = new Application(applicationConfig);
  container.appendChild(app.view);

  if (hasLoaded) {
    setup();
  } else {
    try {
      Loader.shared.add("/assets/matching/spritesheet.json").load(setup);
    } catch {
      setup();
    }
  }

  function setup() {
    hasLoaded = true;
    let deckTextures =
      Loader.shared.resources["/assets/matching/spritesheet.json"].textures;

    let numberOfCards = 10;
    const deckNumber = randomNumber(1, 6);
    let spacingY = 140;
    let spacingX = 210;
    let possibleCards = [...Array(5)].map((_, i) => randomDeckNumber());
    possibleCards = [...possibleCards, ...possibleCards];
    shuffleArray(possibleCards);

    let row = 0;
    let column = 0;
    for (let i = 0; i < numberOfCards; i++) {
      row = i % 5;
      if (i % 5 === 0) {
        column++;
      }
      const id = possibleCards.pop();

      let backTexture = Texture.from(`back_${deckNumber}.png`);
      let frontTexture = Texture.from(`symbol${id}.png`);
      let card = new Sprite(frontTexture);
      cards.push({
        sprite: card,
        backTexture,
        frontTexture,
        id,
        isFront: false,
      });
      card.scale.set(1, 1);
      card.interactive = true;
      card.buttonMode = true;
      card.x = window.screen.width / 8 + row * spacingY;
      card.y = column * spacingX;

      app.stage.addChild(card);
    }
  }
  console.log("init", cards);
}

const FIRST_GUESS = 0;
const SECOND_GUESS = 1;

export async function startGame() {
  return new Promise((resolve, reject) => {
    let round = FIRST_GUESS;
    let firstGuess;
    let secondGuess;
    let numberOfMatches = 0;
    let inPerfectMode = true;
    let perfects = 0;
    let hits = 0;

    const processTurn = () => {
      if (firstGuess.id === secondGuess.id) {
        console.log("process success");
        if (inPerfectMode) {
          perfects++;
          const rewards = chanceRewards();
          if (rewards.length > 0) {
            store.dispatch(
              award({
                rewards: rewards,
                dateString: new Date().toISOString(),
              })
            );
            for (let reward of rewards) {
              runAward(
                reward.amount,
                `/assets/unlockable/thumbnail/${reward.id}.jpg`
              );
            }
          }
        } else {
          hits++;
        }
        numberOfMatches++;
        if (numberOfMatches === 5) {
          resolve({ perfects, hits });
        }
        firstGuess = undefined;
        secondGuess = undefined;
        round = FIRST_GUESS;
      } else {
        window.setTimeout(() => {
          inPerfectMode = false;
          firstGuess.isFront = false;
          firstGuess.sprite.texture = firstGuess.backTexture;
          secondGuess.isFront = false;
          secondGuess.sprite.texture = secondGuess.backTexture;
          secondGuess = undefined;
          round = FIRST_GUESS;
        }, 500);
      }
    };

    var freebieCardsById = {};

    cards.forEach((card) => {
      card.sprite.texture = card.backTexture;

      if (freebieCardsById[card.id]) {
        freebieCardsById[card.id].push(card.sprite);
      } else {
        freebieCardsById[card.id] = [card.sprite];
      }

      card.sprite.on("click", () => {
        if (!card.isFront) {
          card.isFront = true;
          if (round === FIRST_GUESS) {
            card.sprite.texture = card.frontTexture;
            firstGuess = card;
            round = SECOND_GUESS;
          } else if (round === SECOND_GUESS && !secondGuess) {
            card.sprite.texture = card.frontTexture;

            secondGuess = card;
            processTurn();
          }

          window.setTimeout(() => {
            // Timeout so we don't modify the array in place while iterating for freebies
            for (let [index, freebieCard] of freebieCardsById[
              card.id
            ].entries()) {
              if (freebieCard.texture === card.frontTexture) {
                freebieCardsById[card.id].splice(index, 1);
              }

              if (freebieCardsById[card.id].length === 0) {
                delete freebieCardsById[card.id];
              }
            }
          }, 1);
        }
      });
    });

    document.addEventListener("keydown", (ev) => {
      if (ev.code === "Semicolon" && numberOfMatches < 5) {
        store.dispatch(useFreebie());
        inPerfectMode = true;
        if (firstGuess) {
          for (let freebieCard of freebieCardsById[firstGuess.id]) {
            freebieCard.emit("click");
          }
        } else {
          const freebieCards = Object.values(freebieCardsById);

          for (let freebieCard of freebieCards[
            (freebieCards.length * Math.random()) << 0
          ]) {
            freebieCard.emit("click");
          }
        }
      }
    });
  });
}
