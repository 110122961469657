import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { Box } from "@mui/material";
import Clickable from "lib/audio/components/Clickable";
import { generateGoal } from "lib/goal/generateGoal";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { refreshGoal } from "lib/progression/slice";
import GradientText from "lib/typography/components/GradientText";
import { useNavigate } from "react-router-dom";
import { pushModal } from "../slice";
import Modal from "./Modal";

import theme from "theme";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

export interface GoalRefreshModalProps {
  onClose: (ev: any) => void | (() => void);
  refreshIndex: number;
}

export default function GoalRefreshModal({
  onClose,
  refreshIndex,
}: GoalRefreshModalProps) {
  const progression = useAppSelector((state) => state.progression);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const freebieRefreshPrice = useAppSelector(
    (state) => state.app.goal.freebieRefreshPrice
  );
  return (
    <Modal
      onClose={onClose}
      dialogTitle={"Refresh Goal"}
      dialogProps={{ maxWidth: "md", fullWidth: true }}
      dialogBodyContent={
        <Box>
          <Typography sx={{ fontSize: "2em" }}>
            Spend {freebieRefreshPrice}{" "}
            <LocalActivityIcon
              sx={{ marginLeft: -0.8, marginTop: 1.2, marginRight: -0.1 }}
              fontSize="medium"
            />{" "}
            to replace this goal with a new one?{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 8,
              [theme.breakpoints.down("md")]: { flexWrap: "wrap" },
            }}
          >
            {" "}
            <Clickable>
              <Button
                onClick={() => {
                  onClose(undefined);
                }}
                size="large"
                sx={{
                  letterSpacing: "6px",
                  display: "block",
                  fontSize: "2em",
                  color: "#89023E",
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                    fontSize: "1.3em",
                  },
                }}
                autoFocus
                variant="outlined"
              >
                Cancel
              </Button>
            </Clickable>
            <Clickable>
              <Button
                onClick={() => {
                  if (progression.freebies >= freebieRefreshPrice) {
                    const goalData = generateGoal();
                    dispatch(
                      refreshGoal({
                        freebiePrice: freebieRefreshPrice,
                        goalIndex: refreshIndex,
                        goalData: {
                          type: goalData.type,
                          requiredAmount: goalData.requiredAmount,
                          progress:
                            goalData.type === "freeClaim"
                              ? 1
                              : goalData.progress,
                          rewards: goalData.rewards,
                          hasClaimed: false,
                          requirementMeta: goalData.requirementMeta || "",
                        },
                      })
                    );
                    onClose(undefined);
                  } else {
                    dispatch(
                      pushModal({ componentName: "BuyFreebies", props: {} })
                    );
                  }
                }}
                size="large"
                sx={{
                  letterSpacing: "6px",
                  display: "block",
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                  },
                }}
                autoFocus
                variant="contained"
                color="primary"
              >
                <GradientText
                  sx={{
                    fontSize: "3em",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1.3em",
                      WebkitTextStrokeWidth: "0.5px",
                    },
                  }}
                >
                  Confirm
                </GradientText>
              </Button>
            </Clickable>
          </Box>
        </Box>
      }
    />
  );
}
