import Container from "@mui/material/Container";
import * as React from "react";
import DailyWheel from "lib/daily/DailyWheel";
import Button from "@mui/material/Button";
import GradientText from "lib/typography/components/GradientText";
import { useMediaQuery } from "@mui/material";
import {
  grey,
  red,
  green,
  blue,
  orange,
  pink,
  yellow,
  purple,
} from "@mui/material/colors";
import {
  addBasicCurrency,
  addPremiumCurrency,
  setTutorialStep,
} from "lib/progression/slice";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import { guaranteedRewards } from "lib/progression/poolReward";
import Clickable from "lib/audio/components/Clickable";
import { Navigate, useNavigate } from "react-router-dom";
import { generatePack } from "lib/pack/generatePack";
import useWindowSize from "lib/hooks/useWindowSize";
import playSound, { stopSound } from "lib/audio/sfx";
import theme from "theme";
import FastForwardIcon from "@mui/icons-material/FastForward";
import { setSpotlight } from "lib/navigation/slice";
import { progress } from "popmotion";
const Track = () => {
  return (
    <img
      src="/assets/track.png"
      style={{
        position: "absolute",
        left: "50%",
        top: -20,
        transform: "translateX(-50%)",
        width: "100px",
        height: "100px",
      }}
    />
  );
};
export default function HomePage() {
  const dispatch = useAppDispatch();
  let [theWheel, setTheWheel] = React.useState<any>(null);
  const [hasFastForward, setHasFastForward] = React.useState(false);
  const [hasBegunSpinning, setHasBegunSpinning] = React.useState(false);
  const navigate = useNavigate();
  const progression = useAppSelector((state) => state.progression);
  const canClaimDailyBonus =
    Date.now() - 60 * 60 * 24 * 1000 >
    new Date(progression.dailyBonus.lastClaimed).getTime();
  let canvasSize = 800;
  // const canvasSize =
  //   window.innerWidth > window.innerHeight
  //     ? window.innerHeight / 1.8
  //     : window.innerWidth / 1.8;

  let scaleFactor = 1.2;

  let centerScaleOffset = 2.4;

  if (useMediaQuery(theme.breakpoints.up("xl"))) {
    scaleFactor = 1.2;
    centerScaleOffset = 2.4;
    canvasSize = 700;
  } else if (useMediaQuery(theme.breakpoints.up("lg"))) {
    console.log("md");
    scaleFactor = 1;
    centerScaleOffset = 2.2;
    canvasSize = 800;
  } else if (useMediaQuery(theme.breakpoints.up("md"))) {
    console.log("lg");
    scaleFactor = 1;
    centerScaleOffset = 2;
    canvasSize = 700;
  } else if (useMediaQuery(theme.breakpoints.up("sm"))) {
    scaleFactor = 1;
    centerScaleOffset = 2;
    canvasSize = 650;
  } else if (useMediaQuery(theme.breakpoints.down("sm"))) {
    scaleFactor = 1;
    centerScaleOffset = 2;
    canvasSize = 500;
  }

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (progression.tutorialStep === 2) {
      dispatch(
        setSpotlight({
          queryTarget: ".daily-wheel-button",
          message: {
            text: "Spin the wheel!",
            position: isMobile ? "top" : "right",
          },
        })
      );
    }
  }, []);
  const size = useWindowSize();
  if (!canClaimDailyBonus) {
    navigate("/dashboard");
  }
  const calibrateWheel = (wheel: any) => {
    if (!wheel) {
      return;
    }
    var canvasRef = document.getElementById("daily-wheel-canvas");
    let canvas = canvasRef as HTMLCanvasElement;
    // Get width of window.
    var w = canvas.width;

    // Set height to the current height of the canvas since we don't adjust it.
    var h = canvas.height;

    // Assuming only the width will change to be responsive.

    canvas.width = isMobile ? window.innerWidth : w;

    // To ensure the wheel stays inside the canvas, work out what is the smaller
    // value between width and height, and set the outerRadius to half of that.

    if (w < h) {
      wheel.outerRadius = w / 2.4;
    } else {
      wheel.outerRadius = h / 2.4;
    }

    // In order to keep the wheel in the center of the canvas the centerX and
    // centerY need to be set to the middle point of the canvas.

    wheel.centerX = canvas.width / centerScaleOffset;

    wheel.centerY =
      canvas.height / centerScaleOffset -
      (isMobile ? window.innerWidth / 18 : 0);
    wheel.textFontSize = isMobile ? 16 : 24;
    // Other possible TODO
    // - Alter the font size.
    // - Adjust inner radius if the wheel has one.

    // Re-draw the wheel.
    wheel.draw();
  };
  React.useEffect(() => {
    var f = new FontFace(
      "Material Icons",
      "url(/assets/MaterialIcons-Regular.ttf)"
    );
    f.load().then(function (font) {
      document.fonts.add(font);
      setTheWheel(() => {
        const wheel = new DailyWheel({
          // centerX: canvasSize / 2,
          // centerY: canvasSize / 2,

          outerRadius: 212, // Set outer radius so wheel fits inside the background.
          innerRadius: 75, // Make wheel hollow so segments dont go all way to center.
          textFontSize: 24, // Set default font size for the segments.
          textOrientation: "vertical", // Make text vertial so goes down from the outside of wheel.
          textAlignment: "outer", // Align text to outside of wheel.
          numSegments: 24, // Specify number of segments.
          // Define segments including colour and text.
          segments: [
            // font size and text colour overridden on backrupt segments.
            { fillStyle: red[600], text: "10", icon: "self_improvement" },
            { fillStyle: green[600], text: "450", icon: "ac_unit" },
            { fillStyle: red[200], text: "600", icon: "ac_unit" },
            { fillStyle: blue[500], text: "75", icon: "auto_awesome" },
            { fillStyle: orange[600], text: "500", icon: "ac_unit" },
            {
              fillStyle: grey[900],
              text: "50",
              textFillStyle: "#ffffff",
              icon: "self_improvement",
            },
            { fillStyle: pink[500], text: "3000", icon: "ac_unit" },
            { fillStyle: yellow[400], text: "600", icon: "ac_unit" },
            { fillStyle: red[200], text: "700", icon: "ac_unit" },
            { fillStyle: red[600], text: "10", icon: "self_improvement" },
            { fillStyle: green[600], text: "500", icon: "ac_unit" },
            { fillStyle: orange[600], text: "30", icon: "self_improvement" },
            { fillStyle: purple[500], text: "300", icon: "ac_unit" },
            { fillStyle: yellow[400], text: "5", icon: "local_activity" },
            { fillStyle: blue[500], text: "50", icon: "auto_awesome" },
            { fillStyle: red[600], text: "1000", icon: "ac_unit" },
            { fillStyle: red[200], text: "500", icon: "ac_unit" },
            { fillStyle: orange[600], text: "3", icon: "local_activity" },
            { fillStyle: green[600], text: "50", icon: "self_improvement" },
            {
              fillStyle: grey[900],
              text: "20",
              textFillStyle: "#ffffff",
              icon: "self_improvement",
            },
            { fillStyle: purple[500], text: "600", icon: "ac_unit" },
            { fillStyle: yellow[400], text: "700", icon: "ac_unit" },
            { fillStyle: blue[500], text: "800", icon: "ac_unit" },
            { fillStyle: "#ffffff", text: "50", icon: "self_improvement" },
          ],
          scaleFactor,
          // Specify the animation to use.
          animation: {
            type: "spinToStop",
            duration: 10,
            spins: 10,
            stopAngle: progression.tutorialStep <= 3 ? 32 : undefined,
            callbackSound: () => {
              playSound("spin-tick");
            },
            soundTrigger: "pin",
            callbackFinished: ({
              text,
              icon,
            }: {
              text: string;
              icon: string;
            }) => {
              let rewards;
              switch (icon) {
                case "ac_unit":
                  rewards = [
                    { type: "basic_currency", amount: parseInt(text) },
                  ];
                  break;
                case "diamond":
                  rewards = [
                    { type: "premium_currency", amount: parseInt(text) },
                  ];
                  break;
                case "self_improvement":
                  rewards = [
                    { type: "zen_experience", amount: parseInt(text) },
                  ];

                  break;
                case "local_activity":
                  rewards = [{ type: "freebies", amount: parseInt(text) }];

                  break;
                case "auto_awesome":
                  rewards = [{ type: "experience", amount: parseInt(text) }];

                  break;
              }
              dispatch(
                pushModal({
                  componentName: "Reward",
                  props: {
                    rewards,
                    navTo: "/dashboard",
                    title: "Your Daily Spin Result",
                    source: "dailySpin",
                  },
                })
              );
            }, // Function to call whent the spinning has stopped.
          },
          // Turn pins on.
          pins: {
            number: 24,
            fillStyle: "silver",
            outerRadius: 4,
            responsive: true,
          },
        });
        calibrateWheel(wheel);
        return wheel;
      });
    });
  }, []);

  React.useEffect(() => {
    calibrateWheel(theWheel);
  }, [size]);
  return (
    <Container
      maxWidth="lg"
      sx={{
        position: "relative",
        mt: 4,
        mb: 4,
        display: "flex",
        justifyContent: "center",
        paddingTop: 9,
      }}
    >
      <canvas
        id="daily-wheel-canvas"
        data-responsiveMinWidth="180"
        data-responsiveScaleHeight="false" /* Optional Parameters */
        data-responsiveMargin="50"
        width={canvasSize}
        height={canvasSize}
      >
        Canvas not supported, use another browser.
      </canvas>
      <Track />
      <Clickable>
        <Button
          variant="contained"
          color={"primary"}
          className="daily-wheel-button"
          onClick={() => {
            if (progression.tutorialStep === 2) {
              dispatch(
                setSpotlight({
                  queryTarget: "",
                  message: { text: "Spin the wheel!", position: "right" },
                })
              );

              dispatch(setTutorialStep(3));
            }

            if (hasBegunSpinning) {
              setHasFastForward(true);
              theWheel.fastForward();

              playSound("wheel-spin-complete");
              return;
            }
            if (theWheel) {
              setHasBegunSpinning(true);
              let timerAmount = 1200;
              const slowDownSound = () => {
                if (!hasFastForward) {
                  window.setTimeout(() => {
                    timerAmount -= 110;
                    //console.log('playbackrate', wheelSpinSound)
                    // wheelSpinSound.playbackRate = Math.max(
                    //   Math.round(
                    //     (Math.round(wheelSpinSound.playbackRate) * 10) / 10 -
                    //       0.1
                    //   ),
                    //   0
                    // );

                    if (timerAmount > 100) {
                      slowDownSound();
                    } else {
                    }
                  }, Math.max(10, timerAmount));
                }
              };

              window.setTimeout(slowDownSound, 800);
              window.setTimeout(() => {
                setHasFastForward((ff) => {
                  if (ff) {
                    //wheelSpinSound.pause();
                    playSound("wheel-spin-complete");
                  }
                  return false;
                });
              }, 8500);
              theWheel.startAnimation();
            }
          }}
          sx={{
            marginTop: 5,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "100%",
            width: "17em",
            height: "17em",
            overflow: "hidden",
            background: hasBegunSpinning
              ? "url('assets/hero/spinning.png')"
              : "",
            backgroundSize: "cover",
            [theme.breakpoints.down("md")]: {
              width: "12em",
              height: "12em",
              marginTop: 2,
            },

            [theme.breakpoints.between("md", "lg")]: { marginTop: 0 },
            ["@media (min-width:700px) and (max-width: 900px)"]: {
              marginTop: 0,
            },
            "&:before": {
              content: "''",
              position: "absolute",

              top: 0,
              left: "-100px",
              width: "70px",
              height: "100%",
              background: "rgba(255, 247, 204, 0.3)",
              transform: "skewX(-30deg)",
              animationName: hasBegunSpinning ? "" : "spinButtonShimmer",
              animationDuration: "2s",
              animationTimingFunction: "linear",
              animationDelay: `${0}s`,
              animationIterationCount: "infinite",
              animationDirection: "normal",
              backgroundImage: `linear-gradient(
                    to right, 
                    rgba(255, 247, 204, 0.13) 0%,
                    rgba(255, 247, 204, 0.13) 77%,
                    rgba(255, 247, 204, 0.5) 92%,
                    rgba(255, 247, 204, 0.0) 100%
                    );
                }`,
              "@keyframes spinButtonShimmer": {
                "0%": {
                  opacity: 1,
                  left: "-100px",
                  top: 0,
                },

                "10%": {
                  left: "290px",
                  top: 0,
                },
                "98%": {
                  opacity: 0,
                  left: "290px",
                  top: 0,
                },
                "99%": {
                  opacity: 0,
                  left: "-100px",
                  top: 0,
                },

                "100%": {
                  opacity: 0,
                  left: "-100px",
                  top: 0,
                },
              },
            },
          }}
        >
          <GradientText
            sx={{
              fontSize: "6em",

              [theme.breakpoints.down("md")]: { fontSize: "4em" },
            }}
          >
            {hasBegunSpinning ? (
              <FastForwardIcon
                fontSize="large"
                sx={{
                  height: "2em",
                  width: "2em",
                  filter: "drop-shadow(3px 3px 3px #222)",
                }}
              />
            ) : (
              "Spin"
            )}
          </GradientText>
        </Button>
      </Clickable>
    </Container>
  );
}
