import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Game } from "lib/data/slice";
import { TutorialTypes } from "lib/progression/slice";
export type NewsItemTypes = "betaNews" | "game" | "sale" | TutorialTypes;
export interface NewsItem {
  id: string;
  title: string;
  body: string;
  image: string;
  expiration?: string;
  type: NewsItemTypes;
}

interface WelcomeNewsState {
  items: NewsItem[];
  hasSeen: boolean;
}

// Define the initial state using that type
const initialState: WelcomeNewsState = {
  items: [],
  hasSeen: false,
  //   title: "Welcome Exclusive",
  //     body: "200% more shards",
  //     image: ''
  //     expiration: ''
};

export const welcomeNewsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNewsItems: (state, action: PayloadAction<NewsItem[]>) => {
      state.items = action.payload;
    },
    setHasSeen: (state, action: PayloadAction<boolean>) => {
      state.hasSeen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNewsItems, setHasSeen } = welcomeNewsSlice.actions;

export default welcomeNewsSlice.reducer;
