import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Clickable from "lib/audio/components/Clickable";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { grey } from "@mui/material/colors";

import { useAppSelector } from "lib/hooks/react-redux";
import Modal from "./Modal";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import GradientText from "lib/typography/components/GradientText";
import Tilt from "react-parallax-tilt";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import BuyButton from "lib/purchase/components/BuyButton";
import RewardItem from "lib/progression/components/RewardItem";
import Tooltip from "@mui/material/Tooltip";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  marginBottom: 20,

  backgroundColor: "#22222225",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.secondary.light,
  },
}));

interface Reward {
  currency: number;
}

const StatHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <GradientText
      variant="h3"
      sx={{
        marginTop: 2,
        fontSize: "2rem",
        display: "inline-block",
        textDecorationColor: "#22222275",
        WebkitTextStrokeColor: "#FFD700",

        color: "transparent",
        WebkitTextStrokeWidth: "1px",
        background: "linear-gradient(180deg, #FFD700 35%, #89023E)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
      }}
      gutterBottom
    >
      {children}
    </GradientText>
  );
};

export interface BuyLevelUpModalProps {
  reward: Reward;
  onClose: (ev: any) => void | (() => void);
}
export default function BuyLevelUpModal({
  onClose,
  reward,
}: BuyLevelUpModalProps) {
  const progression = useAppSelector((state) => state.progression);

  const levels = useAppSelector((state) => state.app.levels);
  const store = useAppSelector((state) => state.app.store);
  const nextLevel = progression.level + 1;
  const cost =
    store.fastTrackProgression.xpCost *
    (levels[progression.level].experienceRequired - progression.experience);

  const upcomingUnlocks = useAppSelector(
    (state) => state.app.unlockable.items
  ).filter((unlockable) => unlockable.unlock.level === progression.level + 1);
  return (
    <Modal
      onClose={onClose}
      borderRadius={"3em"}
      sx={{
        background: "#89023E",
        color: "#FFD700",
        borderRadius: "3em",
      }}
      dialogTitle={
        <GradientText
          sx={{
            fontSize: "3rem",
            background: "linear-gradient(180deg, #FFD700 35%, #89023E)",
            WebkitTextStrokeColor: "#FFD700",
          }}
        >
          Fast Track Progression
        </GradientText>
      }
      dialogHeaderContent={
        <Typography sx={{ verticalAlign: "middle", display: "flex" }}>
          Spend{" "}
          <AcUnitIcon
            sx={{ marginLeft: 1, marginTop: 0.1, marginRight: -0.1 }}
            fontSize="small"
          />
          {cost} to go to level {nextLevel}
        </Typography>
      }
      dialogBodyContent={
        <>
          <GradientText
            sx={{
              fontSize: "2rem",
              background: "linear-gradient(180deg, #FFD700 35%, #89023E)",
              WebkitTextStrokeColor: "#FFD700",
              WebkitTextStrokeWidth: "1px",
            }}
          >
            Level {nextLevel} Rewards
          </GradientText>
          <Grid
            sx={{ marginBottom: 4 }}
            xs={12}
            spacing={6}
            container
            justifyContent={"center"}
            alignItems="center"
          >
            {levels[progression.level].rewards.map((reward, index) => (
              <Grid key={index} item>
                <RewardItem color="#FFD700" {...reward} />
              </Grid>
            ))}
          </Grid>
          <Divider />

          <StatHeader>Next Level Unlocks Chance To Get</StatHeader>
          {upcomingUnlocks.length > 0 && (
            <>
              <Grid justifyContent="space-evenly" alignItems="center" container>
                {upcomingUnlocks.map((unlockable) => {
                  return (
                    <Grid key={unlockable.id} item>
                      <Tooltip title={unlockable.name}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 0,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              maxWidth: 50,
                              borderRadius: 50,
                              marginRight: 4,
                            }}
                            src={`/assets/unlockable/thumbnail/${unlockable.id}.jpg`}
                          />
                        </Typography>
                      </Tooltip>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </>
      }
      dialogActions={
        <>
          <Clickable>
            <Button
              onClick={onClose}
              size="large"
              color="info"
              variant="contained"
              autoFocus
              sx={{
                fontSize: "2em",
                letterSpacing: "4px",
                overflow: "hidden",
                borderRadius: "1.5em 0 0 0",
                right: "-8px",
                bottom: "-8px",
                color: grey[400],
                background: grey[600],
              }}
            >
              Close
            </Button>
          </Clickable>
          <Clickable>
            <BuyButton
              label="Claim"
              onClick={onClose}
              sx={{
                fontSize: "2em",
                letterSpacing: "4px",
                borderRadius: "0 0 1.5em 0",
                right: "-8px",
                bottom: "-8px",
                left: 2,
              }}
            ></BuyButton>
          </Clickable>
        </>
      }
    />
  );
}
