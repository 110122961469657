import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Game } from "lib/data/slice";

interface SpotlightState {
  queryTarget: string;
  offset: { left: number; top: number; size: number };
  message: { text: string; position: "right" | "top" | "bottom" };
}
interface NavigationState {
  isInGame: boolean;
  lastGame?: Game;
  isLeavingGame: boolean;
  showNavCurrencyPeekThrough: boolean;
  canAnimate: boolean;
  isMusicPlaying: boolean;
  spotlight: SpotlightState;
  backgroundTransition: {
    initialPosition: {
      left: number;
      top: number;
      width: number;
      height: number;
    };
    backgroundId: string;
    transitionState: "entering" | "exiting";
  };
}

// Define the initial state using that type
const initialState: NavigationState = {
  isInGame: false,
  isLeavingGame: false,
  showNavCurrencyPeekThrough: false,
  canAnimate: false,
  isMusicPlaying: false,
  backgroundTransition: {
    initialPosition: { left: 0, top: 0, width: 0, height: 0 },
    backgroundId: "",
    transitionState: "entering",
  },
  spotlight: {
    queryTarget: "",
    message: { text: "", position: "right" },
    offset: { left: 0, top: 0, size: 0 },
  },
};
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setIsInGame: (state, action: PayloadAction<boolean>) => {
      state.isInGame = action.payload;
    },
    setIsLeavingGame: (state, action: PayloadAction<boolean>) => {
      state.isLeavingGame = action.payload;
    },
    setLastGame: (state, action: PayloadAction<Game>) => {
      state.lastGame = action.payload;
    },
    setNavCurrencyPeekThrough: (state, action: PayloadAction<boolean>) => {
      state.showNavCurrencyPeekThrough = action.payload;
    },
    setCanAnimate: (state, action: PayloadAction<boolean>) => {
      state.canAnimate = action.payload;
    },
    setIsMusicPlaying: (state, action: PayloadAction<boolean>) => {
      state.isMusicPlaying = action.payload;
    },
    setSpotlight: (
      state,
      action: PayloadAction<Optional<SpotlightState, "offset">>
    ) => {
      state.spotlight = { ...state.spotlight, ...action.payload };
    },
    setBackgroundTransition: (
      state,
      action: PayloadAction<
        | Partial<NavigationState["backgroundTransition"]>
        | NavigationState["backgroundTransition"]
      >
    ) => {
      for (const key in action.payload) {
        const property = key as keyof NavigationState["backgroundTransition"];
        if (property in state.backgroundTransition) {
          // @ts-ignore
          state.backgroundTransition[property] = action.payload[property];
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsInGame,
  setLastGame,
  setIsLeavingGame,
  setNavCurrencyPeekThrough,
  setCanAnimate,
  setIsMusicPlaying,
  setSpotlight,
  setBackgroundTransition,
} = navigationSlice.actions;

export default navigationSlice.reducer;
