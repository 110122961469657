import { ChallengeLevel, GameTypes, SelectableGame } from "./slice";
// @ts-ignore
import rwc from "random-weighted-choice";
import { v4 as uuidv4 } from "uuid";
import store from "store";
import { Rarity } from "lib/data/slice";
const GAME_AMOUNT = 4;

const rarityWeightingByChallengeLevel: {
  [key in ChallengeLevel]: { id: Rarity; weight: number }[];
} = {
  1: [
    { id: 0, weight: 60 },
    { id: 1, weight: 25 },
    { id: 2, weight: 15 },
    { id: 3, weight: 0 },
    { id: 4, weight: 0 },
  ],
  2: [
    { id: 0, weight: 45 },
    { id: 1, weight: 30 },
    { id: 2, weight: 20 },
    { id: 3, weight: 5 },
    { id: 4, weight: 0 },
  ],
  3: [
    { id: 0, weight: 30 },
    { id: 1, weight: 40 },
    { id: 2, weight: 24 },
    { id: 3, weight: 5 },
    { id: 4, weight: 1 },
  ],
  4: [
    { id: 0, weight: 25 },
    { id: 1, weight: 42 },
    { id: 2, weight: 25 },
    { id: 3, weight: 6 },
    { id: 4, weight: 2 },
  ],
  5: [
    { id: 0, weight: 20 },
    { id: 1, weight: 40 },
    { id: 2, weight: 30 },
    { id: 3, weight: 7 },
    { id: 4, weight: 3 },
  ],
  6: [
    { id: 0, weight: 15 },
    { id: 1, weight: 35 },
    { id: 2, weight: 35 },
    { id: 3, weight: 10 },
    { id: 4, weight: 5 },
  ],
  7: [
    { id: 0, weight: 10 },
    { id: 1, weight: 30 },
    { id: 2, weight: 35 },
    { id: 3, weight: 15 },
    { id: 4, weight: 10 },
  ],
  8: [
    { id: 0, weight: 10 },
    { id: 1, weight: 25 },
    { id: 2, weight: 30 },
    { id: 3, weight: 20 },
    { id: 4, weight: 15 },
  ],
  9: [
    { id: 0, weight: 10 },
    { id: 1, weight: 20 },
    { id: 2, weight: 25 },
    { id: 3, weight: 25 },
    { id: 4, weight: 20 },
  ],
  10: [
    { id: 0, weight: 5 },
    { id: 1, weight: 15 },
    { id: 2, weight: 20 },
    { id: 3, weight: 30 },
    { id: 4, weight: 30 },
  ],
};

export default function generateSelectableGames(
  idPool: string[]
): SelectableGame[] {
  const state = store.getState();
  const unlockablesByRarity: {
    0: string[];
    1: string[];
    2: string[];
    3: string[];
    4: string[];
  } = state.app.unlockable.items.reduce(
    (acc, unlockable) => {
      if (idPool.includes(unlockable.id)) {
        acc[unlockable.rarity].push(unlockable.id);
      }

      return acc;
    },
    {
      0: [] as string[],
      1: [] as string[],
      2: [] as string[],
      3: [] as string[],
      4: [] as string[],
    }
  );
  let selectableGames: SelectableGame[] = [];
  for (let i = 0; i < GAME_AMOUNT; i++) {
    const raritySet =
      rarityWeightingByChallengeLevel[state.progression.challengeLevel];

    const rwcSet = raritySet.map((rarity) => {
      if (unlockablesByRarity[rarity.id].length === 0) {
        rarity.weight = 0;
      }
      return rarity;
    });
    console.log(rwcSet);
    const rarity: Rarity = rwc(rwcSet);
    const possibleIds = unlockablesByRarity[rarity];
    const cardId = possibleIds[(possibleIds.length * Math.random()) << 0];
    let type: GameTypes = "words";
    switch (i) {
      case 0:
        type = "words";
        break;
      case 1:
        type = "equations";
        break;
      case 2:
        type = "letters";
        break;
      case 3:
        type = "numbers";
    }
    selectableGames.push({
      id: uuidv4(),
      type,
      cardId,
    });
  }
  return selectableGames;
}
