import * as React from "react";

import { Grid } from "@mui/material";
import { green } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { keyframes } from "@mui/system";
import FlashCard from "lib/agility/components/FlashCard";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import playSound from "lib/audio/sfx";
import { ChallengePossibility, Reward } from "lib/data/slice";
import { searchWord } from "lib/data/static/word-association-service";
import Game from "lib/game/components/Game";
import perfectOccurred from "lib/game/perfectOccurred";
import useCommonGameControls from "lib/game/useCommonGameControls";
import { useAppDispatch } from "lib/hooks/react-redux";
import { award, useFreebie } from "lib/progression/slice";
import generateTokenFromRuleData from "lib/token/generateTokenFromRuleData";
import GradientText from "lib/typography/components/GradientText";
import { shuffleArray } from "lib/utils/array";
import theme from "theme";
import determineGlowRarity from "../determineGlowRarity";

interface LettersProps {
  challenges: ChallengePossibility[];
  currentChallenge: string;
  onNextChallenge: () => void;
  onChallengeSuccess: (params: {
    hits: number;
    perfects: number;
    itemsDiscovered: Reward[];
    gameBackground: string;
  }) => void;
  answerPadOptions: string[];
  challengeLevelIndex: number;
}
const inputEntrance = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(100px) scale(0.8);
    
  }
  to {
    opacity: 1;
    transform: translateY(0px) scale(1) ease-out;
    
  }
`;

const ChallengeInput = styled(TextField)(({ theme }) => ({}));
let completionTimeout = 0;
export default function Letters({
  challenges,
  currentChallenge,
  onChallengeSuccess,
  onNextChallenge,
  answerPadOptions,
  challengeLevelIndex,
}: LettersProps) {
  const {
    navigate,
    perfectStreak,
    setPerfectStreak,
    progression,
    mousePosition,
    setMousePosition,
    gameReady,
    setGameReady,
    stopWatchPercent,
    setStopWatchPercent,
    isProcessingRound,
    setIsProcessingRound,
    hits,
    setHits,
    perfects,
    setPerfects,
    itemsDiscovered,
    setItemsDiscovered,
    totalWordsLength,
    setTotalWordsLength,
    isMobile,
    isComplete,
    setIsComplete,
    renderableChallenges,
    setRenderableChallenges,
    validAnswer,
    setValidAnswer,
    lastValidAnswer,
    setLastValidAnswer,
    lastGlowRarity,
    setLastGlowRarity,
    currentCard,
  } = useCommonGameControls();
  const dispatch = useAppDispatch();

  const index = challenges.findIndex(
    (challenge) => challenge.id === currentChallenge
  );

  if (!currentCard) {
    navigate("/");
    return null;
  }

  React.useEffect(() => {
    if (gameReady) {
      let timer = window.setInterval(() => {
        if (stopWatchPercent > 0) {
          setStopWatchPercent((time) => time - 2);
        } else {
          setLastValidAnswer("");
          setIsProcessingRound(true);
        }
      }, 100);
      if (isProcessingRound) {
        window.clearInterval(timer);

        if (index < challenges.length - 1) {
          window.setTimeout(() => {
            onNextChallenge();
            setStopWatchPercent(100);
            setIsProcessingRound(false);
            setLastGlowRarity(-1);
          }, 250);
        }
      }
      return () => {
        window.clearInterval(timer);
        completionTimeout = 0;
      };
    }
  }, [
    gameReady,

    stopWatchPercent,
    isProcessingRound,
    hits,
    perfects,
    itemsDiscovered,
    currentChallenge,
    challenges.length,
  ]);
  React.useEffect(() => {
    if (
      completionTimeout === 0 &&
      isProcessingRound &&
      index === challenges.length - 1
    ) {
      console.log(completionTimeout);
      setIsComplete(perfects === challenges.length ? 2 : 1);
      completionTimeout = window.setTimeout(() => {
        onChallengeSuccess({
          hits,
          perfects,
          itemsDiscovered,
          gameBackground: progression.currentCardId,
        });
      }, 2000);
    }
  }, [index, challenges.length, isProcessingRound]);

  const wordsPerfectOccurred = (fromFreebie = true) => {
    perfectOccurred({
      perfectStreak,
      setPerfectStreak,
      perfects,
      setPerfects,
      currentCard,
      challengeLevelIndex,
      setItemsDiscovered,
      setLastGlowRarity,
      mousePosition,
      itemsDiscovered,
      dispatch,
    });
    if (fromFreebie) {
      setTotalWordsLength(totalWordsLength + 10);
    }
  };

  const onFormSubmit = (isValid: boolean, characterBonus: number = 0) => {
    if (isValid && !isProcessingRound) {
      console.log("correct");
      if (stopWatchPercent >= 40) {
        wordsPerfectOccurred(false);
      } else {
        setPerfectStreak(0);
        playSound("streak-1");
        if (currentCard) {
          const rewards = [
            generateTokenFromRuleData(currentCard, "hit", challengeLevelIndex),
          ];
          dispatch(
            award({
              rewards: rewards,
              dateString: new Date().toISOString(),
            })
          );
          setItemsDiscovered([...itemsDiscovered, ...rewards]);
          setLastGlowRarity(determineGlowRarity(rewards));
          runAwardAnimation(
            rewards,
            "agilityCenter",
            {
              x: mousePosition.x,
              y: mousePosition.y,
            },
            { goToEnd: 30 }
          );
        }
        setHits(hits + 1);
      }
      setTotalWordsLength(totalWordsLength + characterBonus);

      setValidAnswer("");
      setLastValidAnswer("");
      setIsProcessingRound(true);
    } else if (!isValid) {
      setPerfectStreak(0);
      console.log("incorrect");
      setIsProcessingRound(true);
      setValidAnswer("");
      setLastValidAnswer("");
    }
    return false;
  };

  return (
    <Game
      challenges={challenges}
      challengeIndex={index}
      stopWatchPercent={stopWatchPercent}
      type="words"
      answerPad={{
        onMousePositionUpdate: ({ x, y }) => {
          setMousePosition({ x, y });
        },
        onUseFreebie: () => {
          if (progression.freebies > 0 && gameReady && !isProcessingRound) {
            dispatch(useFreebie());
            wordsPerfectOccurred();

            setIsProcessingRound(true);
          }
        },
        options: answerPadOptions,
        onAnswerSelect: async (selectedLetter: string) => {
          const challenge = challenges[index];
          const query = challenge.question.replace("_", selectedLetter);
          const ourAnswer = challenge.answer;
          if (query === ourAnswer) {
            setValidAnswer(query);
            setLastValidAnswer(query);
            onFormSubmit(true, query.length);
            return;
          }
          console.log("Challenge:", challenge);
          console.log("Searching:", query);
          let results = [];
          try {
            results = await searchWord(query);
            console.log("try 1:", results);
          } catch (e) {
            console.log(e);
          }

          if (results.length > 0) {
            setValidAnswer(query);
            setLastValidAnswer(query);
            onFormSubmit(true, query.length);
            return;
          }

          setValidAnswer("");
          onFormSubmit(false);
        },
      }}
      onGameReady={() => setGameReady(true)}
      isComplete={isComplete}
    >
      {" "}
      <form
        id="word-challenge-form"
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          width: "100%",
          flex: 1,
          maxWidth: isMobile ? "100%" : "60%",
        }}
      >
        {challenges.map((challenge, idx) => {
          let answerRenderable;
          const index = challenges.findIndex(
            (chal) => chal.id === currentChallenge
          );
          if (challenge.id === currentChallenge && !isProcessingRound) {
            answerRenderable = (
              <ChallengeInput
                key={`${currentChallenge}`}
                id={`challenge-${idx}`}
                variant="standard"
                autoComplete="off"
                autoFocus
                onChange={async (ev) => {
                  if ((ev.nativeEvent as InputEvent).data == ";") {
                    ev.target.value = "";

                    setValidAnswer("");

                    return;
                  }
                  const query = `${ev.target.value}`;
                  console.log("Searching:", query);
                  let results = [];
                  try {
                    results = await searchWord(query);
                    console.log("1st try:", results.length);
                  } catch (e) {
                    console.log(e);
                  }

                  if (
                    challenge.question === query ||
                    query === "" ||
                    query.length < 3
                  ) {
                    console.log("1st fail");
                    setValidAnswer("");
                    return;
                  }
                  if (results.indexOf(challenge.question) > -1) {
                    console.log("1st valid");
                    setValidAnswer(query);
                    if (query.length > lastValidAnswer.length) {
                      setLastValidAnswer(query);
                    }
                    return;
                  } else {
                    console.log("searching...");
                    const results2 = await searchWord(challenge.question);
                    console.log("2nd try:", results2.length);
                    if (results2.indexOf(query) > -1) {
                      console.log("2nd valid");
                      setValidAnswer(query);
                      if (query.length > lastValidAnswer.length) {
                        setLastValidAnswer(query);
                      }
                      return;
                    }

                    for (const result of results) {
                      const graceResults = await searchWord(result);
                      console.log("3rd try:", graceResults.length);
                      if (graceResults.indexOf(challenge.question) > -1) {
                        console.log("3rd valid");
                        setValidAnswer(query);
                        if (query.length > lastValidAnswer.length) {
                          setLastValidAnswer(query);
                        }
                        return;
                      }
                    }
                  }

                  setValidAnswer("");
                }}
                sx={{
                  display: "none",
                  width: "100%",
                  fontSize: "5em",
                  "& .MuiInputLabel-root, input": {
                    fontSize: "5em",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, 0.6)",
                    // validAnswer
                    //   ? validAnswer === lastValidAnswer ||
                    //     validAnswer.length > lastValidAnswer.length
                    //     ? green[400]
                    //     : blue[400]
                    //   : "rgba(0, 0, 0, 0.6)",
                    position: "relative",
                    outline: "none",
                    transition: "color 0.4s cubic-bezier(0, 1.21, 1, 1.00)",
                  },
                  "& .MuiInput-root:after": {
                    // borderColor: validAnswer
                    //   ? `${
                    //       validAnswer === lastValidAnswer ||
                    //       validAnswer.length > lastValidAnswer.length
                    //         ? green[400]
                    //         : blue[400]
                    //     }`
                    //   : "#89023E",
                    transition:
                      "border-color 0.4s cubic-bezier(0, 1.21, 1, 1.00)",
                  },
                  "& .MuiInput-root:before": {
                    borderBottomColor: "rgba(255, 255, 255, 0.42)",
                  },
                  "&:after": {
                    content: '""',
                    top: 0,
                    left: 0,
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    // boxShadow: `0 0 0.4em 0.01em ${
                    //   validAnswer === lastValidAnswer ||
                    //   validAnswer.length > lastValidAnswer.length
                    //     ? green[400]
                    //     : blue[400]
                    // }`,
                    transition: "opacity 0.4s cubic-bezier(0, 1.21, 1, 1.00)",
                    opacity: validAnswer === "" ? 0 : 1,
                    pointerEvents: "none",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            );
          }

          if (idx > index) {
            return null;
          }
          return (
            <>
              <FlashCard
                key={`${idx}`}
                elevation={1}
                isLeaving={challenge.id !== currentChallenge}
                glowRarity={isProcessingRound ? lastGlowRarity : -1}
                hiding={!gameReady}
              >
                <Typography gutterBottom variant="h5" component="div">
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Grid
                      item
                      sx={{
                        alignItems: "center",
                        fontSize: "4em",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        [theme.breakpoints.down("md")]: {
                          fontSize: `${
                            challenge.question.length > 5
                              ? 4 - challenge.question.length / 8
                              : 4
                          }em`,
                        },
                      }}
                    >
                      <GradientText
                        sx={{
                          fontFamily: "Open Sans",
                          fontSize: "1em",
                          background:
                            "linear-gradient(180deg, #fafafa 35%, #222)",
                          WebkitTextStrokeColor: "#fafafa",
                        }}
                      >
                        {challenge.question}
                      </GradientText>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "4em",
                      }}
                    >
                      {answerRenderable}
                    </Grid>
                  </Grid>
                </Typography>
                {!answerPadOptions && lastValidAnswer && (
                  <Paper
                    elevation={0}
                    sx={{
                      position: "relative",
                      width: "100%",
                      left: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GradientText
                      sx={{
                        fontSize: "4em",
                        background: `linear-gradient(180deg, ${green[500]} 35%, transparent)`,
                        WebkitTextStrokeColor: green[500],
                      }}
                    >
                      {lastValidAnswer}
                    </GradientText>
                  </Paper>
                )}
              </FlashCard>
            </>
          );
        })}
      </form>
    </Game>
  );
}
