var dict;
var lookup_tbl = {};
export async function initialize() {
  var dictFiles = [];
  var dict_urls = [
    "/associations.ifo",
    "/associations.idx.dz",
    "/associations.dict.dz",
  ];
  var nRequest = new Array();
  for (var i = 0; i < dict_urls.length; i++) {
    (function (i) {
      nRequest[i] = new XMLHttpRequest();
      nRequest[i].open("GET", dict_urls[i], true);
      nRequest[i].responseType = "arraybuffer";
      nRequest[i].onload = function () {
        var blob = new Blob([nRequest[i].response], { type: dict_urls[i] });
        dictFiles.push(blob);
        if (dictFiles.length == 3) loadDict();
      };
      nRequest[i].send();
    })(i);
  }

  function loadDict() {
    dict = new StarDict();

    dict
      .load(dictFiles)
      .then(function () {
        return dict.index({
          include_offset: true,
          include_dictpos: true,
          include_term: true,
        });
      })
      .then(function (idx) {
        for (var i = 0, len = idx.length; i < len; i++) {
          lookup_tbl[idx[i].term] = idx[i];
        }
      });
  }

  function searchDict(searchTerm) {}
}

export async function searchWord(searchTerm) {
  return new Promise((resolve, reject) => {
    if (typeof dict == "undefined" || typeof lookup_tbl == "undefined") {
      reject("Dictionary not loaded");
    }
    var idx_obj = lookup_tbl[searchTerm];
    if (typeof idx_obj != "undefined") {
      dict
        .entry(idx_obj.dictpos)
        .then(function (entry) {
          var entriesArray = entry.split(",");

          resolve(entriesArray);
        })
        .catch((e) => reject(e));
    } else {
      reject("Word not found");
    }
  });
}

export function randomWord(characterLength) {
  //debugger;
  var idx_obj = lookup_tbl["ok"];
  console.log(dict);
  return "dog";
  if (typeof idx_obj != "undefined")
    dict.entry(idx_obj.dictpos).then(function (entry) {
      var entriesArray = entry.split(",");

      resolve(entriesArray[0]);
    });
}
