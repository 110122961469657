import {
  AvatarGroup,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  SxProps,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { blue, green, grey, purple, yellow } from "@mui/material/colors";
import Clickable from "lib/audio/components/Clickable";
import { Unlockable } from "lib/data/slice";
import { useAppDispatch } from "lib/hooks/react-redux";
import { pushModal } from "lib/modal/slice";
import GradientText from "lib/typography/components/GradientText";
import React from "react";
import theme from "theme";
import rarityBasedFrames from "../rarityBasedFrames";
import CategoryBadge from "./CategoryBadge";

export default function CardTile({
  unlockable,
  unlockDate,
  sx,
  onClick,
  withBadges,
}: {
  unlockable: Unlockable;
  unlockDate: string;
  sx?: SxProps<Theme> | undefined;
  onClick?: () => void;
  withBadges?: boolean;
}) {
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  sx = sx || {};
  return (
    <Clickable>
      <>
        <Card
          sx={{
            width: 240,
            height: 212,
            border:
              (unlockDate && rarityBasedFrames[unlockable.rarity].border) ||
              "none",
            background: "linear-gradient(0deg,#89023e , hsl(333deg 97% 21%))",

            backdropFilter: "blur( 0px )",
            "&:after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              width: 240,
              height: 212,
              zIndex: 1,
              border: "40px solid transparent",
              borderImage:
                (unlockDate &&
                  rarityBasedFrames[unlockable.rarity].borderImage) ||
                "none",
              borderImageWidth:
                (unlockDate &&
                  rarityBasedFrames[unlockable.rarity].borderImageWidth) ||
                "none",

              pointerEvents: "none",
              [theme.breakpoints.down("md")]: {
                width: screen.availWidth / 2 - 20,
              },
            },
            ...sx,

            [theme.breakpoints.down("md")]: {
              width: screen.availWidth / 2 - 20,
            },
          }}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              if (unlockDate) {
                dispatch(
                  pushModal({
                    componentName: "UnlockableDetails",
                    props: { unlockable },
                  })
                );
              }
            }
          }}
        >
          <CardActionArea
            sx={{
              cursor: unlockDate ? "pointer" : "default",
            }}
          >
            <CardMedia
              component="img"
              height="140"
              image={`/assets/unlockable/thumbnail/${unlockable.id}.jpg`}
              alt={unlockDate ? unlockable.name : "Locked"}
              sx={{
                filter: unlockDate ? "" : "grayscale(100%)",
                objectFit: "contain",
                objectPosition: withBadges ? "left" : "center",
                [theme.breakpoints.down("md")]: {
                  objectPosition: "center",
                },
              }}
            />
            <CardContent sx={{ background: unlockDate ? "#89023E" : "#222" }}>
              <GradientText
                gutterBottom
                variant="h5"
                sx={{
                  whiteSpace: "nowrap",
                  WebkitTextStrokeWidth: "1px",
                  textAlign: "center",
                  lineHeight: "2.1rem",
                  fontSize: unlockable.name.length > 15 ? "1.2rem" : "1.5rem",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "clamp(0.8em, 6vw, 1.2em)",
                  },
                }}
              >
                {unlockDate ? unlockable.name : "Locked"}
              </GradientText>
            </CardContent>
          </CardActionArea>
          {!isMobile && withBadges && (
            <Box
              sx={{
                left: "calc(50% + 2em)",
                top: "1em",
                right: "1em",
                bottom: "4em",
                position: "absolute",
                display: "flex",
                marginLeft: "auto",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {unlockable.categories.map((category) => (
                <Box
                  sx={{
                    flex: "50%",
                    height: "1em",
                    "&:nth-child(even)": {
                      display: "flex",
                      justifyContent: "flex-end",
                    },
                  }}
                >
                  <CategoryBadge
                    sx={{
                      position: "absolute",
                      "&:hover": {
                        zIndex: 2,
                      },
                    }}
                    size="medium"
                    name={category}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Card>
        {isMobile && withBadges && (
          <AvatarGroup
            max={6}
            sx={{
              display: "flex",
              marginLeft: "auto",
              justifyContent: "center",
            }}
          >
            {unlockable.categories.map((category) => (
              <CategoryBadge size="small" name={category} />
            ))}
          </AvatarGroup>
        )}
      </>
    </Clickable>
  );
}
