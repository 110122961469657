import * as React from "react";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import { useFreebie } from "lib/progression/slice";
import { useNavigate } from "react-router-dom";
import { ChallengePossibility, Reward } from "lib/data/slice";
import { useMediaQuery } from "@mui/material";
import theme from "theme";

interface useCommonGameControlsProps {}

export default function useCommonGameControls() {
  const navigate = useNavigate();
  const [perfectStreak, setPerfectStreak] = React.useState(0);
  const progression = useAppSelector((state) => state.progression);
  const unlockables = useAppSelector((state) => state.app.unlockable.items);
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });
  const [gameReady, setGameReady] = React.useState(false);

  const [stopWatchPercent, setStopWatchPercent] = React.useState(100);

  const [isProcessingRound, setIsProcessingRound] = React.useState(false);
  const [hits, setHits] = React.useState(0);
  const [perfects, setPerfects] = React.useState(0);
  const [itemsDiscovered, setItemsDiscovered] = React.useState<Reward[]>([]);
  const [totalWordsLength, setTotalWordsLength] = React.useState(0);

  const [isComplete, setIsComplete] = React.useState(0);
  const [renderableChallenges, setRenderableChallenges] = React.useState<
    ChallengePossibility[]
  >([]);
  const [validAnswer, setValidAnswer] = React.useState("");
  const [lastValidAnswer, setLastValidAnswer] = React.useState("");

  const [lastGlowRarity, setLastGlowRarity] = React.useState(-1);

  const currentCard = unlockables.find(
    (unlockable) => unlockable.id === progression.currentCardId
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return {
    navigate,
    perfectStreak,
    setPerfectStreak,
    progression,
    mousePosition,
    setMousePosition,
    gameReady,
    setGameReady,
    stopWatchPercent,
    setStopWatchPercent,
    isProcessingRound,
    setIsProcessingRound,
    hits,
    setHits,
    perfects,
    setPerfects,
    itemsDiscovered,
    setItemsDiscovered,
    totalWordsLength,
    setTotalWordsLength,
    isMobile,
    isComplete,
    setIsComplete,
    renderableChallenges,
    setRenderableChallenges,
    validAnswer,
    setValidAnswer,
    lastValidAnswer,
    setLastValidAnswer,
    lastGlowRarity,
    setLastGlowRarity,
    currentCard,
  };
}
