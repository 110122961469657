import { Reward } from "lib/data/slice";
import runAward from "./RewardCanvas";

export default function runAwardAnimation(
  rewards: Reward[],
  startPoint?: "agilityCenter" | { x: number; y: number },
  endPoint?: { x: number; y: number },
  frameChange?: { goToEnd: number },
  isMobile?: boolean
) {
  for (let reward of rewards) {
    let assetUrl = "";
    switch (reward.type) {
      case "basic_currency":
        assetUrl = "/assets/particle/basic_currency_particle.svg";
        break;
      case "premium_currency":
        assetUrl = "/assets/particle/premium_currency_particle.svg";
        break;
      case "background":
        assetUrl = `/assets/unlockable/thumbnail/${reward.id}.jpg`;
        break;
      case "freebies":
        assetUrl = "/assets/particle/freebie_particle.svg";
        break;
      case "pack":
        assetUrl = "/assets/particle/pack_particle.png";
        break;
      case "experience":
        assetUrl = "/assets/particle/experience_particle.svg";
        break;
      case "zen_experience":
        assetUrl = "/assets/particle/zen_experience_particle.svg";
        break;
    }

    if (reward.type.indexOf("token_currency") > -1) {
      assetUrl = `/assets/particle/token/token_currency_${
        reward.type.split("token_currency_")[1]
      }.svg`;
    }

    if (assetUrl) {
      runAward(
        reward.amount,
        assetUrl,
        startPoint === "agilityCenter"
          ? { x: window.innerWidth / 2, y: window.innerHeight / 2 - 100 }
          : startPoint,
        endPoint,
        frameChange,
        isMobile
      );
    }
  }
}
