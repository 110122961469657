import { Unlockable } from "lib/data/slice";
import store from "store";

export default function generateTokenFromRuleData(
  currentCard: Unlockable,
  type: "hit" | "perfect",
  challengeLevelIndex: number
) {
  const challengeRules = store.getState().app.challenge.rules;
  const tokenRewardType =
    currentCard.categories[
      (currentCard.categories.length * Math.random()) << 0
    ];
  const tokenRewardRule = challengeRules.perfectRewards.find(
    (rule) => rule.type === "token_currency"
  );
  if (!tokenRewardRule) {
    throw new Error("No token reward rule found");
  }
  console.log(
    "??",
    tokenRewardRule.amount[currentCard.rarity] +
      challengeLevelIndex +
      (type === "perfect" ? challengeLevelIndex : 0)
  );
  return {
    type: `token_currency_${tokenRewardType}`,
    amount:
      tokenRewardRule.amount[currentCard.rarity] +
      challengeLevelIndex +
      (type === "perfect" ? challengeLevelIndex : 0),
  };
}
