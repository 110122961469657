import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";

import clsx from "clsx";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Clickable from "lib/audio/components/Clickable";
import * as React from "react";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import GradientText from "lib/typography/components/GradientText";
import CategoryBadge, {
  iconsByCategoryName,
} from "lib/card/components/CategoryBadge";
import { Reward, Unlockable } from "lib/data/slice";
import AvatarGroup from "@mui/material/AvatarGroup";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, IconProps, Tooltip } from "@mui/material";
import {
  ChallengeLevel,
  setCurrentCardId,
  setTutorialStep,
} from "lib/progression/slice";
import {
  buyBasicCurrency,
  setSelectableGames,
  clearSelectableGames,
  setChallengeLevel,
  charge,
} from "lib/progression/slice";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import theme from "theme";
import { useMediaQuery } from "@mui/material";
import { pushModal } from "../slice";
import playSound from "lib/audio/sfx";
import generateSelectableGames from "lib/progression/generateSelectableGames";
import rarityBasedFrames from "lib/card/rarityBasedFrames";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { green, grey, lightGreen, red } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import AbcOutlinedIcon from "@mui/icons-material/AbcOutlined";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import { AutoModeCardIcon } from "lib/design-system/components/AutoModeIcon";
import UploadIcon from "@mui/icons-material/Upload";

import SportsScoreIcon from "@mui/icons-material/SportsScore";
import GameSelectCard from "lib/card/components/GameSelectCard";
import { setSpotlight } from "lib/navigation/slice";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className, "BackdropRoot")}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10001;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-tap-highlight-color: transparent;
`;

const CardLink = styled(Link)``;

export interface GameSelectModalProps {
  onClose: (ev: any) => void | (() => void);
}
export default function GameSelectModal({ onClose }: GameSelectModalProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const progression = useAppSelector((state) => state.progression);
  const unlockables = useAppSelector((state) => state.app.unlockable.items);
  const games = useAppSelector((state) => state.app.games);
  const [showNextAdvanceTooltip, setShowNextAdvanceTooltip] =
    React.useState(false);
  const [chestStatus, setChestStatus] = React.useState<"open" | "closed">(
    "closed"
  );
  const selectableGames = progression.selectableGames;
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    window.setTimeout(() => {
      setHasLoaded(true);
    }, 3000);
    if (selectableGames.length === 0) {
      dispatch(
        setSelectableGames(
          generateSelectableGames(Object.keys(progression.unlocks))
        )
      );
    }

    if (progression.tutorialStep === 3) {
      dispatch(
        setSpotlight({
          queryTarget: ".advance-button",
          message: {
            text: "Advance to increase the difficulty of your next game",
            position: "top",
          },
        })
      );
    }

    return () => {
      if (progression.tutorialStep === 3) {
        dispatch(setTutorialStep(4));
        dispatch(
          setSpotlight({
            queryTarget: "",
            message: {
              text: "",
              position: "top",
            },
          })
        );
      }
    };
  }, []);

  React.useEffect(() => {
    playSound(`bet-${progression.challengeLevel - 1}`);
  }, [progression.challengeLevel]);

  const advanceThresholds = [
    0, 200, 400, 750, 1000, 1500, 2000, 3000, 5000, 7500, 10000,
  ];
  const advanceCost = advanceThresholds[progression.challengeLevel];
  const canAffordAdvance = advanceCost < progression.currency.basic;

  const rerollCost = (Math.round(advanceCost / 100) * 100) / 2;
  const canAffordReroll = rerollCost < progression.currency.basic;

  // advanceLockThresholds makes it so that you reach the next level of advance every 2 levels. but the first level allows 1 level of advance

  const advanceLockThresholds = advanceThresholds
    .map((threshold) => [threshold, threshold])
    .flat();
  //debugger;
  // Makes it so that you can advance once at level 1 and 2
  advanceLockThresholds[1] = advanceThresholds[1] + 1;
  advanceLockThresholds[2] = advanceThresholds[1] + 1;
  const isAdvanceLocked =
    advanceThresholds[progression.challengeLevel] >=
    advanceLockThresholds[progression.level];
  const nextAdvanceUnlock = progression.level + (progression.level % 2 ? 2 : 1);

  return (
    <Modal
      open
      onClose={onClose}
      onClick={(ev) => {
        console.log({ ev });

        //onClose(ev);
      }}
      components={{ Root: Backdrop }}
    >
      <>
        <Box
          sx={{
            position: "absolute",
            top: "0vh",
            left: "0",
            height: "100%",
            padding: "0 5vw",
            width: "100%",
            zIndex: 11,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "2px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: "0em",
              top: "0em",

              zIndex: 11111,
              [theme.breakpoints.down("md")]: { display: "none" },
            }}
          >
            <IconButton
              sx={{ color: grey[50], padding: "2em" }}
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          <Tooltip
            placement="right"
            title={`Higher Challenge level makes games more difficult`}
          >
            <Box
              key={progression.challengeLevel}
              sx={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba( 255, 255, 255, 0.25 )",
                boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
                backdropFilter: "blur( 4px )",
                border: "1px solid rgba(255,255,255,0.4)",
                borderRadius: "40px",
                position: "relative",
                textShadow: `-2px -2px 0.4em ${red[900]}, 2px -2px 0.4em ${red[900]}, -2px 2px 0.4em ${red[900]}, 2px 2px 0.4em ${red[900]}`,
                "@keyframes GameSelectChallengeLevelEntrance": {
                  "0%": {
                    transform: "scale(2)",
                    filter: "blur(5px)",
                  },
                  "100%": {
                    transform: "scale(1)",
                    filter: "blur(0px)",
                  },
                },
                animation:
                  "GameSelectChallengeLevelEntrance 0.3s ease-in-out forwards",
              }}
            >
              <Box
                sx={{
                  "@keyframes wagerBreathing": {
                    "0%": { filter: "contrast(100%)" },
                    "50%": {
                      transform: "scalex(1.05) scaleY(1.01)",
                      filter: "contrast(200%)",
                    },
                    "100%": {
                      transform: "scalex(1) scaleY(1)",
                      filter: "contrast(100%)",
                    },
                  },
                  animation: "wagerBreathing 1.33s infinite forwards",
                  transformOrigin: "center",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0.2em 1.2em 0.2em 0.6em",
                }}
              >
                <Typography
                  sx={{ display: "inline-block", fontSize: "3em" }}
                ></Typography>
                <FitnessCenterIcon
                  color="secondary"
                  sx={{
                    width: "2em",
                    height: "2em",
                    verticalAlign: "middle",
                    stroke: red[900],
                  }}
                />
                <GradientText sx={{ fontSize: "3em" }}>
                  {progression.challengeLevel}
                </GradientText>
              </Box>
            </Box>
          </Tooltip>
          <GradientText
            sx={{
              display: "inline-block",
              [theme.breakpoints.up("md")]: { fontSize: "2.5em" },
            }}
          >
            Pick a Challenge
          </GradientText>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flex: 1,
              flexDirection: "column",
              gap: 1,
              [theme.breakpoints.up("md")]: { flexDirection: "row", gap: 2 },
            }}
          >
            {selectableGames.map((game, idx) => {
              const hasGoalProgress = false;
              return (
                <GameSelectCard
                  unlockables={unlockables}
                  isMobile={isMobile}
                  game={game}
                  progression={progression}
                  hasLoaded={hasLoaded}
                  idx={idx}
                  onClose={onClose}
                  hasGoalProgress={hasGoalProgress}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1111,
              marginTop: "auto",
              marginBottom: "1em",
              width: "100%",
              gap: 2,
              [theme.breakpoints.up("md")]: {
                maxWidth: "40em",
              },
            }}
          >
            <Button
              sx={{
                mt: 2,
                width: "100%",
                justifyContent: "space-between",

                background: `linear-gradient(222deg, hsl(210 100% 50%), hsl(210 100% 30%) 39%, hsl(210 100% 10%) 40%, hsl(210 100% 10%))`,
                backgroundPosition: "50% 0%",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: -1,
                  background:
                    "linear-gradient(0deg, rgba(0,0,0,0.5), transparent, rgba(255,255,255,0.3), transparent)",
                },
              }}
              onClick={async () => {
                //dispatch(clearSelectableGames());

                if (!canAffordReroll) {
                  dispatch(
                    pushModal({
                      componentName: "BuyBasicCurrency",
                      props: {},
                    })
                  );
                  return;
                }
                playSound("game-select-reroll");
                dispatch(
                  setSelectableGames(
                    generateSelectableGames(Object.keys(progression.unlocks))
                  )
                );
                dispatch(charge({ basic: rerollCost }));
              }}
              variant="contained"
              endIcon={
                <Box sx={{}}>
                  <AutoModeCardIcon
                    sx={{
                      color: canAffordReroll ? "#FFD700" : red[700],
                      width: "3em",
                      height: "3em",
                      [theme.breakpoints.down("md")]: {
                        width: "1em",
                        height: "1em",
                      },
                      //stroke: lightGreen[400],
                    }}
                  />
                </Box>
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "#fafafa",
                  alignItems: "space-between",
                }}
              >
                <Typography
                  sx={{
                    letterSpacing: "1px",
                    fontSize: "2.4em",
                    lineHeight: "1.2",
                    filter: "drop-shadow(2px 2px 2px #222)",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1em",
                    },
                  }}
                >
                  Re-Roll
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    letterSpacing: "2px",
                    fontSize: "1.2em",
                    color: !canAffordReroll ? red[700] : "inherit",
                  }}
                >
                  <AcUnitIcon fontSize="small" sx={{}} />
                  {rerollCost.toLocaleString("en-US")}
                </Box>
              </Box>
            </Button>
            <Tooltip
              open={showNextAdvanceTooltip}
              title={`Next advance at Level ${nextAdvanceUnlock}`}
            >
              <Button
                className="advance-button"
                sx={{
                  mt: 2,
                  width: "100%",
                  justifyContent: "space-between",
                  filter: `${
                    isAdvanceLocked ? "contrast(60%) brightness(41%)" : "none"
                  }`,

                  background: `linear-gradient(222deg, hsl(51deg 100% 50%), hsl(51deg 100% 30%) 39%, hsl(51deg 100% 10%) 40%, hsl(51deg 100% 10%))`,
                  backgroundPosition: "50% 0%",
                  "&:before": {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                    background:
                      "linear-gradient(0deg, rgba(0,0,0,0.5), transparent, rgba(255,255,255,0.3), transparent)",
                  },
                }}
                onClick={async () => {
                  //dispatch(clearSelectableGames());
                  if (progression.tutorialStep === 3) {
                    dispatch(setTutorialStep(4));
                    dispatch(
                      setSpotlight({
                        queryTarget: "",
                        message: {
                          text: "",
                          position: "top",
                        },
                      })
                    );
                  }
                  if (isAdvanceLocked) {
                    setShowNextAdvanceTooltip(true);
                    return;
                  }
                  if (!canAffordAdvance) {
                    dispatch(
                      pushModal({
                        componentName: "BuyBasicCurrency",
                        props: {},
                      })
                    );
                    return;
                  }
                  const newChallengeLevel =
                    progression.challengeLevel < 10
                      ? ((progression.challengeLevel + 1) as ChallengeLevel)
                      : 10;
                  dispatch(setChallengeLevel(newChallengeLevel));
                  dispatch(
                    setSelectableGames(
                      generateSelectableGames(Object.keys(progression.unlocks))
                    )
                  );
                  dispatch(charge({ basic: advanceCost }));
                }}
                variant="contained"
                endIcon={
                  <Box
                    sx={{
                      animation:
                        !canAffordAdvance || isAdvanceLocked
                          ? ""
                          : "wagerBreathing 1.33s infinite forwards",
                    }}
                  >
                    <UploadIcon
                      sx={{
                        color:
                          canAffordAdvance || isAdvanceLocked
                            ? "#FFD700"
                            : red[700],
                        width: "3em",
                        height: "3em",

                        stroke: red[900],
                        [theme.breakpoints.down("md")]: {
                          width: "1em",
                          height: "1em",
                        },
                      }}
                    />
                  </Box>
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#fafafa",
                    alignItems: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      letterSpacing: "1px",
                      fontSize: "2.4em",
                      lineHeight: "1.2",
                      filter: "drop-shadow(2px 2px 2px #222)",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "1em",
                      },
                    }}
                  >
                    Advance
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      letterSpacing: "2px",
                      fontSize: "1.2em",
                      color: !canAffordAdvance ? red[700] : "inherit",
                    }}
                  >
                    <AcUnitIcon fontSize="small" sx={{}} />
                    {advanceCost.toLocaleString("en-US")}
                  </Box>
                </Box>
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </>
    </Modal>
  );
}
