import * as React from "react";

import { Box, useMediaQuery } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import GradientText from "lib/typography/components/GradientText";
import { blue, green, grey, red } from "@mui/material/colors";

import theme from "theme";
export default function Stopwatch(
  props: CircularProgressProps & {
    value: number;
    perfectThreshold?: number;
    isReady?: boolean;
  }
) {
  const perfectThreshold = props.perfectThreshold || 79;
  const gradientTextSx =
    props.value > 79
      ? {
          background: `linear-gradient(180deg, ${grey[200]} 35%, ${grey[900]})`,
          WebkitTextStrokeColor: grey[200],
        }
      : props.value > 19
      ? {
          background: `linear-gradient(180deg, #fafafa 35%, #222)`,
          WebkitTextStrokeColor: "#fafafa",
        }
      : {};

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const renderValue = `${(props.value / 20).toFixed(1)}`;
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: "13.1em",
        marginLeft: "1.6em",
        [theme.breakpoints.down("md")]: {
          position: "relative",
          top: "0em",
          width: "auto",
        },
        "&:before": {
          content: '""',
          position: "absolute",

          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "100%",
          background:
            props.value > 20
              ? "radial-gradient(transparent, #fafafa50, transparent 100%)"
              : "radial-gradient(transparent, #FFD70050, transparent 100%)",
          boxShadow:
            props.value > 20 ? "0px 0px 4px #fafafa" : "0px 0px 4px #FFD700",
          "@keyframes StopWatchPulse": {
            "0%": {
              transform: "scale(0.5)",
              opacity: 0,
            },
            "10%": {
              opacity: 1,
            },
            "50%": {
              transform: "scale(1.1)",
              opacity: 0,
            },
            "100%": {
              transform: "scale(1.2)",
              opacity: 0,
            },
          },
          animation: props.isReady ? "StopWatchPulse 1s infinite" : "",
        },
      }}
    >
      {" "}
      <Paper
        variant="outlined"
        sx={{
          background: "transparent",
          borderRadius: "100%",
          lineHeight: "0",
          border: 0,
          position: "relative",
        }}
      >
        <Box
          sx={{
            top: 1,
            left: 1,
            bottom: 1,
            right: 1,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            background: "transparent",
            "&:after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              borderRadius: "100%",
              background: "rgba( 255, 255, 255, 0.25 )",
              boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
              backdropFilter: "blur( 4px )",
            },
          }}
        ></Box>
        <CircularProgress
          size={isMobile ? screen.availHeight / 5 - 80 : 210}
          thickness={6}
          sx={{
            zIndex: 2,
            "& .MuiCircularProgress-circle": {
              transitionDuration: "0.1s",
              opacity: 1,
              //stroke: "url(#gradient)",
              stroke: grey[50],
              backdropFilter: "blur( 4px )",
            },
            transition: "color 0.1s ease-in-out",
          }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            textAlign: "center",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {[...renderValue].map((char, idx) => (
            <GradientText
              key={idx}
              sx={{
                fontSize: "3.8em",
                fontFeatureSettings: '"tnum"',
                fontVariantNumeric: "tabular-nums",
                width: idx === 1 ? "0.3em" : "0.65em",
                [theme.breakpoints.down("md")]: {
                  fontSize: "2em",
                  WebkitTextStrokeWidth: "0.5px",
                },
                [theme.breakpoints.down("sm")]: { fontSize: "1.4em" },
                ...gradientTextSx,
              }}
            >
              {char}
            </GradientText>
          ))}
        </Box>
      </Paper>
    </Box>
  );
}
