import * as React from "react";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { green, blue, teal, red } from "@mui/material/colors";
import { keyframes } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import GradientText from "lib/typography/components/GradientText";
import Game from "lib/game/components/Game";

interface Challenge {
  question: string;
  answer: string;
}
interface SequencingProps {
  challenges: Challenge[];
  currentChallenge: number;
  onNextChallenge: () => void;
  onChallengeSuccess: (params: { hits: number; perfects: number }) => void;
}

const Corner = ({
  highlight,
  type,
  onClick,
}: {
  highlight?: boolean;
  type: number;
  onClick?: (ev: any) => void;
}) => {
  let background;
  let borderRadius;
  switch (type) {
    case 0:
      background = highlight ? "#1fff75" : "#00b846";
      borderRadius = "5em 5em 0em 5em";
      break;
    case 1:
      background = highlight ? "#ff4f1f" : "#b82800";
      borderRadius = "5em 5em 5em 0";
      break;
    case 2:
      background = highlight ? "#f4ff1f" : "#aeb800";
      borderRadius = "5em 0em 5em 5em";
      break;
    case 3:
      background = highlight ? "#1fa9ff" : "#89023E";
      borderRadius = "0em 5em 5em 5em";
  }
  return (
    <Grid item onClick={onClick}>
      <Paper
        sx={{
          background,
          width: "15em",
          height: "15em",
          borderRadius,
          transition: "background .5s ease-in-out",
          position: "relative",
          cursor: onClick ? "pointer" : "default",
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "inherit",
            opacity: highlight ? 0.25 : 0,
            boxShadow: "0px 0px 12px 0px #222222",
          },
          "&:hover:before": {
            opacity: onClick ? 0.25 : 0,
          },
        }}
      ></Paper>
    </Grid>
  );
};

export default function Sequencing({
  challenges,
  currentChallenge,
  onChallengeSuccess,
  onNextChallenge,
}: SequencingProps) {
  const [gameReady, setGameReady] = React.useState(false);

  const [turn, setTurn] = React.useState(0); // 1 Show sequence 2 Accept input
  const [stopWatchPercent, setStopWatchPercent] = React.useState(100);

  const [isProcessingRound, setIsProcessingRound] = React.useState(false);
  const [gameBackground, setGameBackground] = React.useState("");
  const [hits, setHits] = React.useState(0);
  const [perfects, setPerfects] = React.useState(0);
  const [isComplete, setIsComplete] = React.useState(false);
  const [hasStarted, setHasStarted] = React.useState(false);
  const [steps, addStep] = React.useState<number[]>([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [userSteps, setUserSteps] = React.useState<number[]>([]);
  console.log({ turn, currentStep });
  const processStep = (guess: number) => (ev: any) => {
    console.log({ guess, currentStep, steps });
    if (steps[currentStep] === guess) {
      setPerfects((perfects) => perfects + 1);
      if (currentStep === steps.length - 1) {
        setCurrentStep(0);
        setTurn(1);
      } else {
        setCurrentStep((step) => {
          return step + 1;
        });
      }
    } else {
      setIsComplete(true);
    }
  };

  React.useEffect(() => {
    if (turn === 1) {
      addStep((steps) => [...steps, Math.floor(Math.random() * 4)]);
    }
  }, [turn]);
  React.useEffect(() => {
    if (turn === 1 && steps.length > 0) {
      for (let index = 0; index < steps.length; index++) {
        setTimeout(() => {
          setCurrentStep(index);
          if (index === steps.length - 1) {
            setTimeout(() => {
              setTurn(2);
              setCurrentStep(0);
            }, 500 * (steps.length + 1));
          } else {
          }
        }, 500 * (index * 0.5));
      }
    }
  }, [turn, steps]);
  return <></>;
  // return (
  //   <Game
  //     type="words"
  //     gameBackground={gameBackground}
  //     setGameBackground={setGameBackground}
  //     skipCountdown
  //     isComplete={isComplete}
  //     sx={{
  //       height: "80vh",
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //       background:
  //         turn === 2
  //           ? "transparent"
  //           : "radial-gradient(circle, #221, circle, #221 40%, transparent 60%)",
  //       backgroundSize: "cover",
  //     }}
  //   >
  //     <Grid
  //       columns={2}
  //       container
  //       spacing={2}
  //       justifyContent="center"
  //       alignItems="center"
  //       sx={{ marginBottom: 2 }}
  //     >
  //       <Corner
  //         highlight={steps[currentStep] === 0 && turn === 1}
  //         onClick={turn === 2 ? processStep(0) : undefined}
  //         type={0}
  //       ></Corner>
  //       <Corner
  //         highlight={steps[currentStep] === 1 && turn === 1}
  //         onClick={turn === 2 ? processStep(1) : undefined}
  //         type={1}
  //       ></Corner>
  //     </Grid>
  //     <Grid
  //       columns={2}
  //       container
  //       spacing={2}
  //       justifyContent="center"
  //       alignItems="center"
  //     >
  //       <Corner
  //         highlight={steps[currentStep] === 2 && turn === 1}
  //         onClick={turn === 2 ? processStep(2) : undefined}
  //         type={2}
  //       ></Corner>
  //       <Corner
  //         highlight={steps[currentStep] === 3 && turn === 1}
  //         onClick={turn === 2 ? processStep(3) : undefined}
  //         type={3}
  //       ></Corner>
  //     </Grid>
  //     {turn === 0 && (
  //       <Button
  //         onClick={async () => {
  //           setTurn(1);
  //           // const { hits, perfects } = await startGame();
  //           // setIsComplete(true);
  //           // window.setTimeout(() => {
  //           //   dispatch(
  //           //     pushModal({
  //           //       componentName: "AssignmentComplete",
  //           //       props: {
  //           //         hits: {
  //           //           amount: hits,
  //           //           experience:
  //           //             hits *
  //           //             appState.challenge.rules.hitRewards.filter(
  //           //               (reward) => reward.type === "experience"
  //           //             )[0].amount,
  //           //           currency:
  //           //             hits *
  //           //             appState.challenge.rules.hitRewards.filter(
  //           //               (reward) => reward.type === "basic_currency"
  //           //             )[0].amount,
  //           //         },
  //           //         perfects: {
  //           //           amount: perfects,
  //           //           experience:
  //           //             perfects *
  //           //             appState.challenge.rules.perfectRewards.filter(
  //           //               (reward) => reward.type === "experience"
  //           //             )[0].amount,
  //           //           currency:
  //           //             perfects *
  //           //             appState.challenge.rules.perfectRewards.filter(
  //           //               (reward) => reward.type === "basic_currency"
  //           //             )[0].amount,
  //           //         },
  //           //         completion: {
  //           //           experience:
  //           //             appState.challenge.rules.completionRewards.filter(
  //           //               (reward) => reward.type === "experience"
  //           //             )[0].amount,
  //           //           currency:
  //           //             appState.challenge.rules.completionRewards.filter(
  //           //               (reward) => reward.type === "basic_currency"
  //           //             )[0].amount,
  //           //         },
  //           //       },
  //           //     })
  //           //   );
  //           // }, 1200);
  //         }}
  //         size="large"
  //         sx={{
  //           fontFamily: "'Sriracha', cursive",
  //           fontSize: "2em",
  //           letterSpacing: "6px",
  //           position: "absolute",
  //           bottom: 0,
  //           left: "50%",
  //           transform: "translateX(-50%)",
  //         }}
  //         autoFocus
  //         variant="contained"
  //         color="success"
  //       >
  //         Start
  //       </Button>
  //     )}
  //   </Game>
}
