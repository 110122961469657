import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";

import clsx from "clsx";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Clickable from "lib/audio/components/Clickable";
import * as React from "react";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import GradientText from "lib/typography/components/GradientText";
import CategoryBadge from "lib/card/components/CategoryBadge";
import { Reward, Unlockable } from "lib/data/slice";
import AvatarGroup from "@mui/material/AvatarGroup";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Paper } from "@mui/material";
import {
  green,
  grey,
  blue,
  purple,
  yellow,
  red,
  orange,
} from "@mui/material/colors";
import FastForwardOutlinedIcon from "@mui/icons-material/FastForwardOutlined";
import RewardItem from "lib/progression/components/RewardItem";
import { NewspaperTwoTone } from "@mui/icons-material";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import ChallengeProgressTracker from "lib/agility/components/ChallengeProgressTracker";
import { useMediaQuery } from "@mui/material";
import theme from "theme";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import playSound from "lib/audio/sfx";
import { setCanAnimate } from "lib/navigation/slice";
import { useAppDispatch } from "lib/hooks/react-redux";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  ${(props) => props.theme.breakpoints.down("md")} {
    background-color: rgba(0, 0, 0, 1);
  }
  -webkit-tap-highlight-color: transparent;
`;

export interface UnlockableDetailsModalProps {
  unlockable: Unlockable | Unlockable[];
  rewards?: Reward[];
  onClose: (ev: any) => void | (() => void);
}
export default function UnlockableDetailsModal({
  onClose,
  rewards,
  unlockable,
}: UnlockableDetailsModalProps) {
  const dispatch = useAppDispatch();
  const [readyToRender, setReadyToRender] = React.useState(false);
  const defaultAnimationDuration = 1;
  const [
    entranceAnimationDurationSeconds,
    setEntranceAnimationDurationSeconds,
  ] = React.useState(defaultAnimationDuration);
  const isSkipping =
    entranceAnimationDurationSeconds != defaultAnimationDuration;
  const gradientTextSX = [
    {
      WebkitTextStrokeColor: grey[200],
      background: `linear-gradient(180deg, ${grey[200]} 35%, ${grey[900]})`,
    },
    {
      WebkitTextStrokeColor: green[200],
      background: `linear-gradient(180deg, ${green[200]} 35%, ${green[900]})`,
    },
    {
      WebkitTextStrokeColor: blue[200],
      background: `linear-gradient(180deg, ${blue[200]} 35%, ${blue[900]})`,
    },
    {
      WebkitTextStrokeColor: purple[200],
      background: `linear-gradient(180deg, ${purple[200]} 35%, ${purple[900]})`,
      textShadow: `0 0 0.4em ${purple[200]}`,
    },
    {
      WebkitTextStrokeColor: yellow[200],
      background: `linear-gradient(180deg, ${yellow[200]} 35%, ${yellow[900]})`,
      textShadow: `0 0 0.4em ${yellow[200]}`,
    },
  ];

  const [unlockableRender, setUnlockableRender] = React.useState(
    Array.isArray(unlockable)
      ? (unlockable as Unlockable[])
      : ([unlockable] as Unlockable[])
  );
  const [rewardRender, setRewardRender] = React.useState(
    Array.isArray(rewards) ? (rewards as Reward[]) : ([] as Reward[])
  );

  const [totalUnlockableDetails, setTotalUnlockableDetails] = React.useState(
    unlockableRender.length
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const nextItem = () => {
    setUnlockableRender((currentState) => {
      const newState = [...currentState];
      newState.shift();
      if (newState.length === 0) {
        onClose(undefined);
      }
      return newState;
    });
    setRewardRender((currentState) => {
      const newState = [...currentState];
      newState.shift();
      return newState;
    });
  };
  React.useEffect(() => {
    if (
      readyToRender &&
      (rewards ?? []).length > 0 &&
      Array.isArray(unlockable)
    ) {
      playSound(`unlockable-${unlockableRender[0].rarity}`);
    }
  }, [readyToRender, unlockableRender[0]?.id]);

  const preloadImages = async () => {
    for (let unlockable of unlockableRender) {
      await fetch(
        `/assets/unlockable/${isMobile ? "mobileWide" : "large"}/${
          unlockable.id
        }.jpg`
      );
      if (unlockable.rarity === 4) {
        await fetch(`/assets/unlockable/animation/${unlockable.id}.webm`);
      }
    }
    setReadyToRender(true);
  };
  React.useEffect(() => {
    preloadImages();

    dispatch(setCanAnimate(false));
    return () => {
      dispatch(setCanAnimate(true));
    };
  }, []);
  if (!readyToRender) {
    return (
      <Modal open components={{ Root: Backdrop }}>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </Modal>
    );
  }
  console.log(unlockableRender.length);
  const skipButtonBackground = Array.from(
    Array(totalUnlockableDetails).keys()
  ).map((item, idx) =>
    idx > totalUnlockableDetails - unlockableRender.length
      ? "hsl(333deg 97% 17%)"
      : "hsl(333deg 97% 27%)"
  );
  console.log({ skipButtonBackground });
  return (
    <Modal
      open
      sx={{
        cursor: "pointer",
        zIndex: 10000,
        ...(rewards
          ? {
              "&:before": {
                content: "''",
                top: 0,
                left: 0,
                right: 0,
                position: "absolute",
                bottom: 0,
                filter: "blur( 5px) brightness(0.2)",
                backgroundImage: `url('/assets/unlockable/${
                  isMobile ? "mobileTall" : "large"
                }/${unlockableRender[0].id}.jpg')`,
                backgroundSize: "150% 150%",
                backgroundPosition: "50% 50%",
                transition: "background-image 0.5s ease-in-out",
              },
            }
          : {}),
      }}
      onClose={nextItem}
      onClick={nextItem}
      components={{ Root: Backdrop }}
    >
      <>
        {[unlockableRender[0]].map((unlockable, idx) => {
          const shouldRender = idx === 0;
          return (
            <Box
              key={`${unlockable.id}`}
              sx={{
                position: "absolute",
                display: "flex",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                "&:hover .Description": {
                  opacity: 1,
                },
                filter: `drop-shadow(0 0 5rem ${
                  gradientTextSX[unlockable.rarity].WebkitTextStrokeColor
                })`,
                "@keyframes unlockableModalGlowExit": {
                  to: {
                    filter: `drop-shadow(0 0 0rem ${
                      gradientTextSX[unlockable.rarity].WebkitTextStrokeColor
                    })`,
                  },
                },

                animation:
                  shouldRender && !isSkipping
                    ? `unlockableModalGlowExit ${entranceAnimationDurationSeconds}s ease-in-out forwards`
                    : "none",
              }}
            >
              <Box
                sx={{
                  opacity: shouldRender ? 1 : 0,
                  position: "absolute",
                  display: "flex",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: "100vh",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 11,
                    alignItems: "center",

                    display: "flex",
                    flexDirection: "column",
                    [theme.breakpoints.down("md")]: {
                      top: 0,
                      height: "98vh",
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 2,
                      top: 0,
                      position: "relative",
                      [theme.breakpoints.down("md")]: {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        transformOrigin: "bottom",
                        "@keyframes CardTitleEntrance": {
                          from: {
                            transform: "translateY(-20%) scale(1,0.8)",
                            opacity: 0.4,
                          },
                          to: {
                            transform: "translateY(0) scale(1,1)",
                            opacity: 1,
                          },
                        },
                        animation: "CardTitleEntrance 1.6s ease-in forwards",

                        [theme.breakpoints.down("md")]: {
                          width: "100%",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                        },
                      }}
                    >
                      <GradientText
                        sx={{
                          alignSelf: "flex-start",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          fontSize: "clamp(1.4em, 6vw, 3em)",
                          //WebkitTextStrokeWidth: "1px",
                          ...gradientTextSX[unlockable.rarity],
                          [theme.breakpoints.down("md")]: {
                            WebkitTextStrokeWidth: "0.5px",
                            width: "100%",
                          },
                        }}
                      >
                        {unlockable.name}
                      </GradientText>
                      <AvatarGroup
                        max={10}
                        sx={{
                          display: "inline-flex",
                          marginLeft: "auto",

                          [theme.breakpoints.down("md")]: {
                            marginLeft: "0",
                          },
                        }}
                      >
                        {unlockable.categories.map((category) => (
                          <CategoryBadge
                            size={isMobile ? "small" : "large"}
                            name={category}
                          />
                        ))}
                      </AvatarGroup>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50vw",
                      height: totalUnlockableDetails > 1 ? "80vh" : "90vh",
                      //background: "rgba( 255, 255, 255, 0.25 )",
                      //boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
                      //backdropFilter: "blur( 4px )",
                      //border: "1px solid rgba(255,255,255,0.4)",
                      borderRadius: "40px",
                      //minHeight: "71.5vh",
                      "@keyframes unlockableModalEnter0": {
                        "0%": {
                          filter: "grayscale(100%)",
                          opacity: 0.4,
                          transform: "scale(0.4) translateY(-10%)",
                        },
                        "50%": {
                          opacity: 1,
                          filter: "grayscale(40%)",

                          transform: "scale(1) translateY(-1%)",
                        },
                        "100%": {
                          opacity: 1,
                          filter: "grayscale(0%) ",

                          transform: "scale(1) translateY(-0%)",
                        },
                      },
                      "@keyframes unlockableModalEnter1": {
                        "0%": {
                          filter: "grayscale(100%)",
                          opacity: 0.4,
                          transform:
                            "skew(10deg, -10deg) scale(0.6) translateY(-10%)",
                        },
                        "50%": {
                          opacity: 1,
                          filter: "grayscale(40%)",

                          transform:
                            "skew(0deg, 0deg) scale(1) translateY(-1%)",
                        },
                        "100%": {
                          opacity: 1,
                          filter: "grayscale(0%) ",

                          transform:
                            "skew(0deg, 0deg) scale(1) translateY(-0%)",
                        },
                      },
                      "@keyframes unlockableModalEnter2": {
                        "0%": {
                          filter: "grayscale(100%)",
                          opacity: 0.4,
                          clipPath: "circle(0%)",
                          transform:
                            "skew(-30deg, 10deg) scale(0.9) translateY(-10%) rotateX(20deg)",
                        },
                        "50%": {
                          opacity: 1,
                          filter: "grayscale(40%)",

                          transform:
                            "skew(0deg, 0deg) scale(1) translateY(-1%)",
                        },
                        "100%": {
                          opacity: 1,
                          filter: "grayscale(0%) ",
                          clipPath: "circle(100%)",
                          transform:
                            "skew(0deg, 0deg) scale(1) translateY(-0%)",
                        },
                      },
                      "@keyframes unlockableModalEnter3": {
                        "0%": {
                          clipPath:
                            "polygon(68% 43%, 61% 55%, 51% 63%, 45% 61%, 26% 58%, 13% 59%, 29% 81%, 37% 70%, 55% 72%, 70% 63%, 73% 52%, 89% 47%, 91% 39%, 78% 42%)",
                          // clipPath:
                          //   "polygon(85% 6%, 70% 15%, 30% 15%, 15% 5%, 0% 25%, 10% 45%, 10% 75%, 25% 90%, 40% 95%, 60% 95%, 75% 91%, 90% 75%, 90% 45%, 100% 25%)",
                          filter: "grayscale(100%)",
                          opacity: 0.4,
                          transform:
                            "skew(-10deg, 10deg) scale(0.9) translateY(-10%)",
                        },
                        "50%": {
                          clipPath:
                            "polygon(85% 9%, 60% 3%, 39% 2%, 18% 5%, 1% 9%, 2% 28%, 2% 70%, 3% 96%, 26% 99%, 63% 98%, 98% 100%, 99% 69%, 96% 48%, 100% 5%)",
                          opacity: 1,
                          filter: "grayscale(40%)",

                          transform:
                            "skew(0deg, 0deg) scale(1) translateY(-1%)",
                        },
                        "99%": {
                          clipPath:
                            "polygon(85% -50%, 42% -50%, 25% -50%, 8% -50%, -50% -50%, -50% 34%, -50% 73%, 0% 150%, 82% 150%, 79% 150%, 150% 150%, 150% 55%, 150% 41%, 100% -50%)",
                          opacity: 1,
                          filter: "grayscale(0%)",

                          transform:
                            "skew(0deg, 0deg) scale(1) translateY(-0%)",
                        },
                        "100%": {
                          clipPath: "none",
                        },
                      },
                      "@keyframes unlockableModalEnter4": {
                        "0%": {
                          clipPath:
                            "polygon(68% 43%, 61% 55%, 51% 63%, 45% 61%, 26% 58%, 13% 59%, 29% 81%, 37% 70%, 55% 72%, 70% 63%, 73% 52%, 89% 47%, 91% 39%, 78% 42%)",
                          // clipPath:
                          //   "polygon(85% 6%, 70% 15%, 30% 15%, 15% 5%, 0% 25%, 10% 45%, 10% 75%, 25% 90%, 40% 95%, 60% 95%, 75% 91%, 90% 75%, 90% 45%, 100% 25%)",
                          filter: "blur(20px) hue-rotate(180deg)",
                          opacity: 0.8,
                          transform:
                            "skew(-30deg, 10deg) scale(0.9) translateY(-10%)",
                        },
                        "50%": {
                          clipPath:
                            "polygon(85% 9%, 60% 3%, 39% 2%, 18% 5%, 1% 9%, 2% 28%, 2% 70%, 3% 96%, 26% 99%, 63% 98%, 98% 100%, 99% 69%, 96% 48%, 100% 5%)",
                          opacity: 1,
                          filter: "blur(0px) hue-rotate(0deg)",

                          transform:
                            "skew(0deg, 0deg) scale(1) translateY(-1%)",
                        },
                        "99%": {
                          clipPath:
                            "polygon(85% -50%, 42% -50%, 25% -50%, 8% -50%, -50% -50%, -50% 34%, -50% 73%, 0% 150%, 82% 150%, 79% 150%, 150% 150%, 150% 55%, 150% 41%, 100% -50%)",
                          opacity: 1,
                          filter: "grayscale(0%) hue-rotate(0deg) blur(0px)",

                          transform:
                            "skew(0deg, 0deg) scale(1) translateY(-0%)",
                        },
                        "100%": {
                          clipPath: "none",
                        },
                      },

                      "@keyframes unlockableModalEnterFast": {
                        "0%": {
                          opacity: 0.5,
                          transform: "scale(0.9)",
                        },
                        "50%": {
                          opacity: 1,
                          transform: "scale(1)",
                        },
                        "100%": {
                          opacity: 1,
                          transform: "scale(1)",
                        },
                      },
                      animation: shouldRender
                        ? `${
                            isSkipping
                              ? "unlockableModalEnterFast"
                              : `unlockableModalEnter${unlockable.rarity}`
                          } ${entranceAnimationDurationSeconds}s ease-in-out forwards`
                        : "none",

                      [theme.breakpoints.down("md")]: {
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        maxHeight: "59vh",
                      },
                    }}
                  >
                    {/* <IconButton
                      onClick={nextItem}
                      size="large"
                      color="info"
                      sx={{ position: "absolute", right: "-8vw", top: "5vh" }}
                    >
                      <CloseIcon />
                    </IconButton> */}
                    <Box
                      sx={{
                        minHeight: "0",
                        flex: "1 1 70%",
                        maxHeight: "90%",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        [theme.breakpoints.down("md")]: {
                          width: "100vw",
                          flex: "0 1 40%",
                        },
                      }}
                    >
                      {unlockable.rarity === 4 ? (
                        <CardMedia
                          component="video"
                          src={`/assets/unlockable/animation/${unlockable.id}.webm`}
                          autoPlay
                          loop
                          poster={`/assets/unlockable/${
                            isMobile ? "mobileWide" : "large"
                          }/${unlockable.id}.jpg`}
                          sx={{
                            // maxHeight: "80vh",
                            // width: "80vw",
                            height: "100%",
                            objectFit: "contain",
                            width: "auto",
                            transition: "object-position 0.5s ease-in-out",
                            position: "relative",
                          }}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          image={`/assets/unlockable/${
                            isMobile ? "mobileWide" : "large"
                          }/${unlockable.id}.jpg`}
                          alt={unlockable.name}
                          sx={{
                            // maxHeight: "80vh",
                            // width: "80vw",
                            height: "100%",
                            objectFit: "contain",
                            width: "auto",
                            transition: "object-position 0.5s ease-in-out",
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 1,
                          right: 1,

                          opacity: isMobile ? 0 : 0.4,
                          "&:hover": {
                            opacity: isMobile ? 0 : 1,
                          },
                        }}
                      >
                        <a
                          href={unlockable.attribution.referralLink}
                          target="_blank"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            textShadow: "1px 1px 1px #222",
                            color: "#fafafa",
                            opacity: 1,
                          }}
                        >
                          <PhotoCameraIcon />
                          {unlockable.attribution.author}
                        </a>
                      </Box>
                    </Box>

                    {rewards && (
                      <Paper
                        elevation={8}
                        sx={{
                          display: "flex",
                          width: "100%",
                          padding: "0 2em",
                          justifyContent: "center",

                          background:
                            unlockable.rarity === 4
                              ? `linear-gradient(90deg, ${orange[200]}, ${orange[300]})`
                              : unlockable.rarity === 1
                              ? `linear-gradient(90deg, ${green[200]}, ${green[300]})`
                              : unlockable.rarity === 2
                              ? `linear-gradient(90deg, ${blue[200]}, ${blue[300]})`
                              : unlockable.rarity === 3
                              ? `linear-gradient(90deg, ${purple[200]}, ${purple[300]})`
                              : `linear-gradient(90deg, ${grey[200]}, ${grey[300]})`,

                          "@keyframes unlockableDetailsRewardPulse": {
                            from: {
                              filter: "brightness(0.9)",
                            },
                            to: {
                              filter: "brightness(1.2)",
                            },
                          },
                          animation:
                            "unlockableDetailsRewardPulse 1s infinite ease-in alternate",
                          [theme.breakpoints.down("md")]: {
                            width: "100vw",
                            padding: 0,
                            fontSize: "clamp(0.5em, 6vw, 0.8em)",
                          },
                        }}
                      >
                        <GradientText
                          sx={{
                            WebkitTextStrokeWidth: "1px",
                            WebkitTextStrokeColor:
                              unlockable.rarity === 4
                                ? orange[900]
                                : unlockable.rarity === 1
                                ? green[900]
                                : unlockable.rarity === 2
                                ? blue[900]
                                : unlockable.rarity === 3
                                ? purple[900]
                                : grey[900],
                            background:
                              unlockable.rarity === 4
                                ? `linear-gradient(180deg, ${orange[400]} 50%, ${orange[800]})`
                                : unlockable.rarity === 1
                                ? `linear-gradient(180deg, ${green[400]} 50%, ${green[800]})`
                                : unlockable.rarity === 2
                                ? `linear-gradient(180deg, ${blue[400]} 50%, ${blue[800]})`
                                : unlockable.rarity === 3
                                ? `linear-gradient(180deg, ${purple[400]} 50%, ${purple[800]})`
                                : `linear-gradient(180deg, ${grey[400]} 50%, ${grey[800]})`,

                            [theme.breakpoints.down("md")]: {
                              whiteSpace: "nowrap",
                            },
                          }}
                          dark
                        >
                          New Card Reward
                        </GradientText>
                        {[rewardRender[idx]].map((reward) => (
                          <RewardItem key={unlockable.id} dark {...reward} />
                        ))}
                      </Paper>
                    )}

                    <Typography
                      className="Description"
                      sx={{
                        color: "#fafafa",
                        textShadow:
                          "2px 2px 1px #222, -2px -2px 1px #222, -2px 2px 1px #222, 2px -2px 1px #222",

                        transition: "opacity 0.5s ease-in-out",
                        marginTop: 2,
                        fontSize: "clamp(0.8em, 4vw, 0.9em)",
                        width: "95%",
                        lineHeight: "1",

                        [theme.breakpoints.up("md")]: {
                          //display: "none",
                        },
                      }}
                      variant="h6"
                      gutterBottom
                    >
                      {unlockable.description}
                    </Typography>
                    {isMobile && (
                      <a
                        href={unlockable.attribution.referralLink}
                        target="_blank"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          textShadow: "1px 1px 1px #222",
                          color: "#fafafa",
                          opacity: 1,
                        }}
                      >
                        <PhotoCameraIcon />
                        {unlockable.attribution.author}
                      </a>
                    )}
                  </Box>
                  {rewards && totalUnlockableDetails > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1111,
                        marginTop: "0",
                        marginBottom: "1em",
                        paddingTop: 2,
                        transform: `translateY(${2})`,
                        [theme.breakpoints.down("md")]: {
                          flexWrap: "wrap",
                          justifyContent: "stretch",
                        },
                      }}
                    >
                      <Button
                        sx={{
                          background: `linear-gradient(90deg, ${skipButtonBackground.join(
                            ","
                          )})`,

                          [theme.breakpoints.down("md")]: { width: "100%" },
                        }}
                        color="primary"
                        onClick={async () => {
                          nextItem();
                        }}
                        variant="outlined"
                        endIcon={
                          <SkipNextIcon
                            sx={{
                              stroke: "#FFD700",
                              strokeWidth: "1px",
                              fontSize: "3em!important",
                              fill: `url(#GameIconGradientFill)`,
                            }}
                          />
                        }
                      >
                        <GradientText
                          sx={{
                            fontSize: "3em",
                            letterSpacing: "4px",
                            //fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          }}
                        >
                          Next
                        </GradientText>
                      </Button>
                      <Button
                        sx={{
                          ml: 4,

                          [theme.breakpoints.down("md")]: {
                            ml: 0,
                            width: "100%",
                          },
                        }}
                        color="secondary"
                        onClick={async () => {
                          if (unlockableRender.length > 30) {
                            onClose(undefined);
                            return;
                          }
                          setEntranceAnimationDurationSeconds(3);
                          let timeoutDuration = 300;
                          function delay(timeoutDuration: number) {
                            return new Promise<void>((resolve) =>
                              setTimeout(function () {
                                resolve();
                              }, Math.max(100, timeoutDuration))
                            );
                          }

                          while (unlockableRender.length > 0) {
                            nextItem();
                            timeoutDuration -= 50;
                            setEntranceAnimationDurationSeconds(
                              (currentState) => {
                                return Math.max(0.1, currentState - 0.5);
                              }
                            );
                            await delay(timeoutDuration);
                          }
                        }}
                        variant="outlined"
                        endIcon={
                          <FastForwardOutlinedIcon
                            sx={{
                              stroke: "#FFD700",
                              strokeWidth: "1px",
                              fontSize: "3em!important",
                              fill: `url(#GameIconGradientFill)`,
                            }}
                          />
                        }
                      >
                        <GradientText
                          sx={{
                            fontSize: "3em",
                            letterSpacing: "4px",
                            //fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          }}
                        >
                          Skip
                        </GradientText>
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </>
    </Modal>
  );
}
