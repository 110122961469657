import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";

import clsx from "clsx";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Clickable from "lib/audio/components/Clickable";
import * as React from "react";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import GradientText from "lib/typography/components/GradientText";
import CategoryBadge from "lib/card/components/CategoryBadge";
import { Reward, Unlockable } from "lib/data/slice";
import AvatarGroup from "@mui/material/AvatarGroup";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { buyBasicCurrency } from "lib/progression/slice";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import theme from "theme";
import { useMediaQuery } from "@mui/material";
import { pushModal } from "../slice";
import playSound from "lib/audio/sfx";
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-tap-highlight-color: transparent;
`;

interface Chest {
  name: string;
  type: "small" | "medium" | "large";
  currencyType: "shards" | "gems";
}
export interface ChestRewardModalProps {
  chest: Chest;
  consumableId?: string;
  onClose: (ev: any) => void | (() => void);
}
export default function ChestRewardModal({
  onClose,
  chest,
  consumableId,
}: ChestRewardModalProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const progression = useAppSelector((state) => state.progression);
  const [chestStatus, setChestStatus] = React.useState<"open" | "closed">(
    "closed"
  );
  const dispatch = useAppDispatch();
  return (
    <Modal
      open
      onClose={onClose}
      //onClick={onClose}
      components={{ Root: Backdrop }}
    >
      <>
        <div
          style={{
            position: "absolute",
            top: "10vh",
            left: "10vw",
            fontSize: "2em",
            zIndex: 11,
            display: "flex",
            alignItems: "center",
            width: "80vw",
          }}
        >
          <GradientText sx={{ display: "inline-block" }}>
            Open your {chest.name}!
          </GradientText>
        </div>
        <CardMedia
          component="img"
          onClick={(ev: any) => {
            setChestStatus("open");
            if (consumableId) {
              playSound("open-premium");
              window.setTimeout(() => {
                const rewards = progression.consumables[consumableId].rewards;
                onClose(ev);
                dispatch(
                  pushModal({
                    componentName: "Reward",
                    props: {
                      rewards,
                      source: consumableId ? "payment" : undefined,
                    },
                  })
                );
              }, 1000);
            } else {
              playSound("open-basic");
              dispatch(buyBasicCurrency({ type: chest.type }));
              window.setTimeout(() => {
                let amount = 1000;
                switch (chest.type) {
                  case "medium":
                    amount = 7500;
                    break;
                  case "large":
                    amount = 15000;
                    break;
                }
                runAwardAnimation(
                  [{ type: "basic_currency", amount }],
                  undefined,
                  undefined,
                  undefined,
                  isMobile
                );
                onClose(ev);
              }, 1000);
            }
          }}
          image={`/assets/store/package/${chest.currencyType}_${chestStatus}_${chest.type}_full.png`}
          alt="success"
          sx={{
            maxHeight: "90vh",
            maxWidth: "90vw",
            width: "1000px",
            height: "1000px",
            objectFit: "contain",
            cursor: "pointer",
            position: "absolute",
            "@keyframes closedChestJiggle": {
              "0%": {
                transform: "rotate(0deg)",
              },
              "5%": {
                transform: "rotate(5deg)",
              },
              "10%": {
                transform: "rotate(-5deg)",
              },
              "15%": {
                transform: "rotate(5deg)",
              },
              "20%": {
                transform: "rotate(0deg)",
              },
              "100%": {
                transform: "rotate(0deg)",
              },
            },
            animation: `closedChestJiggle ${
              chestStatus === "closed" ? "1s" : "0s"
            } infinite`,
          }}
        />
      </>
    </Modal>
  );
}
