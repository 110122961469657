// @ts-ignore
import * as PIXI from "pixi.js";

import { sound } from "@pixi/sound";
import { AnimatedSprite, Texture } from "pixi.js";
import { useMediaQuery } from "@mui/material";
import BezierEasing from "bezier-easing";
import theme from "theme";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
const localStorageRaw = window.localStorage.getItem("persist:root") || "{}";
const localStorage = JSON.parse(localStorageRaw);
let globalVolume =
  localStorage?.preferences && JSON.parse(localStorage.preferences).sfx ? 1 : 0;

const app = new PIXI.Application({
  width: window.innerWidth,
  height: window.innerHeight,
  resolution: 1,
  backgroundAlpha: 0,
});

app.view.style.position = "absolute";
app.view.style.width = "100%";
app.view.style.height = "100%";
app.view.style.top = "0";
app.view.style.left = "0";
app.view.style.zIndex = "9";
app.view.style.pointerEvents = "none";
document.body.appendChild(app.view);
let tickers: PIXI.Ticker[] = [];
function randomOffset(multiplier = 100) {
  const operand = Math.random() >= 0.5 ? -1 : 1;
  return operand * Math.random() * multiplier;
}

interface Currency {
  frame: number;
  initialTarget: { x: number; y: number };
  initialSpeed: number;
  sprite: PIXI.Sprite;
}
sound.volumeAll = globalVolume;
sound.add("award", "/assets/audio/award-item.wav");
sound.add("found", "/assets/audio/in-game-found.wav");
sound.add("zenExperience", "/assets/audio/zen-experience-gain.wav");
export const setGlobalVolume = (newVolume: number) => {
  globalVolume = newVolume;
  sound.volumeAll = globalVolume;
};
let tickerSpeed = 0;

app.ticker.speed = tickerSpeed;
export default function runAward(
  amount: number,
  asset: string,
  startCoords?: { x: number; y: number },
  endCoords?: { x: number; y: number },
  frameChange?: {
    goToEnd: number;
  },
  isMobile?: boolean
) {
  let animatables: Currency[] = [];
  let audioType = "award";
  const target = new PIXI.Point();
  const scaleTarget = new PIXI.Point(10, 10);
  let particleWidth = 80;
  let particleHeight = 80;
  target.x = endCoords?.x ? endCoords.x : isMobile ? 88 : 28;
  target.y = endCoords?.y
    ? endCoords.y
    : isMobile
    ? window.innerHeight - 20
    : 145;
  let tickFunction = (delta: number, animatable: Currency) => {};
  if (!endCoords) {
    if (asset === "/assets/particle/basic_currency_particle.svg") {
      target.x = isMobile ? 20 : window.innerWidth / 2 - 560;
      target.y = 31;
    } else if (asset === "/assets/particle/premium_currency_particle.svg") {
      target.x = window.innerWidth / 2 + 460;
      target.y = 31;
    } else if (asset === "/assets/particle/freebie_particle.svg") {
      target.x = window.innerWidth / 2 - 24;
      target.y = window.innerHeight - 40;
    } else if (asset === "/assets/particle/pack_particle.png") {
      target.x = isMobile
        ? window.innerWidth - 40
        : window.innerWidth / 2 + 480;
      target.y = isMobile ? window.innerHeight - 20 : 230;
      particleWidth = 30;
    } else if (asset === "/assets/particle/experience_particle.svg") {
      target.x = isMobile ? window.innerWidth / 2 : window.innerWidth / 2;
      target.y = 20;
    } else if (asset === "/assets/particle/zen_experience_particle.svg") {
      target.x = isMobile ? window.innerWidth / 2 : window.innerWidth / 2;

      target.y = 200;
    } else if (asset.indexOf("token_currency") > -1) {
      audioType = "found";
      target.x = isMobile
        ? window.innerWidth - 20
        : window.innerWidth / 2 - 470;
      target.y = isMobile ? window.innerHeight - 20 : 590;

      particleHeight = 30;
      particleWidth = 30;
    }
  }
  if (asset === "/assets/particle/zen_experience_particle.svg") {
    tickFunction = (delta, animatable) => {
      animatable.sprite.alpha -= 0.02;
    };
    audioType = "";
  }
  if (asset.indexOf("token_currency") > -1) {
    audioType = "found";
  }

  type BezierEasingParams = Parameters<typeof BezierEasing>;
  const possibleEasingsX: BezierEasingParams[] = [
    [0.32, 0, 0.67, 0],
    // [0.65, 0, 0.35, 1],
    // [0.22, 1, 0.36, 1],
    // [0.5, 0, 0.75, 0],
    // [0.87, 0, 0.13, 1],
    // [0.33, 1, 0.68, 1],
    // [0.36, 0, 0.66, -0.2],
    // [0.83, 0, 0.17, 1],
    // [0.11, 0, 0, -0.3],
  ];
  // No ease outs for y because it can obfuscated progress bar
  const possibleEasingsY: BezierEasingParams[] = [
    [0.32, 0, 0.67, 0],
    // [0.5, 0, 0.75, 0],
    // [0.36, 0, 0.66, -0.2],
    // [0.55, 0, 1, 0.45],
    // [0.11, 0, 0, -0.3],
  ];
  let easeFunctionX = BezierEasing.apply(
    null,
    possibleEasingsX[Math.floor(Math.random() * possibleEasingsX.length)]
  );
  //Math.floor(Math.random() * possibleEasings.length)
  let easeFunctionY = BezierEasing.apply(
    null,
    possibleEasingsY[Math.floor(Math.random() * possibleEasingsY.length)]
  );
  const spawn = () => {
    // let texture = PIXI.Texture.from(asset, {
    //   resolution: 2,
    //   resourceOptions: { scale: 2 },
    // });

    let textures = [asset, asset];

    const textureArray = [];
    let frameAmount = 1;
    switch (asset) {
      case "/assets/particle/basic_currency_particle.svg":

      case "/assets/particle/experience_particle.svg":
        frameAmount = 3;
        textures.push("/assets/particle/basic_currency_particle_side.svg");
        break;
    }
    for (let i = 0; i < frameAmount; i++) {
      const texture = Texture.from(textures[i], {
        resolution: 2,
        width: 80,
        height: 80,
        resourceOptions: { scale: 2 },
      });
      textureArray.push(texture);
    }

    const animatedSprite = new PIXI.AnimatedSprite(textureArray);

    animatedSprite.loop = true;
    animatedSprite.animationSpeed = 0.3;
    animatedSprite.play();
    animatedSprite.width = 80;
    animatedSprite.height = 80;
    const animatable = {
      sprite: animatedSprite,
      frame: 0,
      initialSpeed: Math.random() / 150,
      initialTarget: {
        x: window.innerWidth / 2 + randomOffset(1000),
        y: window.innerHeight / 2 + randomOffset(1000),
      },
    };
    animatable.sprite.anchor.set(0.5);
    animatable.sprite.x = startCoords?.x
      ? startCoords.x
      : window.innerWidth / 2 + randomOffset();
    animatable.sprite.y = startCoords?.y
      ? startCoords.y
      : window.innerHeight / 2 + randomOffset();

    //animatable.sprite.rotation = Math.random() * Math.PI * 2;
    app.stage.addChild(animatable.sprite);
    animatables.push(animatable);
    if (animatables.length < Math.min(amount, 100)) {
      if (audioType) {
        try {
          const playAudio = () => {
            const promise = new Promise((resolve, reject) => {
              sound.play(audioType);
            });
            return promise.catch((e) => {
              console.warn("Could not play sound:", e);
            });
          };
          playAudio();
        } catch (e) {
          console.warn("Could not play sound:", e);
        }
      }

      if (animatables.length < 200) {
        window.setTimeout(() => {
          spawn();
        }, 100 / animatables.length + 1);
      } else {
        const renderAmount = Math.min(animatables.length, 100);
        while (animatables.length < renderAmount) {
          spawn();
        }
      }
    }
  };

  spawn();

  const ticker = new PIXI.Ticker();
  ticker.speed = tickerSpeed;
  tickers.push(ticker);
  const tickerId = tickers.length - 1;
  ticker.add((delta) => {
    //console.log({ delta });
    for (let animatable of animatables) {
      tickFunction(delta, animatable);
      animatable.frame++;
      // epsilon determines the precision of the solved values
      // a good approximation is:
      var duration = 1000; // duration of animation in milliseconds.
      var epsilon = 1000 / 60 / duration / 4;
      // var timingFunction = bezier(
      //   animatable.initialTarget.x,
      //   animatable.initialTarget.y,
      //   target.x,
      //   target.y,
      //   epsilon
      // );

      const elapsedEaseProgress = frameChange?.goToEnd
        ? (animatable.frame - frameChange.goToEnd) / 200
        : animatable.frame / 200;
      const easeProgressX = easeFunctionX(Math.min(elapsedEaseProgress, 1));
      const easeProgressY = easeFunctionY(Math.min(elapsedEaseProgress, 1));

      if (frameChange?.goToEnd && animatable.frame < frameChange.goToEnd) {
        animatable.sprite.x +=
          (animatable.initialTarget.x - animatable.sprite.x) *
          animatable.initialSpeed;
        animatable.sprite.y +=
          (animatable.initialTarget.y - animatable.sprite.y) *
          animatable.initialSpeed;
      } else {
        animatable.sprite.rotation += (0 - animatable.sprite.rotation) * 0.1;
        // animatable.sprite.x += easeProgress;
        // animatable.sprite.y += easeProgress;
        animatable.sprite.x +=
          (target.x - animatable.sprite.x) * 0.8 * easeProgressX;
        animatable.sprite.y +=
          (target.y - animatable.sprite.y) * 0.82 * easeProgressY;
        animatable.sprite.width +=
          (scaleTarget.x - animatable.sprite.width) * 0.01;
        animatable.sprite.height +=
          (scaleTarget.y - animatable.sprite.height) * 0.01;
      }

      if (
        Math.abs(animatable.sprite.x - target.x) < 1 &&
        Math.abs(animatable.sprite.y - target.y) < 1
      ) {
        app.stage.removeChild(animatable.sprite);
      }
    }
  });

  ticker.start();
  window.setTimeout(() => {
    animatables.forEach((animatable) => {
      app.stage.removeChild(animatable.sprite);
    });
    animatables = [];
    tickers.splice(tickerId, 1);
    ticker.destroy();
  }, 10000);
}

export const pauseRewardAnimations = () => {
  tickerSpeed = 0;
  app.ticker.speed = tickerSpeed;
  tickers.forEach((ticker) => {
    ticker.speed = tickerSpeed;
  });
};
export const resumeRewardAnimations = () => {
  tickerSpeed = 1;
  app.ticker.speed = tickerSpeed;
  tickers.forEach((ticker) => {
    ticker.speed = tickerSpeed;
  });
};
