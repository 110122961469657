import * as React from "react";
import Dashboard from "lib/dashboard/components/Dashboard";
import PageTransitionWrapper from "lib/animation/components/PageTransitionWrapper";
export default function DashboardPage() {
  return (
    <PageTransitionWrapper>
      <Dashboard />
    </PageTransitionWrapper>
  );
}
