import { blue, green, grey, purple, yellow } from "@mui/material/colors";

export default [
  {
    border: `3px solid ${grey[400]}`,
    borderImage: "none",
    borderImageWidth: "0",
  },
  {
    border: `3px solid ${green[400]}`,
    borderImage: 'url("assets/unlockable/border/uncommon.png") 190 195 190 195',
    borderImageWidth: "30px 30px 30px 30px",
  },
  {
    border: `3px solid ${blue[400]}`,
    borderImage: 'url("assets/unlockable/border/rare.png") 190 195 190 195',
    borderImageWidth: "30px 30px 30px 30px",
  },
  {
    border: `3px solid ${purple[400]}`,
    borderImage: 'url("assets/unlockable/border/epic.png") 190 195 190 195',
    borderImageWidth: "30px 30px 30px 30px",
  },
  {
    border: `3px solid ${yellow[400]}`,
    borderImage:
      'url("assets/unlockable/border/legendary.png") 190 195 190 195',
    borderImageWidth: "30px 30px 30px 30px",
  },
] as const;
