import * as React from "react";

import PageTransitionWrapper from "lib/animation/components/PageTransitionWrapper";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkRedeemableExists,
  checkCanRedeem,
  redeemItem,
} from "lib/data/firebase";
import runAwardAnimation from "lib/animation/runAwardAnimation";
import { Reward } from "lib/data/slice";
import { Box, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "lib/modal/components/Modal";
import Button from "@mui/material/Button";
import RewardItem from "lib/progression/components/RewardItem";
import { award } from "lib/progression/slice";
import { useMediaQuery } from "@mui/material";
import theme from "theme";
export default function RedeemableModal({
  onClose,
  code,
}: {
  onClose: (ev: any) => void;
  code: string;
}) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [redeemableItems, setRedeemableItems] = React.useState<Reward[]>([]);
  const [hasClaimed, setHasClaimed] = React.useState(false);
  const eligibleForReward =
    !isLoading && redeemableItems.length > 0 && !hasClaimed;
  const dialogTitle = eligibleForReward ? "Congratulations!" : "Sorry!";
  const dialogBodyContent = eligibleForReward ? (
    <>
      You will receive
      <Box sx={{ display: "flex" }}>
        {redeemableItems.map((reward) => (
          <RewardItem dark {...reward} />
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={(ev) => {
            claimReward();
            onClose({ ...ev, success: true });
          }}
        >
          Collect
        </Button>
      </Box>
    </>
  ) : (
    <>
      {redeemableItems.length > 0
        ? "You've already claimed this reward"
        : "This promotion is expired or invalid"}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={(ev) => {
            onClose(ev);
          }}
        >
          Return
        </Button>
      </Box>
    </>
  );
  const claimReward = () => {
    if (code) {
      redeemItem(`${user.uid}`, code);
      runAwardAnimation(
        redeemableItems,
        undefined,
        undefined,
        undefined,
        isMobile
      );
      dispatch(
        award({
          rewards: redeemableItems,
          dateString: new Date().toISOString(),
          source: "redeemable",
        })
      );
    } else {
      throw new Error("Invalid code");
    }
  };
  const assignRedeemable = async () => {
    setIsLoading(true);
    if (code) {
      const data = await checkRedeemableExists(code);
      if (data.rewards) {
        setRedeemableItems(data.rewards);
        const eligibleRedeem = await checkCanRedeem(user.uid, code);
        setHasClaimed(!eligibleRedeem);
      }
    }
    setIsLoading(false);
  };
  React.useEffect(() => {
    assignRedeemable();
  }, []);

  return (
    <Modal
      onClose={(ev) => {
        if (eligibleForReward) {
          claimReward();
        }
        onClose({ ...ev, success: eligibleForReward });
      }}
      dialogTitle={isLoading ? "" : dialogTitle}
      dialogBodyContent={isLoading ? <CircularProgress /> : dialogBodyContent}
    />
  );
}
