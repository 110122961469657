import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "lib/hooks/react-redux";
import Modal from "./Modal";
import { Level, Reward } from "lib/data/slice";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import PremiumCurrencyIcon from "lib/design-system/components/PremiumCurrencyIcon";
import RewardItem from "lib/progression/components/RewardItem";
import Clickable from "lib/audio/components/Clickable";
import GradientText from "lib/typography/components/GradientText";
import playSound from "lib/audio/sfx";
import { NewsItemTypes, setHasSeen } from "lib/welcome-news/slice";
type FatalErrorTypes = "OldTab" | "UncaughtError" | "UpdateRequired";
export interface FatalErrorModalProps {
  onClose: (ev: any) => void | (() => void);
  navTo?: string;
  title: string;
  body: string;
  type: FatalErrorTypes;
  image: string;
  expiry?: string;
}

export default function FatalErrorModal({
  onClose,
  title,
  body,
  image,
  type,
}: FatalErrorModalProps) {
  const dispatch = useAppDispatch();
  const progression = useAppSelector((state) => state.progression);
  React.useEffect(() => {
    //playSound("modal-load");
    dispatch(setHasSeen(true));
  }, []);

  return (
    <Modal
      sx={{
        backgroundImage: `url("${image}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      onClose={() => {}}
      dialogTitle={
        <Box
          sx={{
            background: "rgba( 255, 255, 255, 0.25 )",
            boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
            backdropFilter: "blur( 4px )",
            border: "1px solid rgba(255,255,255,0.4)",
            borderRadius: "20px",
            padding: "0.2em 0.6em",
          }}
        >
          <GradientText dark sx={{ fontSize: "1em" }}>
            {title}
          </GradientText>
        </Box>
      }
      dialogBodyContent={
        <Box
          sx={{
            textShadow:
              "1px 1px 1px #222, -1px -1px 1px #222, -1px 1px 1px #222, 1px -1px 1px #222",
            background: "rgba( 255, 255, 255, 0.25 )",
            boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.37 )",
            backdropFilter: "blur( 4px )",
            border: "1px solid rgba(255,255,255,0.4)",
            borderRadius: "20px",
            color: "#fafafa",
            padding: "0.2em 1.6em",
          }}
        >
          {<p>{body}</p>}
        </Box>
      }
      dialogActions={
        <Clickable>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              letterSpacing: "2px",
            }}
            size="large"
            variant="contained"
            autoFocus
            color="secondary"
          >
            Refresh
          </Button>
        </Clickable>
      }
    />
  );
}
