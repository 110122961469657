import { signIn } from "lib/data/firebase";
import { useAppSelector } from "lib/hooks/react-redux";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function GuestPage() {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const handleNav = async () => {
    if (user.uid) {
      navigate("/");
    } else {
      await signIn({ asGuest: true });
      navigate("/tutorial");
    }
  };
  React.useEffect(() => {
    handleNav();
  }, []);
  return null;
}
